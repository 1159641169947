import React, {useEffect, useRef, useState} from 'react';
import './HVACSummaryDetailsDashboard.scss';
import {Link, Outlet, useLocation, useParams} from "react-router-dom";
import BackArrow from "../../../eventList/assets/backArrow.svg";
import HVACSummaryDashboardCard from "./HVACSummaryDashboardCard";
import HVACSummaryDashboardFooter from "./HVACSummaryDashboardFooter";
import {useQuery, useQueryClient} from "react-query";
import {fetchHVACSummary} from "../../service/HVACSummaryService";
import {cancelExistingQuery} from "../../../../Utils/utils";
import LoaderStyle from "../../../../a1Components/LoaderStyle/LoaderStyle";
import DisplayError from "../../../../a1Components/DisplayError/DisplayError";
import {AssetData} from "../../types/HVACSummaryLandingDataType";
import {HVACAssetHooks} from "../../hooks/HVACAssetHooks";
import LayoutHVAC from "./Layout";
import {transformAssetsToHierarchy} from "./HelperFn";
import PageFilters from "../../../pageFilters/pageFilters";
import {FilterUtils} from "../../../assetList/utils/FilterUtils";
import AmbientTemperature from "./AmbientTemperature";
import {GROUPS_SUPPORTED_BY_PAGE_FILTERS} from "../../../../Constants/constants";

const HVACSummaryDetailsDashboard = () => {

    const {id} = useParams();

    const queryClient = useQueryClient();
    const queryKey: string = 'HVACAssetSummaryDashboard';
    const assetDetailsKey: string = 'HVACAssetSummaryAssetDashboard';

    // var minTemp = null
    // var maxTemp = null

    const [searchPayload, setSearchPayload] = useState<any>([]);
    const [pageIndex, setPageIndex] = useState<number>(1);
    const scrollableContainerRef = useRef();

    const [apiError, setApiError] = useState<any>(false);


    const responseBodyObj = ({
        "viewIndex": pageIndex,
        "viewSize": 4,
        "hvacFixedAssetTypeId": id,
        "searchBy": searchPayload
    });

    const selectedAsset = useRef<AssetData | null>(null);

    const {
        categoryAssetData,
        updateCategoryAssetData,
        initialApiCall,
        updateInitialApiCall,
    } = HVACAssetHooks();

    const {data, error, isLoading, refetch, isFetching} = useQuery(
        queryKey,
        () => fetchHVACSummary(responseBodyObj),
        {
            enabled: false,
            keepPreviousData: false,
            onSuccess: (fetchedData) => {
                if (typeof fetchedData === "object" && fetchedData.hasOwnProperty("assets")) {
                    const assetList = fetchedData?.assets;
                    const pagination = fetchedData?.pagination;

                    if (pagination && pagination?.currentPage == 1) {
                        setApiError(false)
                        updateCategoryAssetData({
                            data: assetList,
                            pagination: pagination
                        });
                        updateInitialApiCall(false);
                        selectedAsset.current = assetList[0];
                        setPageIndex(1);
                        fetchAssetDetailsData();
                        setTimeout(() => {
                            const container = scrollableContainerRef.current;
                            container?.scrollTo({
                                left: 0,
                                behavior: "smooth",
                            });
                        }, 0);
                    } else if (!pagination) {
                        setApiError(true)
                    } else {
                        updateCategoryAssetData({
                            data: [...categoryAssetData.data, ...assetList],
                            pagination: pagination
                        });
                        setTimeout(() => {
                            const container = scrollableContainerRef.current;
                            container?.scrollTo({
                                left: container.scrollWidth,
                                behavior: "smooth",
                            });
                        }, 0);
                    }
                }
            },
        }
    );


    const {
        data: assetDetailsData,
        error: assetDetailsError,
        isLoading: assetDetailsIsLoading,
        refetch: assetDetailsRefetch,
        isFetching: assetDetailsIsFetching
    } = useQuery(
        assetDetailsKey,
        () => fetchHVACSummary({"hvacAssetId": (selectedAsset?.current?.assetId == "15683" ? "15611" : selectedAsset?.current?.assetId)}),
        {
            enabled: false,
            keepPreviousData: false,
            onSuccess: (fetchedData) => {
                if (typeof fetchedData === "object" && fetchedData.hasOwnProperty("assets")) {
                    // Todo for the Asset Location SVG Path
                    console.log("HVAC :: fetchedData : ", fetchedData)
                }
            },
        });


    const fetchAssetDetailsData = async () => {
        try {
            cancelExistingQuery(assetDetailsKey, queryClient);
            await assetDetailsRefetch();
        } catch (exception) {
            console.error("Error re-fetching data:", exception);
        }
    };

    const fetchListData = async () => {
        try {
            cancelExistingQuery(queryKey, queryClient);
            await refetch();
        } catch (exception) {
            console.error("Error re-fetching data:", exception);
        }
    };

    const handleSelectedAsset = (assetId: string) => {
        const selectedAssetData = categoryAssetData.data.find((item: AssetData) => item.assetId === assetId);
        selectedAsset.current = selectedAssetData === undefined ? null : selectedAssetData;
        fetchAssetDetailsData();
    }

    useEffect(() => {
        fetchListData();
        return (() => {
            cancelExistingQuery(queryKey, queryClient);
        })
    }, [searchPayload, pageIndex]);

    const transformedData = transformAssetsToHierarchy(assetDetailsData);

    console.log("HVAC :: transformedData : ", transformedData)

    const previousStateFilterData = useRef([]);

    const updateSearchList = (filterDataArg: any) => {
        setPageIndex(1)
        const utils = new FilterUtils();
        const newSearchList = utils.updateSearchList(
            filterDataArg.current,
            ''
        );

        setSearchPayload(newSearchList)
        console.log("HVAC :: updateSearchList:", newSearchList);
    }


    const handleClearFilter = (filterDataArg: any) => {
        setPageIndex(1)
        const utils = new FilterUtils();
        const newSearchList = utils.updateSearchList(filterDataArg.current, '');
        setSearchPayload(newSearchList)
        console.log("HVAC :: clearFilter:", newSearchList);
    }

    function getMinTemp(type : string) {
        if (transformedData) {
            const minTempObj = selectedAsset?.current?.attributes?.find(
                (item: { attrName: string; }) => item?.attrName === "TEMP_MIN"
            );
            if (type === 'number') {
                return  minTempObj?.attrValue;
            } else {
                return (minTempObj?.attrValue ? (minTempObj?.attrValue + minTempObj?.UomDescription) : null)
            }
        }
        return null
    }

    function getMaxTemp(type: string) {
        if (transformedData) {
            const maxTempObj = selectedAsset?.current?.attributes?.find(
                (item: { attrName: string; }) => item?.attrName === "TEMP_MAX"
            );
                if (type === 'number') {
                    return maxTempObj?.attrValue;
                } else {
                    return (maxTempObj?.attrValue ? (maxTempObj?.attrValue + maxTempObj?.UomDescription) : null)
                }
        }
        return null
    }

    function getAmbientTemp() {
        let totalValue = 0;
        let count = 0;
        let uomDesc: string | null = null
        let pointerValue: number = -1

        if (assetDetailsData?.assets && Array.isArray(assetDetailsData?.assets)) {
            assetDetailsData?.assets.forEach(asset => {
                if (asset?.meterData && asset?.meterData?.liveMeters) {
                    asset?.meterData?.liveMeters?.forEach(meter => {
                        if (meter?.meterTypeId === "TEMP" && meter?.meterValue) {
                            console.log("HVAC :: meterValue:", meter?.meterValue);
                            if (!uomDesc) {
                                uomDesc = meter?.readingUomDesc;
                            }
                            if (pointerValue == -1) {
                                pointerValue =
                                    asset?.meterValue?.hasOwnProperty("decimalPrecision") &&
                                    asset?.meterValue?.decimalPrecision !== null
                                        ? asset?.meterValue?.decimalPrecision
                                        : 2;
                            }
                            totalValue += meter?.meterValue;
                            count++;
                        }
                    });
                }
            });
        }
        return count > 0 ? (totalValue / count).toFixed(pointerValue) + uomDesc : "";
    }


    return (
        <div className="hvacDashboardMainDiv">
            {isLoading ? (
                <div className="loadingBox1">
                    <LoaderStyle/>
                </div>
            ) : error ? (
                <div className="loadingBox1">
                    <DisplayError type="err_1"/>
                </div>
            ) : data !== undefined && typeof data === "number" ? (
                <div className="loadingBox1">
                    <DisplayError type="err-500/404"/>
                </div>
            ) : (
                <>
                    <div className="hvacDashboardHeaderMainDiv">
                        <div className="hvacDashboardHeaderDiv">
                            <Link to="/" style={{width: 'fit-content'}}>
                                <div className="goBackDivs">
                                    <img src={BackArrow}/>
                                    <p className="goBackDivTitles">Dashboard</p>
                                </div>
                            </Link>
                            <p className="componentHeadings">{typeof id === "string" && `${id.toUpperCase()} `}Summary</p>
                        </div>

                    </div>

                    <div className='hvac-filter'>
                        {/*<img src={FilterIcon}/>*/}
                        {/*<p className='filterText'>Filter</p>*/}

                        <PageFilters
                            controlPageFiltersGroups={{
                                ...GROUPS_SUPPORTED_BY_PAGE_FILTERS,
                                "Asset Type": false,
                                "Status": false,
                                "Asset Category Live": false
                            }}
                            isLoading={false}
                            showLeft={false}
                            showAddAsset={() => null}
                            previousStateFilterData={previousStateFilterData}
                            assetDisplayObj={''}
                            updateSearchList={updateSearchList}
                            handleClearFilter={handleClearFilter}/>
                    </div>
                    {apiError ? (
                    <div className="loadingBox1">
                        <DisplayError type="err-empty"/>
                    </div>
                    ) :
                    <div>
                    {/*  Summary List  */}
                    <div className='summaryListMainDiv'>
                        <div className='summaryCardListDiv' ref={scrollableContainerRef}>
                            {
                                categoryAssetData.data.map((item: AssetData, index: number) => (
                                    <HVACSummaryDashboardCard
                                        data={item}
                                        key={index}
                                        index={index}
                                        id={id}
                                        isSelected={selectedAsset?.current?.assetId === item?.assetId}
                                        handleSelectedAsset={handleSelectedAsset}
                                    />
                                ))
                            }
                        </div>

                        {(categoryAssetData?.pagination?.nextPage != categoryAssetData?.pagination?.currentPage) &&
                            <div className='summaryCardLoadMoreDiv' onClick={() => {
                                setPageIndex(pageIndex + 1)
                            }}>
                                <div>
                                    <div className="summaryCardLoadMoreArrow"> &gt; </div>
                                </div>
                                <div className='summaryCardLoadMoreText'> {categoryAssetData?.data?.length}/{categoryAssetData?.pagination?.totalCount} assets</div>
                            </div>
                        }

                    </div>


                    <div>
                        {transformedData.length > 0 &&
                            <AmbientTemperature
                                currentTemperature={getAmbientTemp()}
                                minTemperature={getMinTemp('string')}
                                maxTemperature={getMaxTemp('string')}/>
                        }
                    </div>

                    <div className='hvacLayout'>
                        {/*<LayoutHVAC/>*/}

                        {/*transformedData.ma*/}
                        {/*SORT BY IDs*/}
                        {transformedData.length > 0 &&
                            <LayoutHVAC data={transformedData} min={getMinTemp('number')} max={getMaxTemp('number')}/> //ToDo
                        }
                    </div>

                    <HVACSummaryDashboardFooter id={id} data={selectedAsset?.current}/>
                    </div>}

                </>
            )}


        </div>
    );
};


export default HVACSummaryDetailsDashboard;
