require("dotenv").config();
let urlObj = {
  qa : "https://aone-qa.app.aone.ai/api/1.0/",
  prod : "https://aone.atsuyatech.com/api/1.0/",
  stage : "https://stg-casaata.app.aone.ai/api/1.0/",
  dev: "https://waffle.app.aone.ai/api/1.0/"
}
let downloadUrlObj = {
  qa : "https://aone-qa.app.aone.ai/",
  prod : "https://aone.atsuyatech.com/",
  stage : "https://stg-casaata.app.aone.ai/",
  dev: "https://waffle.app.aone.ai/"
}
const envType = 'qa';

export const API_BASE_URL = urlObj[envType];
export const API_BASE_URL_DOWNLOAD = downloadUrlObj[envType];

//test

/* Asset Category has two ID:
1.Asset Category is for Asset Setting Filter Component.
2.Asset Category Live is for Asset List. */

export const getKeyForSelectedFilter = {
  "Asset Name": "fixedAssetName",
  "Serial Number": "serialNumber",
  "Asset Id": "fixedAssetId",
  "Asset Type": "fixedAssetTypeId",
  "Status": "statusId",
  "Facility": "locatedAtFacilityId",
  "City": "cityId",
  "Region": "regionId",
  "Cluster": "clusterId",
  // 'Last Number': 'lastNumberSearchKey',
  // 'Username': 'usernameSearchKey',
  // 'Group Name': 'groupNameSearchKey',
  "Asset Category": "fixedAssetTypeParentId",
  "Asset Category Live": "fixedAssetTypeParentId",
  // "parentTypeId",
  "Event Category": "eventTypePrntType",
  "Event Type": "parentNoteTypeId",
  "Area": "locatedAtLocationSeqId",
  "Location" : 'locationId',
  "Sub Locations":'subLocationId',
  //getuserlist keys
  "First Name":"firstName",
  "Last Name": "lastName",
  "User Name": "userName"
};

export const weekDaysData = [
  {
    key: "SUNDAY",
    name: "S",
    selected: false,
  },
  {
    key: "MONDAY",
    name: "M",
    selected: false,
  },
  {
    key: "TUESDAY",
    name: "T",
    selected: false,
  },
  {
    key: "WEDNESDAY",
    name: "W",
    selected: false,
  },
  {
    key: "THURSDAY",
    name: "T",
    selected: false,
  },
  {
    key: "FRIDAY",
    name: "F",
    selected: false,
  },
  {
    key: "SATURDAY",
    name: "S",
    selected: false,
  },
];

export const getTrendChartKey = {
  "Temperature compliance trend": "TEMP_COMP_PCT",
  "Energy Consumption Trend": "KWH",
  "Consumption (kWh)": "Wh_EB",
  "Power Factor": "POWER_FACTOR",
  "Apparent Power (kVA)": "KVA",
  "Load (kW)": "KW",
  "Reactive power (kVAR)": "KVAR",
  "Consumption (KL)": "WTR_FLOW_AGR",
  "Consumption (m3)": "AIR_FLOW_AGR",
}

export const ASSET_CATEGORY_HIERARCHY = ["Asset Category Live", "Asset Type"];
export const FACILITY_HIERARCHY = ["Region", "City", "Cluster", "Facility",'Area', 'Location', 'Sub Locations'];
export const AREA_HIERARCHY = ["Region", "City", "Cluster", "Facility", "Area"];

export const GROUPS_SUPPORTED_BY_PAGE_FILTERS = {
    // "enabled":true,
    "Status": true,
    "Asset Category Live": true,
    "Asset Type": true,
    "Region": true,
    "City": true,
    "Cluster": true,
    "Facility": true,
    'Area': true,
    'Location': true,
    'Sub Locations': true,
}
