import React, {useEffect} from 'react';
import './CoolingTowerAssetCard.scss';
import RightIcon from '../../../assets/RightIcon.svg';
import NoDataAvailableIcon from "../../../../assetList/assets/noDataAvailable.svg";
import {AssetData} from "../../../types/HVACSummaryLandingDataType";
import {navigateAssetDetailPage} from "../../../../../Utils/utils";
import {useNavigate} from "react-router-dom";
import {HVACAssetHooks} from "../../../hooks/HVACAssetHooks";
import CoolingTowerAssetCardValue from "./CoolingTowerAssetCardValue";

type Props = {
    data: AssetData;
};

const CoolingTowerAssetCard = (props: Props) => {
    const navigate = useNavigate();

    const {
        liveMeterList,
        updateLiveMeterList,
    } = HVACAssetHooks();

    useEffect(() => {
        const extractData = () => {
            if (props.data.hasOwnProperty("meterData") && props.data.meterData.hasOwnProperty("liveMeters")) {
                updateLiveMeterList(props.data.meterData.liveMeters);
            } else {
                updateLiveMeterList(null);
            }
        }
        extractData();
    }, [props.data]);

    return (
        <div className='coolingTowerAssetCardMainDiv'
             onClick={() => navigateAssetDetailPage(props.data.assetId, navigate)}>
            <div className='coolingTowerAssetCardHeaderDiv'>
                <p className='coolingTowerAssetName'>{props.data.assetName}</p>
                <img src={RightIcon} alt='Icon'/>
            </div>

            {
                liveMeterList === null ? (
                    <div className='noDataAvailableDiv'>
                        <img src={NoDataAvailableIcon} alt='No Data Available'/>
                        <p className='noDataAvailableText'>Data unavailable at the moment</p>
                    </div>
                ) : (
                    <div className='coolingTowerBodyMainDiv'>
                        {
                            [1,2,3].map((item, index) => (
                                <CoolingTowerAssetCardValue item={item} key={index} data={liveMeterList} />
                            ))
                        }
                    </div>
                )
            }
        </div>
    );
};

export default CoolingTowerAssetCard;
