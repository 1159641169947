import { faAngleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import * as R from "ramda";
import { useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import { useLocation, useNavigate } from "react-router-dom";
import useState from "react-usestateref";
import styled from "styled-components";
import useGetPortletByPageId from "../../Hooks/useGetPortletByPageId";
import AlertChip from "../../a1Components/AlertChips/AlertChip";
import DisplayError from "../../a1Components/DisplayError/DisplayError";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import DownloadPopup from "../../a1Components/ModelPopupcomp/DownloadPopup/DownloadPopup";
import SummaryParentCard from "../../a1Components/SummaryCards/SummaryParentCard";
import AssetInfo from "../../a1Components/assetDetailInfo/AssetInfo";
import StackedLineChart from "../../a1Components/lineChart";
import AssetDetailsIndex from "../../a1Components/tab/AssetDetailsIndex";
import activityalertsicon from "../../assets/icons/activity-alerts-icon.svg";
import activityrepeatedalertsicon from "../../assets/icons/activity-repeated-alerts-icon.svg";
import activitywatchlisticon from "../../assets/icons/activity-watchlist-icon.svg";
import downloadicon from "../../assets/icons/download-icon.svg";
import { useStateValue } from "../../redux/StateProvider";
import "./AssetDetailPage.scss";
import airControllerICon from "../../assets/icons/air-controller-asset.svg";
import Lottie from "react-lottie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_BASE_URL, getTrendChartKey } from "../../Constants/constants";
import ADAirQuality from "../../a1Components/AssetDetailLive/ADAirQualityLive/ADAirQuality";
import ADAir from "../../a1Components/AssetDetailLive/ASAirLive/ADAir";
import ASDG from "../../a1Components/AssetDetailLive/ASDGLive/ASDG";
import ADEnergy from "../../a1Components/AssetDetailLive/ASEnergyLive/ADEnergy";
import ADTempEnergy from "../../a1Components/AssetDetailLive/ASTempEnergyLive/ADTempEnergy";
import ADTemp from "../../a1Components/AssetDetailLive/ASTempLive/ADTemp";
import ADWater from "../../a1Components/AssetDetailLive/ASWaterLive/ADWater";
import TrendBarChart from "./charts/TrendBarChart";
import TrendLineChart from "./charts/TrendLineChart";
import Switch from "../../a1Components/FormElements/switch";
import AssetEventLog from "../../a1Components/assetEventLog/AssetEventLog";
import ADAirConsumption from "../../a1Components/consumptionCardsAD/ADAirConsumption/ADAirConsumption";
import ADDGConsuption from "../../a1Components/consumptionCardsAD/ADDGConsuption/ADDGConsuption";
import ADEnergyConsuption from "../../a1Components/consumptionCardsAD/ADEnergyConsuption/ADEnergyConsuption";
import ADWaterConsumption from "../../a1Components/consumptionCardsAD/ADWaterConsumption/ADWaterConsumption";
import CustomSelect from "../../a1Components/tab/customSelector/CustomSelect";
import blink from "../../assets/Lotties/whiteblink.json";
import AirAssetIcon from "../../assets/icons/AirAssetIcon.svg";
import ArrowDownBtn from "../../assets/icons/ArrowDownBtn.svg";
import CloseButton from "../../assets/icons/CloseButton.svg";
import alerts from "../../assets/icons/activitytab-alerts.svg";
import tickets from "../../assets/icons/activitytab-tickets.svg";
import watchList from "../../assets/icons/activitytab-watchlist.svg";
import FilterArrowIcon from "../../assets/icons/filterDownArrowIcon.svg";
import fuelAsseticon from "../../assets/icons/fuelAsseticon.svg";
import waterPulseIcon from "../../assets/icons/WaterPulseIcon.svg";
import pressureMonitor from "../../assets/icons/icon-assets-pressure.svg";
import alertsImg from "../../assets/icons/icon-alert.svg";
import iconairquality from "../../assets/icons/iconairquality.svg";
import dgicon from "../../assets/image/dg-icon.png";
import energyAssetIcon from "../../assets/image/energy-asset-icon.svg";
import energytempicon from "../../assets/image/energy-temp-icon.png";
import groupBar from "../../assets/image/group-bar.png";
import lineChartImg from "../../assets/image/line-chart.png";
import temperatureIcon from "../../assets/image/temperature-icon.svg";
import iconCompressor from "../../assets/icons/Asset-Icon-compressor.svg";
import waterAsseticon from "../../assets/image/water-asset-icon.svg";
import TempControllerIcon from "../../assets/icons/TempControllerIcon.svg";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import TempControllerLive from "../../a1Components/AssetDetailLive/ASTempController/TempControllerLive";
import CompressorLiveCard from "../../a1Components/consumptionCardsAD/CompressorLiveCard/CompressorLiveCard";
import PressureLive from "../../a1Components/AssetDetailLive/ASpressureLive/PressureLive";
import {
  subtractWholeNumber,
  getCheckNullValue,
  hexToRgb,
  isUndefined,
} from "../../Utils/utils";
import ADEnergyDemandMetrics from "../../a1Components/consumptionCardsAD/ADEnergyConsuption/ADEnergyDemandMetrics";
import TrendDataBlackIcon from "../../assets/image/trendDataIcon.svg";
import LiveDataWhiteIcon from "../../assets/image/liveDataIcon.svg";
import TrendDataWhiteIcon from "../../assets/image/trendDataIconWhite.svg";
import LiveDataBlackIcon from "../../assets/image/liveDataIconBlack.svg";
import ListDownIcon from "../../assets/icons/listDownIcon.svg";
import SelectedBarChartIcon from "./assets/selectedBarChartIcon.svg";
import UnSelectedBarChartIcon from "./assets/unselectedBarChartIcon.svg";
import SelectedLineChartIcon from "./assets/selectedLineChartIcon.svg";
import UnselectedLineChartIcon from "./assets/unselectedLineChartIcon.svg";
import { Asset } from "../Assets/types";
import TrendChartUtils from "./utils/TrendChartUtils";
import { TrendChartDataType, YAsix } from "./types/TrendChartDataType";
import AirControllerLiveCard from "../../a1Components/AssetDetailLive/AirControllerLiveCard/AirControllerLiveCard";
//test commit
import AcControllerIcon from "../../../src/features/acController/assets/acControllerIcon.svg";
import AcControllerCard from "../../features/acController/components/AcControllerCard";
import { Button, Offcanvas } from "react-bootstrap";
import ReactTooltip from "react-tooltip";
import ADWaterPulse from "../../a1Components/AssetDetailLive/ADWaterPulse/ADWaterPulse";
import { NewAssetData } from "../../features/assetList/types";
import LocalStorageService from "../../Utils/LocalStorageService";
import { assetImageList } from "../../features/assetList/utils";
import { FacilityDataType } from "./types";
import React from "react";
import AssetDetailChildComp from "./updatedAssetDetails/AssetDetailChildComp";
import CustomToast from "../../a1Components/ModelPopupcomp/CustomToast/CustomToast";

const Tab: any = styled.button`
  font-size: 13px;
  padding: 0px 0px;
  cursor: pointer;
  // opacity: 0.6;
  background: #f2f5f7;
  border: 0;
  outline: 0;
  ${({ active }: any) =>
    active &&
    `
    border: 1px solid #D8CECE;
    opacity: 1;
    background:white !important;
  `}
`;

const ButtonGroup: any = styled.div`
  display: flex;
  textalign: left;
`;

export type AssetLocationType = {
  latitude: number | null;
  longitude: number | null;
}

function AssetDetailPage({ isDark, rightOpen, toggleBtn }: any) {
  const [assetDetails, setAssetDetails] = useState<any>({});
  const [facilityData, setFacilityData] = useState<FacilityDataType>({
    facilityId: "",
    parentFacilityGroupType: "",
    facilityName: "",
    parentFacilityGroupId: "",
  });
  const [{ dateRange, currAssetId, dateChip }, dispatch] = useStateValue();
  const [portlets] = useGetPortletByPageId("ASSET_DTL_PG");
  const [lineData, setLineData, lineDataRef] = useState<object | any>([]);
  // for the  model popup please don't remove when merge
  const [assetSummaryNew, setAssetSummaryNew] = useState<any>([]);
  const [tendRepoData, setTendRepoData] = useState<any>({});
  const [assetTypeDesc, setAssetTypeDesc] = useState("");
  const [assetData, setAssetData] = useState<Asset>({
    productModel: "",
    purchaseDate: null,
    productBrand: "",
    fixedAssetAssocWithId: "",
    temp_max: {
      abbreviation: "",
      value: "",
    },
    actualEndOfLife: null,
    productName: "",
    meters: [],
    locatedAtFacility: {
      facilityId: "",
      facilityName: "",
    },
    temp_min: {
      abbreviation: "",
      value: "",
    },
    productTypeDesc: "",
    monthlyMeters: [],
    ownerName: "",
    assetTypeDesc: "",
    productYear: "",
    assetId: "",
    assetEnumId: "",
    fixedAssetAssocTypeId: "",
    fixedAssetAssocWithTypeDesc: "",
    fixedAssetAssocStatusId: "",
    productType: "",
    ratedPower: {
      defaultUom: "",
      value: "",
    },
    fixedAssetAssocWithName: "",
    fixedAssetAssocTypeDesc: "",
    serialNumber: null,
    statusDesc: "",
    index: 0,
    productMake: "",
    classEnumDesc: "",
    assetType: "",
    productDesc: null,
    ownerPartyId: "",
    firstIndex: 0,
    dailyMeters: [],
    healthStatus: "",
    fixedAssetAssocWithTypeId: "",
    assetName: "",
    Alerts: [],
    expectedEndOfLife: null,
    instanceOfProductId: "",
    status: "",
    lastIndex: 0,
  });

  const [trendData, setTrendData] = useState<any>([]);
  const [trendLoad, setTrendLoad] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const [assetLocation, setAssetLocation] = useState<AssetLocationType>({
    latitude: null,
    longitude: null,
  });

  const [liveData, setLiveData] = useState<any>();

  const [arrayData, setArrayData] = useState<any>({});

  const [lodeState, setLodeState] = useState(false);

  const [btnState, setBtnState] = useState(false);

  const [timeoutVal, setTimeoutVal, timeoutValRef] = useState<any>(null);

  const [hoveredLegend, setHoveredLegend] = useState(null);
  const isHovered = (value) => {
    return hoveredLegend === value;
  };

  const [xData, setXdata, xDataRef] = useState<any>([]);
  const [summaryLoader, setSummaryLoader] = useState(true);
  const [legend, setLegend, legendRef] = useState<any>([
    "Temperature (C)",
    "Load",
    "PF",
    "Current (A)",
    "Frequency (Hz)",
  ]);

  const [selectedGroupChart, setSelectedGroupChart] = useState("");
  const [chartGroupData, setChartGroupData] = useState<string[]>([]);
  const [showDropDown, setShowDropDown] = useState(false);

  const [selectedEnergyTrendValue, setSelectedEnergyTrendValue] = useState<any>(
    []
  );
  const [selectedTrend, setSelectedTrend] = useState<string[]>([
    "Temperature compliance trend",
  ]);
  const [selectedTrendRange, setSelectedTrendRange] = useState<string>("");
  const [trendRange, setTrendRange] = useState<string[]>([]);
  const [zoomLevel, setZoomLevel] = useState<any>(90);
  const tList: any = ["Summary", "Activity", "Gallery", "Info", "Event Log"];
  const tablist: any = tList[0];
  //download popup end
  // for the  model popup please don't remove when merge
  const [popupState, setPopupState] = useState(false);
  const [showDrop, setShowDrop] = useState(false);
  const [active, setActive] = useState<any>("Activity");
  const [tabLabel, setTabLabel] = useState<any>([]);
  const [dataVal, setdataVal] = useState<any>();
  const [activityLoader, setActivityLoader] = useState(false);
  const [eventLoader, setEventLoader] = useState(false);

  const [openClose, setOpenClose] = useState(false);

  const [closeOpenBtn, setCloseOpenBtn] = useState(false);

  const [eventLogData, setEventLogData] = useState<any>([]);

  const [lineChart, setLineChart] = useState(false);
  const [stateVal, setStateVal] = useState(1);

  const dropdownRef = useRef(null);
  const [dropdownWidth, setDropdownWidth] = useState(0);

  const [zoomSliderData, setZoomSliderData] = useState({
    start: 0,
    end: 100,
  });

  const [trendArrList, setTrendArrList] = useState<string[]>([]);
  const [meterTypeIdsList, setMeterTypeIdsList] = useState<string[]>([]);
  const [trendChartData, setTrendChartData, trendChartDataRef] = useState<
    string | number | TrendChartDataType
  >({
    xAsix: [],
    yAsix: [],
  });
  const [
    filterTrendChartData,
    setFilterTrendChartData,
    filterTrendChartDataRef,
  ] = useState<YAsix[]>([]);

  useEffect(() => {
    if (dropdownRef.current) {
      const width = dropdownRef.current.offsetWidth;
      setDropdownWidth(width);
    }
  }, [dropdownRef.current, trendArrList, filterTrendChartDataRef.current]);

  const warningPopup = () => {
    setOpenClose(false);
  };
  const showError = () => {
    setOpenClose(true);
  };
  function handleActive(type: any) {
    setActive(type);
  }
  //download  popup//
  const openModalHandler = () => {
    // if (!R.isEmpty(arrayData) && !R.isNil(arrayData)) {
    setPopupState(true);
    // }
  };

  const closeModalHandler = () => {
    setPopupState(false);
  };

  const [repoLiveData, setRepoLiveData] = useState<any>({});
  const [selectedLegend, setSelectedLegend, selectedLegendRef] = useState([
    "Temperature (C)",
  ]);
  const [kiloWattToWatt, setKiloWattToWatt] = useState({
    showDropDown: false,
    ActiveValue: "kW",
    showList: false,
  });

  useEffect(() => {
    let list: any = [];
    for (let item of selectedLegend) {
      if (item === "Temperature (C)") list.push(repoLiveData.temp);
      if (item === "Load")
        list.push(
          kiloWattToWatt.ActiveValue === "kW"
            ? repoLiveData.kw
            : repoLiveData.kw.map((el: any) => +el * 1000)
        );
      if (item === "PF") list.push(repoLiveData.pf);
      if (item === "Current (A)") list.push(repoLiveData.Current);
      if (item === "Frequency (Hz)") list.push(repoLiveData.freq);
    }
    setLineData(list);
  }, [kiloWattToWatt.ActiveValue]);

  const updateLineData = () => {
    if (assetData.fixedAssetAssocWithTypeId === "TEMP_ENERGY") {
      let list: any = [];
      for (let item of selectedLegend) {
        if (item === "Temperature (C)") list.push(repoLiveData.temp);
        if (item === "Load")
          list.push(
            kiloWattToWatt.ActiveValue === "kW"
              ? repoLiveData.kw
              : repoLiveData.kw.map((el: any) => +el * 1000)
          );
        if (item === "PF") list.push(repoLiveData.pf);
        if (item === "Current (A)") list.push(repoLiveData.Current);
        if (item === "Frequency (Hz)") list.push(repoLiveData.freq);
      }
      setLineData(list);
    } else if (assetData.fixedAssetAssocWithTypeId === "ENERGY") {
      let list: any = [];

      for (let item of selectedLegend) {
        if (item === "Load (kW)") list.push(repoLiveData.kw);
        if (item === "Power Factor") list.push(repoLiveData.pf);
        if (item === "Actual Demand (kVA)") list.push(repoLiveData.kva);
        if (item === "Frequency (Hz)") list.push(repoLiveData.freq);
        if (item === "R-Amp") list.push(repoLiveData.ra);
        if (item === "Y-Amp") list.push(repoLiveData.ya);
        if (item === "B-Amp") list.push(repoLiveData.ba);
        if (item === "R-Volt") list.push(repoLiveData.rv);
        if (item === "Y-Volt") list.push(repoLiveData.yv);
        if (item === "B-Volt") list.push(repoLiveData.bv);
        if (item === "R-Y Volt") list.push(repoLiveData.ry);
        if (item === "Y-B Volt") list.push(repoLiveData.yb);
        if (item === "B-R Volt") list.push(repoLiveData.br);
        if (item === "kVAR") list.push(repoLiveData.kvar);
        if (item === "MD") list.push(repoLiveData.md);
        if (item === "PD") list.push(repoLiveData.pd);
        if (item === "RD") list.push(repoLiveData.rd);
        if (item === "THD-RV") list.push(repoLiveData.thd_rv);
        if (item === "THD-YV") list.push(repoLiveData.thd_yv);
        if (item === "THD-BV") list.push(repoLiveData.thd_bv);
        if (item === "THD-RA") list.push(repoLiveData.thd_ra);
        if (item === "THD-YA") list.push(repoLiveData.thd_ya);
        if (item === "THD-BA") list.push(repoLiveData.thd_ba);
      }
      setLineData(list);
    } else if (assetData.fixedAssetAssocWithTypeId === "COMPRESSOR") {
      let list: any = [];
      for (let item of selectedLegend) {
        if (item === "Suction Temp 1") list.push(repoLiveData.sucPb1);
        if (item === "Suction Temp 2") list.push(repoLiveData.sucPb2);
        if (item === "Condensation Temp 1") list.push(repoLiveData.condPb1);
        if (item === "Condensation Temp 2") list.push(repoLiveData.condPb2);
      }
      setLineData(list);
    } else if (assetData.fixedAssetAssocWithTypeId === "IAQ") {
      let list: any = [];
      for (let item of selectedLegend) {
        if (item === "Temperature") list.push(repoLiveData.temp);
        if (item === "Humidity") list.push(repoLiveData.hum);
        if (item === "CO2") list.push(repoLiveData.co2);
        if (item === "PM1.0") list.push(repoLiveData.pm1_0);
        if (item === "PM2.5") list.push(repoLiveData.pm2_5);
        if (item === "PM10") list.push(repoLiveData.pm10);
        if (item === "Total volatile organic compound")
          list.push(repoLiveData.tvoc);
        if (item === "Formaldehyde") list.push(repoLiveData.hcho);
      }
      setLineData(list);
    } else if (assetData.fixedAssetAssocWithTypeId === "TEMPCONTROLLER") {
      let list: any = [];
      for (let item of selectedLegend) {
        if (item === "Supply Temp 1") list.push(repoLiveData.sp1);
        if (item === "Supply Temp 2") list.push(repoLiveData.sp2);
        if (item === "Return Air Temp 1") list.push(repoLiveData.rat1);
        if (item === "Return Air Temp 2") list.push(repoLiveData.rat2);
      }
      setLineData(list);
    } else if (assetData.fixedAssetAssocWithTypeId === "AC_CONTROLLER") {
      let list: any = [];
      for (let item of selectedLegend) {
        if (item === "Temperature") list.push(repoLiveData.temp);
        if (item === "Current") list.push(repoLiveData.current);
      }
      setLineData(list);
    } else if (assetData.fixedAssetAssocWithTypeId === "WATER_PULSE") {
      let list: any = [];
      for (let item of selectedLegend) {
        if (item === "Consumption (L)") list.push(repoLiveData.temp);
      }
      setLineData(list);
    }
  };

  function clickLegend(item: any) {
    if (repoLiveData.typeDev === "TEMP_ENERGY" && item === "Load") {
      setKiloWattToWatt((element) => {
        return {
          ...element,
          showDropDown: !element.showDropDown,
          ActiveValue: "kW",
        };
      });
    }
    if (selectedLegend.includes(item)) {
      let selected = [...selectedLegend.filter((el: any) => el !== item)];
      //if(selected.length > 1 ) selected.shift();
      setSelectedLegend(selected);
    } else {
      let selected = [...selectedLegend, item];
      //if(selected.length > 1 ) selected.shift();
      setSelectedLegend(selected);
    }
  }

  function clickEnergyTrend(item: any) {
    //let selected: any = [...selectedEnergyTrendValue, item];
    let selected = [
      ...selectedEnergyTrendValue.filter((el: any) => el !== item),
    ];
    setSelectedEnergyTrendValue(selected);
    if (selectedEnergyTrendValue.includes(item)) {
      let filterSel = selected.filter((sl: any) => sl !== item);
      setSelectedEnergyTrendValue(filterSel);
    }
  }

  const filterTrendDataSet = () => {
    if (
      trendChartData === "error" ||
      typeof trendChartData === "string" ||
      typeof trendChartData === "number"
    ) {
    } else {
      setBtnState(false);
      let result: YAsix[] = [];
      selectedTrend.forEach((item: string) => {
        const key = getTrendChartKey[item];
        const temp = trendChartData.yAsix.find((ele) => ele.key === key);
        if (temp !== undefined) {
          result.push(temp);
        }
      });
      setFilterTrendChartData(result);
      setBtnState(true);
    }
  };

  useEffect(() => {
    filterTrendDataSet();
  }, [selectedTrend, trendChartData]);

  function clickTrend(item: string) {
    let selected = [];
    if (selectedTrend.includes(item)) {
      selected = [...selectedTrend.filter((el) => el !== item)];
    } else {
      selected = [...selectedTrend, item];
    }
    setSelectedTrend(selected);
  }

  async function asset_Summary_new(fromDateStr: any, toDateStr: any) {
    setSummaryLoader(true);
    const fetchDB = new DashBoardServices();
    let fetchData = await fetchDB.asset_Summary_new(fromDateStr, toDateStr);
    if (fetchData === "error") {
      setAssetSummaryNew(fetchData);
    } else if (typeof fetchData === "number") {
      setAssetSummaryNew(fetchData);
    } else {
      let data = await fetchData?.summary;
      if (R.isEmpty(data) || R.isNil(fetchData?.eventSummary)) {
        setAssetSummaryNew([]);
      } else {
        setAssetSummaryNew([...data, fetchData?.eventSummary]);
      }
    }
    setSummaryLoader(false);
  }

  const functionDateDiff = (date1: any, date2: any) => {
    const timeDiff = date2 - date1;
    const oneDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
    const differenceInDays = Math.round(timeDiff / oneDay);
    return differenceInDays;
  };

  async function fetchTrendData(fromDateStr: any, toDateStr: any) {
    //setSummaryLoader(true);
    try {
      const url = `${API_BASE_URL}assets/summary`;
      const token_id: any = localStorage.getItem("@tokenID");
      let myAsset: any = localStorage.getItem("myassets");
      const deviceId = localStorage.getItem("assetId");
      const word = selectedTrendRange.toLocaleLowerCase();
      const filterFlag = word.charAt(0).toUpperCase() + word.slice(1);
      const resp = await fetch(url, {
        method: "POST",
        body: JSON.stringify({
          fixedAssetId: deviceId,
          fromDate: fromDateStr,
          thruDate: toDateStr,
          token: token_id,
          filterFlag: filterFlag,
        }),
        headers: { "Content-Type": "application/json" },
      });
      if (resp.status === 200) {
        return await resp.json();
      } else {
        return resp.status;
      }
    } catch {
      return "error";
    }
  }

  const getAssetById = async () => {
    let facilityData: FacilityDataType =
      LocalStorageService.getSingleAssetCardDetailsData();
    setFacilityData(facilityData);
  };

  let myArr: {
    date: any;
    time: any;
    temp: any;
    kw: any;
    pf: any;
    current: any;
    tw: any;
    tva: any;
    freq: any;
    pfy: any;
    fuellvl: any;
    runhr: any;
    runhr_dg: any;
    fuellvlData_Dg: any;
  }[] = [];

  const [liveRepoStatus, setLiveRepoStatus, liveRepoStatusRef] = useState<
    string | object | number
  >([]);
  const [trendRepoStatus, setTrendRepoStatus, trendRepoStatusRef] =
    useState<any>();

  const [liveDataLoader, setLiveDataLoader] = useState(false);
  const [heraderList, setHeaderList] = useState({
    alert: 0,
    watchList: 0,
  });


  //variable for initial api call
  const [dateUpdate, setDateUpdate] = useState("Today");

  useEffect(() => {
    if (dateUpdate !== dateChip) {
      setDateUpdate(dateChip);
    }
  }, [dateRange]);

  const getDataSet = async (fromDateStr: any, id: any, endDateStr: any) => {
    const DBservice = new DashBoardServices();
    const deviceId = localStorage.getItem("assetId");
    const fetchData = await DBservice.getActivityCenter(
      fromDateStr,
      1,
      endDateStr,
      deviceId
    );
    if (fetchData === "error") {
      setdataVal(fetchData);
    } else if (typeof fetchData === "number") {
      setdataVal(fetchData);
    } else if (R.isEmpty(fetchData)) {
      setdataVal({});
      setTabLabel({});
    } else if (
      fetchData?.alerts === undefined ||
      fetchData?.watchList === undefined ||
      fetchData?.tickets === undefined
    ) {
      setdataVal("error");
    } else {
      const newJsonSet = [
        {
          name: "All",
          id: 1,
          img: null,
          count:
            (fetchData?.alerts?.count ?? 0) +
            (fetchData?.watchList?.count ?? 0) +
            (fetchData?.tickets?.count ?? 0),
          value: {
            data: [
              ...(fetchData?.alerts?.data ?? []),
              ...(fetchData?.watchList?.data ?? []),
              ...(fetchData?.tickets?.data ?? []),
            ],
          },
        },
        {
          name: "WatchList",
          id: 2,
          img: watchList,
          count: fetchData?.watchList?.count ?? 0,
          value: {
            data: [...(fetchData?.watchList?.data ?? [])],
          },
        },
        {
          name: "Alerts",
          id: 3,
          img: alerts,
          count: fetchData?.alerts?.count ?? 0,
          value: {
            data: [...(fetchData?.alerts?.data ?? [])],
          },
        },
        {
          name: "Tickets",
          img: tickets,
          id: 4,
          count: fetchData?.tickets?.count ?? 0,
          value: {
            data: [...(fetchData?.tickets?.data ?? [])],
          },
        },
      ];
      const newSortedArr = R.sortBy(R.prop("id"), newJsonSet);
      setTabLabel(newSortedArr);
      setHeaderList({
        alert: fetchData?.alerts?.count ?? 0,
        watchList: fetchData?.watchList?.count ?? 0,
      });
      setdataVal(fetchData);
    }
    setActivityLoader(false);
  };

  const [liveButtonStatus, setLiveButtonStatus] = useState(false);

  const getTimeCheck = (element: any) => {
    let timeCheck = new Date(element).getTime();
    let currentTime = new Date().getTime() - 20 * 60 * 1000;
    if (timeCheck < currentTime) {
      setLiveButtonStatus(false);
    } else {
      setLiveButtonStatus(true);
    }
  };

  const [getAssetFetchStatus, setGetAssetFetchStatus] = useState(false);
  //function for get specific asset api
  const getAssetApiFetch = async () => {
    setGetAssetFetchStatus(false);
    let fetchDB = new DashBoardServices();
    let getData = await fetchDB.getAssetApiFetch();
    if (getData === "error") {
      setAssetDetails(getData);
    } else if (typeof getData === "number") {
      setAssetDetails(getData);
    } else {
      let data = await getData?.assets[0];
      if(data === undefined || data === null) {
        setAssetDetails("error");
        setGetAssetFetchStatus(false);
        return;
      }
      let array = [...data?.meters];
      let tempObj = array.find(
        (el: any) =>
          el.meterTypeId === "TEMP" ||
          el.meterTypeId === "TW" ||
          el.meterTypeId === "F_VOLUME" ||
          el.meterTypeId === "F_MASS" ||
          el.meterTypeId === "WATER_VOLUME"
      );
      if (tempObj === undefined || tempObj === null) {
      } else {
        getTimeCheck(tempObj?.readingDate);
      }
      setAssetDetails(data);
    }
    setGetAssetFetchStatus(false);
  };

  const eventLogOnLandingFetch = async (fromDate: any, endDate: any) => {
    setEventLoader(true);
    let fetchDB = new DashBoardServices();
    let dataFetch = await fetchDB.eventLogOnLandingFetch(fromDate, endDate);
    if (typeof dataFetch === "object") {
      let data = await dataFetch?.eventLog;
      if (isUndefined(data)) {
        setEventLogData([]);
      } else {
        setEventLogData(data);
      }
    } else {
      setEventLogData(dataFetch);
    }
    setEventLoader(false);
  };

  useEffect(() => {
    let alertsStatus = localStorage.getItem("alertStatus");
    if (alertsStatus === null || alertsStatus === undefined) {
      //setActive("Summary");
    } else {
      setActive("Activity");
    }
    setLegend([{ value: "Temperature", color: "#FCD670" }]);
    setSelectedLegend(["Temperature"]);
    setKiloWattToWatt({
      showDropDown: false,
      ActiveValue: "kW",
      showList: false,
    });
    return () => localStorage.removeItem("alertStatus");
  }, []);

  //useEffect for the asset api to get specific asset data
  useEffect(() => {
    const fromDate = moment(dateRange[0]).startOf("day").toISOString();
    const endDate = moment(dateRange[1]).endOf("day").toISOString();
    const fromDateStr = moment(dateRange[0])
      .startOf("day")
      .format("YYYY-MM-DD HH:mm:ss");
    const toDateStr = moment(dateRange[1])
      .endOf("day")
      .format("YYYY-MM-DD HH:mm:ss");

    //code for Date Difference
    const date1 = moment(fromDate).valueOf();
    const date2 = moment(endDate).valueOf();

    eventLogOnLandingFetch(fromDateStr, toDateStr);
    getAssetApiFetch();
    asset_Summary_new(fromDateStr, toDateStr);
    setActivityLoader(true);
    getDataSet(moment(fromDate).valueOf(), 1, moment(endDate).valueOf());
    setKiloWattToWatt({
      showDropDown: false,
      ActiveValue: "kW",
      showList: false,
    });
  }, [dateUpdate]);

  const [graphDatas, setGraphDatas] = useState<any>({
    xData: [],
    tempData: [],
    energyData: [],
  });

  useEffect(() => {
    setLodeState(true);
    dispatch({
      type: "PATH_NAME",
      pathName: location.pathname,
    });
    getAssetById();
      }, [dateUpdate]);

  const loadState = () => {
    const fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
    const endDateStr = moment(dateRange[1]).endOf("day").toISOString();
    setActivityLoader(true);
    getDataSet(moment(fromDateStr).valueOf(), 1, moment(endDateStr).valueOf());
  };

  const chartSwitch = () => {
    setLineChart(!lineChart);
  };

  const [isShown, setIsShown] = useState(false);
  const activeAlert = useRef<any>({});
  const [otherReason, setOtherReason] = useState("");
  const [selectReason, setSelectReason] = useState("");
  const [closeAlertStatus, setCloseAlertStatus] = useState(false);
  const [permissionForUpdate, setPermissionForUpdate] = useState(false);

  const activeAlertUpdateFun = (ele: any) => {
    activeAlert.current = ele;
    setIsShown(true);
  };

  const [filterReason, setFilterReason] = useState("");

  const updateReason = (ele: any) => {
    if (ele === "") {
      setFilterReason("");
      setSelectReason("");
      setEmptyCheckStatus(false);
    } else {
      let idValue = reasonsList.find((obj: any) => obj.enumId === ele);
      setFilterReason(idValue.description);
      setSelectReason(ele);
      setEmptyCheckStatus(false);
    }
  };

  const getAlertReasons = async () => {
    const fetchDB = new DashBoardServices();
    const reasonsListData = await fetchDB.getAlertReasons();
    if (reasonsListData === "error") {
      setReasonsList([]);
    } else if (typeof reasonsListData === "number") {
      setReasonsList([]);
    } else {
      let data = await reasonsListData.enumerationList;
      setReasonsList(data);
    }
  };

  const getCloseAlert = async () => {
    setCloseAlertStatus(true);
    let message = "";
    const fetchDB = new DashBoardServices();
    let reason = selectReason === "OTHERS" ? otherReason : selectReason;
    const reasonsListData: any = await fetchDB.getCloseAlert(
      activeAlert.current.alertId,
      reason
    );
    if (reasonsListData === "error") {
      message = "Failed Try Again";
    } else {
      switch (reasonsListData.status) {
        case 200:
          let data = await reasonsListData.json();
          message = await data.responseMessage;
          setIsShown(false);
          loadState();
          break;

        case 412:
          let data_412 = await reasonsListData.json();
          message = await data_412.errorMessage;
          loadState();
          setIsShown(false);
          break;

        case 413:
          let data_413 = await reasonsListData.json();
          message = await data_413.errorMessage;
          break;

        case 419:
          let data_419 = await reasonsListData.json();
          message = await data_419.errorMessage;
          break;

        case 420:
          let data_420 = await reasonsListData.json();
          message = await data_420.errorMessage;
          setIsShown(false);
          break;

        case 204:
          let data_204 = await reasonsListData.json();
          message = await data_204.errorMessage;
          setIsShown(false);
          break;

        case 500:
          let data_500 = await reasonsListData.json();
          message = await data_500.errorMessage;
          break;

        case 400:
          let data_400 = await reasonsListData.json();
          message = await data_400.errorMessage;
          break;

        default:
          message = "error";
      }
    }
    setCloseAlertStatus(false);
    setToastState(true);
    setTimeout(() => {
      setToastState(false);
    }, 3000);
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000, // Close the toast after 5 seconds
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
    });
  };

  const [dropdownStatus, setDropdownStatus] = useState(false);
  const [reasonsList, setReasonsList] = useState<any>([]);
  const [toastState, setToastState] = useState(false);
  const [emptyCheckStatus, setEmptyCheckStatus] = useState(false);

  const [demandShow, setDemandShow] = useState(true);

  const updateMessage = (newMessage: any) => {
    setDemandShow(newMessage);
  };

  const closeAlertFun = async () => {
    setDropdownStatus(true);
    if (dropdownStatus && !toastState) {
      if (selectReason === "OTHERS") {
        if (otherReason.trim() === "") {
          setEmptyCheckStatus(true);
        } else {
          setEmptyCheckStatus(false);
          getCloseAlert();
        }
      } else if (selectReason.trim() === "") {
        setEmptyCheckStatus(true);
      } else {
        setEmptyCheckStatus(false);
        getCloseAlert();
      }
    }
  };

  useEffect(() => {
    getAlertReasons();
    let permission: any = localStorage.getItem("permissions");
    let permissionsList: any = JSON.parse(permission);
    if (permissionsList === null || permissionsList === undefined) {
    } else {
      const permissionIds = [];

      for (const item of permissionsList) {
        if (Array.isArray(item.permissionId)) {
          permissionIds.push(...item.permissionId);
        }
      }
      if (permissionIds === undefined || permissionIds === null) {
      } else {
        let list = permissionIds.includes("ALERT_UPDATE");

        setPermissionForUpdate(list);
      }
    }

    let myAsset: any = localStorage.getItem("selectedAsset");
    const assetDetailJson: NewAssetData = JSON.parse(myAsset);
    let assetName = assetDetailJson.assetName;
    assetName = assetName.toString().trim();
    const currentPath = window.location.pathname;
    let decodedLastPath = decodeURIComponent(
      currentPath.substring(currentPath.lastIndexOf("/") + 1)
    );
    decodedLastPath = decodedLastPath.toString().trim();
    if (assetName !== decodedLastPath) {
      navigate(`/ErrorPage`);
    }
  }, []);

  useEffect(() => {
    if (!isShown) {
      setDropdownStatus(false);
      setEmptyCheckStatus(false);
      setCloseAlertStatus(false);
    }
  }, [isShown]);

  const checkDate = (dateToCheck: any) => {
    const now = moment(); // Get the current moment
    const fifteenMinutesAgo = now.clone().subtract(15, "minutes"); // 15 minutes ago

    const momentDateToCheck = moment(dateToCheck); // Convert the input date to a moment

    // Compare if the date is less than 15 minutes ago
    const isLessThan15MinutesAgo = momentDateToCheck.isAfter(fifteenMinutesAgo);

    return isLessThan15MinutesAgo;
  };

  const defaulterrorLottie = {
    loop: true,
    autoplay: true,
    animationData: blink,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  /**
   * Check the Asset contains the Trend Chart or not.
   */
  const trendAsssetTypeCheck = (): boolean => {
    const trendAsssetTypeList = ["TEMP_ENERGY", "TEMP", "ENERGY", "AIR", "WATER"];
    return trendAsssetTypeList.includes(assetData.fixedAssetAssocWithTypeId);
  };

  /**
   * Set Legend for the Trend Chart.
   */
  const setLegendTrendChart = () => {
    if (assetData.fixedAssetAssocWithTypeId === "TEMP_ENERGY") {
      setTrendArrList([
        "Temperature compliance trend",
        "Energy Consumption Trend",
      ]);
      setSelectedTrend(["Temperature compliance trend"]);
      setMeterTypeIdsList(["TEMP_COMP_PCT", "KWH"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "ENERGY") {
      setTrendArrList([
        "Consumption (kWh)",
        "Power Factor",
        "Apparent Power (kVA)",
        "Load (kW)",
        "Reactive power (kVAR)",
      ]);
      setSelectedTrend(["Consumption (kWh)"]);
      setMeterTypeIdsList(["Wh_EB", "POWER_FACTOR", "KVA", "KW", "KVAR"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "AIR") {
      setTrendArrList(["Consumption (m3)"]);
      setSelectedTrend(["Consumption (m3)"]);
      setMeterTypeIdsList(["AIR_FLOW_AGR"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "WATER") {
      setTrendArrList(["Consumption (KL)"]);
      setSelectedTrend(["Consumption (KL)"]);
      setMeterTypeIdsList(["WTR_FLOW_AGR"]);
    } else{
      setTrendArrList(["Temperature compliance trend"]);
      setSelectedTrend(["Temperature compliance trend"]);
      setMeterTypeIdsList(["TEMP_COMP_PCT"]);
    }
  };

  /**
   * Set Switch botton between the charts.
   */
  const setChartButtons = () => {
    if (trendAsssetTypeCheck()) {
      setChartGroupData(["Live Data", "Trend Data"]);
      setSelectedGroupChart("Live Data");
    } else {
      setChartGroupData(["Live Data"]);
      setSelectedGroupChart("Live Data");
    }
  };

  /**
   * Set legends for the Raw Data Chart.
   */
  const setLegendRawChart = () => {
    if (assetData.fixedAssetAssocWithTypeId === "TEMP") {
      if (dateChip === "Today") {
        setLegend([{ value: "Temperature (C)", color: "#FCD670" }]);
        setSelectedLegend(["Temperature (C)"]);
      } else {
        setLegend([{ value: "Average Temperature", color: "#FCD670" }]);
        setSelectedLegend(["Average Temperature"]);
      }
      if (R.isEmpty(selectedLegend)) {
        if (dateChip === "Today") {
          setLegend([{ value: "Temperature (C)", color: "#FCD670" }]);
          setSelectedLegend(["Temperature (C)"]);
        } else {
          setLegend([{ value: "Average Temperature", color: "#FCD670" }]);
          setSelectedLegend(["Average Temperature"]);
        }
      }
    } else if (assetData.fixedAssetAssocWithTypeId === "TEMP_ENERGY") {
      setLegend([
        { value: "Temperature (C)", color: "#FCD670" },
        { value: "Load", color: "#D9B3B3" },
        { value: "PF", color: "#FBC093" },
        { value: "Current (A)", color: "#EC644B" },
        { value: "Frequency (Hz)", color: "#D5B8FF" },
      ]);
      setSelectedLegend(["Temperature (C)"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "ENERGY") {
      setLegend([
        { value: "Load (kW)", color: "#D9B3B3" },
        { value: "Power Factor", color: "#FBC093" },
        { value: "Actual Demand (kVA)", color: "#D5B8FF" },
        { value: "Frequency (Hz)", color: "#67F2D1" },
        { value: "R-Amp", color: "#FF9478" },
        { value: "Y-Amp", color: "#EBEB3D" },
        { value: "B-Amp", color: "#008B8B" },
        { value: "R-Volt", color: "#FF7878" },
        { value: "Y-Volt", color: "#FFA500" },
        { value: "B-Volt", color: "#C5EFF7" },
        { value: "R-Y Volt", color: "#F9BF3B" },
        { value: "Y-B Volt", color: "#7EB06F" },
        { value: "B-R Volt", color: "#AD91A9" },
        { value: "kVAR", color: "#FBC093" },
        { value: "MD", color: "#40C4FF" },
        { value: "PD", color: "#BE90D4" },
        { value: "RD", color: "#00BFA5" },
        { value: "THD-RV", color: "#B2675E" },
        { value: "THD-YV", color: "#847676" },
        { value: "THD-BV", color: "#FC7A1E" },
        { value: "THD-RA", color: "#BFA6A2" },
        { value: "THD-YA", color: "#00AAA7" },
        { value: "THD-BA", color: "#D0681E" },
      ]);
      setSelectedLegend(["Load (kW)"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "DG") {
      setLegend([
        "Fuel Level",
        "Run hour (Hrs)",
        "Total Watt",
        "Actual Demand (kVA)",
        "Power Factor",
      ]);
      setSelectedLegend(["Fuel Level"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "WATER") {
      setSelectedLegend(["Flow Rate"]);
      setLegend([{ value: "Flow Rate", color: "#40C4FF" }]);
    } else if (assetData.fixedAssetAssocWithTypeId === "AIR") {
      setSelectedLegend(["Flow Rate"]);
      setLegend([{ value: "Flow Rate", color: "#40C4FF" }]);
    } else if (assetData.fixedAssetAssocWithTypeId === "WATER_PULSE") {
      setSelectedLegend(["Consumption (L)"]);
      setLegend([{ value: "Consumption (L)", color: "#40C4FF" }]);
    } else if (assetData.fixedAssetAssocWithTypeId === "IAQ") {
      setLegend([
        { value: "Temperature", color: "#FCD670" },
        { value: "Humidity", color: "#72CCD8" },
        { value: "CO2", color: "#A3A0A9" },
        { value: "PM1.0", color: "#A2DED0" },
        { value: "PM2.5", color: "#FBC093" },
        { value: "PM10", color: "#EFCFE3" },
        { value: "Total volatile organic compound", color: "#C9F29B" },
        { value: "Formaldehyde", color: "#BE90D4" },
      ]);
      setSelectedLegend(["Temperature"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "TEMPCONTROLLER") {
      setLegend([
        { value: "Supply Temp 1", color: "#83F8FF" },
        { value: "Supply Temp 2", color: "#1CD1E8" },
        { value: "Return Air Temp 1", color: "#1DE9B6" },
        { value: "Return Air Temp 2", color: "#00BFA5" },
      ]);
      setSelectedLegend(["Supply Temp 1"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "COMPRESSOR") {
      setLegend([
        { value: "Suction Temp 1", color: "#83F8FF" },
        { value: "Suction Temp 2", color: "#1CD1E8" },
        { value: "Condensation Temp 1", color: "#1DE9B6" },
        { value: "Condensation Temp 2", color: "#00BFA5" },
      ]);
      setSelectedLegend(["Suction Temp 1"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "PRESSURE") {
      setLegend([{ value: "Pressure", color: "#90A4AE" }]);
      setSelectedLegend(["Pressure"]);
    } else if (assetData.fixedAssetAssocWithTypeId === "AC_CONTROLLER") {
      setLegend([
        { value: "Temperature", color: "#FCD670" },
        { value: "Current", color: "#EC644B" },
      ]);
      setSelectedLegend(["Temperature"]);
    } else {
      setLegend([{ value: "Temp", color: "#FCD670" }]);
      if (R.isEmpty(selectedLegend)) {
        setSelectedLegend([
          "Temp",
          "KW",
          "PF",
          "Current",
          "TW",
          "TVA",
          "Freq",
          "PFy",
          "fuellvl",
          "runhr",
        ]);
      }
    }
  };

  /**
   * Set the required legends for the graph.
   */
  useEffect(() => {
    if (assetData.assetId !== "") {
      setLegendTrendChart();
      setChartButtons();
      setLegendRawChart();
    }
  }, [assetData]);

  /**
   * Load the asset detail from the Local Storage
   */
  useEffect(() => {
    const assetDatas = LocalStorageService.getSelectedAssetData();
    setAssetData(assetDatas?.data);
    setAssetTypeDesc(assetDatas?.assetTypeDesc);
  }, []);

  /**
   *  If date range will be changed then the legend will be changed
   */
  useEffect(() => {
    const updateTrendLegendChip = () => {
      if (dateUpdate === "Today") {
        setTrendRange([
          "Last 7 days",
          "Last 14 days",
          "Last 30 days",
          "Last 60 days",
          "Last 90 days",
        ]);
        setSelectedTrendRange("Last 7 days");
      } else if (dateUpdate === "This Week") {
        setTrendRange(["Last 4 weeks", "Last 8 weeks", "Last 12 weeks"]);
        setSelectedTrendRange("Last 4 weeks");
      } else if (dateUpdate === "This Month") {
        setTrendRange([
          "Last 3 months",
          "Last 6 months",
          "Last 9 months",
          "Last 12 months",
        ]);
        setSelectedTrendRange("Last 3 months");
      } else if (dateUpdate === "Custom") {
        setTrendRange([""]);
        setSelectedTrendRange("");
      }
    };
    updateTrendLegendChip();
  }, [dateUpdate]);

  /**
   * Update the data for the Raw Chart when selected legend changed or at the time of pooling.
   */
  useEffect(() => {
    updateLineData();
  }, [selectedLegend, repoLiveData]);

  const [acControllerSettingPopUp, setAcControllerSettingPopUp] =
    useState(false);
  const [trendDataLegendsScroll, setTrendDataLegendsScroll] = useState(true);
  const [rawDataLegendsScroll, setRawDataLegendsScroll] = useState(true);

  const toggleClassName = () => {
    setTrendDataLegendsScroll((prevState) => !prevState);
  };

  const handleDropdownClick = (event) => {
    // Check if the click is inside the dropdown or its trigger
    if (
      event.target.closest(".dropDownView") ||
      event.target.closest(".dateRagneFrequenciesMenu")
    ) {
      return;
    }
    setShowDropDown(false);
  };

  const [downloadToastMessage, setDownloadToastMessage] = useState('');
  const [showDownloadToastMessage, setShowDownloadToastMessage] = useState(false);

  const handleDownloadToastMessage = (message: string) => {
    setDownloadToastMessage(message);
    closeModalHandler();
    setShowDownloadToastMessage(true);
    setTimeout(() => {
      setShowDownloadToastMessage(false);
    }, 3000);
  }

  React.useEffect(() => {
    document.addEventListener("mousedown", handleDropdownClick);
    return () => {
      document.removeEventListener("mousedown", handleDropdownClick);
    };
  }, []);

  const [fullSize, setFullSize] = useState(false);

  return (
    <>
      {acControllerSettingPopUp && (
        <AcControllerCard
          show={acControllerSettingPopUp}
          onClose={() => setAcControllerSettingPopUp(false)}
          assetId={assetDetails?.assetId}
          macId={assetDetails?.fixedAssetAssocWithId}
        />
      )}
      <Modal
        show={isShown}
        centered
        keyboard={false}
        backdrop={"static"}
        //size="sm"
        onHide={() => setIsShown(false)}
        className="modelPopAlerts"
      >
        <div className="alertset">
          <div className="alertset_div">
            <div className="alertse_divSpace">
              <img className="alertsImg" src={alertsImg} />
              <div>
                <h3 className="alertTypeDesc">
                  {activeAlert.current.alertTypeDesc}
                </h3>
                <p className="fixedAssetName">
                  {activeAlert.current.fixedAssetName}
                </p>
              </div>
            </div>
            <div>
              <p onClick={() => setIsShown(false)}>
                <img src={CloseButton} />
              </p>
            </div>
          </div>
          <h3 className="alertDesc">{activeAlert.current.alertDesc}</h3>
          {dropdownStatus ? (
            <div className="reasonClose">
              <p className="reason">Reason for closing</p>
              {closeAlertStatus ? (
                <input
                  type="text"
                  className="filterReason"
                  placeholder=""
                  value={filterReason}
                  readOnly
                />
              ) : (
                <CustomSelect
                  callBackValue={updateReason}
                  options={reasonsList}
                />
              )}
              {selectReason === "OTHERS" ? (
                closeAlertStatus ? (
                  <input
                    type="text"
                    className="otherReason"
                    placeholder="Enter reason for closing"
                    style={{ borderColor: "#A7B8F4" }}
                    value={otherReason}
                    readOnly={true}
                  />
                ) : (
                  <input
                    type="text"
                    className="otherReason"
                    placeholder="Enter reason for closing"
                    style={{
                      borderColor: emptyCheckStatus ? "#ff8282" : "#A7B8F4",
                    }}
                    value={otherReason}
                    onClick={() => setEmptyCheckStatus(false)}
                    maxLength={100}
                    onChange={(e) => {
                      if (e.target.value.trim() === "") {
                        setEmptyCheckStatus(true);
                        setOtherReason(e.target.value.trim());
                      } else if (e.target.value.trim().length > 0) {
                        setEmptyCheckStatus(false);
                        setOtherReason(e.target.value);
                      } else {
                        setEmptyCheckStatus(false);
                        setOtherReason(e.target.value);
                      }
                    }}
                  />
                )
              ) : null}

              <p
                style={{
                  color: "#ff1212",
                  fontSize: "12px",
                  fontWeight: "500",
                  opacity: emptyCheckStatus ? 1 : 0,
                }}
              >
                please{" "}
                {selectReason === "OTHERS"
                  ? "enter your reason for closing alert"
                  : "select reason for closing alert"}
              </p>
            </div>
          ) : null}

          <div className="footerAlert">
            <div>
              {/* //style={{ cursor: "pointer" }}
              //onClick={navigateAssetDetailPage}
              //className="ViewStyle" */}

              {/* <img src={view_Dtlimg} />
              <p>View Details</p> */}
            </div>
            <div>
              {/* <div>
                <p>Cancel</p>
              </div> */}
              {permissionForUpdate ? (
                <div
                  style={{ cursor: "pointer" }}
                  className="closeStyle"
                  onClick={closeAlertFun}
                >
                  {closeAlertStatus ? (
                    <div className="loader"></div>
                  ) : (
                    <p>Close Alert</p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Modal>

      {toastState && <ToastContainer position="bottom-right" />}
      <div className="assetDetailPage">
        {openClose && (
          <div className="alerterrorState">
            No data available for download.
            <span className="closebtn" onClick={warningPopup}>
              &times;
            </span>
          </div>
        )}
        {popupState ? (
          <DownloadPopup
            className="modal"
            data={arrayData}
            show={popupState}
            close={closeModalHandler}
            summaryData={assetSummaryNew}
            assetDataDownload={assetDetails}
            assetType={assetData.fixedAssetAssocWithTypeId}
            faData={assetDetails}
            errorShow={showError}
            assetID={assetData.fixedAssetAssocWithTypeId}
            assetName={assetData.assetName}
            handleDownloadToastMessage={handleDownloadToastMessage}
          />
        ) : null}

        {showDownloadToastMessage && (
            <CustomToast message={downloadToastMessage} onClose={() => setShowDownloadToastMessage(false)}  />
        )}

        <div className="assetDetailPageCont" style={{flexDirection: fullSize? 'column':'row'}}>
          {/* For Left Sction */}
          <div className="asssetDetailLeftCont" style={{width: fullSize? '100%': '48%'}}>
            <AssetDetailChildComp fullSize={fullSize} setFullSize={setFullSize} acControllerSettingPopUp={acControllerSettingPopUp} setAcControllerSettingPopUp={setAcControllerSettingPopUp} setAssetLocation={setAssetLocation} />
            </div>

          {/* For Right Sction */}
          <div className="asssetDetailRightCont rightCol" style={{width: fullSize? '100%': '50%'}}>
          <div className="rightDivImple">
            <div className="alertList" style={{width: fullSize? '35%': '98%'}}>
              <AlertChip
                color="#F44336"
                text="ALERTS"
                value={heraderList.alert}
                img={activityalertsicon}
              />
              <AlertChip
                color="#FFC107"
                text="WATCHING"
                value={heraderList.watchList}
                img={activitywatchlisticon}
              />
              {/* For now we are not integrating this data. */}
              {/* <AlertChip
                color="#F44336"
                text="REPEATED"
                value={
                  R.isEmpty(assetDetails?.Alert_Repeated)
                    ? "0"
                    : assetDetails?.Alert_Repeated
                }
                img={activityrepeatedalertsicon}
              /> */}
              {/* {assetDetails?.fixedAssetAssocWithTypeDesc === "TEMP_ENERGY" ||
            assetDetails?.fixedAssetAssocWithTypeDesc === "TEMP" ? */}
              <div className="download" onClick={openModalHandler}>
                <img src={downloadicon} />
                <h3>Download </h3>
              </div>
              {/* :""} */}
            </div>
          </div>
          <div className="tabsList-desktop">
            <div className="tabBackClor" style={{ padding: "15px" }}>
              <ButtonGroup className="tabClass">
                {tList &&
                  tList.map((ele: any, index: any) => (
                    <Tab
                      key={index}
                      active={active === ele}
                      onClick={() => handleActive(ele)}
                      className={`${
                        active === ele ? "TabBtn active" : "TabBtn"
                      }`}
                    >
                      <h6>{ele}</h6>
                    </Tab>
                  ))}
              </ButtonGroup>
            </div>

            {active === "Info" ? (
              <AssetInfo data={assetDetails} faData={facilityData[0]} assetLocation={assetLocation} />
            ) : active === "Summary" ? (
              <>
                {summaryLoader || getAssetFetchStatus ? (
                  <div className="loderInCards" style={{ margin: "15%" }}>
                    <LoaderStyle />
                  </div>
                ) : assetSummaryNew === "error" ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err_1"} />{" "}
                  </div>
                ) : typeof assetSummaryNew === "number" ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-500/404"} />{" "}
                  </div>
                ) : R.isEmpty(assetSummaryNew) ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-empty"} />{" "}
                  </div>
                ) : (
                  <SummaryParentCard
                    data={assetSummaryNew}
                    faData={assetDetails}
                  />
                )}
              </>
            ) : active === "Activity" ? (
              <>
                {activityLoader ? (
                  <div className="activityTabScss">
                    <LoaderStyle />
                  </div>
                ) : dataVal === "error" ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err_1"} />{" "}
                  </div>
                ) : typeof dataVal === "number" ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-500/404"} />{" "}
                  </div>
                ) : R.isEmpty(tabLabel) ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-empty"} />{" "}
                  </div>
                ) : tabLabel[0].count === 0 ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-empty"} />{" "}
                  </div>
                ) : (
                  <AssetDetailsIndex
                    pageIndex={stateVal}
                    data={tabLabel}
                    lodeStateVal={"show"}
                    loadState={loadState}
                    callBackalertPopUp={activeAlertUpdateFun}
                    acticityImg={true}
                  />
                )}
                {console.log("tabLabel", tabLabel)}
              </>
            ) : // <DisplayError type="err-empty" />
            active === "Event Log" ? (
              <>
                {eventLoader ? (
                  <div className="activityTabScss">
                    <LoaderStyle />
                  </div>
                ) : eventLogData === "error" ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err_1"} />{" "}
                  </div>
                ) : typeof eventLogData === "number" ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-500/404"} />{" "}
                  </div>
                ) : R.isEmpty(eventLogData) ? (
                  <div className="activityTabScss">
                    {" "}
                    <DisplayError type={"err-empty"} />{" "}
                  </div>
                ) : (
                  <AssetEventLog data={eventLogData} />
                )}
              </>
            ) : (
              // <ActivityParentCard data={assetSummary.Activity} />
              <DisplayError type="err-empty" />
              // <h2>No data available to display. </h2>
              // <DetailGallery data={assetSummary.Gallery} />
            )}
          </div>
          <div className="tabsList-mobile">
            {/* <select value={active} onChange={(e) => setActive(e.target.value)}>
              {tList.map((ele, index) => (<option value={ele}>{ele}</option>))}
            </select> */}
            <div className="selectDropdown">
              <div
                className="selectedOption"
                onClick={() => setShowDrop(!showDrop)}
              >
                {active}
              </div>
              <div
                className={`selectedOption-list ${showDrop ? "showList" : ""}`}
              >
                {tList.map((ele: any, index: any) => (
                  <div
                    key={index}
                    className="selectedOption-list-item"
                    onClick={() => {
                      setActive(ele);
                      setShowDrop(!showDrop);
                    }}
                  >
                    {ele}
                  </div>
                ))}
              </div>
            </div>
            <div>
              {active === "Info" ? (
                <AssetInfo data={assetDetails} faData={facilityData[0]} assetLocation={assetLocation} />
              ) : active === "Summary" ? (
                <>
                  {summaryLoader ? (
                    <div className="loderInCards" style={{ margin: "15%" }}>
                      <LoaderStyle />{" "}
                    </div>
                  ) : (
                    <SummaryParentCard
                      data={assetSummaryNew}
                      faData={assetDetails}
                    />
                  )}
                </>
              ) : active === "Activity" ? (
                <>
                  {activityLoader ? (
                    <div className="activityTabScss">
                      <LoaderStyle />
                    </div>
                  ) : dataVal === "error" ? (
                    <div className="activityTabScss">
                      {" "}
                      <DisplayError type={"err_1"} />{" "}
                    </div>
                  ) : typeof dataVal === "number" ? (
                    <div className="activityTabScss">
                      {" "}
                      <DisplayError type={"err-500/404"} />{" "}
                    </div>
                  ) : R.isEmpty(dataVal) ? (
                    <div className="activityTabScss">
                      {" "}
                      <DisplayError type={"err-empty"} />{" "}
                    </div>
                  ) : (
                    <AssetDetailsIndex
                      pageIndex={stateVal}
                      data={tabLabel}
                      lodeStateVal={"show"}
                      loadState={loadState}
                      acticityImg={true}
                    />
                  )}
                </>
              ) : active === "Event Log" ? (
                //<DisplayError type="err-empty" />
                <AssetEventLog data={eventLogData} />
              ) : (
                <DisplayError type="err-empty" />
                // <DetailGallery data={assetSummary.Gallery} />
              )}
            </div>
          </div>
          </div>
        </div>

      </div>
    </>
  );
}

export default AssetDetailPage;
