import {Data, Location} from "./types";
import LocalStorageService from "../../../../Utils/LocalStorageService";
import {getLocationDetails, getSubLocationDetails} from "../../../assetList/utils";

export function transformAssetsToHierarchy(data: Data = []): any {
    // Check if assets is a valid array
    const assets = data?.assets;
    if (!Array.isArray(assets)) {
        console.log("HVAC :: Invalid input, assets is not an array:", assets);
        return "";
    }

    console.log("HVAC :: assets : ", assets);

    function getLatLong(locatedAtLocationSeqId: any, locationId: any, subLocationId1: any, index: any, subLocationId: any): Location {
        // const latLong: Location = {
        //     lat: Math.floor(Math.random() * 50) + 1,
        //     // lat: (index+1) *10,
        //     long: Math.floor(Math.random() * 50) + 1,
        //     // long: (index+1)*10,
        // };

        //ToDo !
        const locationDate = LocalStorageService.getLocationData();
        const location = locationDate.find((el) => el.locationId == locationId)
        const areaObj = getAreaSize(subLocationId)
        const size = areaObj?.size
        console.log(`HVAC :: Location: ${size}`, location);
        const latLong: Location = {
            // lat: ((location.geoPoint.latitude*100)/size),
            long: ((location.geoPoint.longitude) / size),
            lat: ((location.geoPoint.latitude) / size),
            // long: (location.geoPoint.longitude)
        };

        console.log("HVAC :: Location", latLong);
        return latLong;
    }

    function getAreaSize(areaId: string): any {
        const areaIds = LocalStorageService.getAreaData()
        return areaIds.find((el) => el.locationSeqId === areaId);
    }

    // Group assets by subLocationId
    const groupedAssets = assets.reduce((acc, asset, index) => {
        const subLocationId = asset.locatedAtLocationSeqId || "unknown";
        if (!acc[subLocationId]) {
            acc[subLocationId] = [];
        }
        let meterVal = null;
        if (asset?.meterData?.liveMeters) {
            meterVal = asset?.meterData?.liveMeters?.find((item) =>
                item.meterTypeId === "TEMP"
            );
        }
        console.log("HVAC :: meterVal meterVal : ", asset);
        const latLong = getLatLong(asset?.locatedAtLocationSeqId, asset?.locationId, asset?.subLocationId, index, subLocationId);
        const subLocation =  getSubLocationDetails(asset?.subLocationId);
        const locationId =  getLocationDetails(asset?.locationId);
        acc[subLocationId].push({
            id: asset.assetId,
            name: asset.assetName,
            meterValue: meterVal,
            lat: latLong.lat,
            long: latLong.long,
            healthStatus: asset.healthStatus,
            subLocationId: subLocationId,
            locationId: locationId
        });



        return acc;
    }, {} as Record<string, any[]>);

    console.log("HVAC :: groupedAssets : ", groupedAssets);

    // Transform grouped assets into the desired array of objects
    const hierarchy = Object.entries(groupedAssets).map(([areaId, assets]) => ({
        areaId,
        areaObj: getAreaSize(areaId),
        assets,
    }));

    // Convert the hierarchy to a JSON string
    // const hierarchyJSON = JSON.stringify(hierarchy, null, 2);

    return hierarchy;
}


