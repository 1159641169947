import React, { useEffect, useState,useRef} from "react";
import IconAdd from "../../../assets/icons/IconAdd.svg";
import ImageSelectorComponent from "../../../Utils/imageSelector/ImageSelectorComponent";
import EyeOff from '../icons/EyeOff.svg';
import EyeOn from '../icons/EyeOn.svg';
import Dropdown from '../../../assets/icons/Dropdown.svg';
import SearchIcon from '../../Users/usersCardContainer/userCard/ViewUserIcons/SearchIcon.svg';
import Select from "react-select";
import UseStatics from "../utils/utils";
import { components } from 'react-select';
import Unchecked from '../../../assets/icons/Checked.svg';
import Checked from '../../../assets/icons/Unchecked.svg';
import IconClose from "../../../assets/icons/IconClose.svg";
export const BasicContainer=(props)=>{
    const{setBasicInfo,setValidationMessages,setshowUserAddAddress,setShouldValidate,validationMessages,basicInfo,setImageCreate,imageCreate}=props;
    const[basicInfos,setBasicInfos]=useState(basicInfo);
    const [validationMessagess, setValidationMessagess] = useState(validationMessages);
    const [searchPhoneCountry, setSearchPhoneCountry] = useState('');
    const [showEmailPurpose, setShowEmailPurpose] = useState(false);
    const [showPhonePurpose, setShowPhonePurpose] = useState(false);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [showCountryPopUp, setShowCountryPopUp] = useState(false);
    const [selectedState, setSelectedState] = useState(null);
    const [filteredStates, setFilteredStates] = useState([]);
    const [images, setImages] = useState<File>(null);

    const {
        emailPurposeData,
        phonePurposeData,
        countryArr,
      } = UseStatics();
    const [imgUrl, setImgUrl] = useState('');
    const selectRef = useRef(null);
    const handleImageChange = async (newImage: File) => {
        setImages(newImage);
      };
    
      useEffect(() => {setImages(images)}, [images]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const handleInputChange = (inputValue: string) => {
        setSearchPhoneCountry(inputValue);
      };
      const CustomDropdownIndicator = (props) => (
        <components.DropdownIndicator {...props}>
          <img src={SearchIcon} alt="Search" className="search-icon" />
        </components.DropdownIndicator>
      );
      const filteredCountryArr = countryArr.filter((country: { name: string; }) =>
        country.name.toLowerCase().includes(searchPhoneCountry.toLowerCase())
      )
    const options = filteredCountryArr.map((el) => ({
      value: el.name,
      label: (
        <div className="flagCountryPrefixDiv">
          <img src={el.contentUrl} alt="flag" className="option-flag" />
          <span className="option-name">{el.name}</span>
          <span className="option-telecode">+{el.teleCode}</span>
        </div>
      ),
      teleCode: el.teleCode,
      contentUrl: el.contentUrl,
    }));

    const filterEmailPurposeData = (el: string) => {
        setBasicInfos((prevState) => ({
          ...prevState,
          selectedEmailPurposeData: prevState.selectedEmailPurposeData.includes(el)
            ? prevState.selectedEmailPurposeData.filter((obj: string) => obj !== el)
            : [...prevState.selectedEmailPurposeData, el],
        }));
      };
      const filterPhonePurposeData = (el: string) => {
        setBasicInfos((prevState) => ({
          ...prevState,
          selectedPhonePurposeData: prevState.selectedPhonePurposeData.includes(el)
            ? prevState.selectedPhonePurposeData.filter((obj: string) => obj !== el)
            : [...prevState.selectedPhonePurposeData, el],
        }));
      };
    const validateValue = (value: string | any[], regex: { test: (arg0: any) => any; }, maxLength: number) => {
        if (!regex.test(value)) {
          return "Invalid format";
        }
        if (value.length > maxLength) {
          return `The value must be less than ${maxLength} characters.`;
        }
        return "";
      };
   
    const handleChange = (field: string, regex: RegExp, maxLength: number, e: React.FormEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const validationMessage = validateValue(value, regex, maxLength);
        if (validationMessage === "") {
          setBasicInfos(prevState => ({ ...prevState, [field]: value }));
        }
    
        setValidationMessages(prevMessages => ({
          ...prevMessages,
          [field]: validationMessage
        }));
    
        if (validationMessage === "") {
          setShouldValidate(false);
        }
      };
    const handleShowCountryPopUp = () => {
        setShowCountryPopUp(!showCountryPopUp);
      }
      const handlePrefixSelection = (el: { teleCode: string; contentUrl: string }) => {
        setBasicInfos((prevState) => ({
          ...prevState,
          selectedPhoneNumberPrefix: el.teleCode,
        }));
        setImgUrl(el.contentUrl);
      };
      
      useEffect(() => {
        const handleClickOutside = (event) => {
          if (selectRef.current && !selectRef.current.contains(event.target)) {
            setIsDropdownOpen(false);
          }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [selectRef]);
      useEffect(() => {
        if (selectedCountry) {
          const selectedCountryObj = countryArr.find(
            (country) => country.id === selectedCountry.id
          );
          
          if (selectedCountryObj && selectedCountryObj.states) {
            const statesMapped = selectedCountryObj.states.map((state) => ({
              value: state.geoId,
              label: state.geoName,
            }));
            setFilteredStates(statesMapped);
          } else {
            setFilteredStates([]);
          }
        } else {
          setFilteredStates([]);
        }
      }, [selectedCountry, countryArr]);
      useEffect(() => {
        setBasicInfo(basicInfos);
      },[basicInfos,basicInfo])
      useEffect(()=>{
        setValidationMessagess(validationMessages);
      },[validationMessagess,validationMessages])
      useEffect(()=>{
        setImageCreate(imageCreate);
      },[imageCreate])
      useEffect(()=>{
        setBasicInfos(basicInfo)
      },[basicInfo])
    return(
   <div className="basicContainer">
               <div className="firstRow">
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">First Name *</label>
                       <input
                         className="userInput"
                         type="text"
                         name="firstName"
                         maxLength={30}
                         value={basicInfos.firstName}
                         onInput={(e) => handleChange("firstName", /^[A-Za-z' ]*$/, 30, e)}
                       />
                     {validationMessagess.firstName && (
                       <div className="warningDiv" style={{ color: 'red',width:'100%' }}>{validationMessagess.firstName}</div>
                     )}
                   </div>
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">Last Name *</label>
                       <input
                         className="userInput"
                         type="text"
                         name="lastName"
                         maxLength={30}
                         value={basicInfos.lastName}
                         onInput={(e) => handleChange("lastName", /^[A-Za-z' ]*$/, 30, e)}
                       />
                     {validationMessagess.lastName && (
                       <div className="warningDiv" style={{ color: 'red',width:'100%' }}>{validationMessagess.lastName}</div>
                     )}
                   </div>
                 </div>
                 <div className="firstRow">
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">Email *</label>
                     <div className="userInputCont">
                       <input
                         className="userInput"
                         type="email"
                         name="email"
                         maxLength={30}
                         value={basicInfos.email}
                         onChange={(e) => handleChange("email", /^[A-Za-z0-9._+@\-]*$/, 30, e)}
                       />
                     </div>
                     {validationMessagess.email && (
                       <div className="warningDiv" style={{ color: 'red',width:'100%' }}>{validationMessagess.email}</div>
                     )}
                     <p
                       className="setPurpose"
                       onClick={() => setShowEmailPurpose(true)}
                     >
                       Set purpose
                     </p>
                     {showEmailPurpose && (
                       <div className="emailSetPurpose" onMouseLeave={() => setShowEmailPurpose(false)}>
                         <div className="setPurposeHeader">
                           <div className="purposeCloseCont" onClick={() => setShowEmailPurpose(false)}>
                             <img
                               src={IconClose}
                               alt="icon"
                             />
                           </div>
                         </div>
                         <div className="setPurposeDataCont">
                           {emailPurposeData.length > 0 && emailPurposeData.map((el: {id: any; }, index: any) => (
                             <div className="setPurposeData" key={index} onClick={() => filterEmailPurposeData(el.id)}>
                               {basicInfos.selectedEmailPurposeData.includes(el.id) ? (
                                 <img
                                 src={Checked}
                                 alt="checked"
                               />
                             ) : (
                               <img
                                 src={Unchecked}
                                 alt="unchecked"
                               />
                               )}
                               <label>{el.name}</label>
                             </div>
                           ))}
                         </div>
                       </div>
                     )}
                   </div>
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">Phone Number *</label>
                     <div className="userInputCont">
                       <div className="userInputContPartition">
                         <div className="phoneNumberImgSelectBox" onClick={handleShowCountryPopUp}>
                           <img className="countryImg" src={imgUrl} />
                           <div className="selectedPhoneNumber">{basicInfo.selectedPhoneNumberPrefix}</div>
                           <img src={Dropdown} alt="icon"/>
                         </div>
                         <div className="userInputPhoneNumber">
                           <input
                             type="text"
                             name="phoneNumber"
                             maxLength={20}
                             value={basicInfos.phoneNumber}
                             onInput={(e) => handleChange("phoneNumber", /^[0-9]*$/, 20, e)}
                           />
                         </div>
                       </div>
                     </div>
                     {validationMessagess.phoneNumber && (
                       <div className="warningDiv" style={{ color: 'red' ,width:'100%',fontSize:'15px' }}>{validationMessagess.phoneNumber}</div>
                     )}
                       {showCountryPopUp &&
                     <div className="phoneSelectContainer" >
                     <Select
                       options={options}
                       onHide={()=>{setShowCountryPopUp(showCountryPopUp)}}
                       className="react-select-container"
                       classNamePrefix="react-select"
                       onInputChange={handleInputChange}
                       onChange={handlePrefixSelection}
                       isSearchable
                       value={null}
                       components={{
                         DropdownIndicator: CustomDropdownIndicator,
                         IndicatorSeparator: () => null
                       }}
                       styles={{
                         control: (provided) => ({
                           ...provided,
                           display: 'flex',
                           width: '350px',
                           alignItems: 'center',
                           border: 'none',
                           outline: 'none',
                           marginTop: '5px',
                           paddingRight: '10px',
                         }),
                       }}
                       // menuIsOpen={isDropdownOpen}
                       onFocus={() => setIsDropdownOpen(true)}
                       onBlur={() => setIsDropdownOpen(false)}
                     />
                   </div>
                   }
                     <p
                       className="setPurpose"
                       onClick={() => setShowPhonePurpose(true)}
                     >
                       Set purpose
                     </p>
                     {showPhonePurpose && (
                       <div className="emailSetPurpose" onMouseLeave={() => setShowPhonePurpose(false)}>
                         <div className="setPurposeHeader">
                           <div className="purposeCloseCont" onClick={() => setShowPhonePurpose(false)}>
                             <img
                               src={IconClose}
                               alt="icon"
                             />
                           </div>
                         </div>
                         <div className="setPurposeDataCont">
                           {phonePurposeData.length > 0 && phonePurposeData.map((el: { id: any; }, index: any) => (
                             <div className="setPurposeData" key={index} onClick={() => filterPhonePurposeData(el.id)}>
                               {basicInfos.selectedPhonePurposeData.includes(el.id) ? (
                                 <img
                                   src={Checked}
                                   alt="checked"
                                 />
                               ) : (
                                 <img
                                   src={Unchecked}
                                   alt="unchecked"
                                 />
                               )}
                               <label>{el.name}</label>
                             </div>
                           ))}
                         </div>
                       </div>
                     )}
                   </div>
                 </div>
                 <div className="firstRow">
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">Address</label>
                       <div className="addressImgSelectBox">
                         <div className="userInputAddress">
                           <input
                             className="userInputAdd"
                             type="text"
                             value={
                               [
                                 basicInfos.addressLine1,
                                 basicInfos.addressLine2,
                                 basicInfos.city,
                                 basicInfos.selectedCountry,
                                 basicInfos.postalCode,
                                 basicInfos.selectedState,
                               ]
                                 .filter(Boolean)
                                 .join(",")
                             }
                           />
                         </div>
                         <div className="addressIconImg" >
                           <img src={IconAdd} alt="IconAdd" onClick={() => setshowUserAddAddress(true)} />
                         </div>
                       </div>
                   </div>
                 </div>
                 <p className="credentialsHeader">Credentials</p>
                 <div className="credentialsBorder"></div>
                 <div className="firstRow">
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">User Name *</label>
                     <div className="userInputContUserName">
                       <input
                         type="text"
                         className="userInputUserName"
                         name="userName"
                         maxLength={20}
                         value={basicInfos.userName}
                         onChange={(e) => handleChange("userName", /^[A-Za-z0-9._+@\-]*$/, 20, e)}
                       />
                     </div>
                     {validationMessagess.userName && (
                       <div className="warningDiv" style={{ color: 'red',width:'100%' }}>{validationMessagess.userName}</div>
                     )}
                   </div>
                 </div>
                 <div className="firstRow">
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">Password *</label>
                     <div className="userInputCont">
                       <div className="addressImgSelectBoxs">
                         <div className="userInputAddress">
                         <input
                           style={{ backgroundColor: "white" }}
                           type={basicInfos.clickPassword ? 'text' : 'password'}
                           value={basicInfos.password}
                           onChange={(e) => handleChange("password", /.*/, 20, e)}
                         />
                         </div>
                         {basicInfos.clickPassword ? (
                           <div className="addressIconImg">
                           <img
                             src={EyeOff}
                             alt="eyeOff"
                             onClick={() => setBasicInfos((prevState) => ({
                               ...prevState,
                               clickPassword: !prevState.clickPassword
                             }))}
                           />
                           </div>
                         ) : (
                           <div className="addressIconImg">
                           <img
                             src={EyeOn}
                             alt="eyeOn"
                             onClick={() => setBasicInfos((prevState) => ({
                               ...prevState,
                               clickPassword: !prevState.clickPassword
                             }))}
                           />
                           </div>
                         )}
 
                       </div>
                     </div>
                     {(validationMessagess.password) && (
                       <div className="warningDiv" style={{ color: 'red',width:'100%' }}>{validationMessagess.password}</div>
                     )}
                   </div>
                   <div className="userLabelAndInputContainer">
                     <label className="userLabel">Confirm Password *</label>
                     <div className="userInputCont">
                       <div className="addressImgSelectBoxs">
                         <div className="userInputAddress">
                         <input
                           type={basicInfos.confirmClickPassword ? 'text' : 'password'}
                           value={basicInfos.confirmPassword}
                           onChange={(e) => handleChange("confirmPassword", /.*/, 20, e)}
                         />
                         </div>
                         {basicInfos.confirmClickPassword ? (
                           <div className="addressIconImg">
                           <img
                             src={EyeOff}
                             alt="eyeOff"
                             onClick={() => setBasicInfos((prevState) => ({
                               ...prevState,
                               confirmClickPassword: !prevState.confirmClickPassword
                             }))}
                           />
                           </div>
                         ) : (
                           <div className="addressIconImg">
                           <img
                             src={EyeOn}
                             alt="eyeOn"
                             onClick={() => setBasicInfos((prevState) => ({
                               ...prevState,
                               confirmClickPassword: !prevState.confirmClickPassword
                             }))}
                           />
                           </div>
                         )}
 
                       </div>
                     </div>
                     {(validationMessagess.confirmPassword) && (
                       <div className="warningDiv" style={{ color: 'red',width:'100%' }}>{validationMessagess.confirmPassword}</div>
                     )}
 
                   </div>
                 </div>
                 <div className="profileImgFormatConts">
                   <div className="profileImgDiv">
                     <ImageSelectorComponent
                      alt="Profile Image"
                       onChange={()=> null}
                       images={images}
                    // onChange={()=>(handleImageChange)}
                     />
                   </div>
                   <div className="profileContentDiv">
                     <ul className="profileImgDetails">
                       <li className="profileImgHeader">Profile Image</li>
                       <li>Max file size: 2MB. File format: png, jpeg</li>
                       <li>Recommended resolution: 300x300</li>
                     </ul>
                   </div>
                 </div>
               </div>
    )
}