import React, { useEffect, useState } from "react";
import "./DownloadList.scss";
import DownloadeIcon from "../../assets/icons/DownloadeIcon.svg";
import downloadIcon from "../../assets/icons/downlloadIcon.svg";
import Vector from "../../assets/icons/Vector_arleft.svg";
import statusIconeQueue from "../../assets/icons/stausIconQueue.svg";
import statusIconCancel from "../../assets/icons/stausIconCancel.svg";
import statusIconPending from "../../assets/icons/statusIconPending.svg";
import retryIcon from "../../assets/icons/retryIcon.svg";
import closeIcon from "../../assets/icons/closeIcon.svg";
import { useStateValue } from "../../redux/StateProvider";
import { DashBoardServices } from "../../services/DasboardServices/DashboardService";
import moment from "moment";
import * as R from "ramda";
import DisplayError from "../../a1Components/DisplayError/DisplayError";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import { Link } from "react-router-dom";
import { API_BASE_URL_DOWNLOAD } from "../../Constants/constants";
import Loadmore from "../Loadmore";

const DownloadList = () => {
  const [{ dateRange, downloadReportStatus }, dispatch ] = useStateValue();
  const [dataLoader, setDataLoader] = useState(false);
  const [downloadList, setDownloadList] = useState({ count: 0, data: [] });
  const [responseCheck, setResponseCheck] = useState<any>();
  const [loadmoreLoader, setLoadmoreLoader] = useState(false);
  const [pageNum, setPageNum] = useState(1);

  const userDownloadslist = async (
    fromData: any,
    endDate: any,
    pageIndex: any
  ) => {
    let fetchDB = new DashBoardServices();
    setDataLoader(true);
    dispatch({ type: "SET_REPORT_STATUS", downloadReportStatus: false });
    let dataFetch = await fetchDB.user_Downloads_API(
      fromData,
      endDate,
      pageIndex
    );
    if (dataFetch === "error") {
      setResponseCheck(dataFetch);
      setDataLoader(false);
    } else if (typeof dataFetch === "number") {
      setResponseCheck(dataFetch);
      setDataLoader(false);
    } else {
      let data = await dataFetch.data; //count  jobList
      let temp: any = { count: 0, data: [] };
      if (R.isEmpty(data)) {
        setDataLoader(false);
      } else {
        setResponseCheck(dataFetch);

        temp.count = typeof data.count === "number" ? data.count : 0;
        temp.data = typeof data.jobList === "object" ? [...data.jobList] : [];
        setDownloadList(temp);
        setDataLoader(false);
      }
    }
  };

  const userDownloadslistOne = async (
    fromData: any,
    endDate: any,
    pageIndex: any
  ) => {
    let fetchDB = new DashBoardServices();
    let dataFetch = await fetchDB.user_Downloads_API(
      fromData,
      endDate,
      pageIndex
    );
    if (dataFetch === "error") {
      setResponseCheck(dataFetch);
      setDataLoader(false);
    } else if (typeof dataFetch === "number") {
      setResponseCheck(dataFetch);
      setDataLoader(false);
    } else {
      let data = await dataFetch.data.jobList;
      if (R.isEmpty(data)) {
        setDataLoader(false);
      } else {
        setResponseCheck([]);
        let list: any = [...downloadList.data, ...data];
        setDownloadList({
          ...downloadList,
          data: list,
        });
        setLoadmoreLoader(false);
      }
    }
  };

  const loadMore = () => {
    setLoadmoreLoader(true);
    const fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
    const endDateStr = moment(dateRange[1]).endOf("day").toISOString();
    userDownloadslistOne(
      moment(fromDateStr).valueOf(),
      moment(endDateStr).valueOf(),
      pageNum + 1
    );
    setPageNum(pageNum + 1);
  };

  let apiInterval: NodeJS.Timer | undefined;
  function startAPICalls(startDate: any, endDate: any) {
    apiInterval = setInterval(() => {
      userDownloadslist(
        moment(startDate).valueOf(),
        moment(endDate).valueOf(),
        1
      );
    }, 2 * 60 * 1000);
  }

  function stopAPICalls() {
    if (apiInterval) {
      clearInterval(apiInterval);
    }
  }

  useEffect(() => {
    dispatch({ type: "LOCATION_PATH_UPDATE", locationPath: window.location.pathname });
    setDownloadList({ count: 0, data: [] });
    setPageNum(1);
    const fromDateStr = moment(dateRange[0]).startOf("day").toISOString();
    const endDateStr = moment(dateRange[1]).endOf("day").toISOString();
    userDownloadslist(
      moment(fromDateStr).valueOf(),
      moment(endDateStr).valueOf(),
      1
    );
    startAPICalls(fromDateStr, endDateStr);
    return () => stopAPICalls();
  }, [dateRange]);

  type responseObject = {
    StatusDesc: string,
    maxRetry: string,
    createdStamp: string,
    jobResult: string,
    JobName: string,
    StatusId: string,
    cancelDateTime: null,
    JobId: string,
    currentRetryCount: string
  }

  useEffect(() => {
    if(downloadList.data.length > 0 ){
      downloadList.data.map((obj:responseObject) => {
        if (obj.StatusDesc === "Pending" || obj.StatusDesc === "Running"){
          dispatch({ type: "SET_REPORT_STATUS", downloadReportStatus: true });
        }
      })
    }
    return () => {
      dispatch({ type: "SET_REPORT_STATUS", downloadReportStatus: false });
    }
  }, [downloadList.data])

  return (
    <div className="mainContainer_div">
      {dataLoader ? (
        <div className="loader_divContainer">
          <LoaderStyle />
        </div>
      ) : responseCheck === "error" ? (
        <div className="loader_divContainer ">
          <DisplayError type={"err_1"} />
        </div>
      ) : typeof responseCheck === "number" ? (
        <div className="loader_divContainer ">
          <DisplayError type={"err-500/404"} />
        </div>
      ) : typeof downloadList === "object" && !R.isEmpty(downloadList.data) ? (
        <>
          <div className="back_arrow">
            <Link to="/">
              <img src={Vector} />
              Dashboard
            </Link>
          </div>
          <div className="headertext_div">
            <p className="download_list-title">Downloads</p>
            <p>
              You are viewing {downloadList.data.length} download(s)/
              {downloadList.count} download(s)
            </p>
          </div>
          <div className="header">
            <div className="header-title">
              <div className="div_one">
                <p className="header_text">Name</p>
              </div>
              <div className="div_two">
                <p className="header_text">Requested on</p>
              </div>
              <div className="div_two">
                <p className="header_text">Status</p>
              </div>
              <div className="div_two">
                <p className="header_text">Actions</p>
              </div>
            </div>
          </div>

          <div className="bottom_div">
            {downloadList.data.map((ele: any, index: any) => (
              <div key={index} className="table_Body">
                <div className="div_one">
                  {ele.StatusDesc == "Failed" || ele.StatusDesc == "Cancel" ? (
                    <img src={statusIconCancel} className="img1" />
                  ) : ele.StatusDesc == "Finished" ? (
                    <img src={DownloadeIcon} className="img1" />
                  ) : ele.StatusDesc == "In Queue" ? (
                    <img src={statusIconeQueue} className="img1" />
                  ) : (
                    <img src={statusIconPending} className="img1" />
                  )}
                  <p className="details-text">{ele.JobName}</p>
                </div>
                <div className="div_two">
                  <p className="description-text">
                    {moment(ele.createdStamp).format("MMM Do YY hh:mm a")}
                  </p>
                </div>
                <div className="div_two">
                  <p className="description-text">{ele.StatusDesc}</p>
                </div>

                <div className="div_two">
                  {ele.StatusDesc == null || ele.StatusDesc == "" ? (
                    <div className="retry-btn">
                      {/*this should work only when we have retry option enabled <img src={retryIcon} className="" /> */}
                      <p>{ele.StatusDesc}</p>
                    </div>
                  ) : ele.StatusDesc == "Finished" ? (
                    <a
                      target="_blank"
                      href={`${API_BASE_URL_DOWNLOAD.slice(0, -1)}${ele.jobResult}`}
                    >
                      <div className="download-btn">
                        {/* this should work only when we have retry option enabled <img src={downloadIcon} className="" /> */}
                        <p>
                          {ele.StatusDesc === "Finished"
                            ? "Download"
                            : ele.StatusDesc}
                        </p>
                      </div>
                    </a>
                  ) : (
                    <div className="cancel-btn">
                      {/*  this should work only when we have retry option enabled <img src={closeIcon} className="" /> */}
                      <p>{ele.StatusDesc}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
            <div className="downloadListLoadMore">
              <Loadmore
              className="loadMore"
              showLoadMore={downloadList.count > downloadList.data.length}
              handleLoadMore={loadMore}
              showLoadSpin={loadmoreLoader} />
            </div>
            {/* {downloadList.count > downloadList.data.length ? (
              loadmoreLoader ? (
                <div className="loadmore_div_Container">
                  <div className="loadmore_div" style={{ cursor: "wait" }}>
                    Loading...
                  </div>
                </div>
              ) : (
                <div className="loadmore_div_Container">
                  <div
                    className="loadmore_div"
                    style={{ cursor: "pointer" }}
                    onClick={loadMore}
                  >
                    Load more
                  </div>
                </div>
              )
            ) : null} */}
          </div>
        </>
      ) : (
        <div className="loader_divContainer ">
          <DisplayError type={"err-empty"} />
        </div>
      )}
    </div>
  );
};

export default DownloadList;
