import React, {useEffect, useRef, useState} from 'react'
import './HVACCategoryCard.scss'
import {useNavigate} from "react-router-dom";
import {AssetTypeCount} from "../../types/HVACSummaryLandingDataType";
import {HVACCategoryHooks} from "../../hooks/HVACCategoryHooks";
import {useCategoryWidthCalculator} from "../../hooks/useCategoryWidthCalculator";
import {getDescription} from "../../utils";

type PropTypes = {
    data: AssetTypeCount;
    showLine: boolean;
}

const HVACCategoryCard = (props: PropTypes) => {
    const navigate = useNavigate();
    const {
        categoryRepresentationDivRef,
        divWidth,
        updateDivWidth
    } = HVACCategoryHooks();

    const {calculateWidth} = useCategoryWidthCalculator()

    const description = getDescription(props.data.fixedAssetTypeId);

    useEffect(() => {
        if (categoryRepresentationDivRef.current) {

            const {
                widthForOn,
                widthForOff,
                widthForUnavailable,
                totalWidth
            } = calculateWidth(categoryRepresentationDivRef, props.data);

            updateDivWidth({
                on: widthForOn,
                off: widthForOff,
                unavailable: widthForUnavailable,
                total: totalWidth,
            });
        }
    }, [props.data, categoryRepresentationDivRef.current]);

    return (
        <div className='hvacCategoryMainCard'>
            <div className='hvacCategoryCard' onClick={() => navigate(`/${props.data.fixedAssetTypeId}`)}>
                <div className='hvacCategoryCardMainCont'>
                    <div className='hvacCategoryCardHeader'>
                        <p className='categoryHeadingText'>{props.data.fixedAssetTypeId}s</p>
                        <p className='categoryDescriptionText'>{description}</p>
                    </div>
                    <p className='categoryTotalCountText'>{props.data.count}</p>
                </div>
                <div className='assetCategoriesDataCont'>
                    {/*  For ON  */}
                    <div className='assetStatusMainDiv'>
                        <div className='assetStatusDiv' style={{backgroundColor: 'rgba(48, 211, 229, 1)'}}/>
                        <p className='assetStatusValueText'>{props.data.runStatusCount.ON === undefined ? '-' : props.data.runStatusCount.ON}</p>
                        <p className='assetStatusCategoryText'>ON</p>
                    </div>

                    {/*  OFF  */}
                    <div className='assetStatusMainDiv'>
                        <div className='assetStatusDiv' style={{backgroundColor: 'rgba(167, 143, 81, 1)'}}/>
                        <p className='assetStatusValueText'>{props.data.runStatusCount.OFF === undefined ? '-' : props.data.runStatusCount.OFF}</p>
                        <p className='assetStatusCategoryText'>OFF</p>
                    </div>

                </div>

                {/*  UNAVAILABLE  */}
                <div className='assetUnavailableMainDiv'>
                    <div className='assetUnavailableOuterDiv'>
                        <div className='assetUnavailableInnerDiv'/>
                    </div>
                    <p className='unavailableValueCount'>{props.data.uaStatusCount.FA_OFFLINE === undefined ? '-' : props.data.uaStatusCount.FA_OFFLINE} unavailable</p>
                </div>
                <div className='assetCategoryRepresentationMainDiv' ref={categoryRepresentationDivRef}>
                    <div className='assetCategoryOnRepresentationDiv' style={{width: `${divWidth.on}px`}}/>
                    <div className='assetCategoryOffRepresentationDiv' style={{width: `${divWidth.off}px`}}/>
                    <div className='assetCategoryUnavailableRepresentationDiv'
                         style={{width: `${divWidth.unavailable}px`}}/>
                </div>
            </div>
            {
                props.showLine && (
                    <div className='hvacCardHorizontalLine'/>
                )
            }
        </div>
    )
}

export default HVACCategoryCard