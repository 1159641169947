import React, {} from 'react';
import './PumpAssetComponent.scss';
import PumpAssetCard from "./PumpAssetCard";
import {PumpAssetData} from "../../../types/HVACSummaryLandingDataType";
import ScrollOverRightIcon from '../../../assets/scrollHoverRight.svg';

type Props = {
    pumpAsset: PumpAssetData;
    handlePagination(id: string): void;
    showPagination: boolean;
};

const PumpAssetComponent = (props: Props) => {

    return (
        <div className="pumpAssetComponentMainDiv">

            <div className='pumpAssetCategoryMainDiv' key={props.pumpAsset.chilled.id}>
                <p className='pumpAssetCategoryText'>{props.pumpAsset.chilled.title}</p>
                <div className='pumpAssetMainDiv'>
                    {
                        props.pumpAsset.chilled.data.map((item, index) => (
                            <PumpAssetCard data={item} key={index} showLine={true}/>
                        ))
                    }
                    {
                        props.showPagination && (
                            <img
                                className='paginationIcon'
                                src={ScrollOverRightIcon}
                                onClick={() => props.handlePagination('CHILLER')}
                                alt='Pagination'
                            />
                        )
                    }
                </div>
            </div>

            <div className='pumpAssetCategoryMainDiv' key={props.pumpAsset.condensor.id}>
                <p className='pumpAssetCategoryText'>{props.pumpAsset.condensor.title}</p>
                <div className='pumpAssetMainDiv'>
                    {
                        props.pumpAsset.condensor.data.map((item, index) => (
                            <PumpAssetCard data={item} key={index} showLine={true}/>
                        ))
                    }
                    {
                        props.showPagination && (
                            <img
                                className='paginationIcon'
                                src={ScrollOverRightIcon}
                                onClick={() => props.handlePagination('CHILLER')}
                                alt='Pagination'
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default PumpAssetComponent;
