import React, {useEffect,} from "react"
import './HVACCategoryComponent.scss'
import HVACCategoryCard from "./HVACCategoryCard";
import {HVACSummaryLandingDataType} from "../../types/HVACSummaryLandingDataType";
import HVACSummaryUtils from "../../utils";
import {HVACCategoryHooks} from "../../hooks/HVACCategoryHooks";

type PropTypes = {
    data: HVACSummaryLandingDataType;
}

const HVACCategoryComponent = (props: PropTypes) => {

    const {categoryData, updateCategoryData} = HVACCategoryHooks();

    const extractData = () => {
        const utils = new HVACSummaryUtils();
        const result = utils.extractHVACCategoryData(props.data);
        updateCategoryData(result);
    };

    useEffect(() => {
        extractData()
    }, []);

    return (
        <div className='hvacCategoryComponent'>
            {
                categoryData.map((item, index) => (
                    <HVACCategoryCard data={item} key={index} showLine={index !== categoryData.length - 1}/>
                ))
            }
        </div>
    )
}

export default HVACCategoryComponent