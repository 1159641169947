import moment from 'moment';

export class FieldValidatorUtil {
  static phoneNumber(number: string): string | null {
    const errorMessage: string = "Please enter a valid 10-digit phone number";

    const condition1 = !/[a-zA-Z~`!@#$%^&*()+=\-[\]\\';,./{}|":<>?_]/.test(
      number
    );
    const condition2 = number.replace(/\D/g, "").length < 10;

    if (condition1 && condition2) {
      return errorMessage;
    }

    return null;
  }

  static postalCode(number: string): string | null {
    const numericRegex: RegExp = /^\d+$/;
    console.log("ERROR HERE HERE");
    const errorMessage: string = "Please enter a valid 6-digit postal code";

    const condition1 = !/[a-zA-Z~`!@#$%^&*()+=\-[\]\\';,./{}|":<>?_]/.test(
      number
    );
    if (condition1 && number.replace(/\D/g, "").length < 6) {
      return errorMessage;
    }

    return null;
  }

  static isNumeric(data: string, size: number): boolean {
    const regex = /^[0-9]+$/;
    return regex.test(data) && data.length <= size;
  }

  validateAssetName(data: string) {
    const regex = /^[A-Za-z0-9-#.,\/' ()&]+$/;
    return regex.test(data) && data.length <= 20;
  }

  /**
   * Validates a serial number.
   * Validity Check - uppercase and lowercase letters, digits, hyphens, and hashtags
   * @param {string} data - The serial number to validate.
   * @returns {boolean} - True if the serial number is valid, false otherwise.
   */
  validateSerialNumber(data: string) {
    if (!data) {
      return true;
    }
    const regex = /^[A-Za-z0-9-#]+$/;
    return regex.test(data) && data.length <= 20;
  }

  isValidAssetData(data: string) {
    const regex = /^[A-Za-z_]+$/;
    return data.length != 0;
  }

  isValidPhoneNumber(data: string) {
    const regex = /^[0-9]+$/;
    return regex.test(data) && data.length == 10;
  }

  static isAlphabetical(data: string): boolean {
    const regex = /^[A-Za-z]+$/;
    return regex.test(data);
  }

  isAssetAttributeValidate(data: string, regex: RegExp) {
    return regex.test(data);
  }

  static isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  validateToAndAttName(data: string) {
    if (data === "" || data === undefined) {
      return { isValid: false, message: "This field is required*" };
    }
    const regex = /^[A-Za-z ']+$/;
    const result = regex.test(data);
    if (result) {
      if (data.length > 100) {
        return { isValid: false, message: "Not valid length*" };
      } else {
        return { isValid: true, message: "" };
      }
    } else {
      return { isValid: false, message: "Not valid data*" };
    }
  }

  validateOrgDetail(data: string) {
    const regex = /^[A-Za-z ']+$/;
    return regex.test(data) && data.length <= 100;
  }

  validateOrgEmail(data: string) {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(data) && data.length <= 255;
  }

  validateCity(data: string) {
    if (data === "" || data === undefined) {
      return { isValid: false, message: "This field is required*" };
    }
    const regex = /^[A-Za-z ']+$/;
    const result = regex.test(data);
    if (result) {
      if (data.length > 100) {
        return { isValid: false, message: "Not valid length*" };
      } else {
        return { isValid: true, message: "" };
      }
    } else {
      return { isValid: false, message: "Not valid data*" };
    }
  }

  validateAddressLine(data: string) {
    if (data === "" || data === undefined) {
      return { isValid: false, message: "This field is required*" };
    }
    const regex = /^[A-Za-z0-9-#.,&\/' ]+$/;
    const result = regex.test(data);
    if (result) {
      if (data.length > 255) {
        return { isValid: false, message: "Not valid length*" };
      } else {
        return { isValid: true, message: "" };
      }
    } else {
      return { isValid: false, message: "Not valid data*" };
    }
  }

  checkMandatoryFields(obj, fields) {
    return fields.every((field) => 
      _.isString(obj[field])?obj[field].trim() !== "":obj[field]?.value.trim() !=="")
  }

  checkNullData(data: string) {
    if (data === "" || data === undefined || data === null) {
      return false;
    }
    return true;
  }

  validateDateLessThanToday(selectedDate: any) {
    if (!selectedDate){
      return true // date is purchase date is optional
    }
    const parsedDate=Date.parse(selectedDate)
    console.log(parsedDate)
    if (!parsedDate) {
      return false;
    }
    const today = moment().toDate().getTime();
    const minDate=moment().subtract(50,'years').toDate().getTime();
    return parsedDate >= minDate && parsedDate <= today;
  }
}
