import React, { useState,useEffect } from "react";
import { Modal } from "react-bootstrap";
import IconClose from '../../../../../assets/icons/IconClose.svg';
import AddIcon from '../../../../../assets/icons/AddIcon.svg';
import DeleteIcon from '../ViewUserIcons/DeleteIcon.svg';
import Select from "react-select";
import UseStatics from "../../../utils/utils";
import { AssignUserFacility } from "../service/ViewUserPostData";
import './AssignUserToFacility.scss';
export const AssignUserToFacility = (props) => {
  const { data, setAddToFacilityShow } = props;
  const { facilitiesData, facilityRoleData, } = UseStatics();
  const [add, setAdd] = useState([
    { facility: "", role: "", selectedOptionsFacility: null, selectedOptionsRole: null },
  ]);
  const [openDropdowns, setOpenDropdowns] = useState(add.map(() => ({ facility: false, role: false })));
  const [selectedRoleIds, setSelectedRoleIds] = useState<any[]>({});
  const [selectedFacilityIds, setSelectedFacilityIds] = useState<{ [index: number]: string }>({});
  const handleAddFacilities = () => {
    setAdd([...add, { selectedOptionsFacility: '', selectedOptionsRole: '' }]);
    setOpenDropdowns([...openDropdowns, { facility: false, role: false }]);
  };
  const handleRemoveFacilities = (index: number) => {
    const updatedAdd = add.filter((_, i) => i !== index);
    setAdd(updatedAdd);
    const updatedOpenDropdowns = openDropdowns.filter((_, i) => i !== index);
    setOpenDropdowns(updatedOpenDropdowns);
    const updatedSelectedRoleIds = Object.fromEntries(
      Object.entries(selectedRoleIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedRoleIds(updatedSelectedRoleIds);

    const updatedSelectedFacilityIds = Object.fromEntries(
      Object.entries(selectedFacilityIds)
        .filter(([key]) => parseInt(key) !== index)
        .map(([key, value]) => [key > index ? key - 1 : key, value])
    );
    setSelectedFacilityIds(updatedSelectedFacilityIds);
  };
  const handleFacility = (index, selectedOption) => {
    const updatedAdd = [...add];
    updatedAdd[index].selectedOptionsFacility = selectedOption;
    updatedAdd[index].facility = selectedOption?.label || '';
    setAdd(updatedAdd);
  };

  const handleRole = (index, selectedOption) => {
    const updatedAdd = [...add];
    updatedAdd[index].selectedOptionsRole = selectedOption;
    updatedAdd[index].role = selectedOption?.label || '';
    setAdd(updatedAdd);
  };
  const handleAssignUserFacility = async (e) => {
    setAddToFacilityShow(false);

    const payLoadAssignUser = {
      toAdd: add.map((el) => ({
        facilityId: el?.selectedOptionsFacility.value,
        partyId: data?.userLoginDetails?.[0]?.partyId,
        roleTypeId: el.selectedOptionsRole.value,
      })),
      toRemove: []
    };
    const result = await AssignUserFacility(payLoadAssignUser);
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      height: '40px',
      width: '300px',
      border: state.isFocused ? '2px solid #007bff' : '2px solid #ced4da',
      boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
      '&:hover': {
        border: '2px solid #007bff'
      }
    }),
    placeholder: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    input: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    singleValue: (provided) => ({
      ...provided,
      fontSize: '16px',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: '8px',
    }),
    clearIndicator: (provided) => ({
      ...provided,
      padding: '8px',
    }),
  };
  useEffect(() => {
    if (data && data.facilityDetails?.length > 0) {
      const defaultValues = data.facilityDetails.map((facilityDetail) => ({
        facility: facilityDetail.facilityName,
        role: facilityDetail.roleTypeDescription,
        selectedOptionsFacility: {
          value: facilityDetail.facilityId,
          label: facilityDetail.facilityName,
        },
        selectedOptionsRole: {
          value: facilityDetail.roleTypeId,
          label: facilityDetail.roleTypeDescription,
        },
      }));

      setAdd(defaultValues);
    }
  }, [data]);
  return (
    <Modal
      show={setAddToFacilityShow}
      onHide={() => setAddToFacilityShow(false)}
      centered
      size="lg"
      backdrop="static"
      className="AssignUserFacilityPopupModals"
    >
      <div className="AssignUserFacilityPopUp">
        <div className="AssignUserFacilityHeader">
          <div className="AssignUserFacilityHeaderContent">Map user to facilities</div>
          <div className="AssignUserFacilityHeaderClose" onClick={() => setAddToFacilityShow(false)}> <img className="iconCloseImg" src={IconClose} alt="icon"/></div>
        </div>
        <div className="AssignUserFacilityBody">
          <div className="AssignUserFacilityBodyHeaderss">
            Adding <b>{data?.userBasicDetails?.[0]?.firstName} ({data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString})</b> to following facilities.
          </div>
          <div className="AssignUserFacilityBodyLabels">
            <p>Facility</p>
            <p>Role</p>
          </div>
        <div className="AssignUserFacilityDiv">
        {add.map((el, index) => (
          <div className="AssignUserFacilityContainer" key={index}>
            <Select
              value={el.selectedOptionsFacility}
              styles={customStyles}
              options={facilitiesData.map((facility) => ({
                value: facility.id,
                label: facility.name,
              }))}
              onChange={(selectedOption) => handleFacility(index, selectedOption)}
              isClearable
            />
            <Select
              value={el.selectedOptionsRole}
              styles={customStyles}
              options={facilityRoleData.map((role) => ({
                value: role.id,
                label: role.name,
              }))}
              isClearable
              onChange={(selectedOption) => handleRole(index, selectedOption)}
            />
            <img
              src={DeleteIcon}
              alt="icon"
              className="deleteIcon"
              onClick={() => handleRemoveFacilities(index)}
            />
          </div>
        ))}
        </div>
        </div>
        <div className="AssignUserFacilityDetailsBtnCont">
          <button
            className="AssignUserFacilityDetailsBtn"
            onClick={handleAddFacilities}
          >
            <img src={AddIcon} alt="icon" />
            Add
          </button>
        </div>
        <Modal.Footer style={{ border: 'none' }}>
          <div className="AssignUserFacilityFooter">
            <button
              onClick={() => setAddToFacilityShow(false)}
              className="AssignUserFacilityCancelBtn"
            >
              Cancel
            </button>
            <button
              onClick={handleAssignUserFacility}
              className="AssignUserFacilityMappingBtn"
            >
              {" "}
              Confirm Mapping
            </button>
          </div>
        </Modal.Footer>
      </div>
    </Modal>
  )
}