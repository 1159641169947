import React, {useEffect, useState} from 'react';
import './HVACSummaryDashboardCard.scss';
import SupplyIcon from '../../assets/ic-supply-icon.svg';
import ReturnIcon from '../../assets/ic-return-icon.svg';
import LocationIcon from '../../assets/Location.svg';
import NoDataAvailableIcon from "../../../assetList/assets/noDataAvailable.svg";
import CurrentLoad from '../../assets/ic-current-load.svg';
import {AssetData} from "../../types/HVACSummaryLandingDataType";
import {LiveMeter} from "../../../assetList/types";
import moment from "moment";
import {checkUndefinedConditions, getFacilityDetails} from "../../../assetList/utils";

type Props = {
    data: AssetData;
    index: number;
    id: string;
    isSelected: boolean;
    handleSelectedAsset(assetId: string): void;
}

const HVACSummaryDashboardCard = (props: Props) => {

    const showErrorMessage = props.index % 2 == 0;

    const [assetStatus, setAssetStatus] = useState<string | null>(null);
    const [assetLiveMeter, setAssetLiveMeter] = useState<LiveMeter[] | null>(null);
    const [assetSupplyData, setAssetSupplyData] = useState<any>('');
    const [assetReturnData, setAssetReturnData] = useState<any>('');
    const [facilityData, setFacilityData] = useState<any>({});

    const getValue = (id: string) => {
        if (props.data !== null && props.data.hasOwnProperty("meterData") && props.data.meterData.hasOwnProperty("liveMeters")) {
            const liveMeters = props.data.meterData.liveMeters;

            const result = liveMeters.find((item) => item.meterTypeId === 'id');

            return result === undefined || result.meterValue === null ? '-' : result.meterValue;
        } else {
            return '-';
        }
    }

    const extractData = async () => {
        if (props.data.hasOwnProperty("meterData") && props.data.meterData.hasOwnProperty("liveMeters")) {
            const liveMeters = props.data.meterData.liveMeters;
            const assetStatusObj = liveMeters.find((item: LiveMeter) => item.meterTypeId === 'EQUIP_STATUS');
            const assetStatus = assetStatusObj === undefined ? null : assetStatusObj.meterValue;

            const assetSupplyObj = liveMeters.find((item: LiveMeter) => item.meterTypeId === 'SUPPLY_TEMP_1');
            const assetSupplyValue = assetSupplyObj === undefined || assetSupplyObj.meterValue === null ? '-' : assetSupplyObj?.meterValue?.toFixed(2);

            const assetReturnObj = liveMeters.find((item: LiveMeter) => item.meterTypeId === 'RET_TEMP_1');
            const assetReturnValue = assetReturnObj === undefined || assetReturnObj.meterValue === null ? '-' : assetReturnObj?.meterValue?.toFixed(2);

            setAssetSupplyData(assetSupplyValue);
            setAssetReturnData(assetReturnValue);
            setAssetStatus(assetStatus);
            setAssetLiveMeter(props.data.meterData.liveMeters);

            const facility = await getFacilityDetails(props.data.locatedAtFacilityId);
            setFacilityData(facility);

        } else {
            setAssetLiveMeter(null)
        }
    }

    useEffect(() => {
        extractData();
    }, [props.data]);

    return (
        <div className='fcuSummaryCardMainDiv'
             style={{borderColor: props.isSelected ? 'rgba(55, 77, 198, 1)' : 'rgba(218, 226, 255, 1)'}}
             onClick={() => props.handleSelectedAsset(props.data.assetId)}>
            <div className='fcuSummaryCardHeaderMainDiv'>
                <div className='fcuSummaryCardHeaderFirstDiv'>
                    {/* Todo Asset Serial Number */}
                    {/*<p className='fcuAssetProductId'>{}</p>*/}
                    <p className='fcuAssetStatusId'>{assetStatus === null ? '-' : assetStatus}</p>
                </div>
                <div className='fcuSummaryCardHeaderSecondDiv'>
                    <p className='fcuAssetName'>{props.data.assetName}</p>
                    {
                        props.id === 'FCU' && assetStatus !== null && (
                            <p className='fcuStatusDetails'>{assetStatus}</p>
                        )
                    }

                </div>
            </div>
            {
                assetLiveMeter === null ? (
                    <div className='noDataAvailableDiv'>
                        <img src={NoDataAvailableIcon}/>
                        <p className='noDataAvailableText'>Data unavailable at the moment</p>
                    </div>
                ) : (
                    <div className='fcuSummaryCardBodyDiv'>
                        <div className='fcuSummaryCardBodyParentDiv'>
                            <div className='fcuDataMainDiv'>
                                <div className='fcuDataDiv'>
                                    <img src={SupplyIcon}/>
                                    <p className='fcuAssetSupplyData'>{assetSupplyData}°C</p>
                                </div>
                                <p className='fcuDataCategoryText'>Supply</p>
                            </div>
                            <div className='fcuDataMainDiv'>
                                <div className='fcuDataDiv'>
                                    <img src={ReturnIcon}/>
                                    <p className='fcuAssetSupplyData'>{assetReturnData}°C</p>
                                </div>
                                <p className='fcuDataCategoryText'>Return</p>
                            </div>
                        </div>
                        {
                            props.id === 'AHU' && (
                                <div className='fcuDataMainDivs'>
                                    <div className='fcuDataDiv'>
                                        <img src={CurrentLoad}/>
                                        <p className='fcuAssetSupplyData'>{getValue('KW')} kW</p>
                                    </div>
                                    <p className='fcuDataCategoryText'>Current Load</p>
                                </div>
                            )
                        }
                    </div>
                )
            }
            <div className='fcuSummaryCardFooterDiv'>
                <div className='fcuAssetLocationDiv'>
                    <img src={LocationIcon}/>
                    <p className='fcuLocationDetails'>
                        {checkUndefinedConditions(facilityData?.facilityName, false)}
                        {checkUndefinedConditions(facilityData?.address?.city, true)}
                        {checkUndefinedConditions(facilityData?.address?.stateProvinceGeoId, true)}
                    </p>
                </div>
                {
                    !showErrorMessage && (
                        <p className='fcuLastUpdatedTime'>{moment(props.data.lastCommTime).fromNow()}</p>
                    )
                }
            </div>
        </div>
    );
};

export default HVACSummaryDashboardCard;
