import React, {useEffect, useState} from "react";
import './HVACSummaryDashboardFooter.scss';
import FanIcon from '../../assets/ic-fan.svg';
import ActuatorIcon from '../../assets/icon-actuator.svg';
import NavigateIcon from '../../assets/icon-navigate.svg';
import {AssetData} from "../../types/HVACSummaryLandingDataType";
import {navigateAssetDetailPage} from "../../../../Utils/utils";
import {useNavigate} from "react-router-dom";
import Lottie from "react-lottie";
import vfd_fan_lottie from "../../../../assets/Lotties/vfdFanAHU.json";

const Vfd_Fan_Lottie = {
    loop: true,
    autoplay: true,
    animationData: vfd_fan_lottie,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

type Props = {
    id: string;
    data: AssetData;
}

type SummaryType = {
    title: string;
    text: any;
    backgroundColor: string;
    color: string;
}

const HVACSummaryDashboardFooter = (props: Props) => {
    const navigate = useNavigate();

    const [summaryData, setSummaryData] = useState<SummaryType[]>([])

    const getValue = (id: string) => {
        if (props?.data !== null && props?.data?.hasOwnProperty("meterData") && props?.data?.meterData.hasOwnProperty("liveMeters")) {
            const liveMeters = props.data.meterData.liveMeters;

            const result = liveMeters.find((item) => item.meterTypeId === 'id');

            return result === undefined || result.meterValue === null ? '-' : result.meterValue;
        } else {
            return '-';
        }
    }

    const extractData = () => {
        if (props?.data !== null && props?.data?.hasOwnProperty("meterData") && props?.data?.meterData?.hasOwnProperty("liveMeters")) {
            const liveMeters = props.data.meterData.liveMeters;

            if (props.id === 'AHU') {
                const filterStatus = liveMeters.find((item) => item.meterTypeId === 'FILTER_STATUS');
                const fanTripStatus = liveMeters.find((item) => item.meterTypeId === 'SUPP_FAN_TRIP_ST');
                const mode = liveMeters.find((item) => item.meterTypeId === 'EQUIP_MODE');
                const supplyFanState = liveMeters.find((item) => item.meterTypeId === 'SUP_FAN_STATUS');

                const summaryData = [
                    {
                        title: 'Filter Status',
                        text: filterStatus === undefined || filterStatus.meterValue === null ? '-' : filterStatus.meterValue,
                        backgroundColor: 'rgba(37, 56, 88, 0.2)',
                        color: 'rgba(37, 56, 88, 1)',
                    },
                    {
                        title: 'Fan trip status',
                        text: fanTripStatus === undefined || fanTripStatus.meterValue === null ? '-' : fanTripStatus.meterValue,
                        backgroundColor: 'rgba(219, 240, 220, 1)',
                        color: 'rgba(60, 139, 63, 1)',
                    },
                    {
                        title: 'Mode',
                        text: mode === undefined || mode.meterValue === null ? '-' : mode.meterValue,
                        backgroundColor: 'rgba(37, 56, 88, 0.2)',
                        color: 'rgba(37, 56, 88, 1)',
                    },
                    {
                        title: 'Supply fan state',
                        text: supplyFanState === undefined || supplyFanState.meterValue === null ? '-' : supplyFanState.meterValue,
                        backgroundColor: 'rgba(219, 240, 220, 1)',
                        color: 'rgba(227, 27, 12, 1)',
                    },
                ];
                setSummaryData(summaryData)
            } else {
                const filterStatus = liveMeters.find((item) => item.meterTypeId === 'FILTER_STATUS');
                const fanTripStatus = liveMeters.find((item) => item.meterTypeId === 'SUPP_FAN_TRIP_ST');
                const fcuTripStatus = liveMeters.find((item) => item.meterTypeId === 'EQUIP_TRIP_ST');

                const summaryData = [
                    {
                        title: 'Filter Status',
                        text: filterStatus === undefined || filterStatus.meterValue === null ? '-' : filterStatus.meterValue,
                        backgroundColor: 'rgba(37, 56, 88, 0.2)',
                        color: 'rgba(37, 56, 88, 1)',
                    },
                    {
                        title: 'FCU trip status',
                        text: fcuTripStatus === undefined || fcuTripStatus.meterValue === null ? '-' : fcuTripStatus.meterValue,
                        backgroundColor: 'rgba(219, 240, 220, 1)',
                        color: fcuTripStatus?.colorCode || 'rgba(60, 139, 63, 1)',
                    },
                    {
                        title: 'Fan trip status',
                        text: fanTripStatus === undefined || fanTripStatus.meterValue === null ? '-' : fanTripStatus.meterValue,
                        backgroundColor: 'rgba(219, 240, 220, 1)',
                        color: fanTripStatus?.colorCode || 'rgba(60, 139, 63, 1)',
                    },
                ];
                setSummaryData(summaryData)
            }
        }
    }

    useEffect(() => {
        extractData();
    }, [props.data]);

    return (
        <div className='ahuSummaryParentDiv'>

            <div className='ahuSummaryFooterMainContainer'>
                <div className='ahuSummaryFooterFirstDiv'>
                    {
                        summaryData.map((item, index) => (
                            <div className='ahuSummaryFooterContainer' key={`${item.title}-${index}`}>
                                <div className='ahuSummaryContainer'>
                                    <p className='title1'>{item.title}</p>
                                    <p className='text1'
                                       style={{
                                           backgroundColor: item.backgroundColor,
                                           color: item.color,
                                       }}>{item.text}</p>
                                </div>
                                {
                                    index !== summaryData.length - 1 && (
                                        <div className='ahuSummaryLineContainer'/>
                                    )
                                }
                            </div>
                        ))
                    }
                </div>
                <div className='ahuSummaryFooterSecondDiv'>

                    {props.id !== 'FCU' ? (
                        <>
                            <div className='ahuSummarySpeedDivMainContainer'>
                                <div className='ahuSummaryFavDiv'>
                                    <Lottie options={Vfd_Fan_Lottie} height={30} width={30} />
                                </div>
                                <div className='ahuSummaryFavDataDiv'>
                                    <p className='ahuSummaryFavDataTitle'>VFD Speed</p>
                                    <p className='ahuSummaryFavDataText'>{getValue('VFD_SPEED_PCT')}%</p>
                                </div>
                                <div className='ahuSummaryFavDataDiv'>
                                    <p className='ahuSummaryFavDataTitle'>Override</p>
                                    <p className='ahuSummaryFavDataText'>56%</p>
                                </div>
                            </div>

                            <div className='ahuSummaryLineContainer'/>
                        </>
                    ) : (
                        <>
                            <div className='ahuSummaryContainer'>
                                <p className='title1'>Manual override status</p>
                                <p className='text1'
                                   style={{
                                       backgroundColor: 'rgba(219, 240, 220, 1)',
                                       color: 'rgba(227, 27, 12, 1)',
                                   }}>{getValue('MAN_OVERDE_ST')}</p>
                            </div>

                            <div className='ahuSummaryLineContainer'/>

                            <div className='ahuSummaryContainer'>
                                <p className='title1'>Manual override lock status</p>
                                <p className='text1'
                                   style={{
                                       backgroundColor: 'rgba(219, 240, 220, 1)',
                                       color: 'rgba(227, 27, 12, 1)',
                                   }}>{getValue('MAN_OVERDE_LOCK_ST')}</p>
                            </div>

                            <div className='ahuSummaryLineContainer'/>

                        </>
                    )}


                    <div className='actuatorDivContainer'>
                        <img src={ActuatorIcon} alt='Actuator'/>
                        <p className='actuatorDivContainerTitle'>Chilled water valve</p>
                        <p className='actuatorDivContainerValue'>{getValue('CHIL_WTR_VALVE')}%</p>
                    </div>
                </div>
            </div>

            {/*   Navigate to Asset detail Page */}
            <div className='ahuSummaryNavigateDiv'
                 onClick={() => navigateAssetDetailPage(props.data.assetId, navigate)}>
                <p className='ahuSummaryNavigateText'>View Details</p>
                <img src={NavigateIcon} alt='Navigate'/>
            </div>
        </div>
    );
};

export default HVACSummaryDashboardFooter;
