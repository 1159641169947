import React, { useEffect, useState, useRef } from "react";
import Unchecked from '../../../assets/icons/Checked.svg';
import Checked from '../../../assets/icons/Unchecked.svg';
import Select from "react-select";
import UseStatics from "../utils/utils";
import Modal from "react-bootstrap/Modal";
import IconClose from "../../../assets/icons/IconClose.svg";
import { customStylesCountry } from "../editUser/customStylesReactSelect";
export const AddressPopUp = (props) => {
    const { setBasicInfo,setValidationMessages,setshowUserAddAddress,setShouldValidate,validationMessages,basicInfo } = props;
    const {
        addressPurposeData,
        countryArr,
    } = UseStatics();
    const [images, setImages] = useState<File>(null);
    const [filteredStates, setFilteredStates] = useState([]);
    const [validationMessagesAddress, setValidationMessagesAddress] = useState(validationMessages);
    const [basicInfor, setBasicInfor] = useState(basicInfo);
    const selectRef = useRef(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const filterAddressData = (el: string) => {
        setBasicInfor((prevState) => ({
          ...prevState,
          selectedAddressData: prevState.selectedAddressData.includes(el)
            ? prevState.selectedAddressData.filter((obj: string) => obj !== el)
            : [...prevState.selectedAddressData, el],
        }));
      };

    const handleImageChange = async (newImage: File) => {
        setImages(newImage);
    };

    useEffect(() => {
        handleImageChange(null);
    }, []);


    const validateValue = (value: string | any[], regex: { test: (arg0: any) => any; }, maxLength: number) => {
        if (!regex.test(value)) {
            return "Invalid format";
        }
        if (value.length > maxLength) {
            return `The value must be less than ${maxLength} characters.`;
        }
        return "";
    };
    const handleChange = (field: string, regex: RegExp, maxLength: number, e: React.FormEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const validationMessagesAddress = validateValue(value, regex, maxLength);
        if (validationMessagesAddress === "") {
            setBasicInfor(prevState => ({ ...prevState, [field]: value }));
        }

        setValidationMessages(prevMessages => ({
            ...prevMessages,
            [field]: validationMessagesAddress
        }));

        if (validationMessagesAddress === "") {
            setShouldValidate(false);
        }
    };
    useEffect(() => {
        if (basicInfor.selectedCountry) {
          const selectedCountryObj = countryArr.find(
            (country) => country.id === basicInfor.selectedCountry
          );
          if (selectedCountryObj && selectedCountryObj.states) {
            const statesMapped = selectedCountryObj.states.map((state) => ({
              value: state.geoId,
              label: state.geoName.trim(), 
            }));
        setFilteredStates(statesMapped); 
          } else {
            setFilteredStates([]);
          }
        } else {
          setFilteredStates([]);
        }
      }, [basicInfor.selectedCountry, countryArr]); 
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (selectRef.current && !selectRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [selectRef]);

    useEffect(()=>{
        setBasicInfo(basicInfor)
    },[basicInfo,basicInfor])
    useEffect(()=>{
        setValidationMessagesAddress(validationMessages)
    },[validationMessagesAddress,validationMessages])
    return (
        <Modal
            show={setshowUserAddAddress}
            onHide={() => setshowUserAddAddress(false)}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editAddressPopup"
        >
            <Modal.Header className="editAddressHeaderCont">
                <h4 className="userAddAddressTxt">Add Address</h4>
                <div
                    className="closeIconDiv"
                    onClick={() => setshowUserAddAddress(false)}
                >
                    <img src={IconClose} alt="icon" />
                </div>
            </Modal.Header>
            <Modal.Body className="editAddressBody">
                <div className="editAddressBodyCont">
                    <div className="editAddressInputsCont">
                        <div className="addressTextInputsCont">
                            <label> To Name </label>
                            <input
                                type="text"
                                name="toName"
                                maxLength={100}
                                value={basicInfor.toName}
                                onInput={(e) => handleChange("toName", /^[A-Za-z' ]*$/, 100, e)}
                            />
                            {validationMessagesAddress.toName && (
                                <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.toName}</div>
                            )}
                        </div>

                        <div className="addressTextInputsCont">
                            <label> Attn Name </label>
                            <input
                                type="text"
                                name="attnName"
                                maxLength={100}
                                value={basicInfor.attnName}
                                onInput={(e) => handleChange("attnName", /^[A-Za-z' ]*$/, 100, e)}
                            />
                            {validationMessagesAddress.attnName && (
                                <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.attnName}</div>
                            )}
                        </div>

                        <div className="addressTextInputsCont">
                            <label>Address Line 1 </label>
                            <input
                                type="text"
                                maxLength={150}
                                name="addressLine1"
                                value={basicInfor.addressLine1}
                                onInput={(e) => handleChange("addressLine1", /^[A-Za-z0-9#.'_ ]*$/, 150, e)}
                            />
                            {validationMessagesAddress.addressLine1 && (
                                <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.addressLine1}</div>
                            )}
                        </div>

                        <div className="addressTextInputsCont">
                            <label>Address Line 2  </label>
                            <input
                                type="text"
                                maxLength={150}
                                name="addressLine2"
                                value={basicInfor.addressLine2}
                                onInput={(e) => handleChange("addressLine2", /^[A-Za-z0-9#.'_ ]*$/, 150, e)}
                            />
                            {validationMessagesAddress.addressLine2 && (
                                <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.addressLine2}</div>
                            )}
                        </div>

                        <div className="addressTextInputsCont">
                            <label>City </label>
                            <input
                                type="text"
                                maxLength={30}
                                name="city"
                                value={basicInfor.city}
                                onInput={(e) => handleChange("city", /^[A-Za-z' ]*$/, 30, e)}
                            />
                            {validationMessagesAddress.city && (
                                <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.city}</div>
                            )}
                        </div>
                        <div className="addressSelectorCont">
                            <label>Country</label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={countryArr.map((ele) => ({ value: ele.id, label: ele.name })).find((option) => option.value === basicInfor.selectedCountry) || null}
                                styles={customStylesCountry}
                                options={countryArr.map((ele) => ({
                                    value: ele.id,
                                    label: ele.name,
                                }))}
                                isClearable
                                onChange={(el) =>setBasicInfor({...basicInfor,selectedCountry: el ? el.value : null,})}
                            />
                        </div>
                        <div className="addressTextInputsCont">
                            <label>Postal Code </label>
                            <input
                                type="text"
                                maxLength={10}
                                name="postalCode"
                                value={basicInfor.postalCode}
                                onInput={(e) => handleChange("postalCode", /^[A-Za-z0-9]*$/, 10, e)}
                            />
                            {validationMessagesAddress.postalCode && (
                                <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.postalCode}</div>
                            )}
                        </div>
                        <div className="addressSelectorCont">
                            <label>State/Province </label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={filteredStates.find((state) => state.value === basicInfor.selectedState) || null}
                                styles={customStylesCountry}
                                options={filteredStates}
                                isClearable
                                onChange={(el) =>
                                    setBasicInfor({
                                        ...basicInfor,
                                        selectedState: el ? el.value : null,
                                    })
                                }
                            />
                        </div>
                        {validationMessagesAddress.state && (
                            <div style={{ color: 'red', width: '100%' }}>{validationMessagesAddress.state}</div>
                        )}
                    </div>

                    <div className="editAddressPurposeCont">
                        {addressPurposeData.length > 0 && addressPurposeData.map((el:
                            { id: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | null | undefined) => (
                            <div className="addressPurposeCont" key={index}  onClick={() => filterAddressData(el.id)}>
                                {basicInfor.selectedAddressData.includes(el.id) ? (
                                    <img
                                        src={Checked}
                                        alt="checked"
                                    />
                                ) : (
                                    <img
                                        src={Unchecked}
                                        alt="unchecked"
                                    />
                                )}
                                <label>{el.name}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="editAddressFooter">
                <button
                    className="userCancelBtn"
                    onClick={() => setshowUserAddAddress(false)}
                >
                    Cancel
                </button>
                <button
                    className="addAddressBtn"
                    onClick={() => setshowUserAddAddress(false)}
                >
                    Add Address
                </button>
            </Modal.Footer>
        </Modal>
    )
}