import React from 'react';
import './CoolingTowerAssetComponent.scss';
import CoolingTowerAssetCard from "./CoolingTowerAssetCard";
import {AssetData, HVACAssetData} from "../../../types/HVACSummaryLandingDataType";

type Props = {
    data: HVACAssetData;
}

const CoolingTowerAssetComponent = (props: Props) => {

    return (
        <div className='coolingTowerAssetComponentMainDiv'>
            {
                props.data.data.map((item: AssetData) => (
                    <CoolingTowerAssetCard key={item.assetId} data={item}/>
                ))
            }
        </div>
    );
};

export default CoolingTowerAssetComponent;
