import {RefObject} from "react";
import {AssetTypeCount} from "../types/HVACSummaryLandingDataType";

export function useCategoryWidthCalculator() {
    const calculateWidth = (ref: RefObject<HTMLDivElement>, data: AssetTypeCount) => {
        if (ref.current) {
            const totalWidth = ref.current.offsetWidth;

            const totalCount = data.count;
            const onCount = data.runStatusCount.ON === undefined ? 0 : data.runStatusCount.ON;
            const offCount = data.runStatusCount.OFF === undefined ? 0 : data.runStatusCount.OFF;

            const contributionForOn = onCount / totalCount;
            const widthForOn = contributionForOn * totalWidth;

            const contributionForOff = offCount / totalCount;
            const widthForOff = contributionForOff * totalWidth;

            const widthForUnavailable = totalWidth - (widthForOn + widthForOff);

            return {
                widthForOn: widthForOn,
                widthForOff: widthForOff,
                widthForUnavailable: widthForUnavailable,
                totalWidth: totalWidth,
            }
        }
        return {
            widthForOn: 0,
            widthForOff: 0,
            widthForUnavailable: 0,
            totalWidth: 0,
        }
    }

    return {calculateWidth};

}