import energyAssetIcon from "../../../assets/image/energy-asset-icon.svg";
import waterAsseticon from "../../../assets/image/water-asset-icon.svg";
import temperatureIcon from "../../../assets/image/temperature-icon.svg";
import energytempicon from "../../../assets/image/energy-temp-icon.png";
import dgicon from "../../../assets/image/dg-icon.png";
import AirAssetIcon from "../../../assets/icons/AirAssetIcon.svg";
import fuelAsseticon from "../../../assets/icons/fuelAsseticon.svg";
import iconairquality from "../../../assets/icons/iconairquality.svg";
import TempControllerIcon from "../../../assets/icons/TempControllerIcon.svg";
import temperatureCompressor from "../../../assets/icons/Asset-Icon-compressor.svg";
import pressureMonitoring from "../../../assets/icons/icon-assets-pressure.svg";
import airControllerAsset from "../../../assets/icons/air-controller-asset.svg"; //icon-assets-default
import defaultAssetIcon from "../../../assets/image/icon-assets-default.svg";
import waterPulseIcon from "../../../assets/image/WaterPulseIcon.svg";
import LocalStorageService from "../../../Utils/LocalStorageService";
import {Attribute, CardMeter, ThresholdRangeDataType} from "../types";
import {Color} from "../../../Utils/enumData";

// This function takes asset type as a parameter and returns matched image and if not matched or undefined it will return default asset icon.
export const assetImageList = (assetType: string | undefined) => {
  const assetIcons = {
    ENERGY: energyAssetIcon,
    TEMP: temperatureIcon,
    TEMP_ENERGY: energytempicon,
    DG: dgicon,
    AIR: AirAssetIcon,
    WATER: waterAsseticon,
    FUEL: fuelAsseticon,
    IAQ: iconairquality,
    TEMPCONTROLLER: TempControllerIcon,
    PRESSURE: pressureMonitoring,
    COMPRESSOR: temperatureCompressor,
    AC_CONTROLLER: airControllerAsset,
    WATER_PULSE: waterPulseIcon,
    LEAK_SENSE: defaultAssetIcon,
    EDGE: defaultAssetIcon,
  };
  return assetIcons[assetType] || defaultAssetIcon;
};

export const statusColorMap = {
  FA_OFFLINE: "#F44336",
  FA_IN_ACTIVE: "#f44747",
  FA_SLOW: "#E9BE03",
  FA_LOST: "#445B94",
  FA_MAINT: "#FF8B00",
  FA_ACTIVE: "#4CAF50",
  FA_ONLINE: "#4CAF50",
  default: "#37B5C6",
};

export const statusMap = {
  FA_OFFLINE: { backgroundColor: "#F44336", text: "Offline" },
  FA_IN_ACTIVE: { backgroundColor: "#f44747", text: "Inactive" },
  FA_SLOW: { backgroundColor: "#E9BE03", text: "Slow" },
  FA_LOST: { backgroundColor: "#445B94", text: "Lost" },
  FA_MAINT: { backgroundColor: "#FF8B00", text: "Maintenance" },
  FA_ACTIVE: { backgroundColor: "#4CAF50", text: "Active" },
  FA_ONLINE: { backgroundColor: "#4CAF50", text: "Online" },
};

export const getAssetTypeDesc = async (id: string) => {
  try {
    const assetTypeList: { fixedAssetTypeId: string; description: string }[] =
    await LocalStorageService.getUserSpecificAssetTypeData();
    const result = assetTypeList.find((item) => item.fixedAssetTypeId === id);
    if (result === undefined) {
      return {
        fixedAssetTypeId: "",
        description: "",
      };
    }
    return result;
  } catch (error) {
    console.error(error);
    return {
      fixedAssetTypeId: "",
      description: "",
    };
  }
};

export const getFacilityDetails = async (id: string) => {
  let facilityIds: any = LocalStorageService.getFacilityData();
  const facility = facilityIds.find((el: any) => el.facilityId === id);
  return facility;
};

export const checkUndefinedConditions = (value: any, addComma: boolean) => {
  if (value === undefined || value === null) return "";
  else return addComma ? `, ${value}` : value;
};

export const checkAssetDataStatus = (
  isLoading: boolean,
  isFetching: boolean,
  showError: boolean,
  assetListLength: number,
  showLoading: boolean,
  showLoadMore: boolean
) => {
  const loadingStatus = isLoading || isFetching;
  const emptyStatus =
    !showError && assetListLength === 0 && !(isFetching || isLoading);
  const errorStatus = showError && assetListLength === 0;
  const showAssetStatus = !showLoading && assetListLength > 0;
  const showLoadMoreButton = !showLoadMore;
  const result = {
    showLoading: loadingStatus,
    showError: errorStatus,
    showEmptyData: emptyStatus,
    showAsset: showAssetStatus,
    showLoadMoreButton: showLoadMoreButton,
  };
  return result;
};

//for color change in asset list metrics
export const getAssetAttributeData = (
  cardMeterData: CardMeter,
  attributeList: Attribute[]
) => {
  const colorValue = cardMeterData.hasOwnProperty("colorCode") && cardMeterData.colorCode !== null ?
      cardMeterData.colorCode : Color.BLACK;

  const isUpperThreshold = cardMeterData.hasOwnProperty("upperThresholdAttr");
  const isLowerThreshold = cardMeterData.hasOwnProperty("lowerThresholdAttr");
  const actualValue = cardMeterData.meterValue;

  if (isUpperThreshold && isLowerThreshold) {
    const upperThresholdAttr = cardMeterData.upperThresholdAttr;
    const lowerThresholdAttr = cardMeterData.lowerThresholdAttr;

    if (upperThresholdAttr === null && lowerThresholdAttr === null) {
      return {
        color: colorValue,
        showRange: false,
        rangeValue: 0,
      };
    }

    const upperThresholdAttrData =
      upperThresholdAttr !== null
        ? attributeList.find((item) => item.attrName === upperThresholdAttr)
        : null;
    const lowerThresholdAttrData =
      lowerThresholdAttr !== null
        ? attributeList.find((item) => item.attrName === lowerThresholdAttr)
        : null;

    // If min and max range of the data is available.
    if (
      upperThresholdAttrData?.attrValue !== null &&
      lowerThresholdAttrData?.attrValue !== null &&
      upperThresholdAttr !== null &&
      lowerThresholdAttr !== null
    ) {
      const upperThresholdAttrValue = Number(upperThresholdAttrData?.attrValue);
      const lowerThresholdAttrValue = Number(lowerThresholdAttrData?.attrValue);

      if (
        actualValue < lowerThresholdAttrValue ||
        actualValue > upperThresholdAttrValue
      ) {
        return {
          color: Color.RED,
          showRange: false,
          rangeValue: 0,
        };
      } else {
        return {
          color: Color.BLACK,
          showRange: false,
          rangeValue: 0,
        };
      }
    } else if (
      (upperThresholdAttrData?.attrValue !== null ||
        lowerThresholdAttrData?.attrValue !== null) &&
      cardMeterData.levelZeroThresholdPct === null &&
      cardMeterData.levelOneThresholdPct === null &&
      cardMeterData.levelTwoThresholdPct === null
    ) {
      const upperThresholdAttrValue =
        upperThresholdAttrData?.attrValue !== null
          ? Number(upperThresholdAttrData?.attrValue)
          : null;
      const lowerThresholdAttrValue =
        lowerThresholdAttrData?.attrValue !== null
          ? Number(lowerThresholdAttrData?.attrValue)
          : null;
      console.log(upperThresholdAttrValue, lowerThresholdAttrValue);
      // If min or max range of the data are available.
      if (
        upperThresholdAttrValue !== null &&
        !Number.isNaN(upperThresholdAttrValue)
      ) {
        if (actualValue > upperThresholdAttrValue) {
          return {
            color: Color.RED,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        } else {
          return {
            color: Color.BLACK,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        }
      } else if (
        lowerThresholdAttrValue !== null &&
        !Number.isNaN(lowerThresholdAttrValue)
      ) {
        if (actualValue < lowerThresholdAttrValue) {
          return {
            color: Color.RED,
            showRange: true,
            rangeValue: lowerThresholdAttrValue,
          };
        } else {
          return {
            color: Color.BLACK,
            showRange: true,
            rangeValue: lowerThresholdAttrValue,
          };
        }
      } else {
        return {
          color: Color.BLACK,
          showRange: false,
          rangeValue: 0,
        };
      }
    } else if (
      upperThresholdAttrData?.attrValue !== null &&
      upperThresholdAttr !== null &&
      (cardMeterData.levelZeroThresholdPct !== null ||
        cardMeterData.levelOneThresholdPct !== null ||
        cardMeterData.levelTwoThresholdPct !== null)
    ) {
      if (upperThresholdAttrData === undefined) {
        return {
          color: Color.BLACK,
          showRange: false,
          rangeValue: 0,
        };
      }
      const upperThresholdAttrValue = Number(upperThresholdAttrData?.attrValue);
      const levelTwoThresholdPct =
        cardMeterData.levelTwoThresholdPct !== null
          ? Number(cardMeterData.levelTwoThresholdPct)
          : null;
      const levelOneThresholdPct =
        cardMeterData.levelOneThresholdPct !== null
          ? Number(cardMeterData.levelOneThresholdPct)
          : null;
      const levelZeroThresholdPct =
        cardMeterData.levelZeroThresholdPct !== null
          ? Number(cardMeterData.levelZeroThresholdPct)
          : null;

      if (levelTwoThresholdPct !== null) {
        const levelTwoThresholdPctValue =
          (levelTwoThresholdPct / 100) * upperThresholdAttrValue;
        if (actualValue > levelTwoThresholdPctValue) {
          return {
            color: Color.RED,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        }
      }

      if (
        levelOneThresholdPct === null ||
        (levelZeroThresholdPct === null && levelOneThresholdPct === null)
      ) {
        return {
          color: Color.BLACK,
          showRange: true,
          rangeValue: upperThresholdAttrValue,
        };
      }

      if (levelOneThresholdPct !== null) {
        const levelOneThresholdPctValue =
          (levelOneThresholdPct / 100) * upperThresholdAttrValue;
        if (actualValue > levelOneThresholdPctValue) {
          return {
            color: Color.AMBER,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        }
        if (levelZeroThresholdPct === null) {
          return {
            color: Color.BLACK,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        }
      }

      if (levelZeroThresholdPct !== null) {
        const levelZeroThresholdPctValue =
          (levelZeroThresholdPct / 100) * upperThresholdAttrValue;
        if (actualValue > levelZeroThresholdPctValue) {
          return {
            color: Color.GREEN,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        } else {
          return {
            color: Color.BLACK,
            showRange: true,
            rangeValue: upperThresholdAttrValue,
          };
        }
      }
    } else if (
      lowerThresholdAttrData?.attrValue !== null &&
      (cardMeterData.levelZeroThresholdPct !== null ||
        cardMeterData.levelOneThresholdPct !== null ||
        cardMeterData.levelTwoThresholdPct !== null)
    ) {
      if (lowerThresholdAttrData === undefined) {
        return {
          color: Color.BLACK,
          showRange: false,
          rangeValue: 0,
        };
      }
      const lowerThresholdAttrValue = Number(lowerThresholdAttrData?.attrValue);
      const levelTwoThresholdPct =
        cardMeterData.levelTwoThresholdPct !== null
          ? Number(cardMeterData.levelTwoThresholdPct)
          : null;
      const levelOneThresholdPct =
        cardMeterData.levelOneThresholdPct !== null
          ? Number(cardMeterData.levelOneThresholdPct)
          : null;
      const levelZeroThresholdPct =
        cardMeterData.levelZeroThresholdPct !== null
          ? Number(cardMeterData.levelZeroThresholdPct)
          : null;

      if (levelTwoThresholdPct !== null) {
        const levelTwoThresholdPctValue =
          (levelTwoThresholdPct / 100) * lowerThresholdAttrValue;
        if (actualValue > levelTwoThresholdPctValue) {
          return {
            color: Color.RED,
            showRange: true,
            rangeValue: lowerThresholdAttrValue,
          };
        }
      }

      if (levelOneThresholdPct !== null) {
        const levelOneThresholdPctValue =
          (levelOneThresholdPct / 100) * lowerThresholdAttrValue;
        if (actualValue > levelOneThresholdPctValue) {
          return {
            color: Color.AMBER,
            showRange: true,
            rangeValue: lowerThresholdAttrValue,
          };
        }
      }

      if (levelZeroThresholdPct !== null) {
        const levelZeroThresholdPctValue =
          (levelZeroThresholdPct / 100) * lowerThresholdAttrValue;
        if (actualValue > levelZeroThresholdPctValue) {
          return {
            color: Color.GREEN,
            showRange: true,
            rangeValue: lowerThresholdAttrValue,
          };
        } else {
          return {
            color: Color.BLACK,
            showRange: true,
            rangeValue: lowerThresholdAttrValue,
          };
        }
      }
    }
  } else {
    return {
      color: colorValue,
      showRange: false,
      rangeValue: 0,
    };
  }
  return {
    color: "rgba(27, 27, 27, 1)",
    showRange: false,
    rangeValue: 0,
  };
};

export const showTempRangeData = (
  cardMeterData: CardMeter,
  attributeList: Attribute[],
  name: string
) => {
  const upperThreshold = cardMeterData?.upperThresholdAttr;
  const lowerThreshold = cardMeterData?.lowerThresholdAttr;

  if (upperThreshold !== undefined && lowerThreshold !== undefined) {
    if (upperThreshold !== null && lowerThreshold !== null) {
      const minTempObj = attributeList.find(
        (item) => item.attrName === lowerThreshold
      );
      const maxTempObj = attributeList.find(
        (item) => item.attrName === upperThreshold
      );
      const status = minTempObj !== undefined && maxTempObj !== undefined;
      const minValue = minTempObj?.attrValue;
      const maxValue = maxTempObj?.attrValue;
      return {
        status: status,
        min: minValue,
        max: maxValue,
        unit: maxTempObj?.UomDescription,
      };
    }
  }

  return {
    status: false,
    min: null,
    max: null,
    unit: null,
  };
};

export const getTooltipMessageData = (cardMeterData: CardMeter) => {
  const thresholdRangeDataList: ThresholdRangeDataType[] = [];
  let firstValue = "0";
  let color = Color.BLACK;

  const isLevelZeroAvailable =
    cardMeterData.hasOwnProperty("levelZeroThresholdPct") &&
    cardMeterData.levelZeroThresholdPct !== null;
  const isLevelOneAvailable =
    cardMeterData.hasOwnProperty("levelOneThresholdPct") &&
    cardMeterData.levelOneThresholdPct !== null;
  const isLevelTwoAvailable =
    cardMeterData.hasOwnProperty("levelTwoThresholdPct") &&
    cardMeterData.levelTwoThresholdPct !== null;
  const isLowerThresholdAvailable =
    cardMeterData.hasOwnProperty("lowerThresholdAttr") &&
    cardMeterData.lowerThresholdAttr !== null;
  const isUpperThresholdAvailable =
    cardMeterData.hasOwnProperty("upperThresholdAttr") &&
    cardMeterData.upperThresholdAttr !== null;

  if (!(isLevelZeroAvailable || isLevelOneAvailable || isLevelTwoAvailable)) {
    return "";
  }

  if (!(isLowerThresholdAvailable || isUpperThresholdAvailable)) {
    return "";
  }

  if (isLevelZeroAvailable) {
    const temp = cardMeterData.levelZeroThresholdPct;
    thresholdRangeDataList.push({
      value: `< ${temp}%`,
      color: color,
    });
    firstValue = temp;
    color = Color.GREEN;
  }

  if (isLevelOneAvailable) {
    const temp = cardMeterData.levelOneThresholdPct;
    thresholdRangeDataList.push({
      value: firstValue==="0" ? `< ${temp}%` : `${firstValue}-${temp}%`,
      color: color,
    });
    firstValue = temp;
    color = Color.AMBER;
  }

  if (isLevelTwoAvailable) {
    const temp = cardMeterData.levelTwoThresholdPct;
    thresholdRangeDataList.push({
      value:  firstValue==="0" ? `< ${temp}%` : `${firstValue}-${temp}%`,
      color: color,
    });
    firstValue = temp;
    color = Color.RED;
  }

  thresholdRangeDataList.push({
    value: `> ${firstValue}%`,
    color: color,
  });

  if (thresholdRangeDataList.length === 0) {
    return "";
  }

  let tooltipMessageString = ` <div style=' display: flex; background-color: #FFF; flex-direction: row; justify-content: center; align-items: center; transform: translateX(0); gap:10px; border-radius: 4px;padding: 0px 0px 0px 0px;'>`;
  let finalString = `</div>`;

  thresholdRangeDataList.forEach((item) => {
    const rangeDataItem = `<div style='display: flex; flex-direction: column;'>
  <p style='color: ${Color.BLACK}; margin: 0;'>${item.value}</p>
  <div style='border-bottom: 3px solid  ${item.color}; border-radius: 25px; width: 100%; height: 2px;'>
 </div>
 </div>`;

    tooltipMessageString = `${tooltipMessageString}\n${rangeDataItem}`;
  });

  tooltipMessageString = `${tooltipMessageString}\n${finalString}`;
  return tooltipMessageString;
};



export const getLocationDetails =  (id: string) => {
  let locationIds: any = LocalStorageService.getLocationData();
  return locationIds?.find((el: any) => el?.locationId === id);
};


export const getSubLocationDetails =  (id: string) => {
  let locationIds: any = LocalStorageService.getSubLocationData();
  return locationIds?.find((el: any) => el?.locationId === id);
};
