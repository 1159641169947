
import React, { useState, useEffect, useRef } from "react";
import IconClose from "../../../assets/icons/IconClose.svg";
import ImageSelectorComponent from "../../../Utils/imageSelector/ImageSelectorComponent";
import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
import IfImgNotUpld from '../usersCardContainer/userCard/ViewUserIcons/IfImgNotUpld.svg'
import UseStatics from "../utils/utils";
import Unchecked from '../../../assets/icons/Unchecked.svg';
import Checked from '../../../assets/icons/Checked.svg';
import Dropdown from '../../../assets/icons/Dropdown.svg';
import AddressIcon from '../../../assets/icons/AddressIcon.svg';
import SearchIcon from '../usersCardContainer/userCard/ViewUserIcons/SearchIcon.svg'
import { EditUserResetPassword } from "../usersCardContainer/userCard/service/ViewUserPostData";
import Select from "react-select";
import { components } from 'react-select';
import checkGreenIcon from '../../../assets/icons/checkGreenIcon.svg';
export const EditBasicContainer=(props)=>{
const {data,basicInfo,setBasicInfo,validationMessages,setValidationMessages,setShowEditAddress,setShouldValidate}=props;
    const [showEmailPurpose, setShowEmailPurpose] = useState(false);
    const [searchPhoneCountry, setSearchPhoneCountry] = useState('');
    const [showCountryPopUp, setShowCountryPopUp] = useState(false);
    const [clickResetPassword, setClickResetPassword] = useState(false);
    const {
      emailPurposeData,
      phonePurposeData,
      countryArr,
    } = UseStatics();
    const [images, setImages] = useState<File | string>(
      data?.mediaList.length === 0 ? { IfImgNotUpld } : `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${data?.mediaList?.[0]?.url}`
    )
    const [updateUserz,setUpdateUserz]=useState(false);
    const [showPhonePurpose, setShowPhonePurpose] = useState(false);
    const [basicInfoe, setBasicInfoe] = useState( basicInfo);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const[errorMessage,setErrorMessage]=useState('');
  const[countryImgUrl,setCountryImgUrl]=useState('');
  const [imgUrl, setImgUrl] = useState(
    countryImgUrl === '' && countryImgUrl
  );
  const CustomDropdownIndicator = (props) => (
    <components.DropdownIndicator {...props}>
      <img src={SearchIcon} alt="Search" className="search-icon" />
    </components.DropdownIndicator>
  );
   const filteredCountryArr = countryArr.filter((country: { name: string; }) =>
      country.name.toLowerCase().includes(searchPhoneCountry.toLowerCase())
    )
  const options = filteredCountryArr.map((el) => ({
    value: el.name,
    label: (
      <div className="flagCountryPrefixDiv">
        <img src={el.contentUrl} alt="flag" className="option-flag" />
        <span className="option-name">{el.name}</span>
        <span className="option-telecode">+{el.teleCode}</span>
      </div>
    ),
    teleCode: el.teleCode,
    contentUrl: el.contentUrl,
  }));
    const [selectedOption, setSelectedOption] = useState(null);
      const validateValue = (value: string | any[], regex: { test: (arg0: any) => any; }, maxLength: number) => {
        if (!regex.test(value)) {
          return "Invalid format.";
        }
        if (value.length > maxLength) {
          return `The value must be less than ${maxLength} characters.`;
        }
        return "";
      };
    const handleChange = (field: string, regex: RegExp, maxLength: number, e: React.FormEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const validationMessage = validateValue(value, regex, maxLength);
        if (validationMessage === "") {
          setBasicInfo(prevState => ({ ...prevState, [field]: value }));
        }
        setValidationMessages(prevMessages => ({
          ...prevMessages,
          [field]: validationMessage
        }));
    
        if (validationMessage === "") {
          setShouldValidate(false);
        }
      };
      const filterEmailPurposeData = (el: string) => {
        setBasicInfo((prevState) => ({
          ...prevState,
          selectedEmailPurposeData: prevState.selectedEmailPurposeData.includes(el)
            ? prevState.selectedEmailPurposeData.filter((obj: string) => obj !== el)
            : [...prevState.selectedEmailPurposeData, el],
        }));
      };
      const filterPhonePurposeData = (el: string) => {
        setBasicInfo((prevState) => ({
          ...prevState,
          selectedPhonePurposeData: prevState.selectedPhonePurposeData.includes(el)
            ? prevState.selectedPhonePurposeData.filter((obj: string) => obj !== el)
            : [...prevState.selectedPhonePurposeData, el],
        }));
      };
    const handlePrefixSelection = (option) => {
        setBasicInfo(prev=>({
          ...prev,teleCode:option.teleCode
        }))
        setImgUrl(option.contentUrl);
        setSelectedOption(option);
      };
      const handleShowCountryPopUp = () => {
        setShowCountryPopUp(!showCountryPopUp);
      }
    const handleInputChange = (inputValue: string) => {
        setSearchPhoneCountry(inputValue);
      };
      const handleClickResetPassword = async () => {
        setClickResetPassword(!clickResetPassword);
        setErrorMessage("");  
    
        const response = await EditUserResetPassword(data?.userLoginDetails?.[0]?.userName, data?.userLoginDetails?.[0]?.partyId);
    
        if (response.error) {
          setErrorMessage(response.error);
          setClickResetPassword(false);
        }
      };
      useEffect(() => {
        if (data?.emailDetails?.[0]?.purposes) {
          const transformedData = data?.emailDetails?.[0]?.purposes.map((item) => ({
            id: item.contactMechPurposeTypeId,
            name: item.description,
          }));
          setBasicInfoe((prev) => ({
            ...prev,
            selectedEmailPurposeData: transformedData,
          }));        }
        if (data?.phoneDetails?.[0]?.purposes) {
          const transformedData = data?.emailDetails?.[0]?.purposes.map((item) => ({
            id: item.contactMechPurposeTypeId,
            name: item.description,
          }));
          setBasicInfoe((prev) => ({
            ...prev,
            selectedPhonePurposeData: transformedData,
          }));  
        }
        if (data?.addressDetails?.[0]?.purposes) {
          const transformedData = data?.emailDetails?.[0]?.purposes.map((item) => ({
            id: item.contactMechPurposeTypeId,
            name: item.description,
          }));
          setBasicInfoe((prev) => ({
            ...prev,
            selectedAddressData: transformedData,
          }));  
        }
        
      }, [data]);
      useEffect(() => {
        if (updateUserz===true) {
          const existingImage = `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${data?.mediaList?.[0]?.url}`; 
          setImages(existingImage ? existingImage : null);
        } else {
          setImages(null);
        }
      }, [updateUserz, data]);
        useEffect(() => {
          const matchedCountry = countryArr.find(country => country.teleCode === basicInfoe.teleCode);
          if (matchedCountry) {
            setCountryImgUrl(matchedCountry.contentUrl);
          }
        }, [countryArr, basicInfoe.teleCode, IfImgNotUpld, API_BASE_URL_DOWNLOAD.slice(0,-1)]); 
        useEffect(() => {
          setImgUrl(countryImgUrl === '' &&  countryImgUrl);
        }, [countryImgUrl, IfImgNotUpld]);  

        useEffect(()=>{
          setBasicInfoe(basicInfo)
        },[basicInfo])
        useEffect(() => {
          setBasicInfo(basicInfoe);
        },[basicInfoe,basicInfo])
        useEffect(()=>{
          setValidationMessages(validationMessages);
        },[validationMessages])
    return(
        <div className="basicContainer">
        <div className="firstRow">
          <div className="userLabelAndInputContainer">
            <label className="userLabel">First Name *</label>
            <input
              className="userInput"
              type="text"
              name="firstName"
              maxLength={30}
              defaultValue={data?.userBasicDetails?.[0]?.firstName}
              onInput={(e) => handleChange("firstName", /^[A-Za-z' ]*$/, 30, e)}
            />
            {validationMessages.value && (
              <div className="warningDiv" style={{ color: 'red', width: "100%" }}>{validationMessages.firstName}</div>
            )}
          </div>
          <div className="userLabelAndInputContainer">
            <label className="userLabel">Last Name *</label>
            <input
              className="userInput"
              type="text"
              name="lastName"
              maxLength={30}
              defaultValue={data?.userBasicDetails?.[0]?.lastName}
              onInput={(e) => handleChange("lastName", /^[A-Za-z' ]*$/, 30, e)}
            />
            {validationMessages.lastName && (
              <div className="warningDiv" style={{ color: 'red' }}>{validationMessages.lastName}</div>
            )}
          </div>
        </div>
        <div className="firstRow">
          <div className="userLabelAndInputContainer">
            <label className="userLabel">Email *</label>
            <div className="userInputCont">
              <input
                className="userInput"
                type="email"
                name="email"
                maxLength={30}
                defaultValue={data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString}
                onChange={(e) => handleChange("email", /^[A-Za-z0-9._+@\-]*$/, 30, e)}
              />
            </div>
            <div
              className="setPurpose"
              onClick={() => setShowEmailPurpose(true)}
            >
              <p>{data?.emailDetails?.[0]?.purposes.length} purposes selected</p>
            </div>
            {validationMessages.email && (
              <div className="warningDiv" style={{ color: 'red' }}>{validationMessages.email}</div>
            )}
            {showEmailPurpose && (
              <div className="emailSetPurpose" onMouseLeave={() => setShowEmailPurpose(false)}>
                <div className="setPurposeHeader">
                  <div className="purposeCloseCont" onClick={() => setShowEmailPurpose(false)}>
                    <img
                      src={IconClose}
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="setPurposeDataCont">
                  {emailPurposeData.length > 0 &&
                    emailPurposeData.map((el: { id: any; name: string }, index: number) => (
                      <div className="setPurposeData" key={index} onClick={() => filterEmailPurposeData(el.id)}>
                        {basicInfo.selectedEmailPurposeData.includes(el.id) ? (
                          <img
                            src={Unchecked}
                            alt="checked"
                          />
                        ) : (
                          <img
                            src={Checked}
                            alt="unchecked"
                          />
                        )}
                        <label>{el.name}</label>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
          <div className="userLabelAndInputContainer">
            <label className="userLabel">Phone Number </label>
            <div className="userInputCont">
              <div className="userInputContPartition">
                <div className="phoneNumberImgSelectBox" onClick={handleShowCountryPopUp}>
                  <img className="countryImg" src={imgUrl} alt="icon"/>
                  <div className="selectedPhoneNumber">{basicInfoe.teleCode}</div>
                  <img src={Dropdown} alt="icon" />
                </div>
                <div className="userInputPhoneNumber">
                  <input
                    type="text"
                    name="phoneNumber"
                    maxLength={20}
                    value={basicInfoe.phoneNumber}
                    onInput={(e) => handleChange("phoneNumber", /^[0-9]*$/, 20, e)}
                  />
                </div>
              </div>
            </div>
            {validationMessages.phoneNumber && (
              <div className="warningDiv" style={{ color: 'red' }}>{validationMessages.phoneNumber}</div>
            )}
            {showCountryPopUp &&
              <div className="phoneSelectContainer">
                <Select
                  options={options}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  onInputChange={handleInputChange}
                  onChange={handlePrefixSelection}
                  isSearchable
                  value={null}
                  components={{
                    DropdownIndicator: CustomDropdownIndicator,
                    IndicatorSeparator: () => null
                  }}
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      display: 'flex',
                      width: '350px',
                      alignItems: 'center',
                      border: 'none',
                      outline: 'none',
                      marginTop: '5px',
                      paddingRight: '10px',
                    }),
                  }}
                  menuIsOpen={isDropdownOpen}
                  onFocus={() => setIsDropdownOpen(true)}
                  onBlur={() => setIsDropdownOpen(false)}
                />
              </div>
            }
            <div
              className="setPurpose"
              onClick={() => setShowPhonePurpose(true)}
            >
              <p>{data?.phoneDetails?.[0]?.purposes.length} purposes selected </p>
            </div>
            {showPhonePurpose && (
              <div className="emailSetPurpose" onMouseLeave={() => setShowPhonePurpose(false)}>
                <div className="setPurposeHeader">
                  <div className="purposeCloseCont" onClick={() => setShowPhonePurpose(false)}>
                    <img
                      src={IconClose}
                      alt="icon"
                    />
                  </div>
                </div>
                <div className="setPurposeDataCont">
                  {phonePurposeData.length > 0 && phonePurposeData.map((el: any, index: number) => (
                    <div className="setPurposeData" key={index} onClick={() => filterPhonePurposeData(el.id)}>
                         {basicInfo.selectedPhonePurposeData.includes(el.id) ? (
                        <img
                          src={Unchecked}
                          alt="checked"
                        />
                      ) : (
                        <img
                          src={Checked}
                          alt="unchecked"
                        />
                      )}
                      <label>{el.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="firstRow">
          <div className="userLabelAndInputContainer">
            <label className="userLabel">Address</label>
            <div className="addressImgSelectBox">
              <div className="userInputAddress">
                <input
                  className="userInputAdd"
                  type="text"
                  value={
                    [
                      data?.addressDetails?.[0]?.commonDetails?.address1,
                      data?.addressDetails?.[0]?.commonDetails?.address2,
                      data?.addressDetails?.[0]?.commonDetails?.city,
                      data?.addressDetails?.[0]?.commonDetails?.postalCode,
                    ]
                      .filter(Boolean)
                      .join(",")
                  }
                />
              </div>
              <div className="addressIconImg" >
                <img src={AddressIcon} alt="IconAdd" onClick={() => setShowEditAddress(true)} />
              </div>
            </div>
          </div>
        </div>
        <p className="credentialsHeader">Credentials</p>
        <div className="credentialsBorder"></div>
        <div className="firstRow">
          <div className="userLabelAndInputContainer">
            <label className="userLabel">User Name</label>
            <div className="userInputCont">
              <input
                className="userInput"
                type="text"
                name="userName"
                maxLength={20}
                defaultValue={data?.userLoginDetails?.[0]?.userName}
                onChange={(e) => handleChange("userName", /^[A-Za-z0-9._+@\-]*$/, 20, e)}
              />
            </div>

            {validationMessages.userName && (
              <div className="warningDiv" style={{ color: 'red' }}>{validationMessages.userName}</div>
            )}
          </div>
          <div className="userLabelAndInputContainer">
            <div className="reset-container">
              {clickResetPassword ? (
                <>
                  <p className="resetPassword success" onClick={handleClickResetPassword}>
                    <img src={checkGreenIcon} alt="icon" /> Check your email for instructions.
                  </p>
                </>
              ) : (
                <p className="resetPassword" onClick={handleClickResetPassword}>
                  Reset Password
                </p>
              )}
              {errorMessage && <p className="resetPassword error" style={{ color: "red",border:"none" }}>{errorMessage}</p>}
            </div>

          </div>
        </div>
        <div className="profileImgFormatConts">
          <div className="profileImgDiv">
            <ImageSelectorComponent
              src={images}
              image={images}
              alt="Profile Image"
            />
          </div>
          <div className="profileContentDiv">
            <ul className="profileImgDetails">
              <li className="profileImgHeader">Profile Image</li>
              <li>Max file size: 2MB. File format: png, jpeg</li>
              <li>Recommended resolution: 300x300</li>
            </ul>
          </div>
        </div>
      </div>
    )
}