import React, {} from 'react';
import './ChillerAssetComponent.scss';
import ChillerAssetCard from "./ChillerAssetCard";
import {AssetData,} from "../../../types/HVACSummaryLandingDataType";
import ScrollOverRightIcon from '../../../assets/scrollHoverRight.svg';

type Props = {
    data: AssetData[];
    handlePagination(id: string): void;
    showPagination: boolean;
};

const ChillerAssetComponent = (props: Props) => {

    return (
        <div className='chillerAssetComponentMainDiv'>
            {
                props.data.map((item, index) => (
                    <ChillerAssetCard data={item} key={index}/>
                ))
            }
            {
                props.showPagination && (
                    <img
                        className='paginationIcon'
                        src={ScrollOverRightIcon}
                        onClick={() => props.handlePagination('CHILLER')}
                        alt='Pagination'
                    />
                )
            }

        </div>
    )
};

export default ChillerAssetComponent;
