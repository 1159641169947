import React, {useEffect, useState} from 'react';
import './ChillerAssetCard.scss';
import NoDataAvailableIcon from '../../../../assetList/assets/noDataAvailable.svg';
import RightIcon from '../../../assets/RightIcon.svg';
import {formatMeterValue, navigateAssetDetailPage} from "../../../../../Utils/utils";
import {AssetData} from "../../../types/HVACSummaryLandingDataType";
import moment from "moment";
import defaultAssetIcon from "../../../../../assets/image/icon-assets-default.svg";
import {LiveMeter} from "../../../../assetList/types";
import HVACSummaryUtils from "../../../utils";
import {useNavigate} from "react-router-dom";

type AssetMeterData = {
    capacityUtilization: LiveMeter | null;
    runStatus: LiveMeter | null
};

type Props = {
    data: AssetData;
};

const ChillerAssetCard = (props: Props) => {

    const [assetMeterData, setAssetMeterData] =
        useState<AssetMeterData | null>(null);

    const navigate = useNavigate();

    function getAssetImageIcon() {
        if (props.data?.contentUrl !== undefined && props.data?.contentUrl !== null) {
            return props.data?.contentUrl;
        } else {
            return defaultAssetIcon;
        }
    }

    const getCardValue = () => {
        if (assetMeterData === null) {
            return '-';
        } else if (assetMeterData.capacityUtilization === null) {
            return '-';
        } else {
            const meterData = assetMeterData.capacityUtilization;
            const value = meterData.meterValue;
            const pointerValue =
                meterData.hasOwnProperty("decimalPrecision") &&
                meterData.decimalPrecision !== null
                    ? meterData.decimalPrecision
                    : 2;
            const formatValue = formatMeterValue(value, "", pointerValue);
            return `${formatValue}%`;
        }
    }

    useEffect(() => {
        const getAssetMetricData = () => {
            const utils = new HVACSummaryUtils();
            const result = utils.getChillerMetricData(props.data);
            setAssetMeterData(result);
        };
        getAssetMetricData();
    }, [props.data]);

    return (
        <div className='chillerAssetCardMainDiv' onClick={() => navigateAssetDetailPage(props.data.assetId, navigate)}>
            <div className='chillerAssetCardHeaderDiv'>
                <div className='chillerAssetCardHeaderMainDiv'>
                    <img
                        className='chillerAssetCardIcon'
                        src={getAssetImageIcon()}
                        alt="icon"
                        loading="lazy"
                    />
                    <p className='chillerAssetName'>{props.data.assetName}</p>
                </div>
                {assetMeterData !== null && assetMeterData?.runStatus !== null && (
                    <p className='chillerAssetStatus'>{assetMeterData.runStatus.meterValue}</p>
                )
                }
            </div>
            {
                assetMeterData === null ? (
                    <div className='noDataAvailableDiv'>
                        <img src={NoDataAvailableIcon} alt='Data unavailable at the moment'/>
                        <p className='noDataAvailableText'>Data unavailable at the moment</p>
                    </div>
                ) : (
                    <div className='assetBodyDataDiv'>
                        <p className='assetBodyText'>current capacity utilization</p>
                        <p className='chillerAssetValue'>{getCardValue()}</p>
                    </div>
                )
            }
            <div className='chillerAssetCardFooter'>
                <p className='chillerAssetLastUpdatedAt'>{moment(props.data.lastCommTime).fromNow()}</p>
                <img src={RightIcon} alt='Right icon'/>
            </div>
        </div>
    );
};

export default ChillerAssetCard;
