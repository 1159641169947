import React, {useEffect,} from 'react';
import './HVACAssetComponent.scss';
import ChillerAssetComponent from "./chillerAsset/ChillerAssetComponent";
import PumpAssetComponent from "./pumpAsset/PumpAssetComponent";
import CoolingTowerAssetComponent from "./coolingTowerAsset/CoolingTowerAssetComponent";
import {HVACSummaryLandingDataType} from "../../types/HVACSummaryLandingDataType";
import HVACSummaryUtils from "../../utils";
import {HVACAssetHooks} from "../../hooks/HVACAssetHooks";
import {useQuery, useQueryClient} from "react-query";
import {fetchHVACSummary} from "../../service/HVACSummaryService";
import {cancelExistingQuery} from "../../../../Utils/utils";

type Props = {
    data: HVACSummaryLandingDataType;
};

const HVACAssetComponent = (props: Props) => {
    const queryClient = useQueryClient();
    const queryKey: string = 'HVACSummaryAsset';

    const {
        assetCategoryTab,
        selectedTab,
        updateSelectedTab,
        updateTotalAssetCount,
        totalAssetCount,
        chillerAssetData,
        updateChillerAssetData,
        paginationRef,
        responseBodyObj,
        pumpAssetData,
        updatePumpAssetData,
        coolingTowerAssetData,
        updateCoolingTowerAssetData,
    } = HVACAssetHooks();

    const {data, error, isLoading, refetch, isFetching} = useQuery(
        queryKey,
        () => fetchHVACSummary(responseBodyObj.current),
        {
            enabled: false,
            keepPreviousData: false,
            onSuccess: (fetchedData) => {
                if (typeof fetchedData === "object" && fetchedData.hasOwnProperty("assets")) {
                    const utils = new HVACSummaryUtils();

                    const data = utils.extractAssetList(fetchedData, selectedTab, chillerAssetData, pumpAssetData, coolingTowerAssetData);
                    updateChillerAssetData(data.chiller);

                    updatePumpAssetData(data.pump);

                    updateCoolingTowerAssetData(data.coolingTower);

                    paginationRef.current = data.pagination;
                }
            },
        });

    const fetAssetData = async () => {
        try {
            cancelExistingQuery(queryKey, queryClient);
            await refetch();
        } catch (exception) {
            console.error("Error re-fetching data:", exception);
        }
    };

    const handlePagination = (id: string) => {
        responseBodyObj.current = {
            "viewIndex": paginationRef.current.nextPage,
            "viewSize": 1,
            "hvacFixedAssetTypeId": id,
        };

        fetAssetData();
    };

    const getAssetComponent = () => {
        if (selectedTab === 'CHILLER') {
            return <ChillerAssetComponent
                data={chillerAssetData.data}
                handlePagination={handlePagination}
                showPagination={paginationRef?.current?.currentPage !== paginationRef?.current?.nextPage}
            />;
        } else if (selectedTab === 'PUMP') {
            return <PumpAssetComponent
                key={JSON.stringify(pumpAssetData)}
                pumpAsset={pumpAssetData}
                handlePagination={handlePagination}
                showPagination={paginationRef?.current?.currentPage !== paginationRef?.current?.nextPage}
            />;
        } else if (selectedTab === "COOLING_TOWER") {
            return <CoolingTowerAssetComponent
                key={JSON.stringify(coolingTowerAssetData)}
                data={coolingTowerAssetData}
            />;
        }
    };

    const extractData = () => {
        const utils = new HVACSummaryUtils();

        const assetCount = utils.getAssetCount(props.data, assetCategoryTab);
        updateTotalAssetCount(assetCount);

        const data = utils.extractAssetList(props.data, selectedTab, chillerAssetData, pumpAssetData, coolingTowerAssetData);
        updateChillerAssetData(data.chiller);

        paginationRef.current = data?.pagination;

    };

    const handleSelectedTab = (id: string) => {
        const chilledAssetLength = chillerAssetData.data.length;
        const pumpAssetLength = pumpAssetData.chilled.data.length + pumpAssetData.condensor.data.length;
        const coolingTowerAssetLength = coolingTowerAssetData.data.length;

        if (chilledAssetLength === 0 && pumpAssetLength === 0 && coolingTowerAssetLength === 0) {
            responseBodyObj.current = {
                "viewIndex": 1,
                "viewSize": 20,
                "hvacFixedAssetTypeId": id,
            };
        }

        fetAssetData();
        updateSelectedTab(id);
    }

    useEffect(() => {
        extractData()
    }, []);

    return (
        <div className='hvacAssetComponentMainDiv'>
            <div className='hvacAssetComponentHeaderDiv'>
                <p className='hvacAssetComponentHeaderText'>Chiller Plants</p>
                <p className='hvacAssetComponentHeaderCount'>{totalAssetCount}</p>
            </div>
            <div className='hvacAssetCategoryMainCont'>
                {
                    assetCategoryTab.map((tab) => (
                        <p className='assetCategoryTabText' key={tab.id} style={{
                            color: tab.id === selectedTab ? 'rgba(15, 67, 249, 1)' : 'rgba(112, 112, 112, 1)',
                            borderBottomWidth: tab.id === selectedTab ? '1px' : 0
                        }} onClick={() => handleSelectedTab(tab.id)}>{tab.name}s</p>
                    ))
                }
            </div>
            {getAssetComponent()}
        </div>
    )
};

export default HVACAssetComponent;
