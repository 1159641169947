import React from 'react';
import './HVACSummaryShimmer.scss';
import ShimmerEffectComponent from "../../../../a1Components/shimmer/ShimmerEffectComponent";

const HVACSummaryShimmer = () => {
    return (
        <>
            {/* For Category */}
            <div className='categoryShimmer'>
                {
                    ["1", "2"].map((item) => (
                        <div className='categoryShimmerCard'>
                            <div className='categoryShimmerCard1'>
                                <div className='categoryShimmerCard2'>
                                    <ShimmerEffectComponent width='40%' height='25px' borderRadius='2px'/>
                                    <ShimmerEffectComponent width='60%' height='16px' borderRadius='2px'/>
                                </div>
                                <ShimmerEffectComponent width='30px' height='35px' borderRadius='4px'/>
                            </div>
                            <ShimmerEffectComponent width='60%' height='25px' borderRadius='4px'/>
                            <div className='categoryShimmerCard3'>
                                <ShimmerEffectComponent width='20px' height='20px' borderRadius='50%'/>
                                <ShimmerEffectComponent width='60%' height='10px' borderRadius='2px'/>
                            </div>
                            <ShimmerEffectComponent width='100%' height='12px' borderRadius='25px'/>
                        </div>
                    ))
                }
            </div>

            <div className='hvacCardWrapperLine'/>

            {/* HVAC Summary Asset Title */}
            <div className='hvacSummaryAssetTitleShimmer'>
                <ShimmerEffectComponent width='20%' height='35px' borderRadius='2px'/>
                <ShimmerEffectComponent width='30px' height='35px' borderRadius='4px'/>
            </div>

            {/*  HVAC Summary Asset Tab  */}
            <div className='hvacSummaryAssetTabShimmer'>
                {
                    ["1", "2", "3"].map((item) => (
                        <ShimmerEffectComponent width='15%' height='25px' borderRadius='2px'/>
                    ))
                }
            </div>

            {/*  Chiller Asset Card  */}
            <div className='chillerAssetCardMainDivShimmer'>
                {
                    ["1", "2"].map((item) => (
                        <div className='chillerAssetCardShimmer'>
                            <div className='chillerAssetCardShimmer1'>
                                <ShimmerEffectComponent width='18px' height='18px' borderRadius='50%'/>
                                <ShimmerEffectComponent width='40%' height='25px' borderRadius='2px'/>
                                <ShimmerEffectComponent width='40%' height='25px' borderRadius='2px'/>
                            </div>
                            <ShimmerEffectComponent width='100%' height='18px' borderRadius='2px'/>
                            <ShimmerEffectComponent width='100%' height='18px' borderRadius='2px'/>
                        </div>
                    ))
                }
            </div>
        </>
    );
};

export default HVACSummaryShimmer;
