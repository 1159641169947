import * as React from "react";
import {useState} from "react";
import {AssetTypeCount} from "../types/HVACSummaryLandingDataType";

export const HVACCategoryHooks = () => {
    const categoryRepresentationDivRef = React.useRef<HTMLDivElement | null>(null);
    const [divWidth, setDivWidth] = React.useState({
        on: 0,
        off: 0,
        unavailable: 0,
        total: 0,
    });
    const [categoryData, setCategoryData] = useState<AssetTypeCount[]>([]);

    const updateDivWidth = React.useCallback(
        (newData) => {
            setDivWidth(() => ({
                ...newData,
            }));
        },
        [setDivWidth]
    );

    const updateCategoryData = React.useCallback((newData) => {
        setCategoryData(newData);
    }, [setCategoryData]);

    return {categoryRepresentationDivRef, divWidth, updateDivWidth, updateCategoryData, categoryData};
};
