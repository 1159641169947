import React, { useEffect, useRef } from "react";
import "./Forecast.scss";
import ListDownIcon from "../../assets/icons/listDownIcon.svg";
import DropdownArrow from "./assets/icons/dropdownArrow.svg";
import ToggleBar from "./assets/icons/toggleBar.svg";
import ToggleLine from "./assets/icons/toggleLine.svg";
import ForecastDetailsIcon from "./assets/icons/forecastDetailsIcon.svg";
import ReactEcharts from "echarts-for-react";
import { API_BASE_URL } from "../../Constants/constants";
import moment from "moment";
import { useStateValue } from "../../redux/StateProvider";
import LoaderStyle from "../LoaderStyle/LoaderStyle";
import { formatMeterValue } from "../../Utils/utils";
import DisplayError from "../DisplayError/DisplayError";
import LocalStorageService from "../../Utils/LocalStorageService";
import { getForecastList } from "./services/ForecastSevices";
import { useQuery } from "react-query";
import useState from "react-usestateref";
import Select from "react-select";
import DashboardFilter from "../singleFacilityEnergyConsumption/DashboardFilter";

function Forecast(props:any) {
  const {energyConsumptionFilter, setEnergyConsumptionFilter} = props
  const [{ dateRange, dateChip }, dispatch] = useStateValue();
  console.log(dateChip, "dateChip")
  const getDateLabels = () => {
    if (dateChip === "Custom") {
      const startDate = moment(dateRange[0]);
      const endDate = moment(dateRange[1]);
      const daysDifference = endDate.diff(startDate, "days");

      const futureStartDate = endDate.clone().add(1, "days");
      const futureEndDate = futureStartDate.clone().add(daysDifference, "days");

      return {
        current: `${startDate.format("YYYY/MM/DD")} - ${endDate.format(
          "YYYY/MM/DD"
        )}`,
        next: `${futureStartDate.format("YYYY/MM/DD")} - ${futureEndDate.format(
          "YYYY/MM/DD"
        )}`,
        currentTitle: "Current Period",
        nextTitle: "Forecast Period",
      };
    }

    const labels = {
      Today: { current: "Today", next: "Tomorrow" },
      "This Week": { current: "This Week", next: "Next Week" },
      "This Month": { current: "This Month", next: "Next Month" },
    };

    return {
      current: labels[dateChip]?.current || "",
      next: labels[dateChip]?.next || "",
      currentTitle: labels[dateChip]?.current || "",
      nextTitle: labels[dateChip]?.next || "",
    };
  };
  const { current, next, currentTitle, nextTitle } = getDateLabels();

  // Forecast
  const [foreCastList, setForecastList] = useState<any>();

  //User Specific
  const [userSpecificData, setUserSpecificData] = useState<any>();

  // Date Range Dropdown
  const [showDropDown, setShowDropDown] = useState(false);

  const dropdownRef = useRef<any>(null);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showDropDown && dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowDropDown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDropDown]);


  const [trendRange, setTrendRange] = useState<string[]>([]);
  const [selectedTrendRange, setSelectedTrendRange, selectedTrendRangeRef] =
    useState<string>("");
  const [dateUpdate, setDateUpdate] = useState("Today");
  const metricTrendRange = (type: string): string[] => {
    const data = {
      Today: [
        "Last 7 days",
        "Last 14 days",
        "Last 30 days",
        "Last 60 days",
        "Last 90 days",
      ],
      "This Week": ["Last 4 weeks", "Last 8 weeks", "Last 12 weeks"],
      "This Month": [
        "Last 3 months",
        "Last 6 months",
        "Last 9 months",
        "Last 12 months",
      ],
      Custom: [""],
      DEFAULT: [""],
    };
    return data[type] || data.DEFAULT;
  };

  useEffect(() => {
    const updateTrendLegendChip = () => {
      const data = metricTrendRange(dateUpdate);
      setTrendRange(data);
      setSelectedTrendRange(data[0]);
    };
    updateTrendLegendChip();
  }, [dateUpdate]);

  useEffect(() => {
      setDateUpdate(dateChip);
  }, [dateChip]);

  //Chart Toggle
  const [isToggled, setIsToggled] = useState(true);
  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  //Chart Data
  const trendSummary = foreCastList?.forecastSummary?.trendData?.trendSummary;
  const [isActualVisible, setIsActualVisible] = useState(true);
  const [isForecastedVisible, setIsForecastedVisible] = useState(true);

  const toggleLegend = (seriesName: any) => {
    if (seriesName === "Actual Value") {
      setIsActualVisible((prev) => !prev);
    } else if (seriesName === "Forecasted Value") {
      setIsForecastedVisible((prev) => !prev);
    }
  };

  // const dateFormatter = (epoch) => {
  //   switch (dateChip) {
  //     case "Today":
  //       return moment(+epoch).format("DD MMM YYYY");
  //     case "This Week":
  //       return "W" + moment(+epoch).week();
  //     case "This Month":
  //       return moment(+epoch).format("MMMM, YYYY");
  //     default:
  //       return moment(+epoch).format("YYYY-MM-DD");
  //   }
  // };

  const formatXAxisData = (trendSummary, dateChip) => {
    return trendSummary
      ? Object.keys(trendSummary).map((epoch) => {
          const date = moment(+epoch);
          if (dateChip === "Today") {
            return date.format("DD MMM YYYY"); 
          } else if (dateChip === "This Week") {
            return `W${date.isoWeek()}`;
          } else if (dateChip === "This Month") {
            return date.format("MMMM"); 
          } else {
            return date.format("YYYY-MM-DD"); 
          }
        })
      : [];
  };

  const tollTipDates = (trendSummary, dateChip) => {
    if (!trendSummary) return [];
    return Object.keys(trendSummary).map((epoch) => {
        const date = moment(+epoch);
        switch (dateChip) {
            case "Today":
                return date.format("MMM DD, YYYY"); 
            case "This Week": {
                const startOfWeek = moment(date).startOf('isoWeek');
                const endOfWeek = moment(date).endOf('isoWeek');
                const weekRange = `${startOfWeek.format("DD MMM, YYYY")} - ${endOfWeek.format("DD MMM, YYYY")}`;
                return `${weekRange} (W${date.isoWeek()})`;
            }
            case "This Month":
                return date.format("MMMM, YYYY");
            default:
                return date.format("MMM DD, YYYY");
        }
    });
};


  const xAxisData = formatXAxisData(trendSummary, dateChip);

  const tollDates = tollTipDates(trendSummary, dateChip)

  const actualData = trendSummary
  ? Object.values(trendSummary).map((dataArray:any) => 
      dataArray[0]?.value !== undefined && dataArray[0]?.value !== null 
        ? parseFloat(Number(dataArray[0].value).toFixed(2)) 
        : null 
    )
  : [];

  const forecastedData = trendSummary
  ? Object.values(trendSummary).map((dataArray:any) => 
      dataArray[1]?.value !== undefined && dataArray[1]?.value !== null 
        ? parseFloat(Number(dataArray[1].value).toFixed(2)) 
        : null 
    )
  : [];


  const chartData1 = {
    tooltip: {
      trigger: "axis",
      backgroundColor: "#2e2e2e",
      borderColor: "#ccc",
      borderWidth: 1,
      padding: [6, 10],
      textStyle: {
        color: "#fff",
        fontSize: 12,
      },
      formatter: (params) => {
        // console.log('UJJJJU:',params[0]?.axisValueLabel)
        const forecasted = params.find(
          (param) => param.seriesName === "Forecasted Value"
        );
        const actual = params.find(
          (param) => param.seriesName === "Actual Value"
        );

        if (!actual) {
          return `
            <div style="font-size: 12px; line-height: 1.4;">
              <div>Forecasted Value</div>
              <div style="font-size: 14px; font-weight: bold;">${formatMeterValue(
                forecasted.value,
                "",
                2
              )} ${foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom}</div>
            </div>
          `;
        }

        if (!forecasted) {
          return `
            <div style="font-size: 12px; line-height: 1.4;">
              <div>Actual Value</div>
              <div style="font-size: 14px; font-weight: bold;">${formatMeterValue(
                actual.value,
                "",
                2
              )} ${foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom}</div>
            </div>
          `;
        }

        const variancePercentage = (
          ((forecasted.value - actual.value) / actual.value) *
          100
        ).toFixed(2);
        const varianceSign = variancePercentage > 0 ? "↑" : "↓";

        return `
          <div style="font-size: 12px; line-height: 1.4; min-width: 200px;">
            <div>Forecasted Value</div>
            <div style="display: flex; justify-content: space-between; align-items: center; font-size: 14px; font-weight: bold;">
              <div>${formatMeterValue(
              forecasted.value,
              "",
              2
            )} ${foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom}</div>
              <div style="background: #f0f0f0; color: #007bff; display: inline-block; padding: 1px 4px; border-radius: 4px; font-size: 10px;">
                ${varianceSign} ${Math.abs(variancePercentage)}% 
              </div>
            </div>
          
            <div>${tollDates[xAxisData.indexOf(params[0]?.axisValueLabel)]}</div>
            <hr style="border-top: 1px solid #888; margin: 4px 0;" />
            <div>Actual Value <strong>${formatMeterValue(
              actual.value,
              "",
              2
            )} ${foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom}</strong></div>
          </div>
        `;
      },
    },
    legend: { show: false },
    xAxis: {
      type: "category",
      boundaryGap: false,
      data: xAxisData,
    },
    yAxis: {
      type: "value",
    },
    // dataZoom: [
    //   {
    //     type: "slider",
    //     start: 0,
    //     end: 100,
    //     xAxisIndex: 0,
    //   },
    // ],
    dataZoom: [
      {
        type: "slider",
        orient: "horizontal",
        start: 0,
        // end: 10,
        height: 15,
        backgroundColor: "transparent",
        fillerColor: "transparent",
        handleSize: 0,
        borderColor: "transparent",
        showDetail: false,
        textStyle: {
          color: "transparent",
        },
        handleStyle: {
          borderRadius: 10,
        },
        bottom: 25,
      },
    ],
    series: [
      {
        name: "Forecasted Value",
        type: "line",
        data: isForecastedVisible ? forecastedData : [],
        areaStyle: {
          color: "rgba(255, 159, 64, 0.4)",
        },
        itemStyle: {
          color: "#ff9f40",
        },
        smooth: true,
        lineStyle: {
          width: 2,
          color: "#ff9f40",
        },
      },
      {
        name: "Actual Value",
        type: "line",
        data: isActualVisible ? actualData : [],
        areaStyle: {
          color: "rgba(75, 192, 192, 0.4)",
        },
        itemStyle: {
          color: "#4bc0c0",
        },
        smooth: true,
        lineStyle: {
          width: 2,
          color: "#4bc0c0",
        },
      },
    ],
  };

  const chartData2 = {
    tooltip: {
      trigger: "axis",
      backgroundColor: "#2E2B2B",
      borderWidth: 0,
      padding: [5, 8],
      textStyle: {
        color: "#fff",
        fontSize: 12,
        lineHeight: 1.4,
      },
      formatter: (params) => {
        const forecasted = params.find(
          (param) => param.seriesName === "Forecasted Value"
        );
        const actual = params.find(
          (param) => param.seriesName === "Actual Value"
        );    
        const tooltipContent = (label, value) => `
          <div style="font-size: 12px; line-height: 1.4; min-width: 150px;">
            <div>${label}</div>
            <div style="font-size: 14px; font-weight: bold;">${value} ${foreCastList?.forecastSummary?.overAllData[0]
              ?.currentValue?.defaultUom}</div>
          </div>
        `;
    
        if (!actual) {
          return tooltipContent("Forecasted Value", formatMeterValue(forecasted.data, "", 2));
        }
    
        if (!forecasted) {
          return tooltipContent("Actual Value", formatMeterValue(actual.data, "", 2));
        }
    
        const variancePercentage = (
          ((forecasted.data - actual.data) / actual.data) *
          100
        ).toFixed(2);
        const varianceSign = variancePercentage > 0 ? "↑" : "↓";
    
        return `
            <div style="font-size: 12px; line-height: 1.4; min-width: 200px;">
              <div>Forecasted Value</div>
              <div style="display: flex; justify-content: space-between; align-items: center; font-size: 14px; font-weight: bold;">
                <div>${formatMeterValue(forecasted.data, "", 2)} ${foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom}</div>
                <div style="background: #f0f0f0; color: #007bff; display: inline-block; padding: 1px 4px; border-radius: 4px; font-size: 10px;">
                  ${varianceSign} ${Math.abs(variancePercentage)}% 
                </div>
              </div>
              <div>${tollDates[xAxisData.indexOf(params[0]?.axisValueLabel)]}</div>
              <hr style="border-top: 1px solid #888; margin: 4px 0;" />
              <div>Actual Value <strong>${formatMeterValue(actual.data, "", 2)} ${foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom}</strong></div>
            </div>
        `;
      },
    },
    
    legend: { show: false },
    xAxis: {
      type: "category",
      data: xAxisData,
    },
    yAxis: {
      type: "value",
    },
    dataZoom: [
      {
        type: "slider",
        orient: "horizontal",
        start: 0,
        // end: 10,
        height: 15,
        backgroundColor: "transparent",
        fillerColor: "transparent",
        handleSize: 0,
        borderColor: "transparent",
        showDetail: false,
        textStyle: {
          color: "transparent",
        },
        handleStyle: {
          borderRadius: 10,
        },
        bottom: 25,
      },
    ],
    series: [
      {
        name: "Actual Value",
        type: "bar",
        data: isActualVisible ? actualData : [],
        itemStyle: {
          color: "#1F77B4",
        },
        barWidth: 15,
        label: {
          show: true,
          position: "top",
          offset: [0, -10],
          formatter: (params) => {
            const actual = actualData[params.dataIndex];
            const forecasted = forecastedData[params.dataIndex];
            const percentage = (((forecasted - actual) / actual) * 100).toFixed(
              0
            );

            if (percentage > 0) {
              const arrow = "↑";
              return `+${percentage}%\n${arrow}`;
            }
            return "";
          },
          color: "#007bff",
          fontSize: 12,
          align: "center",
          verticalAlign: "middle",
        },
      },
      {
        name: "Forecasted Value",
        type: "bar",
        data: isForecastedVisible ? forecastedData : [],
        itemStyle: {
          color: "#FFA500",
        },
        barWidth: 15,
        label: {
          show: true,
          position: "top",
          offset: [0, -10],
          formatter: (params) => {
            const actual = actualData[params.dataIndex];
            const forecasted = params.data;
            const percentage = (((forecasted - actual) / actual) * 100).toFixed(
              0
            );

            if (percentage < 0) {
              const arrow = "↓";
              return `${percentage}%\n${arrow}`;
            }
            return "";
          },
          color: "#007bff",
          fontSize: 12,
          align: "center",
          verticalAlign: "middle",
        },
      },
    ],
  };

  //Filter Dropdown
  const [ilListOpen, setIsListOpen] = useState(false);
  const [
    selectedFilterOption,
    setSelectedFilterOption,
    selectedFilterOptionRef,
  ] = useState("");
  const [selectedFilterDescription, setSelectedFilterDescription] =
    useState("");

  const filterList = userSpecificData?.partyForecastProductMeterType || [];
  useEffect(() => {
    if (filterList.length > 0) {
      const initialFilterOption = filterList[0].productMeterTypeId;
      const initialDescription = filterList[0].description;
      setSelectedFilterOption(initialFilterOption);
      setSelectedFilterDescription(initialDescription);
    }
  }, [filterList]);

  const handleSelectFilter = (option: any) => {
    if (option) {
      setSelectedFilterOption(option.value);
      setSelectedFilterDescription(option.label);
      setIsListOpen(false);
      refetch();
    }
  };

  const firstFacilityId = '_NA_'
  const [currentFilterByTypeAndId, setCurrentFilterByTypeAndId, currentFilterByTypeAndIdRef] = useState({
    filterByType: "_NA_",
    filterById: firstFacilityId,
  });


  useEffect(() => {
    if (selectedFilterOption && selectedTrendRange) {
      refetch();
    }
  }, [dateUpdate, selectedFilterOption, selectedTrendRange, selectedTrendRangeRef.current, currentFilterByTypeAndIdRef.current]);

  useEffect(() => {
    const timer = setTimeout(() => {
      userSpecific();
    }, 500);

    return () => clearTimeout(timer);
  }, []);
  


  //User specific Api
  const userSpecific = () => {
    const userSpecificData = LocalStorageService.getUserSpecificData();
    const preferences = userSpecificData?.data?.all;
    setUserSpecificData(preferences);
  };

  // Forecast Api
  const {
    data: dataResponse,
    error,
    isLoading,
    isFetching,
    refetch,
    dataUpdatedAt,
  } = useQuery(
    "getForecastList",
    () =>
      getForecastList(
        dateRange,
        selectedFilterOptionRef.current,
        selectedTrendRangeRef.current,
        dateChip,
        currentFilterByTypeAndIdRef.current?.filterById,
        currentFilterByTypeAndIdRef.current?.filterByType
      ),
    {
      enabled: false,
      keepPreviousData: false,
    }
  );

  useEffect(() => {
    if (dataResponse) {
      setForecastList(dataResponse);
    }
  }, [dataResponse]);

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background: "#fff",
      border: "2px solid #a7b8f4",
      height: "35px",
      width: "250px",
      alignContent: "center",
      cursor: "pointer",
      "@media (min-width: 400px) and (max-width: 760px)": {
        width: "130px",
        height: "15px",
      },
      "@media (max-width: 400px)": {
        width: "120px",
        height: "25px",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menuList: (provided: any) => ({
      ...provided,
      fontSize: "12px",
      maxWidth: "250px",
      "::-webkit-scrollbar": {
        width: "8px",
      },
      "@media (max-width: 720px)": {
        fontSize: "10px",
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      maxWidth: "250px",
      marginTop: "0px",
      "@media (max-width: 720px)": {
        maxWidth: "100%",
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontSize: "12px",
      "@media (max-width: 720px)": {
        fontSize: "10px",
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "noWrap",
      maxWidth: "90%",
      color: "#707070"
    }),
    option: (provided: any) => ({
      ...provided,
      cursor: "pointer",
    }),
  };

  return (
    <>
      <div className="forecastCompContainer">
        {energyConsumptionFilter && (
          <DashboardFilter setEnergyConsumptionFilter={setEnergyConsumptionFilter} setCurrentFilterByTypeAndId={setCurrentFilterByTypeAndId}/>
        )}
        <div className="forecastFilterDiv">
          <Select
            styles={customStyles}
            options={filterList.map((obj: any) => ({
              value: obj.productMeterTypeId,
              label: obj.description,
            }))}
            onChange={handleSelectFilter}
            placeholder={selectedFilterDescription || "Select"}
          />
        </div>
        {isLoading || isFetching ? (
          <div className="forecastLoaderContainer">
            <LoaderStyle />
          </div>
        ) : error === "error" ? (
          <DisplayError type="err_1" />
        ) : error === "empty" ? (
          <DisplayError type="err-empty" />
        ) : (
          <>
            <div className="forecastCardDataCont">
              <div className="forecastCardData">
                <img src={ForecastDetailsIcon} alt="icon" />
                <div className="forecastDetailsCards">
                  <div className="forecastDetailsCard1">
                    <p className="dayText">{currentTitle}</p>
                    {dateChip === "Custom" && (
                      <p className="dateRangeChipCont">
                        <span>{current.split(" - ")[0]}</span> -{" "}
                        <span>{current.split(" - ")[1]}</span>
                      </p>
                    )}
                    <p className="forecastReadings">
                      {foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.value
                        ? formatMeterValue(
                            foreCastList.forecastSummary.overAllData[0]
                              .currentValue.value,
                            "",
                            2
                          )
                        : "-"}{" "}
                      {foreCastList?.forecastSummary?.overAllData[0]
                        ?.currentValue?.defaultUom || ""}
                    </p>
                  </div>
                  <div className="forecastDetailsCard2">
                    <p className="dayText">{nextTitle}</p>
                    {dateChip === "Custom" && (
                      <p className="dateRangeChipCont">
                        <span>{next.split(" - ")[0]}</span> -{" "}
                        <span>{next.split(" - ")[1]}</span>
                      </p>
                    )}
                    <p className="forecastReadings">
                      {foreCastList?.forecastSummary?.overAllData[1]
                        ?.forecastValue?.value
                        ? formatMeterValue(
                            foreCastList.forecastSummary.overAllData[1]
                              .forecastValue.value,
                            "",
                            2
                          )
                        : "-"}{" "}
                      {foreCastList?.forecastSummary?.overAllData[1]
                        ?.forecastValue?.defaultUom || ""}
                    </p>
                  </div>
                </div>
              </div>
              <p className="forecastSummaryText">
                View and compare forecasted data with actuals.
              </p>
            </div>

            <div
              className="forecastDateAndToggleCont"
              style={{
                justifyContent:
                  trendRange.length > 1 ? "space-between" : "flex-end",
              }}
              ref={dropdownRef}
            >
              {trendRange.length > 1 && (
                <div className="dateRangeDropdownCont">
                  <div
                    className="dateRangeInputCont"
                    onClick={() => setShowDropDown(!showDropDown)}
                  >
                    <p className="dateRangeText">
                      Viewing {selectedTrendRange}
                    </p>
                    <img src={DropdownArrow} alt="icon" />
                  </div>

                  {showDropDown && (
                    <ul className="dateRangeDropdown">
                      {trendRange.map((item, index) => (
                        <li
                          style={{
                            cursor: "pointer",
                            color:
                              selectedTrendRange === item ? "white" : "black",
                            backgroundColor:
                              selectedTrendRange === item ? "#0075FF" : "",
                          }}
                          key={index}
                          onClick={() => {
                            setSelectedTrendRange(item);
                            setShowDropDown(false);
                          }}
                        >
                          {item}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              )}

              <div className="toggleIconsCont" onClick={handleToggle}>
                {!isToggled ? (
                  <img src={ToggleBar} alt="icon" className="toggleIcon" />
                ) : (
                  <img src={ToggleLine} alt="icon" className="toggleIcon" />
                )}
              </div>
            </div>

            <div style={{ height: "400px" }}>
              {isToggled ? (
                <ReactEcharts key={"line"} option={chartData1} />
              ) : (
                <ReactEcharts key={"bar"} option={chartData2} />
              )}

              <div className="legendsContainer">
                <div
                  className="legendsDiv"
                  onClick={() => toggleLegend("Actual Value")}
                  style={{ opacity: isActualVisible ? 1 : 0.5 }}
                >
                  <div className="actualLegend"></div>
                  <p className="legendsText">Actual Value</p>
                </div>

                <div
                  className="legendsDiv"
                  onClick={() => toggleLegend("Forecasted Value")}
                  style={{ opacity: isForecastedVisible ? 1 : 0.5 }}
                >
                  <div className="forecastLegend"></div>
                  <p className="legendsText">Forecasted Value</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default Forecast;
