import React from 'react';
import { NotificationPayload } from "@firebase/messaging";

interface MessageProps {
    notification: NotificationPayload | undefined;
}

const Message: React.FC<MessageProps> = ({ notification }) => {
    return (
        <>
            <div id="notificationHeader">
                {/* image is optional */}
                {notification?.image && (
                    <div id="imageContainer">
                        <img src={notification?.image} width={100} />
                    </div>
                )}
                <span>{notification?.title}</span>
            </div>
            <div id="notificationBody">{notification?.body}</div>
        </>
    );
};

export default Message;
