import React, { useEffect, useMemo, useRef, useState } from "react";
import "./AssetDetailChildComp.scss";
import { useNavigate } from "react-router-dom";
import lineChartAnimat from "../../../assets/Lotties/Animation - 1720606889190.json";
import { useMediaQuery } from "react-responsive";
import { useStateValue } from "../../../redux/StateProvider";
import {
  adjustOpacity,
  convertDynamicsButtonsData,
  findThresHoldColor,
  getLiveMetersData,
  getTimeAgo,
  renderChart,
  validateNullValues,
} from "../../../features/NewAssetDetailPage/AssetDetailsUtils";
import moment from "moment";
import LocalStorageService from "../../../Utils/LocalStorageService";
import { AssetDetailsServices } from "../../../features/NewAssetDetailPage/AssetDetailsServices";
import expandLeftIcon from "../../../assets/icons/expnadIconLeft.svg";
import minimizeIconn from "../../../assets/icons/minimizeIcon.svg";
import backNavigationIcon from "../../../assets/icons/icon-feather-chevron-right.svg";
import assetIcon from "../../../assets/image/icon-assets-default.svg";
import { checkUndefinedConditions } from "../../../features/assetList/utils";
import DisplayError from "../../../a1Components/DisplayError/DisplayError";
import NoDataAvailableIcon from "../../../assets/icons/noDataAvailable.svg";
import LiveMeterCard from "../../../features/NewAssetDetailPage/LiveMeterCard";
import arrowRightDirection from "../../../assets/icons/arrowRightDirection.svg";
import toggleTrendInactiveIcon from "../../../assets/icons/toggleTrendDataInActiveIcon.svg";
import toggleLiveInactiveIcon from "../../../assets/icons/toggleLiveDataInActiveIcon.svg";
import toggleTrendActiveIcon from "../../../assets/icons/toggleTrendDataActiveIcon.svg";
import toggleLiveActiveIcon from "../../../assets/icons/toggleLiveDataActiveIcon.svg";
import tickIcon from "../../../assets/icons/tick-icon.svg";
import dotIcon from "../../../assets/icons/dot-icon.svg";
import Lottie from "react-lottie";
import ReactEcharts from "echarts-for-react";
import MetricTrendSummary from "../../../features/metricTrendSummary/components/MetricTrendSummary";
import { Modal } from "react-bootstrap";
import closeIcon from "../../../assets/icons/closeIcon.svg";
import AcControllerIcon from "../../../../src/features/acController/assets/acControllerIcon.svg";
import ReactTooltip from "react-tooltip";
import { options } from "@amcharts/amcharts4/core";
import {AssetLocationType} from "../AssetDetailPage";



type PropTypes = {
  fullSize: boolean;
  setFullSize: (status: boolean) => void;
  acControllerSettingPopUp: boolean;
  setAcControllerSettingPopUp: (status: boolean) => void;
  setAssetLocation: (data: AssetLocationType) => void;
};

function AssetDetailChildComp({
  fullSize,
  setFullSize,
  acControllerSettingPopUp,
  setAcControllerSettingPopUp, setAssetLocation,
}: PropTypes) {
  const navigate = useNavigate();
  const [assetId, setAssetId] = useState("");
  const [assetAssocTypeId, setAssetAssocTypeId] = useState("");
  const [chartGroupData, setChartGroupData] = useState<string[]>([]);

  const lineChartAnimation = {
    loop: true,
    autoplay: true,
    animationData: lineChartAnimat,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const lottieStyle = {
    width: "60%",
    // height: '60%',
  };

  const [activeChart, setActiveChart] = useState("Live");
  const [liveDataShowFilter, setLiveDataShowFilter] = useState(false);

  // handling screen size?.?.?.?.?.?.?.?.?.?.?.?.?.?.
  const isScreenSmall = useMediaQuery({
    query: "(max-width: 768px)", // Adjust as per your design needs
  });
  // Update state based on screen size
  useEffect(() => {
    setFullSize(isScreenSmall);
  }, [isScreenSmall]);
  const [dynamicOptions, setDynamicOptions] = useState({});
  //    Filter Functionality?.?.?.?.?.
  const handleMouseEnter = (button) => {
    setHoveredButton(button);
  };

  const handleMouseLeave = () => {
    setHoveredButton(null);
  };

  const handleButtonClick = (button) => {
    setShowChart(false);
    const buttonOptions = button?.options?.map((option) => option?.name);
    const allOptionsSelected = buttonOptions?.every((option) =>
      selectedOptions?.some((selected) => selected?.name === option)
    );

    if (button?.options?.length === 0) {
      setSelectedOptions((prev) => {
        if (prev?.some((option) => option?.name === button?.name)) {
          return prev?.filter((option) => option?.name !== button?.name);
        }
        return [...prev, { name: button?.name, color: button?.color }];
      });
    } else if (allOptionsSelected) {
      // Remove all options for this button
      setSelectedOptions((prev) =>
        prev?.filter((option) => !buttonOptions?.includes(option?.name))
      );
    } else {
      // Add all options for this button
      setSelectedOptions((prev) => {
        const newOptions = button?.options?.filter(
          (option) => !prev?.some((selected) => selected?.name === option?.name)
        );
        return [...prev, ...newOptions];
      });
    }
  };

  const handleOptionClick = (option) => {
    setShowChart(false);
    setSelectedOptions((prev) => {
      if (prev?.some((sel) => sel?.name === option?.name)) {
        return prev?.filter((sel) => sel?.name !== option?.name);
      }
      return [...prev, { name: option?.name, color: option?.color }];
    });
  };
  const [hoveredButton, setHoveredButton] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [{ dateRange, dateChip }] = useStateValue();
  const liveDataResponseObj = useRef({
    assetRawData: [],
    assetSpecificData: {devType: null, time: [], assetAttr: []}
    });
  const [showChart, setShowChart] = useState(true);
  const [chartEmptyErrorStatus, setChartEmptyErrorStatus] = useState("");
  const [assetType, setAssetType] = useState({
    fixedAssetTypeId: "",
    description: "",
  });
  const [facilityData, setFacilityData] = useState({});
  useEffect(() => {
    if (selectedOptions?.length == 0) {
      setChartEmptyErrorStatus("empty");
    } else {
      renderChart(
        setChartEmptyErrorStatus,
        liveDataResponseObj,
        selectedOptions,
        setDynamicOptions,
        setShowChart
      );
    }
  }, [selectedOptions]);

  const [buttons, setButtons] = useState([]);
  const [loadingChart, setLoadingChart] = useState(true);

  useEffect(() => {
    if (buttons?.length === 0) {
      setChartEmptyErrorStatus("empty");
    }
    if (buttons?.length >= 1 && selectedOptions?.length == 0) {
      handleButtonClick(buttons[0]);
    } else {
      renderChart(
        setChartEmptyErrorStatus,
        liveDataResponseObj,
        selectedOptions,
        setDynamicOptions,
        setShowChart
      );
    }
  }, [buttons]);

  const handleZoomChange = (params) => {
    if (params?.batch !== undefined) {
      localStorage?.setItem(
        "dataZoom",
        JSON?.stringify({
          start: params?.batch[0]?.start,
          end: params?.batch[0]?.end,
        })
      );
    } else {
      localStorage?.setItem(
        "dataZoom",
        JSON?.stringify({ start: params?.start, end: params?.end })
      );
    }
  };

  const onEvents = useMemo(
    () => ({
      dataZoom: handleZoomChange,
    }),
    []
  );

  // ?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.
  const intervalRef = useRef(null);
  const previousEndDateRef = useRef(null);
  const abortControllerRef = useRef(null);
  //   livemetersData?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.?.
  const [liveMeterDataRespObj, setLiveMeterDataRespObj] = useState({});
  const [liveMeterLoader, setLiveMeterLoader] = useState(false);
  const [metersDataArr, setMetersDataArr] = useState([]);
  const [distributedArr, setDistributedArr] = useState([]);
  const [metersDataErrorResp, setMetersDataErrorResp] = useState(false);
  let previous  = useRef('');
  useEffect(() => {
    const callLiveDataAPI = async (isInitialCall = false) => {
      getLiveMetersData(
        setMetersDataArr,
        setLiveMeterLoader,
        setMetersDataErrorResp,
        setFacilityData,
        setAssetType,
        setLiveMeterDataRespObj,
        setDistributedArr,
        setAssetAssocTypeId,
          setAssetLocation
      );
      setChartEmptyErrorStatus("");
      if (isInitialCall) {
        setLoadingChart(true);
      }

      const token = localStorage?.getItem("@tokenID");
      let startDate;
      let endDate = moment().valueOf();

      if (isInitialCall && dateChip === "Today") {
        startDate = moment(dateRange[0]).startOf('day').valueOf();
      } else if (dateChip === "Today") {
        startDate = previousEndDateRef.current;
      } else {
        startDate = moment(dateRange[0])?.valueOf();
        endDate = moment(dateRange[1])?.valueOf();
      }

      previousEndDateRef.current = endDate;

      const id = await LocalStorageService?.getAssetIdData();
      setAssetId(id);

      if (abortControllerRef.current) {
        abortControllerRef.current?.abort();
      }

      abortControllerRef.current = new AbortController();
      const signal = abortControllerRef.current?.signal;

      try {
        let response = await new AssetDetailsServices()?.fetchNewLiveData(
          id,
          startDate,
          endDate,
          token,
          signal
        );
        if (response === "error") {
          setChartEmptyErrorStatus("error");
          setLoadingChart(false);
          return;
        } else {
          if (response?.assetRawData?.length === 0) {
            setLoadingChart(false);
            setChartEmptyErrorStatus("empty");
            return;
          }
        }
        response =  {
          assetRawData: response?.assetRawData.map((obj:any)=> ({...obj, assetAttr: response?.assetSpecificData?.assetAttr })),
          assetSpecificData: response?.assetSpecificData
          }

        if (liveDataResponseObj.current?.assetRawData.length === 0) {
          liveDataResponseObj.current = response;
          // response?.assetRawData;
          const buttonsArr = await convertDynamicsButtonsData(
            response?.assetRawData?.map((obj) => {
              // const { liveData, ...remObj } = obj;
              return obj;
            })
          );

          if (buttonsArr?.length >= 1) {
            console?.log(buttonsArr);
            setButtons(buttonsArr);
          }else{
              setButtons([]);
          }
        } else {
          const sortedLiveDataResponseObj:any = {
            assetRawData : liveDataResponseObj.current?.assetRawData?.map(
            (obj) => {
              let addOnObj = response?.assetRawData?.find(
                (el) => el?.description === obj?.description
              );
              if (
                addOnObj !== undefined &&
                obj?.liveData !== undefined &&
                addOnObj?.liveData
              ) {
                obj.liveData = [...obj?.liveData, ...addOnObj?.liveData];
              }
              return obj;
            }
          ),
          assetSpecificData : {
            ...liveDataResponseObj.current?.assetSpecificData,
            time : [...liveDataResponseObj.current?.assetSpecificData?.time, ...response?.assetSpecificData?.time ]
          }
        }
          liveDataResponseObj.current = sortedLiveDataResponseObj;
          const buttonsArr = await convertDynamicsButtonsData(
            sortedLiveDataResponseObj?.assetRawData?.map((obj) => {
              // const { liveData, ...remObj } = obj;
              return obj;
            })
          );
          if (buttonsArr?.length >= 1) {
            setButtons(buttonsArr);
          }
        }
        setLoadingChart(false);

        // Schedule the next call
        if (dateChip === "Today") {
          intervalRef.current = setTimeout(() => {
            callLiveDataAPI();
          }, 60000);
        }
      } catch (error) {
        if (error?.name === "AbortError") {
          console?.log("Fetch aborted");
          setLoadingChart(true);
          return;
        } else {
          console?.error("Fetch error:", error);
        }
        setLoadingChart(false);
      }
    };
    if(previous.current !== dateChip){
      previous.current = dateChip;
    liveDataResponseObj.current = {
      assetRawData: [],
      assetSpecificData: {devType: null, time: [], assetAttr: []}
      };
    callLiveDataAPI(true);
  }

    return () => {
      if (intervalRef.current) {
        clearTimeout(intervalRef.current);
      }
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [dateRange, dateChip]);
  // ?.?.?.?.?.?.?.?.?.popup functionality?.?.
  const [showMeterPopup, setShowMeterPopup] = useState(false);

  // handling ThresHold Colors for Meter Readings?.
  const [arrayWithThresHoldColor, setArrayWithThresHoldColor] = useState([]);
  useEffect(() => {
    if (metersDataArr?.length !== 0) {
      findThresHoldColor(
        liveMeterDataRespObj,
        metersDataArr,
        setArrayWithThresHoldColor
      );
    }
  }, [metersDataArr]);

  const checkThresHoldValue = (value:any, color:any, groupName = null) => {

    let findObj :any = arrayWithThresHoldColor?.find((el) => el?.name === value);

    arrayWithThresHoldColor?.forEach((obj,index)=>{
      let testObj = obj?.options?.find((el)=> el.name === value)
      if(testObj !== undefined && obj?.name === groupName){
        if(testObj?.upperThresholdAttr !== null && testObj?.lowerThresholdAttr !== null ){
          findObj = arrayWithThresHoldColor[index];
        }else if(testObj?.upperThresholdAttr !== null || testObj?.lowerThresholdAttr !== null){

          let duplicateArr = [...arrayWithThresHoldColor];
          let keyNullObj :any = duplicateArr[index];

          if(testObj?.upperThresholdAttr !== null){
             keyNullObj.lowerThresholdAttr = null;
             findObj = keyNullObj
             }
          if(testObj?.lowerThresholdAttr !== null){
             keyNullObj.upperThresholdAttr = null;
             findObj = keyNullObj 
            }
        }
      }
    })

    // console.log('thresh',findObj, 'name',value )

    let colorObj:any =  metersDataArr.find((obj:any)=> obj.name === value);



    if (findObj === undefined) return color === "color" ? colorObj !== undefined ? colorObj?.color : "black" : [];

    if (!findObj?.lowerThresholdAttr && !findObj?.upperThresholdAttr)
      return color === "color" ? colorObj !== undefined ? colorObj?.color : "black" : "";

    if (
      findObj?.lowerThresholdAttr !== (null || false) &&
      findObj?.upperThresholdAttr !== (null || false)
    ) {
      if (
        findObj?.value <= findObj?.upperThresholdAttr &&
        findObj?.value >= findObj?.lowerThresholdAttr
      ) {
        return color === "color"
          ? colorObj !== undefined ? colorObj?.color : "black"
          : [
              findObj?.lowerThresholdAttr,
              findObj?.upperThresholdAttr,
              findObj?.readingUomDesc,
            ];
      }
      return color === "color"
        ? findObj?.threshColor === null
          ? "red"
          : findObj?.threshColor
        : [
            findObj?.lowerThresholdAttr,
            findObj?.upperThresholdAttr,
            findObj?.readingUomDesc,
          ];
    }

    if (
      findObj?.lowerThresholdAttr !== (null || false) ||
      findObj?.upperThresholdAttr !== (null || false)
    ) {
      if (
        findObj?.lowerThresholdAttr !== null &&
        findObj?.value < findObj?.lowerThresholdAttr
      )
        return color === "color"
          ? findObj?.threshColor !== null
            ? findObj?.threshColor
            : "red"
          : [findObj?.lowerThresholdAttr, findObj?.readingUomDesc];
      if (
        findObj?.upperThresholdAttr !== null &&
        findObj?.value > findObj?.upperThresholdAttr
      )
        return color === "color"
          ? findObj?.threshColor !== null
            ? findObj?.threshColor
            : "red"
          : [findObj?.upperThresholdAttr, findObj?.readingUomDesc];
    }
    return color === "color" ? colorObj !== undefined ? colorObj?.color : "black" : [];
  };

  /**
   * Check the Asset contains the Trend Chart or not?.
   */
  const trendAsssetTypeCheck = () => {
    const trendAsssetTypeList = [
      "TEMP_ENERGY",
      "TEMP",
      "ENERGY",
      "AIR",
      "WATER",
    ];
    return trendAsssetTypeList?.includes(assetAssocTypeId);
  };

  /**
   * Set Switch botton between the charts?.
   */
  const setChartButtons = () => {
    if (trendAsssetTypeCheck()) {
      setChartGroupData(["Live Data", "Trend Data"]);
    } else {
      setChartGroupData(["Live Data"]);
    }
  };

  const checkControllerPermission = (id: string) => {
    const userSpecificData = LocalStorageService.getUserSpecificData();
    const controllerId = `${id}_CTRL`;
    const preferences = userSpecificData?.data?.all?.preferences;
    return preferences?.some((item)=>
        item?.userPrefTypeId === controllerId &&
        item?.userPrefValue === "Y")
        || false;
  }

  /**
   * Set the required legends for the graph?.
   */
  useEffect(() => {
    if (assetAssocTypeId !== "") {
      setChartButtons();
    }
  }, [assetAssocTypeId]);

  return (
    <>
      {/* Meter Readings POPUP?.?.?. */}
      <Modal
        show={showMeterPopup}
        onHide={() => setShowMeterPopup(false)}
        fullscreen={true}
        className="liveMeterPopupCss"
      >
        <div className="customLiveMetersPopupContainer">
          <div className="customHeaderContainer">
            <div className="readingsHeadingContainer">
              <div className="readingsHeading">
                <h2>Latest Readings</h2>
                {liveMeterDataRespObj?.assets !== undefined &&
                  moment()?.diff(
                    liveMeterDataRespObj?.assets[0]?.lastCommTime,
                    "minutes"
                  ) < 15 && (
                    <p className="live">
                      <span>LIVE</span>
                    </p>
                  )}
              </div>
              {liveMeterDataRespObj?.assets !== undefined && (
                <span className="updatedDesc">
                  updated{" "}
                  {getTimeAgo(liveMeterDataRespObj?.assets[0]?.lastCommTime)}
                </span>
              )}
            </div>
            <img
              src={closeIcon}
              style={{ cursor: "pointer" }}
              onClick={() => setShowMeterPopup(false)}
            />
          </div>
          <div className="cutomBodyContainer">
            {distributedArr?.length !== 0 &&
              distributedArr?.map((element, index) => (
                <div key={index} className="threeRowDynamicContainer">
                  {element?.map((el) => (
                    <LiveMeterCard
                      metersDataArr={element}
                      checkThresHoldValue={checkThresHoldValue}
                      key={el?.name}
                      el={el}
                    />
                  ))}
                </div>
              ))}
            <div className="singleLiveMetersData">
              {metersDataArr?.length !== 0 &&
                metersDataArr
                  ?.filter((el) => el?.options?.length == 0)
                  ?.sort((a, b) => a?.sequenceNo - b?.sequenceNo)
                  ?.map((el) => (
                    <div className="singleLiveMetersCard">
                      <p className="singleLiveTitle">{el?.name}</p>
                      <p
                        className="singleLiveValue"
                        style={{ color: checkThresHoldValue(el?.name, "color") }}
                      >
                        {validateNullValues(
                          el?.value,
                          el?.readingUomDesc,
                          el?.decimalControl
                        )}
                      </p>
                      {/* { checkThresHoldValue(el?.name, 'content') !== '' && <p className='minMaxThresHolds'> {checkThresHoldValue(el?.name, 'content')} </p> } */}
                      {checkThresHoldValue(el?.name, "content")?.length !== 0 && (
                        <p className="minMaxFontText">
                          {checkThresHoldValue(el?.name, "content")?.length ==
                          3 ? (
                            <>
                              <span>Min</span>{" "}
                              {checkThresHoldValue(el?.name, "content")[0]}{" "}
                              {checkThresHoldValue(el?.name, "content")[2]} -{" "}
                              <span>Max</span>{" "}
                              {checkThresHoldValue(el?.name, "content")[1]}{" "}
                              {checkThresHoldValue(el?.name, "content")[2]}
                            </>
                          ) : (
                            <>
                              <span>Limit </span>
                              {checkThresHoldValue(el?.name, "content")[0]}{" "}
                              {checkThresHoldValue(el?.name, "content")[1]}{" "}
                            </>
                          )}
                        </p>
                      )}
                    </div>
                  ))}
            </div>
          </div>
        </div>
      </Modal>
      <div className="assetDetailChildComp">
        <div
          className="assetDetailsLeftContainer"
          style={{ width: fullSize ? "99.8%" : "99%" }}
        >
          {!liveMeterLoader && (
            <div
              className="shimmer-container"
              style={{ width: "160px", height: "50px", borderRadius: "0px" }}
            >
              {" "}
            </div>
          )}
          {liveMeterLoader && (
            <div className="assetDetailsBackNavigationContainer">
              <div className="headerLeftCont">
                <img
                  className="newAssetDetailsBackNavigationIcon"
                  src={backNavigationIcon}
                  onClick={() => navigate(-1)}
                />
                <div className="assetDetailsNameAndIconContainer">
                  {/*{liveMeterDataRespObj?.assets !== undefined &&*/}
                  {/*  liveMeterDataRespObj?.assets[0]?.contentUrl !==*/}
                  {/*    (null || undefined) && (*/}
                      <div className="newAssetDetailsIconContainer">
                        <img
                          className="newAssetIcon"
                          src={
                            liveMeterDataRespObj?.assets !== undefined
                              ? liveMeterDataRespObj?.assets[0]?.contentUrl ===
                                undefined ||
                                liveMeterDataRespObj?.assets[0]?.contentUrl === null
                                ? assetIcon
                                : liveMeterDataRespObj?.assets[0]?.contentUrl
                              : assetIcon
                          }
                        />
                      </div>
                    {/*)}*/}
                  <div className="newAssetDetailsAssetNameConatiner">
                    <p className="newAssetDetailsAssetContent1">
                      {checkUndefinedConditions(
                        facilityData?.facilityName,
                        false
                      )}
                      {checkUndefinedConditions(
                        facilityData?.address?.city,
                        true
                      )}
                      {checkUndefinedConditions(
                        facilityData?.address?.stateProvinceGeoId,
                        true
                      )}
                    </p>
                    <p className="newAssetDetailsAssetContent2">
                      {liveMeterDataRespObj?.assets !== undefined
                        ? liveMeterDataRespObj?.assets[0]?.assetName
                        : ""}
                    </p>
                    <p className="newAssetDetailsAssetContent3">
                      {assetType?.description}
                    </p>
                  </div>
                </div>
              </div>

              {checkControllerPermission(assetAssocTypeId) && (
                <>
                  <img
                    src={AcControllerIcon}
                    className="acControllerIcon"
                    onClick={() => setAcControllerSettingPopUp(true)}
                    data-tip="Show Controls"
                    data-for="ac_controller_setting_icon"
                  />
                  <ReactTooltip
                    id="ac_controller_setting_icon"
                    effect="solid"
                    place="bottom"
                    className="acControlTooltip"
                  />
                </>
              )}
            </div>
          )}

          {/* expand minimize button container */}
          <div
            className="newAssetExpandButtonContainer"
            onClick={() => setFullSize(!fullSize)}
          >
            <img
              className="newAssetExpandButtonIcon"
              src={fullSize ? minimizeIconn : expandLeftIcon}
              data-tip={fullSize ? "Minimize Section" : "Expand Section"}
              data-for="expand_icon"
            />
            <ReactTooltip
              id="expand_icon"
              place="bottom"
              effect="solid"
              className="newAssetExpandButtonTooltip"
            />
          </div>

          {/*chrat and meters data container */}
          <div
            className="readingsAndChartContainer"
            style={{ padding: fullSize ? "10px" : "0px" }}
          >
            {liveMeterLoader ? (
              <div
                className={`leftReadingsContainer ${
                  fullSize ? "" : "leftReadingsContainerSmallScreenCont"
                }`}
                style={{
                  width:
                    fullSize && isScreenSmall
                      ? "99.8%"
                      : fullSize
                      ? "48.5%"
                      : "99.8%",
                  // height: fullSize ? '98%' : '20%'
                }}
              >
                {/* copmilance dummy image */}
                {/* <img src={complianceDummyIcon} style={{ width: '88%' }} /> */}
                <div className="meterReadingscontainer">
                  <div className="meterRadingsCompleteBlueContainer">
                    {metersDataErrorResp ? (
                      <DisplayError type={"err-500/404"} />
                    ) : metersDataArr?.length === 0 ? (
                      <div className="cardMeterNoData">
                        <img src={NoDataAvailableIcon} className="noDataIcon" />
                        <p className="noDataText">
                          Data unavailable at the moment
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="meterLiveIconContainer">
                          {liveMeterDataRespObj?.assets !== undefined &&
                            moment()?.diff(
                              liveMeterDataRespObj?.assets[0]?.lastCommTime,
                              "minutes"
                            ) < 15 && (
                              <div className="live">
                                <p>LIVE</p>
                              </div>
                            )}
                          {liveMeterDataRespObj?.assets !== undefined && (
                            <p>
                              updated{" "}
                              {getTimeAgo(
                                liveMeterDataRespObj?.assets[0]?.lastCommTime
                              )}
                            </p>
                          )}
                        </div>
                        {/* meter Readings dynamic container will render meter readings in this contianer */}
                        <div
                          className="dynamicMeterReadingsContainer"
                          style={{ flexWrap: fullSize ? "wrap" : "nowrap" }}
                        >
                          {metersDataArr?.length !== 0 &&
                            metersDataArr
                              ?.filter((el) => el?.options?.length !== 0)
                              ?.map((el) => (
                                <LiveMeterCard
                                  metersDataArr={metersDataArr?.filter(
                                    (el) => el?.options?.length !== 0
                                  )}
                                  checkThresHoldValue={checkThresHoldValue}
                                  fullSize={fullSize}
                                  key={el?.name}
                                  el={el}
                                />
                              ))}
                          <br></br>
                          {metersDataArr?.length !== 0 &&
                            metersDataArr
                              ?.filter((el) => el?.options?.length == 0)
                              ?.sort((a, b) => a?.sequenceNo - b?.sequenceNo)
                              ?.map((el) => (
                                <div className="singleLiveMetersCard">
                                  <p className="singleLiveTitle">{el?.name}</p>
                                  <p
                                    className="singleLiveValue"
                                    style={{
                                      color: checkThresHoldValue(
                                        el?.name,
                                        "color"
                                      ),
                                    }}
                                  >
                                    {validateNullValues(
                                      el?.value,
                                      el?.readingUomDesc,
                                      el?.decimalControl
                                    )}
                                  </p>
                                  {/* { checkThresHoldValue(el?.name, 'content') !== '' && <p className='minMaxThresHolds'> {checkThresHoldValue(el?.name, 'content')} </p> } */}
                                  {checkThresHoldValue(el?.name, "content")
                                    ?.length !== 0 && (
                                    <p className="minMaxFontText">
                                      {checkThresHoldValue(el?.name, "content")
                                        ?.length == 3 ? (
                                        <>
                                          <span>Min</span>{" "}
                                          {
                                            checkThresHoldValue(
                                              el?.name,
                                              "content"
                                            )[0]
                                          }{" "}
                                          {
                                            checkThresHoldValue(
                                              el?.name,
                                              "content"
                                            )[2]
                                          }{" "}
                                          - <span>Max</span>{" "}
                                          {
                                            checkThresHoldValue(
                                              el?.name,
                                              "content"
                                            )[1]
                                          }{" "}
                                          {
                                            checkThresHoldValue(
                                              el?.name,
                                              "content"
                                            )[2]
                                          }
                                        </>
                                      ) : (
                                        <>
                                          <span>Limit </span>
                                          {
                                            checkThresHoldValue(
                                              el?.name,
                                              "content"
                                            )[0]
                                          }{" "}
                                          {
                                            checkThresHoldValue(
                                              el?.name,
                                              "content"
                                            )[1]
                                          }{" "}
                                        </>
                                      )}
                                    </p>
                                  )}
                                </div>
                              ))}
                        </div>
                      </>
                    )}
                  </div>
                  {metersDataErrorResp ? (
                    ""
                  ) : metersDataArr?.length === 0 ? (
                    ""
                  ) : (
                    <div className="newAssetDetailsViewAllButtonContainer">
                      <p className="" onClick={() => setShowMeterPopup(true)}>
                        View All
                      </p>
                      <img
                        onClick={() => setShowMeterPopup(true)}
                        src={arrowRightDirection}
                      />
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <div
                className="shimmer-container"
                style={{
                  width: fullSize ? "49.5%" : "99%",
                  height: fullSize ? "270px" : "80px",
                  borderRadius: "8px",
                }}
              >
                {" "}
              </div>
            )}

            <div
              className="rightChartContainer"
              style={{
                width:
                  fullSize && isScreenSmall
                    ? "99.8%"
                    : fullSize
                    ? "48.5%"
                    : "99.8%",
              }}
            >
              {/* toggle buttons container */}
              <div className="rigtChartToggleButtonsContainer">
                {
                  // loadingChart ? [1, 2]?.map((el) => <div className='shimmer-container shimmerToggleButtons'> </div>) :
                  <>
                    {chartGroupData?.includes("Live Data") &&
                      chartGroupData?.length > 1 && (
                        <button
                          onClick={() => setActiveChart("Live")}
                          className={
                            activeChart === "Live"
                              ? "newAssetDetailsActiveToggleButton"
                              : "newAssetDetailsToggleButton"
                          }
                        >
                          <img
                            src={
                              activeChart === "Live"
                                ? toggleLiveActiveIcon
                                : toggleLiveInactiveIcon
                            }
                          />
                          <p>Live Data</p>
                        </button>
                      )}
                    {chartGroupData?.includes("Trend Data") && (
                      <button
                        onClick={() => setActiveChart("Trend")}
                        className={
                          activeChart === "Trend"
                            ? "newAssetDetailsActiveToggleButton"
                            : "newAssetDetailsToggleButton"
                        }
                      >
                        <img
                          src={
                            activeChart === "Trend"
                              ? toggleTrendActiveIcon
                              : toggleTrendInactiveIcon
                          }
                        />
                        <p>Trends </p>
                      </button>
                    )}
                  </>
                }
              </div>
              {activeChart === "Live" ? (
                <>
                  {/* filters Container */}
                  <div className="newAssetDetailsLiveChartFilterChipsContainer">
                    <div
                      className="filterChipsOverFlowContainer"
                      style={{ flexWrap: liveDataShowFilter ? "wrap" : "wrap" }}
                    >
                      {loadingChart
                        ? [1, 2, 3, 4, 5, 6]?.map((el) => (
                            <div
                              key={el}
                              className="shimmer-container shimmer-buttons"
                            >
                              {" "}
                            </div>
                          ))
                        : buttons?.length !== 0 &&
                          buttons?.map((button, index) => (
                            <div
                              key={index}
                              className="button-container"
                              onMouseEnter={() => handleMouseEnter(button?.name)}
                              onMouseLeave={handleMouseLeave}
                              onClick={() => handleButtonClick(button)}
                            >
                              <button
                                style={{
                                  borderColor: button?.color !== null ? button?.color : 'rgb(84,112,198)',
                                  backgroundColor: selectedOptions?.some(
                                    (option) =>
                                      option?.name === button?.name ||
                                      button?.options?.some(
                                        (opt) => opt?.name === option?.name
                                      )
                                  )
                                    ? adjustOpacity(button?.color !== null ? button?.color : 'rgb(84,112,198)', 0.3)
                                    : hoveredButton === button?.name
                                    ? adjustOpacity(button?.color !== null ? button?.color : 'rgb(84,112,198)', 0.3)
                                    : "",
                                }}
                              >
                                {selectedOptions?.some(
                                  (option) =>
                                    option?.name === button?.name ||
                                    button?.options?.some(
                                      (opt) => opt?.name === option?.name
                                    )
                                ) && <img src={tickIcon} />}{" "}
                                {button?.name}
                              </button>
                              {
                              hoveredButton === button?.name &&
                                button?.options?.length > 0 &&
                                (
                                  <div className="overlay77">
                                    <div className="innerOverLay">
                                      {button?.options?.map((option, idx) => (
                                      <button
                                        className="overlayButton"
                                        key={idx}
                                        onClick={(e) => {
                                          e?.stopPropagation();
                                          handleOptionClick(option);
                                        }}
                                        style={{
                                          backgroundColor: selectedOptions?.some(
                                            (sel) => sel?.name === option?.name
                                          )
                                            ? adjustOpacity(option?.color !== null ? option?.color : 'rgb(84,112,198)', 0.3)
                                            : "",
                                          borderColor: `${option?.color !== null ? option?.color : 'rgb(84,112,198)' }`,
                                        }}
                                      >
                                        {selectedOptions?.some(
                                          (sel) => sel?.name === option?.name
                                        ) && <img src={dotIcon} />}
                                        {option?.name}
                                      </button>
                                    ))}
                                    </div>
                                  </div>
                                )}
                            </div>
                          ))}
                    </div>
                    {/* <button className='filterChipToggleButton' onClick={() => setLiveDataShowFilter(!liveDataShowFilter)}>
                                <img src={liveDataShowFilter ? filterToggleActiveIcon : filterToggleInActiveIcon} />
                            </button> */}
                  </div>
                  {/* Chart Container */}
                  <div className="newAssetDetailsLiveChartContainer">
                    {loadingChart ? (
                      <div className="shimmer-container">
                        <Lottie
                          options={lineChartAnimation}
                          style={lottieStyle}
                        />
                      </div>
                    ) : (
                      <>
                        { buttons.length ==0 ? <div style={{width:'100%', height:'10vh', display:'flex', alignItems:'center', justifyContent:'end' }}> <DisplayError type={"err-empty"} /></div> : chartEmptyErrorStatus === "empty" ? (
                          <DisplayError type={"err-empty"} />
                        ) : chartEmptyErrorStatus === "error" ? (
                          <DisplayError type={"err-500/404"} />
                        ) : (
                          showChart && (
                            <ReactEcharts
                              onEvents={onEvents}
                              option={dynamicOptions}
                              style={{ height: "320px", width: "100%" }}
                            />
                          )
                        )}
                      </>
                    )}
                  </div>
                </>
              ) : (
                <MetricTrendSummary
                  assetId={assetId}
                  assetAssocTypeId={assetAssocTypeId}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AssetDetailChildComp;
