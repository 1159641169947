import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import './editUser.scss';
import IconClose from "../../../assets/icons/IconClose.svg";
import { filteredFacilityType } from "../dataTypes/DataTypes";
import UseStatics from "../utils/utils";
import Unchecked from '../../../assets/icons/Unchecked.svg';
import Checked from '../../../assets/icons/Checked.svg';
import Select from "react-select";
import { customStylesCountry } from "./customStylesReactSelect";
import IfImgNotUpld from '../usersCardContainer/userCard/ViewUserIcons/IfImgNotUpld.svg';
import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
export const EditAddressPopUp = (props) => {
    const { data, setShowEditAddress, basicInfo, setBasicInfo, validationMessages, setValidationMessages, setShouldValidate } = props;
    const {
        addressPurposeData,
        countryArr,
    } = UseStatics();
    const [validationMessagesAddress, setValidationMessagesAddress] = useState(validationMessages);
    const [basicInfor, setBasicInfor] = useState(basicInfo);
    const [filteredStates, setFilteredStates] = useState([]);
    const filterAddressData = (el: string) => {
        setBasicInfo((prevState) => ({
            ...prevState,
            selectedAddressData: prevState.selectedAddressData.includes(el)
                ? prevState.selectedAddressData.filter((obj: string) => obj !== el)
                : [...prevState.selectedAddressData, el],
        }));
    };
    const [selectedCountryValue,setSelectedCountryValue]=useState( null);
    const[selectedStateValue,setSelectedStateValue]=useState( null);
    const validateValue = (value: string | any[], regex: { test: (arg0: any) => any; }, maxLength: number) => {
        if (!regex.test(value)) {
            return "Invalid format";
        }
        if (value.length > maxLength) {
            return `The value must be less than ${maxLength} characters.`;
        }
        return "";
    };
    const handleChange = (field: string, regex: RegExp, maxLength: number, e: React.FormEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const validationMessagesAddress = validateValue(value, regex, maxLength);
        if (validationMessagesAddress === "") {
            setBasicInfor(prevState => ({ ...prevState, [field]: value }));
        }

        setValidationMessages(prevMessages => ({
            ...prevMessages,
            [field]: validationMessagesAddress
        }));

        if (validationMessagesAddress === "") {
            setShouldValidate(false);
        }
    };

    const AddressDataPopUp = [
        { labels: ' To Name ', id: 'toName', propsData: { name: 'toName', regex: /^[A-Za-z' ]*$/, maxLength: 100 } },
        { labels: ' Attn Name ', id: 'attnName', propsData: { name: 'attnName', regex: /^[A-Za-z' ]*$/, maxLength: 100 } },
        { labels: 'Address Line 1 ', id: 'address1', propsData: { name: 'address1', regex: /^[A-Za-z0-9#.'_ ]*$/, maxLength: 150 } },
        { labels: 'Address Line 2  ', id: 'address2', propsData: { name: 'address2', regex: /^[A-Za-z0-9#.'_ ]*$/, maxLength: 150 } },
        { labels: 'City', id: 'city', propsData: { name: 'city', regex: /^[A-Za-z' ]*$/, maxLength: 30 } }
    ];
    useEffect(() => {
        setBasicInfo(basicInfor)
    }, [basicInfo, basicInfor])
    useEffect(() => {
        const idToFind = basicInfo.selectedCountry;
        const foundCountry = countryArr.find((obj) => obj.id===idToFind)
        if (foundCountry) {
          setSelectedCountryValue({ value: foundCountry.id, label: foundCountry.name });
        }
      }, [basicInfo.selectedCountry]);
      useEffect(() => {
        const idToFind = basicInfo.selectedState;
        const foundState = countryArr.find((obj) => obj.id===idToFind)
        if (foundState) {
          setSelectedStateValue({ value: foundState.id, label: foundState.name });
        }
      }, [basicInfo.selectedState, basicInfo.selectedCountry]);
    useEffect(() => {
        setValidationMessagesAddress(validationMessages)
    }, [validationMessagesAddress, validationMessages])
    useEffect(() => {
        if (basicInfo.selectedCountry) {
            const selectedCountryObj = countryArr.find(
                (country) => country.id === basicInfo.selectedCountry
            );
            if (selectedCountryObj && selectedCountryObj.states) {
                const statesMapped = selectedCountryObj.states.map((state) => ({
                    value: state.geoId,
                    label: state.geoName.trim(),
                }));
                setFilteredStates(statesMapped);
            } else {
                setFilteredStates([]);
            }
        } else {
            setFilteredStates([]);
        }
    }, [basicInfo.selectedCountry, countryArr]);

    console.log("selectedCountry",selectedCountryValue,"selectedstate",selectedStateValue,"countryarr",countryArr)

    return (
        <Modal
            show={setShowEditAddress}
            onHide={() => setShowEditAddress(false)}
            backdrop="static"
            keyboard={false}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="editAddressPopup"
        >
            <Modal.Header className="editAddressHeaderCont">
                <h4 className="editAddressTxt">Edit Address</h4>
                <div
                    className="closeIconDiv"
                    onClick={() => setShowEditAddress(false)}
                >
                    <img src={IconClose} alt="icon" />
                </div>
            </Modal.Header>
            <Modal.Body className="editAddressBody">
                <div className="editAddressBodyCont">
                    <div className="editAddressInputsCont">
                        {AddressDataPopUp.map((el) => {
                            const { labels, id, propsData } = el;
                            const { regex, maxLength } = propsData;

                            return (
                                <div className="addressTextInputsCont" key={id}>
                                    <label>{labels}</label>
                                    <input
                                        type="text"
                                        name={id}
                                        maxLength={maxLength}
                                        defaultValue={data?.addressDetails?.[0]?.commonDetails?.[id]}
                                        onInput={(e) => handleChange(id, regex, maxLength, e)}
                                    />
                                    {validationMessages[id] && (
                                        <div style={{ color: 'red' }}>{validationMessages[id]}</div>
                                    )}
                                </div>
                            );
                        })}
                        <div className="addressTextInputsCont">
                            <label>Country</label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={selectedCountryValue}
                                styles={customStylesCountry}
                                defaultValue={basicInfo.selectedCountry}
                                options={countryArr.map((ele) => ({
                                    value: ele.id,
                                    label: ele.name,
                                }))}
                                isClearable
                                onChange={(el) => setBasicInfor({ ...basicInfor, selectedCountry: el ? el.value : null, })}
                            />

                        </div>
                        <div className="addressTextInputsCont">
                            <label>Postal Code </label>
                            <input
                                type="text"
                                maxLength={10}
                                name="postalCode"
                                defaultValue={data?.addressDetails?.[0]?.commonDetails?.postalCode}
                                onInput={(e) => handleChange("postalCode", /^[A-Za-z0-9]*$/, 10, e)}
                            />
                            {validationMessages.postalCode && (
                                <div style={{ color: 'red' }}>{validationMessages.postalCode}</div>
                            )}
                        </div>
                        <div className="addressTextInputsCont">
                            <label>State/Province </label>
                            <Select
                                className="react-select-container"
                                classNamePrefix="react-select"
                                value={selectedStateValue}
                                styles={customStylesCountry}
                                defaultValue={basicInfo.selectedState}
                                options={filteredStates}
                                isClearable
                                onChange={(el) =>
                                    setBasicInfor({
                                        ...basicInfor,
                                        selectedState: el ? el.value : null,
                                    })
                                }
                            />
                        </div>
                        {validationMessages.state && (
                            <div style={{ color: 'red' }}>{validationMessages.state}</div>
                        )}
                    </div>

                    <div className="editAddressPurposeCont">
                        {addressPurposeData.length > 0 && addressPurposeData.map((el: any) => (
                            <div className="addressPurposeCont" >
                                {basicInfo.selectedAddressData.includes(el.id) ? (
                                    <img
                                        src={Unchecked}
                                        alt="checked"
                                        onClick={() => filterAddressData(el.id)}
                                    />
                                ) : (
                                    <img
                                        src={Checked}
                                        alt="unchecked"
                                        onClick={() => filterAddressData(el.id)}
                                    />
                                )}
                                <label>{el.name}</label>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="editAddressFooter">
                <button
                    className="userCancelBtn"
                    onClick={() => setShowEditAddress(false)}
                >
                    Cancel
                </button>
                <button
                    className="addAddressBtn"
                    onClick={() => setShowEditAddress(false)}
                >
                    Update Address
                </button>
            </Modal.Footer>
        </Modal>
    )
}