import React, { useEffect, useRef, useState } from "react";
import AddIcon from "../../assets/icons/AddIcon.svg";
import "./Users.scss";
import UsersCardContainer from "./usersCardContainer/UsersCardContainer";
import { CreateUser } from "./createUser/CreateUser";
import PageFilters from "../../features/pageFilters/pageFilters";
import { GROUPS_SUPPORTED_BY_PAGE_FILTERS } from "../../Constants/constants";
import { useQuery } from "react-query"; 
import UserCard from "./usersCardContainer/userCard/UserCard";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import { toast } from "react-toastify";
import DisplayError from "../../a1Components/DisplayError/DisplayError";
import { fetchUserList } from "./service/PostData";
import { getKeyForSelectedFilter } from "../../Constants/constants";
import { FilterUtils } from "../../features/assetList/utils/FilterUtils";
function Users(props) {
  const { searchValue, searchKey, initSearch } = props;
  const [setAddUser, setAddUserPopup] = useState(false);
  const previousStateFilterData = useRef("");
  const loadMoreRef = useRef(false);

  const [users, setUsers] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    nextPage: 2,
    pageSize: 100,
    totalCount: 0,
    totalPage: 1,
  });
  const pageIndexRef = useRef(1);
  const[searchKeyValueFilters,setSearchKeyValueFilters]=useState([]);
  const { data, isLoading,isFetching, isError, refetch } = useQuery(
    "fetchUserList",
    () =>
      fetchUserList(
        pageIndexRef.current,
        searchKey,
        searchValue,
        searchKeyValueFilters,
        pagination.pageSize
      ),
    {
      keepPreviousData: false,
      enabled: false
    }
  );
  
  const resetPagination = () => {
    setPagination({
      currentPage: 1,
      nextPage: 2,
      totalCount: 0,
      totalPage: 0,
      pageSize: 100,
    });
    pageIndexRef.current = 1;
  };
  const loadMoreUsers = () => {
    if (pageIndexRef.current <= pagination.totalPage) {
      loadMoreRef.current = true;
      pageIndexRef.current+=1;
      refetch();
    }
  };
  const updateSearchList = (filterDataArg : any) => {
    previousStateFilterData.current = filterDataArg.current;
    const utils = new FilterUtils();
    const newSearchList = utils.updateSearchList(filterDataArg.current, "");
    if (newSearchList.length === 0) {
      setSearchKeyValueFilters([]);
    } else {
      setSearchKeyValueFilters(
        newSearchList.map((el) => ({
          searchKey: el.searchKey,
          searchValue: el.searchValue,
        }))
      );
    }
  };

  const handleClearFilter = () => {
    previousStateFilterData.current = "";
    setSearchKeyValueFilters([]);
  };
  useEffect(() => {
    if (searchValue !== null && searchValue !== undefined) {
      resetPagination();
      refetch();
    }
  }, [searchValue, initSearch]);
  useEffect(() => {
    if (data) {
      const { userList, paginationMap } = data;
      setPagination((prev) => ({
        ...prev,
        currentPage: pageIndexRef.current,
        nextPage: paginationMap.nextPage,
        totalCount: paginationMap.totalCount,
        totalPage: paginationMap.totalPage
      }));
      if (!searchValue && !searchKeyValueFilters) {
        setUsers(userList);
        loadMoreRef.current = false;
      }
       else if (pageIndexRef.current === 1 && searchValue !=='' || pageIndexRef.current === 1 && searchKeyValueFilters.length!==0 ) {
        setUsers(userList);
        loadMoreRef.current = false;
      } else {
        setUsers((prevUsers) => [...prevUsers, ...userList]);
        loadMoreRef.current = false;
      }
      }
  }, [data]);
  useEffect(()=>{
    if(searchValue==='' || searchValue ===null || searchValue === undefined){
      pageIndexRef.current=1;
      refetch();
    }
  },[searchValue])
  useEffect(() => {
    if (searchKeyValueFilters) {
      resetPagination();
      pageIndexRef.current=1;
      refetch();
    }
    if (searchKeyValueFilters.length === 0) {
      refetch();
    }
  }, [searchKeyValueFilters]);
  return (
    <div className="wholeUserCardContainer">
      <div className="usersFilterBtnCont">
        <PageFilters
          controlPageFiltersGroups={{
            ...GROUPS_SUPPORTED_BY_PAGE_FILTERS,
            Status: false,
            "Asset Category Live": false,
            "Asset Type": false,
            Area: false,
            Location: false,
            "Sub Locations": false,
            enabled: false,
            // "Region": false,
            // "City": false,
            // "Cluster": false,
            // "Facility": false,
          }}
          isLoading={false}
          showLeft={true}
          showAddAsset={() => setAddUserPopup(true)}
          previousStateFilterData={previousStateFilterData}
          assetDisplayObj={`${users?.length}/${pagination.totalCount} users`}
          updateSearchList={updateSearchList}
          handleClearFilter={handleClearFilter}
        />
      </div>
      <div className="usersCardContainer">
        {isError ? (
          <div className="height100_asset displayGrid">
            <DisplayError type="err-fetch" />
          </div>
        ) : isLoading && pageIndexRef.current===1 || isFetching && pageIndexRef.current===1 ? (
          <div className="height100_asset displayGrid">
            <LoaderStyle />
          </div>
        ) : users.length === 0 ? (
          <div className="height100_asset displayGrid">
            <DisplayError type="err-empty" />
          </div>
        ) : (
          users.map((el, index) => (
            <UserCard
              key={el.userBasicDetails?.[0]?.partyId || index}
              data={el}
            />
          ))
        )}
        {pageIndexRef.current < pagination.totalPage && users.length > 0 && (
          <div className="usersLoadBtnCont">
            <button
              className="loadMoreBtn"
              onClick={loadMoreUsers}
              disabled={isLoading}
            >
              {isLoading && pageIndexRef.current>1 || isFetching && pageIndexRef.current>1 ? "Loading..." : "Load more"}
            </button>
          </div>
        )}
      </div>
      {setAddUser && <CreateUser setAddUserPopup={setAddUserPopup} />}
    </div>
  );
}
export default Users;