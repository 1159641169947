import React, {useEffect, useRef} from "react";
import "./App.css";
import temp_sensor_purple from "../../../../assets/Lotties/tempSensorPurple.json";
import temp_sensor_red from "../../../../assets/Lotties/tempSensorRed.json";
import Lottie from "react-lottie";
import {formatMeterValue} from "../../../../Utils/utils";
import {HVACLayoutProps} from "./types";
import tempSensorOffline from "../../assets/icon-hvac-offline.svg"
import tempSensorSlow from "../../assets/icon-hvac-slow.svg"
import ReactTooltip from "react-tooltip";

const TempSensorPurple = {
    loop: true,
    autoplay: true,
    animationData: temp_sensor_purple,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

const TempSensorRed = {
    loop: true,
    autoplay: true,
    animationData: temp_sensor_red,
    rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
    },
};

function LayoutHVAC(props: HVACLayoutProps) {
    const {data, min, max} = props;

    console.log("HVAC :: LayoutHVAC : ", min, max)

    const baseRectangleWidth = 500; // Base width for rectangles
    const baseRectangleHeight = 380; // Base height for rectangles
    const gap = 15;
    const itemWidth = 40;

    const connectorPositions = ['top', 'top'];


    const rectangleRefs = useRef<(HTMLDivElement | null)[]>([]);
    const containerRef = useRef<HTMLDivElement>(null);
    const connectorRef = useRef<SVGPathElement>(null);

    // Calculate rectangle dimensions
    const rectangleDimensions = data.map((area) => {
        // const width = (area.size/50) * baseRectangleWidth || baseRectangleWidth;
        const width = area.areaObj.size;
        const height = baseRectangleHeight;
        return {width, height};
    });


    // Draw connectors between area rectangles
    const drawConnector = () => {
        const connector = connectorRef.current;
        const container = containerRef.current;
        if (!connector || !container) return;

        const containerRect = container.getBoundingClientRect();

        // const pathPoints = rectangleRefs.current
        //     .map((div) => {
        //         if (div) {
        //             const rect = div.getBoundingClientRect();
        //             const x = rect.left - containerRect.left + rect.width / 2;
        //             const y = rect.top - containerRect.top + rect.height / 2;
        //             return `${x},${y}`;
        //         }
        //         return null;
        //     })
        //     .filter((point): point is string => point !== null);
        //
        // if (pathPoints.length === 0) return;
        //
        // const pathData = pathPoints.reduce((acc, point, index) => {
        //     if (index === 0) {
        //         return `M ${point}`;
        //     } else {
        //         return `${acc} L ${point}`;
        //     }
        // }, "");
        //
        // console.log("Connector Path Data:", pathData); // Debugging
        //
        // connector.setAttribute("d", pathData);
        let pathString = rectangleRefs.current
            .map((ref, index) => {
                const div = ref;
                if (div) {
                    const rect = div.getBoundingClientRect();
                    const x = rect.left - containerRect.left;
                    const y =
                        rect.top -
                        containerRect.top +
                        (connectorPositions[index] === 'top' ? rect.height * 0.25 : rect.height * 0.75);
                    return `${x},${y}`;
                }
                return null;
            })
            .filter((point) => point !== null)
            .join(' ');

        // Add the coordinates of the last rectangle's starting point
        const lastRect = rectangleRefs.current[rectangleRefs.current.length - 1];
        if (lastRect) {
            const lastRectCoords = lastRect.getBoundingClientRect();
            pathString += ` ${
                lastRectCoords.left +
                lastRectCoords.width -
                containerRect.left
            },${
                lastRectCoords.top -
                containerRect.top +
                (connectorPositions[rectangleRefs.current.length - 1] === 'top'
                    ? lastRectCoords.height * 0.25
                    : lastRectCoords.height * 0.75)
            }`;
        }

        const addCurve = (x1, y1, x2, y2, x, y) => {
            return `C ${x1},${y1} ${x2},${y2} ${x},${y}`;
        };

        const points = pathString.split(' ');
        let curvedPath = `M ${points[0]}`; // Start with the first point

        for (let i = 1; i < points.length; i++) {
            const prevPoint = points[i - 1].split(',');
            const currentPoint = points[i].split(',');

            if (i < points.length - 1) {
                // Calculate control points to create a smooth curve
                const x1 = (parseFloat(prevPoint[0]) + parseFloat(currentPoint[0])) / 2;
                const y1 = parseFloat(prevPoint[1]);
                const x2 = x1;
                const y2 = parseFloat(currentPoint[1]);

                // Add the curve
                curvedPath += addCurve(x1, y1, x2, y2, ...currentPoint);
            } else {
                // Add a straight line
                curvedPath += ` L ${currentPoint[0]},${currentPoint[1]}`;
            }
        }

        console.log(curvedPath)
        connector.setAttribute('d', curvedPath);
    };

    useEffect(() => {
        // Ensure refs are set before drawing the connector
        if (
            rectangleRefs.current.length === data.length &&
            rectangleRefs.current.every((ref) => ref !== null)
        ) {
            drawConnector();
        }
    }, [data]);

    return (
        <div className="App">
            <div className="frame">
                <div
                    className="overlap-group"
                    ref={containerRef}
                    style={{
                        position: "relative",
                        height: "400px",
                        width: "auto",
                    }}
                >
                    {/* SVG for the connectors */}
                    <svg
                        className="connectorSvg"
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 8,
                            pointerEvents: "none", // Ensure the SVG doesn't block mouse events
                        }}
                    >
                        {/*<path*/}
                        {/*    ref={connectorRef}*/}
                        {/*    fill="none"*/}
                        {/*    strokeWidth="40"*/}
                        {/*    stroke="black" // Simple stroke color for testing*/}
                        {/*/>              */}
                        <path
                            ref={connectorRef}
                            fill="none"
                            stroke="url('#logo-gradient')"
                            strokeWidth="40"
                            // stroke="black" // Simple stroke color for testing
                        />
                        <defs>
                            <linearGradient id="logo-gradient" x1="0%" y1="0%" x2="0%" y2="100%"
                                            gradientTransform="rotate(-45)">
                                <stop offset="20%" stopColor="lightblue">
                                    <animate attributeName="stop-color" values="lightblue; pink; lightblue;" dur="4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                                <stop offset="100%" stopColor="pink">
                                    <animate attributeName="stop-color" values="pink; lightblue; pink;" dur="4s"
                                             repeatCount="indefinite"></animate>
                                </stop>
                            </linearGradient>
                        </defs>
                    </svg>

                    {/* Dynamically render rectangles for each area */}
                    {data.map((area, index) => {

                        var leftPointer = 0
                        // Get pre-calculated dimensions
                        let {width: rectangleWidth, height: rectangleHeight} =
                            rectangleDimensions[index];

                        leftPointer = leftPointer + rectangleWidth - itemWidth

                        // Calculate left position by accumulating widths and gaps
                        const leftPosition = rectangleDimensions
                            .slice(0, index)
                            .reduce((acc, dim) => acc + dim.width + gap, 0);

                        // Set top position to align the tops of the rectangles
                        const topPosition = 0; // Fixed top margin

                        let rectangleWidthString = ''
                        if (data.length == 1) {
                            rectangleWidthString = `100%`
                            rectangleWidth = `100%`
                        } else if (rectangleWidth < 200) {
                            rectangleWidthString = `200px`
                            rectangleWidth = 200
                        } else {
                            rectangleWidthString = `${rectangleWidth}px`
                        }

                        //do math for width


                        return (
                            <div
                                key={area.areaId + index}
                                className="rectangle"
                                ref={(el) => (rectangleRefs.current[index] = el)}
                                style={{
                                    position: "absolute",
                                    left: `${leftPosition}px`,
                                    top: `${topPosition}px`,
                                    width: rectangleWidthString,
                                    height: `${rectangleHeight}px`,
                                    backgroundColor: "#E7E6E7",
                                    border: "1px solid #A8A8A8",
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center",
                                    padding: "10px",
                                    boxSizing: "border-box",
                                    zIndex: 2, // Ensure rectangles are above the SVG
                                }}
                            >
                                <div style={{
                                    left: 0,
                                    bottom: 0,
                                    position: "absolute",
                                    paddingBottom: "6px",
                                    paddingLeft: "12px",
                                    fontSize: "12px",
                                    color: "#787878"
                                }}>{area.areaObj.locationName.charAt(0).toUpperCase() + area.areaObj.locationName.substr(1).toLowerCase()}</div>
                                {/* Render assets inside the rectangle */}
                                {area.assets.map((asset) => {
                                    const left = (leftPointer - (((rectangleWidth - itemWidth) * (1 - asset.lat))))
                                    const top = (topPosition + ((rectangleHeight - 60) * asset.long))
                                    console.log("left top ", left, top);
                                    console.log("HVAC :: ", asset)


                                    const pointerValue =
                                        asset?.meterValue?.hasOwnProperty("decimalPrecision") &&
                                        asset?.meterValue?.decimalPrecision !== null
                                            ? asset?.meterValue?.decimalPrecision
                                            : 2;
                                    let formatValue = null;
                                    if (pointerValue != null) {
                                        formatValue = formatMeterValue(asset?.meterValue?.meterValue, asset?.meterValue?.meterTypeId, pointerValue);
                                    }
                                    const tooltipId = `tempSensor-${index}-${asset.id}`; // Unique id for each tooltip

                                    return (
                                        <>
                                            <div
                                                data-tip
                                                data-for={tooltipId} style={{
                                                position: "absolute",
                                                left: `${left}px`,
                                                top: `${top}px`,
                                                // width: "25px",
                                                height: "25px",
                                                justifyContent: "center",
                                                zIndex: 9999,
                                                display: "flex",
                                            }}>
                                                {
                                                    asset?.meterValue?.meterValue > max ? (
                                                            <div>
                                                                {asset?.healthStatus === "FA_SLOW" ? (
                                                                    <img
                                                                        key={asset.id}
                                                                        src={tempSensorSlow}
                                                                        height={40}
                                                                        width={20}
                                                                    />
                                                                ) : asset?.healthStatus === "FA_OFFLINE" ? (
                                                                    <img
                                                                        key={asset.id}
                                                                        src={tempSensorOffline}
                                                                        height={40}
                                                                        width={20}
                                                                    />
                                                                ) : (
                                                                    <Lottie
                                                                        key={asset.id}
                                                                        options={TempSensorRed}
                                                                        height={40}
                                                                        width={20}
                                                                    />
                                                                )}
                                                            </div>
                                                        ) :
                                                        //ToDO ! color
                                                        <div>
                                                            {asset?.healthStatus === "FA_SLOW" ? (
                                                                <img
                                                                    key={asset.id}
                                                                    src={tempSensorSlow}
                                                                    height={40}
                                                                    width={20}
                                                                />
                                                            ) : asset?.healthStatus === "FA_OFFLINE" ? (
                                                                <img
                                                                    key={asset.id}
                                                                    src={tempSensorOffline}
                                                                    height={40}
                                                                    width={20}
                                                                />
                                                            ) : (
                                                                <Lottie
                                                                    key={asset.id}
                                                                    options={TempSensorPurple}
                                                                    height={40}
                                                                    width={20}
                                                                />
                                                            )}
                                                        </div>
                                                }
                                                {formatValue &&
                                                    <div style={{
                                                        height: "40px",
                                                        alignContent: "center",
                                                        fontSize: "14px",
                                                        marginLeft: "4px",
                                                        color: "#000",
                                                        fontWeight: "bold"
                                                    }}>{formatValue + asset.meterValue.readingUomDesc}</div>}
                                            </div>
                                            <ReactTooltip id={tooltipId} type="light" >
                                                <div style={{display: "flex", flexDirection: "column", zIndex: 9999}}>
                                                <span>{asset.name} ( {asset.id} )</span>
                                                <span>{asset?.locationId?.name} { asset?.subLocationId?.name ? `- ${asset?.subLocationId?.name}`  : ""}</span>
                                                </div>
                                            </ReactTooltip>
                                        </>
                                    );

                                })
                                }
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
}

export default LayoutHVAC;


