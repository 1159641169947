import React from 'react';
import './AmbientTemperature.scss';
import AmbientTemperatureIcon from "../../assets/ambientTemperature.svg";


interface AmbientTemperatureProps {
    currentTemperature: string;
    minTemperature: string;
    maxTemperature: string;
}

const AmbientTemperature: React.FC<AmbientTemperatureProps> = (
    {
        currentTemperature,
        minTemperature,
        maxTemperature,
    }) => {
    return (
        <div className="ambient-temperature-container">
            <div className="indicator-bar"></div>
            <div className="ambient-temperature-content">
                <div className="temperature-header">
                    <span className="header-text">Ambient Temperature</span>
                </div>
                <div className="temperature-info">
                    <img className="temperature-icon" src={AmbientTemperatureIcon} alt="Ambient Temperature"/>
                    <div className="current-temperature">{currentTemperature}</div>
                </div>
                {minTemperature && maxTemperature &&
                    <div className="temperature-range">
                        <span className="min-temperature"><b>Min </b>{minTemperature}</span>
                        <span className="max-temperature">- <b>Max</b> {maxTemperature}</span>
                    </div>
                }
            </div>
        </div>
    );
};

export default AmbientTemperature;

/* AmbientTemperature.css */
