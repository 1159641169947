import React from "react";
import "./CoolingTowerAssetCardValue.scss";
import SuccessIcon from "../../../assets/SuccessIcon.svg";
import NormalIcon from "../../../assets/NormalIcon.svg";
import ErrorIcon from "../../../assets/ErrorIcon.svg";
import { LiveMeter } from "../../../../assetList/types";
import { rgbToRgba } from "../../../utils";

type Props = {
  data: LiveMeter[];
  item: number;
};

const CoolingTowerAssetCardValue = (props: Props) => {
  const statusId: string = `CT_FAN_ST_${props.item}`;
  const tripId: string = `CT_FAN_TRIP_ST_${props.item}`;

  const statusData = props.data.find(
    (item: LiveMeter) => item.meterTypeId === statusId
  );
  const tripData = props.data.find(
    (item: LiveMeter) => item.meterTypeId === tripId
  );
  const fontColor = tripData?.colorCode || "rgba(37, 56, 88, 1)";
  const backgroundColor: string =
    tripData?.colorCode || "rgba(37, 56, 88, 0.2)";
  const modifiedBackgroundColor = rgbToRgba(backgroundColor, 0.2);

  const StatusIcon =
    tripData === undefined || tripData.meterValue === null
      ? NormalIcon
      : tripData.meterValue === "ON"
      ? SuccessIcon
      : tripData.meterValue === "TRIPPED"
      ? ErrorIcon
      : NormalIcon;

  return (
    <div className="coolingTowerAssetCardValueDiv">
      <p className="coolingTowerAssetCardValueTitle">
        {statusData === undefined ? "-" : statusData.description}
      </p>
      <div className="coolingTowerAssetCardStatusDiv">
        <img src={StatusIcon} alt="Icon" />
        <p
          className="coolingTowerAssetCardValueStatus"
          style={{
            color: fontColor,
            backgroundColor: modifiedBackgroundColor,
          }}
        >
          {tripData === undefined || tripData.meterValue === null
            ? "-"
            : tripData.meterValue}
        </p>
      </div>
    </div>
  );
};

export default CoolingTowerAssetCardValue;
