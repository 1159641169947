import React from 'react';
import './ShimmerEffectComponent.scss';

type Props = {
    width: string;
    height: string;
    borderRadius: string;
}

const ShimmerEffectComponent = (props: Props) => {
    return (
        <div className='shimmerEffect'
             style={{
                 width: props.width,
                 height: props.height,
                 borderRadius: props.borderRadius
             }}
        />
    )
};

export default ShimmerEffectComponent;
