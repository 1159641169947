import moment from "moment";
import * as R from "ramda";
import React, { useEffect, useRef } from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import useState from "react-usestateref";
import vector from "../.././assets/image/Vector.svg";
import ScreenShot from "../../Utils/ScreenShot";
import AnoviAlertComp from "../../a1Components/AnoviAlertComponent/AnoviAlertComp";
import AnoviBarchart from "../../a1Components/AnoviCustBar/AnoviBarchart";
import EnergyDistribution from "../../a1Components/AnoviEnergyDistribution/EnergyDistribution";
import AnoviEventSummary from "../../a1Components/AnoviEventSummary/AnoviEventSummary";
import DisplayError from "../../a1Components/DisplayError/DisplayError";
import DisplayNote from "../../a1Components/DisplayNote/DisplayNote";
import StackedBarAnovi from "../../a1Components/E-Charts/stackedHorizontalBar/StackedBarAnovi";
import StackedHorizontalBar from "../../a1Components/E-Charts/stackedHorizontalBar/StackedHorizontalBar";
import LinkWithIcon from "../../a1Components/FormElements/linkWithIcon";
import LoaderStyle from "../../a1Components/LoaderStyle/LoaderStyle";
import TicketsTable from "../../a1Components/UserViewDashComp/TicketTable/TicketsTable";
import CardWrapper from "../../a1Components/cardWrapper";
import DonutChartComp from "../../a1Components/donutChart";
import CustDoughnut from "../../a1Components/donutChart/CustDoughnut";
import SingleFacilityEnergyConsumption
    from "../../a1Components/singleFacilityEnergyConsumption/SingleFacilityEnergyConsumption";
import OldFacilityEnergyConsumption from "../../a1Components/OldEnergyConsumption/OldEnergyConsumption";
import Compressor from "../../assets/icons/Compressor.svg";
import DefrostCycle from "../../assets/icons/DefrostCycle.svg";
import DoorOpenings from "../../assets/icons/DoorOpenings.svg";
import {useStateValue} from "../../redux/StateProvider";
import {DashBoardServices} from "../../services/DasboardServices/DashboardService";
import "./dashboardNew.scss";
import {AHUSummaryCard} from "../../features/AHUSummary";
import TempCompilance from "../../a1Components/TempCompilanceCard/TempCompilance";
import {API_BASE_URL} from "../../Constants/constants";
import LocalStorageService from "../../Utils/LocalStorageService";
import {EventSummary} from "../../features/eventSummary";
import {EventList} from "../../features/eventList";
import EventDuration from "../../features/eventDuration/components/EventDuration";
import {messaging, registerDeviceToFirebase} from "../../Firebase/Firebase";
import {onMessage} from "firebase/messaging";
import {HVACSummary } from "../../features/hvacSummary";
import Forecast from "../../a1Components/Forecast/Forecast";
import { toast } from "react-toastify";
import EventNotifications from "../../features/eventList/components/EventNotifications";
import "react-toastify/dist/ReactToastify.css";
import Message from "./AlertLayout";
import CustomToast from "../../a1Components/ModelPopupcomp/CustomToast/CustomToast";
import {getUserSpecificData} from "./services";
import DashboardShimmer from "./components/DashboardShimmer";

let i = 0;

function CommonDashboard(props: any) {
    let location = useLocation();
    const currentPath = location.pathname;
    const splittedCurrentPath = currentPath.split("/");
    const isAssetOpen = splittedCurrentPath.length > 2 && splittedCurrentPath[1] === "asset";
    const { rightOpen, toggleBtn, isDark, theme, handleRightOpen } = props;
    const [portlets, setPortlets] = useState<any>([]);
    const [showEnergyDistributionSettingsPopup, setEnergyDistributionSettingsPopup] = useState(false);
    const [energyConsumptionFilter, setEnergyConsumptionFilter] = useState(false);
    // Event Summary Functionality..
    const [eventSummaryLoader, setEventSummaryLoader] = useState<any>(true);
    const [eventSummaryResponse, setEventSummaryResponse, eventSummaryResponseRef,] = useState<any>([]);
    const [tabButtons, setTabButtons] = useState([
        {
            name: "Defrost Cycle",
            id: "DEFROST_CYCLE",
        },
        {
            name: "Compressor",
            id: "CMPRSR_ON,CMPRSR_OFF",
        },
        {
            name: "Door Openings",
            id: "VIR_DOOR_OPEN",
        },
    ]);
    const [activeTabState, setActiveTabState] = useState("DEFROST_CYCLE");
    const [eventSummaryTabChangeLoader, setEventSummaryTabChangeLoader] = useState(false);
    const [eventTabChangeResponse, setEventTabChangeResponse, eventTabChangeResponseRef,] = useState<any>([]);
    const [{ dashboardFilters, triggerDownload, dateRange, assetFilters, toDateValue }, dispatch,] = useStateValue();

    //anovi dashboard
    const [assetSummaryLoader, setAssetSummaryLoader] = useState(false);
    const [anoviTicketsDu, setAnoviTicketsDu] = useState<any>({});
    const [anoviTicketsDuTwo, setAnoviTicketsDuTwo] = useState<any>({});
    const [errorMessageToastState, setErrorMessageToastState] = useState({
        show: false,
        id: '',
    });

    async function fetchEventSummaryAnovi(fromDateStr: Number, toDateStr: Number, key: String) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviEventSummary(fromDateStr, toDateStr, key);
        return data;
    }

    const fetchDataEventSummaryAnovi = (
        fromDateStrunix: Number,
        toDateStrunix: Number
    ) => {
        setEventSummaryLoader(true);
        fetchEventSummaryAnovi(fromDateStrunix, toDateStrunix, activeTabState).then(
            async (compData: any) => {
                if (compData === "error") {
                    setEventSummaryResponse(compData);
                    setEventSummaryLoader(false);
                } else if (typeof compData === "number") {
                    setEventSummaryResponse(compData);
                    setEventSummaryLoader(false);
                } else {
                    let response = await compData.result[1]?.data;
                    setEventSummaryResponse(response);
                    setEventTabChangeResponse(response);
                    setEventSummaryLoader(false);
                }
            }
        );
        // setEventSummaryLoader(false);
    };

    const handleTabChange = (id: any) => {
        if (activeTabState !== id) {
            let fromDateStrunix = moment(dateRange[0]).valueOf();
            let toDateStrunix = moment(dateRange[1]).valueOf();
            setActiveTabState(id);
            setEventSummaryTabChangeLoader(true);
            fetchEventSummaryAnovi(fromDateStrunix, toDateStrunix, id).then(
                async (compData: any) => {
                    if (compData === "error") {
                        setEventTabChangeResponse(compData);
                        setEventSummaryTabChangeLoader(false);
                    } else if (typeof compData === "number") {
                        setEventTabChangeResponse(compData);
                        setEventSummaryTabChangeLoader(false);
                    } else {
                        let response: any = await compData.result[1].data;
                        console.log("fetchEventSummaryAnovi", response);
                        setEventTabChangeResponse(response);
                        setEventSummaryTabChangeLoader(false);
                    }
                }
            );
        }
    };
    // anovi dasshboard
    async function fetchanoviComSummary(fromDateStr: any, toDateStr: any) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviComplianceSummary(fromDateStr, toDateStr);
        //console.log("fetchanoviComSummary",fromDateStr, data.complianceSummary[0].compliancePercent);
        return data;
    }

    async function fetchanoviAssetSumm(fromDateStr: any, toDateStr: any) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviAssetSumm(fromDateStr, toDateStr);
        return data;
    }

    async function fetchanoviComSummaryTwo(fromDateStr: any, toDateStr: any) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviComplianceSummaryTwo(
            fromDateStr,
            toDateStr
        );
        return data;
    }

    async function fetchanoviTicketData(fromDateStr: string, toDateStr: string) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviTicketsDul(fromDateStr, toDateStr);
        return data;
    }

    async function fetchanoviTicketDataTwo(fromDateStr: any, toDateStr: any) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviTicketsDulTwo(fromDateStr, toDateStr);
        return data;
    }


    onMessage(messaging, (payload) => {
        console.log("FCM :: incoming notification");
        toast(<Message notification={payload.notification}/>);
    });

    // onMessage(messaging, (payload) => {
    //     toast(
    //         <EventNotifications notification={payload.notification} />
    //     )
    //     console.log("rozi:incoming msg::",payload);
    // });

    //Main UseEffect
    useEffect(() => {
        let intervalCompliance: any;
        let intervalTicket: any;
        let intervalEnergy: any;
        let intervalDg: any;
        setPageVariable(5);
        setFacilityVariable(false);
        setFilterLoader(false);
        setShowFilterTab(false);
        let intervalComplianceLive: any;
        let intervalTicketLive: any;
        let intervalEnergyLive: any;
        let intervalDgLive: any;
        if (dateRange.length !== 0) {
            // console.log(dateRange, "dateRangeTest")
            let fromDateStr = moment(dateRange[0]).format("YYYY-MM-DD HH:mm:ss");
            let toDateStr = moment(dateRange[1]).format("YYYY-MM-DD HH:mm:ss");
            let fromDateStrunix = moment(dateRange[0]).valueOf();
            let toDateStrunix = moment(dateRange[1]).valueOf();
            setCurrEnergyData("loading");
            setCurrDgData("loading");
            setCurrCompData("loading");
            setCurrTicketData("loading");
            setComplianceSummaryData("loading");
            setComplianceSummaryDataTwo("loading");
            //Anovi fetch components
            //Anovi Event Summary
            fetchDataEventSummaryAnovi(fromDateStrunix, toDateStrunix);
            //tempComplince
            //assetSumAnovi
            const assetSumAnovi = (fromDateStrunix: any, toDateStrunix: any) => {
                setAssetSummaryLoader(true);
                fetchanoviAssetSumm(fromDateStrunix, toDateStrunix).then(
                    async (compData: any) =>
                    // setAssetSummaryAnovi(compData);
                    {
                        if (compData === "error") {
                            setAssetSummaryAnovi(compData);
                        } else if (typeof compData === "number") {
                            setAssetSummaryAnovi(compData);
                        } else {
                            let data = await compData.statusCount;
                            setAssetSummaryAnovi(data);
                        }
                    }
                );
                setAssetSummaryLoader(false);
            };
            assetSumAnovi(fromDateStrunix, toDateStrunix);
            const anoviCompData = (fromDateStrunix: any, toDateStrunix: any) => {
                fetchanoviComSummary(fromDateStrunix, toDateStrunix).then(
                    (compData: any) => {
                        setComplianceSummaryData(compData);
                    }
                );

                fetchanoviComSummaryTwo(fromDateStrunix, toDateStrunix).then(
                    (compData) => {
                        setComplianceSummaryDataTwo(compData);
                    }
                );
            };
            anoviCompData(fromDateStrunix, toDateStrunix);

            const anoviTicketData = () => {
                fetchanoviTicketData(fromDateStr, toDateStr).then((compData: any) => {
                    if (!R.isEmpty(compData)) {
                        setAnoviTicketsDu(compData);
                    }
                });
                fetchanoviTicketDataTwo(fromDateStrunix, toDateStrunix).then(
                    (compData: any) => {
                        if (!R.isEmpty(compData)) {
                            setAnoviTicketsDuTwo(compData);
                        }
                    }
                );
            };
        }
        return () => {
            clearInterval(intervalCompliance);
            clearInterval(intervalTicket);
            clearInterval(intervalEnergy);
            clearInterval(intervalDg);
            clearInterval(intervalComplianceLive);
            clearInterval(intervalTicketLive);
            clearInterval(intervalEnergyLive);
            clearInterval(intervalDgLive);
        };
    }, [dateRange]);

    //Compliance Summary
    const [complianceSummaryData, setComplianceSummaryData] = useState<any>();
    const [complianceSummaryDataTwo, setComplianceSummaryDataTwo] = useState<any>();
    const [popupState, setPopupState] = useState(false);
    const [breadCrumbComp, setBreadCrumbComp, breadCrumbCompRef] = useState<any>([]);
    /* The following states contain the API response received */
    const [compApiResp, setCompApiResp, compApiRespRef] = useState<any>(null);
    const [energyApiResp, setEnergyApiResp, energyApiRespRef] =
        useState<any>(null);
    const [ticketApiResp, setTicketApiResp, ticketApiRespRef] =
        useState<any>(null);
    const [dgApiResp, setDgApiResp, dgApiRespRef] = useState<any>(null);
    const [currCompData, setCurrCompData] = useState<any>(null);
    const navigate = useNavigate();
    /* Local Dashboard Filter */
    const [dashFilterLoc, setDashFilterLoc, dashFilterLocRef] = useState({ cardname: "", filters: [], });
    /* The following states contain the different dropdown related data */
    const [energyDropdown, setEnergyDropdown, energyDropdownRef] =
        useState<any>(null);
    const [dgDropdown, setDgDropdown, dgDropdownRef] = useState<any>(null);
    const [ticketDropdown, setTicketDropdown, ticketDropdownRef] =
        useState<any>(null);
    const [compDropdown, setCompDropdown, compDropdownRef] = useState<any>(null);
    /* The following states contains the titles for all the filters */
    const [energyTitles, setEnergyTitles, energyTitlesRef] = useState({
        city: "City",
        cluster: "cluster",
        facility: "facility",
    });
    const [dgTitles, setDgTitles, dgTitlesRef] = useState({
        city: "City",
        cluster: "cluster",
        facility: "facility",
    });
    const [ticketTitles, setTicketTitles, ticketTitlesRef] = useState({
        city: "City",
        cluster: "cluster",
        facility: "facility",
    });
    const [compTitles, setCompTitles, compTitlesRef] = useState({
        city: "City",
        cluster: "cluster",
        facility: "facility",
    });
    const [currGeoDistComp, setCurrGeoDistComp] = useState<any>(null);
    const [showBackBtnComp, setShowBackBtnComp] = useState(false);
    /* The foloowing states contain states relevant to the Energy Table */
    const [currGeoDistEnergy, setCurrGeoDistEnergy] = useState<any>(null);
    const [showBackBtnEnergy, setShowBackBtnEnergy] = useState(false);
    const [breadCrumbenergy, setBreadCrumbEnergy, breadCrumbEnergyRef] =
        useState<any>([]);
    /* The following states contain the data to be displayed on the cards */
    const [currEnergyData, setCurrEnergyData] = useState<any>(null);
    const [currDgData, setCurrDgData] = useState<any>(null);
    const [currTicketData, setCurrTicketData] = useState<any>(null);
    /* The foloowing states contain states relevant to the Energy Table */
    const [currGeoDistDg, setCurrGeoDistDg] = useState<any>(null);
    const [showBackBtnDg, setShowBackBtnDg] = useState(false);
    const [breadCrumbDg, setBreadCrumbDg, breadCrumbDgRef] = useState<any>([]);

    /* Title original strings */
    const titleOriginalStr: any = {
        city: "City",
        cluster: "cluster",
        facility: "facility",
    };

    function capitalizeFirstLetter(string: any) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    function populateDropDowns(type: string, data: any) {
        const filterObj: any = {
            region: [],
            city: [],
            cluster: [],
            facility: [],
        };

        if (type === "DG") {
            // console.log("data for DG is::", data);
        }

        if (data.all !== null && !R.isNil(data.all) && !R.isEmpty(data.all)) {
            const regionData = data?.facility?.regionIds;
            const cityData = data?.facility?.cityIds;
            const clusterData = data?.facility?.clusterIds;
            const facilityIds = data?.facility?.facilityIds;

            if (regionData.length !== 0) {
                const regionArr =
                    !R.isEmpty(regionData) &&
                    !R.isNil(regionData) &&
                    regionData.map((obj: any) => {
                        return {
                            name: obj.facilityGroupName,
                            id: obj.facilityGroupId,
                            isSelected: false,
                            isVisible: true,
                            groupId: obj.parentFacilityGroupId,
                        };
                    });

                regionArr.unshift({
                    name: "All",
                    id: "All",
                    isSelected: true,
                    isVisible: true,
                    groupId: "All",
                });

                const cityArr = cityData.map((obj: any) => {
                    return {
                        name: obj.facilityGroupName,
                        id: obj.facilityGroupId,
                        isSelected: false,
                        isVisible: true,
                        groupId: obj.parentFacilityGroupId,
                    };
                });
                const clusterArr = clusterData.map((obj: any) => {
                    return {
                        name: obj.facilityGroupName,
                        id: obj.facilityGroupId,
                        isSelected: false,
                        isVisible: true,
                        groupId: obj.parentFacilityGroupId,
                    };
                });
                const facilityArr = facilityIds.map((obj: any) => {
                    return {
                        name: obj.facilityName,
                        id: obj.facilityId,
                        isSelected: false,
                        isVisible: true,
                        groupId: obj.parentFacilityGroupId,
                    };
                });

                filterObj.region = regionArr;
                filterObj.city = cityArr;
                filterObj.cluster = clusterArr;
                filterObj.facility = facilityArr;
            } else {
                filterObj.region.unshift({
                    name: "All",
                    id: "All",
                    isSelected: true,
                    isVisible: true,
                    groupId: "All",
                });
            }
        }

        if (type === "ENERGY") {
            setEnergyDropdown(filterObj);
        } else if (type === "DG") {
            // console.log("DGFIlter::", filterObj);
            setDgDropdown(filterObj);
        } else if (type === "TICKETS") {
            setTicketDropdown(filterObj);
        } else {
            setCompDropdown(filterObj);
        }

        // console.log("type::", type);
        // console.log("filterObj::", filterObj);
    }

    function refreshTitles(cardName: string) {
        // console.log("refreshing titles::");
        if (cardName === "Energy") {
            setEnergyTitles(titleOriginalStr);
        } else if (cardName === "DG") {
            setDgTitles(titleOriginalStr);
        } else if (cardName === "Tickets") {
            setTicketTitles(titleOriginalStr);
        } else {
            setCompTitles(titleOriginalStr);
        }
    }
    //download  popup//
    const closeModalHandler = () => {
        setPopupState(false);
    };

    function getCategRegId(cardname: string, breadCrumbArr: any) {
        let apiResp: any;
        let respArr: any = [];

        switch (cardname) {
            case "Asset Summary":
                apiResp = Object.assign({}, compApiRespRef.current);
                break;

            case "Energy":
                apiResp = Object.assign({}, energyApiRespRef.current);
                break;

            case "DG":
                apiResp = Object.assign({}, dgApiRespRef.current);
                break;

            default:
                break;
        }

        breadCrumbArr.forEach((breadCrumbObj: any) => {
            const currGeoDist = breadCrumbObj.currGeoDist;
            const geoName = breadCrumbObj.geoName;

            /* Updating dashboardFilter */
            let geoCateg: any;
            switch (currGeoDist) {
                case "region":
                    geoCateg = "region";
                    break;

                case "city":
                    geoCateg = "city";
                    break;

                case "cluster":
                    geoCateg = "cluster";
                    break;

                case "facility":
                    geoCateg = "facility";
                    break;

                default:
                    break;
            }

            let geoKey: any;
            switch (currGeoDist.toLowerCase()) {
                case "region":
                    geoKey = "regionIds";
                    break;

                case "city":
                    geoKey = "cityIds";
                    break;

                case "cluster":
                    geoKey = "clusterIds";
                    break;

                case "facility":
                    geoKey = "facilityIds";
                    break;

                default:
                    return;
            }

            const groupIdFiltObj = apiResp.facilities[geoKey].filter((obj: any) =>
                obj.hasOwnProperty("facilityGroupName")
                    ? obj.facilityGroupName.toLowerCase() === geoName.toLowerCase()
                    : obj.facilityName.toLowerCase() === geoName.toLowerCase()
            )[0];

            const groupId = groupIdFiltObj.hasOwnProperty("facilityGroupId")
                ? groupIdFiltObj.facilityGroupId
                : groupIdFiltObj.facilityId;

            respArr.push({
                categ: geoCateg,
                id: groupId,
            });
        });
        return respArr;
    }

    const onClick = (cardname: any) => {
        let breadCrumbArr: any = [];
        switch (cardname) {
            case "Asset Summary":
                breadCrumbArr = breadCrumbCompRef.current.slice();
                break;

            case "Energy":
                breadCrumbArr = breadCrumbEnergyRef.current.slice();
                break;

            case "DG":
                breadCrumbArr = breadCrumbDgRef.current.slice();
                break;

            default:
                break;
        }

        breadCrumbArr.splice(0, 1);

        let dashFiltArr: any = getCategRegId(cardname, breadCrumbArr);

        if (cardname === "DG") {
            dashFiltArr.push({ categ: "assetType", id: "SI_DG" });
        } else if (cardname === "Energy") {
            dashFiltArr.push({ categ: "assetType", id: "SI_EB" });
        }

        dispatch({
            type: "SET_DASHBOARD_FILTERS",
            dashboardFilters: dashFiltArr,
        });
        setDashFilterLoc({
            cardname: cardname,
            filters: dashFiltArr,
        });

        navigate("/asset-list");
    };

    function containsObject(obj: any, list: any) {
        var i;
        if (list.length === 0) {
            return false;
        }
        for (i = 0; i < list.length; i++) {
            if (JSON.stringify(list[i]) === JSON.stringify(obj)) {
                return true;
            }
        }

        return false;
    }

    // Facility Asset Summary
    const [assetSummaryAnovi, setAssetSummaryAnovi] = useState<any>();
    const assetSummaryAnoviDD = {
        assetSummaryAnovi,
    };
    const coursesPage = () => {
        navigate("/asset-list");
    };

    // Alerts Summary
    const alertDummyData = {
        totalAlerts: "140",
        open: "48",
        close: "60",
        resolution: "3.14",
        mttr: "3.34 mins",
        trendData: [
            {
                "13 Jan": {
                    OPEN: 43.3,
                    CLOSE: 85.8,
                },
            },
            {
                "14 Jan": {
                    OPEN: 83.1,
                    CLOSE: 73.4,
                },
            },
        ],
    };
    const anoviAlertsDD = { alertDummyData, };
    const coursesActivity = () => {
        handleRightOpen();
    };

    // Ticket Summary
    const [currGeoDistTicket, setCurrGeoDistTicket] = useState<any>(null);
    const [showBackBtnTicket, setShowBackBtnTicket] = useState(false);
    const [breadCrumbTicket, setBreadCrumbTicket, breadCrumbTicketRef] = useState<any>([]);

    async function updateTicketTableData(currGeoDistTicket: string, geoNameTicket: string) {
        const ticketApiResp = Object.assign({}, ticketApiRespRef.current);
        if (ticketApiResp.facilities.regionIds.length === 0) {
            setCurrTicketData(null);
            return;
        }
        let tableTitleArr: any = [];
        let tableHeadArr: any = [];
        let tableDataArr: any = [];
        let carouselDataArr: any = [];

        let geoKey: any;

        switch (currGeoDistTicket.toLowerCase()) {
            case "region":
                geoKey = "regionIds";
                break;

            case "city":
                geoKey = "cityIds";
                break;

            case "cluster":
                geoKey = "clusterIds";
                break;

            case "facility":
                geoKey = "facilityIds";
                break;

            default:
                return;
        }

        if (geoNameTicket === "") {
            /* Set Bread Crumb */
            const currBreadCrumbCopy = breadCrumbTicketRef.current.slice();
            const breadCrumbObj = {
                geoName: geoNameTicket,
                currGeoDist: currGeoDistTicket,
            };
            if (!containsObject(breadCrumbObj, currBreadCrumbCopy)) {
                const breadCrumbDrill = [...currBreadCrumbCopy, breadCrumbObj];
                // console.log("latestbreadcrumbvalue is::", breadCrumbDrill);
                setBreadCrumbTicket(breadCrumbDrill);
            }

            // Get the Table Header
            const headerTypeList = [
                "Critical & Overdue",
                "Critical",
                "Overdue",
                "Pending",
            ];
            tableHeadArr.push(capitalizeFirstLetter(currGeoDistTicket));
            tableHeadArr = [...tableHeadArr, ...headerTypeList];

            const ticketAssetObj: any = {};
            const totalTicketObj: any = {
                highPriority: 0,
                critical: 0,
                overDue: 0,
                open: 0,
                closed: 0,
                total: 0,
            };

            // Get the Table titles
            tableTitleArr = ticketApiResp.facilities[geoKey].map((geoObj: any) => {
                if (geoObj.hasOwnProperty("facilityGroupName")) {
                    return geoObj.facilityGroupName;
                } else return geoObj.facilityName;
            });

            // Table Data
            tableDataArr = ticketApiResp.facilities[geoKey].map((geoObj: any) => {
                // console.log("geoObj --> drill down tickets::", geoObj);

                const dataResultObj: any = {
                    highPriority: 0,
                    critical: 0,
                    overDue: 0,
                    open: 0,
                    closed: 0,
                    total: 0,
                };

                geoObj.data.forEach((dataObj: any) => {
                    // Carousel related data for Asset based classification of given tickets
                    if (!ticketAssetObj.hasOwnProperty(dataObj.fixedAssetType)) {
                        ticketAssetObj[dataObj.fixedAssetType] = {
                            highPriority: 0,
                            critical: 0,
                            overDue: 0,
                            open: 0,
                            closed: 0,
                            total: 0,
                        };
                    }

                    // Adding totals for tickets across categories
                    totalTicketObj.highPriority += dataObj.criticalAndOverDueTicketsCount;
                    totalTicketObj.critical += dataObj.criticalTicketsCount;
                    totalTicketObj.overDue += dataObj.overDueTicketsCount;
                    totalTicketObj.open += dataObj.openTickets;
                    totalTicketObj.closed += dataObj.closedTickets;
                    totalTicketObj.total = totalTicketObj.open + totalTicketObj.closed;

                    ticketAssetObj[dataObj.fixedAssetType].overDue +=
                        dataObj.overDueTicketsCount;
                    ticketAssetObj[dataObj.fixedAssetType].open += dataObj.openTickets;
                    ticketAssetObj[dataObj.fixedAssetType].closed +=
                        dataObj.closedTickets;
                    ticketAssetObj[dataObj.fixedAssetType].total =
                        ticketAssetObj[dataObj.fixedAssetType].open +
                        ticketAssetObj[dataObj.fixedAssetType].closed;

                    // Table data
                    dataResultObj.highPriority += dataObj.hasOwnProperty(
                        "criticalAndOverDueTicketsCount"
                    )
                        ? dataObj.criticalAndOverDueTicketsCount
                        : 0;
                    dataResultObj.critical += dataObj.hasOwnProperty(
                        "criticalTicketsCount"
                    )
                        ? dataObj.criticalTicketsCount
                        : 0;
                    dataResultObj.overDue += dataObj.overDueTicketsCount;
                    dataResultObj.open += dataObj.openTickets;
                    dataResultObj.closed += dataObj.closedTickets;
                });

                dataResultObj.total = dataResultObj.open + dataResultObj.closed;

                return [
                    dataResultObj.highPriority,
                    dataResultObj.critical,
                    dataResultObj.overDue,
                    dataResultObj.open,
                    dataResultObj.closed,
                    dataResultObj.total,
                ];
            });

            // console.log("tableDataArr - ticket is::", tableDataArr);

            // Populate the Carousel related Data
            const carouselObj1: any = {};
            carouselObj1.carouselHeader = [];
            carouselObj1.carouselBody = [];

            carouselObj1.carouselHeader.push({
                title: "Total(" + totalTicketObj.total + ")",
                subText: totalTicketObj.closed + " closed",
            });
            carouselObj1.carouselHeader.push({
                title: "Open",
                subText: totalTicketObj.open,
            });

            carouselObj1.carouselBody.push({
                title: "Critical Overdue",
                value: totalTicketObj.highPriority,
                subText: null,
            });
            carouselObj1.carouselBody.push({
                title: "Critical",
                value: totalTicketObj.critical,
                subText: null,
            });
            carouselObj1.carouselBody.push({
                title: "Overdue",
                value: totalTicketObj.overDue,
                subText: null,
            });
            carouselObj1.carouselBody.push({
                title: "Pending",
                value:
                    totalTicketObj.open -
                    (totalTicketObj.overDue +
                        totalTicketObj.highPriority +
                        totalTicketObj.critical),
                subText: null,
            });

            carouselDataArr.push(carouselObj1);

            // Now populate all the Individual asset related ticket details
            const assetNameList = Object.keys(ticketAssetObj);
            assetNameList.forEach((assetName: string) => {
                const carouselObj: any = {};
                carouselObj.carouselHeader = [];
                carouselObj.carouselBody = [];

                carouselObj.carouselHeader.push({
                    title: assetName + "(" + ticketAssetObj[assetName].total + ")",
                    subText: ticketAssetObj[assetName].closed + " closed",
                });
                carouselObj.carouselHeader.push({
                    title: "Open",
                    subText: ticketAssetObj[assetName].open,
                });

                carouselObj.carouselBody.push({
                    title: "Critical Overdue",
                    value: ticketAssetObj[assetName].highPriority,
                    subText: null,
                });
                carouselObj.carouselBody.push({
                    title: "Critical",
                    value: ticketAssetObj[assetName].critical,
                    subText: null,
                });
                carouselObj.carouselBody.push({
                    title: "Overdue",
                    value: ticketAssetObj[assetName].overDue,
                    subText: null,
                });
                carouselObj.carouselBody.push({
                    title: "Pending",
                    value:
                        ticketAssetObj[assetName].open -
                        (ticketAssetObj[assetName].overDue +
                            ticketAssetObj[assetName].highPriority +
                            ticketAssetObj[assetName].critical),
                    subText: null,
                });

                carouselDataArr.push(carouselObj);
            });
        } else {
            // console.log("Drilling down --> geoNameTicket is::", geoNameTicket);

            // This is the equivalent of a drill-down
            /* Set Bread Crumb */
            const currBreadCrumbCopy = breadCrumbTicketRef.current.slice();
            const breadCrumbObj = {
                geoName: geoNameTicket,
                currGeoDist: currGeoDistTicket,
            };

            if (
                !containsObject(breadCrumbObj, currBreadCrumbCopy) &&
                breadCrumbObj.currGeoDist.toLowerCase() !== "facility"
            ) {
                const breadCrumbDrill = [...currBreadCrumbCopy, breadCrumbObj];
                // console.log("latestbreadcrumbvalue is::", breadCrumbDrill);
                setBreadCrumbTicket(breadCrumbDrill);
            }

            // console.log("ticketApiResp::", ticketApiResp);

            const drilldownStr = "facilityGroupId";

            const groupId = ticketApiResp.facilities[geoKey].filter((obj: any) =>
                obj.hasOwnProperty("facilityGroupName")
                    ? obj.facilityGroupName.toLowerCase() === geoNameTicket.toLowerCase()
                    : obj.facilityName.toLowerCase() === geoNameTicket.toLowerCase()
            )[0][drilldownStr];
            // console.log("groupId-Tickets::", groupId);

            switch (currGeoDistTicket.toLowerCase()) {
                case "region":
                    geoKey = "cityIds";
                    currGeoDistTicket = "city";
                    break;
                case "city":
                    geoKey = "clusterIds";
                    currGeoDistTicket = "cluster";
                    break;
                case "cluster":
                    geoKey = "facilityIds";
                    currGeoDistTicket = "facility";
                    break;
                case "facility":
                    // console.log("facility was called::");
                    return;
                default:
                    break;
            }

            const geoList = ticketApiResp.facilities[geoKey].filter(
                (obj: any) => obj.parentFacilityGroupId === groupId
            );

            // Get the Table Header
            // const headerTypeList = ["Critical", "Overdue", "Open", "Closed", "Total"];
            const headerTypeList = [
                "Critical & Overdue",
                "Critical",
                "Overdue",
                "Pending",
            ];

            tableHeadArr.push(capitalizeFirstLetter(currGeoDistTicket));
            tableHeadArr = [...tableHeadArr, ...headerTypeList];

            const ticketAssetObj: any = {};
            const totalTicketObj: any = {
                highPriority: 0,
                critical: 0,
                overDue: 0,
                open: 0,
                closed: 0,
                total: 0,
            };

            // Get the Table titles
            tableTitleArr = geoList.map((geoObj: any) => {
                if (geoObj.hasOwnProperty("facilityGroupName")) {
                    return geoObj.facilityGroupName;
                } else return geoObj.facilityName;
            });

            // Get the Table Data
            tableDataArr = geoList.map((geoObj: any) => {
                const dataResultObj: any = {
                    highPriority: 0,
                    critical: 0,
                    overDue: 0,
                    open: 0,
                    closed: 0,
                    total: 0,
                };
                geoObj.data.forEach((dataObj: any) => {
                    // Carousel related data for Asset based classification of given tickets
                    if (!ticketAssetObj.hasOwnProperty(dataObj.fixedAssetType)) {
                        ticketAssetObj[dataObj.fixedAssetType] = {
                            highPriority: 0,
                            critical: 0,
                            overDue: 0,
                            open: 0,
                            closed: 0,
                            total: 0,
                        };
                    }

                    // Adding totals for tickets across categories
                    // totalTicketObj.highPriority += dataObj.criticalAndOverDueTicketsCount;
                    totalTicketObj.highPriority += dataObj.criticalAndOverDueTicketsCount;
                    totalTicketObj.critical += dataObj.criticalTicketsCount;
                    totalTicketObj.overDue += dataObj.overDueTicketsCount;
                    totalTicketObj.open += dataObj.openTickets;
                    totalTicketObj.closed += dataObj.closedTickets;
                    totalTicketObj.total = totalTicketObj.open + totalTicketObj.closed;

                    ticketAssetObj[dataObj.fixedAssetType].highPriority +=
                        dataObj.criticalAndOverDueTicketsCount;
                    ticketAssetObj[dataObj.fixedAssetType].critical +=
                        dataObj.criticalTicketsCount;
                    ticketAssetObj[dataObj.fixedAssetType].overDue +=
                        dataObj.overDueTicketsCount;
                    ticketAssetObj[dataObj.fixedAssetType].open += dataObj.openTickets;
                    ticketAssetObj[dataObj.fixedAssetType].closed +=
                        dataObj.closedTickets;
                    ticketAssetObj[dataObj.fixedAssetType].total =
                        ticketAssetObj[dataObj.fixedAssetType].overDue +
                        ticketAssetObj[dataObj.fixedAssetType].critical +
                        ticketAssetObj[dataObj.fixedAssetType].highPriority;

                    dataResultObj.highPriority += dataObj.hasOwnProperty(
                        "criticalAndOverDueTicketsCount"
                    )
                        ? dataObj.criticalAndOverDueTicketsCount
                        : 0;
                    dataResultObj.critical += dataObj.hasOwnProperty(
                        "criticalTicketsCount"
                    )
                        ? dataObj.criticalTicketsCount
                        : 0;
                    dataResultObj.overDue += dataObj.overDueTicketsCount;
                    dataResultObj.open += dataObj.openTickets;
                    dataResultObj.closed += dataObj.closedTickets;
                    dataResultObj.total =
                        dataResultObj.overDue +
                        dataResultObj.critical +
                        dataResultObj.highPriority;
                });

                dataResultObj.total =
                    dataResultObj.overDue +
                    dataResultObj.critical +
                    dataResultObj.highPriority;

                return [
                    dataResultObj.highPriority,
                    dataResultObj.critical,
                    dataResultObj.overDue,
                    dataResultObj.open,
                    dataResultObj.closed,
                    dataResultObj.total,
                ];
            });

            // console.log("tableDataArr - ticket is::", tableDataArr);

            // Populate the Carousel related Data
            const carouselObj1: any = {};
            carouselObj1.carouselHeader = [];
            carouselObj1.carouselBody = [];

            carouselObj1.carouselHeader.push({
                title: "Total(" + totalTicketObj.total + ")",
                subText: totalTicketObj.closed + " closed",
            });
            carouselObj1.carouselHeader.push({
                title: "Open",
                subText: totalTicketObj.open,
            });

            carouselObj1.carouselBody.push({
                title: "Critical Overdue",
                value: totalTicketObj.highPriority,
                subText: null,
            });
            carouselObj1.carouselBody.push({
                title: "Critical",
                value: totalTicketObj.critical,
                subText: null,
            });
            carouselObj1.carouselBody.push({
                title: "Overdue",
                value: totalTicketObj.overDue,
                subText: null,
            });
            carouselObj1.carouselBody.push({
                title: "Pending",
                value:
                    totalTicketObj.open -
                    (totalTicketObj.overDue +
                        totalTicketObj.highPriority +
                        totalTicketObj.critical),
                subText: null,
            });

            carouselDataArr.push(carouselObj1);
            // console.log(ticketAssetObj, "lpm");

            // Now populate all the Individual asset related ticket details
            const assetNameList = Object.keys(ticketAssetObj);
            assetNameList.forEach((assetName: string) => {
                const carouselObj: any = {};
                carouselObj.carouselHeader = [];
                carouselObj.carouselBody = [];

                carouselObj.carouselHeader.push({
                    title: assetName + "(" + ticketAssetObj[assetName].total + ")",
                    subText: ticketAssetObj[assetName].closed + " closed",
                });
                carouselObj.carouselHeader.push({
                    title: "Open",
                    subText: ticketAssetObj[assetName].open,
                });

                carouselObj.carouselBody.push({
                    title: "Critical Overdue",
                    value: ticketAssetObj[assetName].highPriority,
                    subText: null,
                });
                carouselObj.carouselBody.push({
                    title: "Critical",
                    value: ticketAssetObj[assetName].critical,
                    subText: null,
                });
                carouselObj.carouselBody.push({
                    title: "Overdue",
                    value: ticketAssetObj[assetName].overDue,
                    subText: null,
                });
                carouselObj.carouselBody.push({
                    title: "Pending",
                    value:
                        ticketAssetObj[assetName].open -
                        (ticketAssetObj[assetName].overDue +
                            ticketAssetObj[assetName].highPriority +
                            ticketAssetObj[assetName].critical),
                    subText: null,
                });

                carouselDataArr.push(carouselObj);
            });

            // console.log("carouselDataArr - Tickets are::", carouselDataArr);
        }

        const combinedObj: any = {
            tableTitle: tableTitleArr,
            tableHead: tableHeadArr,
            tableData: tableDataArr,
            carouselData: carouselDataArr,
        };

        // console.log("combinedObj is - Tickets::", combinedObj);

        setCurrTicketData(combinedObj);

        if (currGeoDistTicket === "region") {
            setShowBackBtnTicket(false);
        } else {
            setShowBackBtnTicket(true);
        }

        setCurrGeoDistTicket(currGeoDistTicket);
    }

    const onRowClickTicket = async (
        currGeoDistTicket: string,
        geoNameTicket: string,
        clickType: string
    ) => {
        await updateTicketTableData(currGeoDistTicket, geoNameTicket);
    };

    const onBackPressedTicket = async () => {
        const breadCrumbArrCopy = breadCrumbTicketRef.current.slice();
        breadCrumbArrCopy.splice(breadCrumbArrCopy.length - 1, 1);
        if (breadCrumbArrCopy.length !== 0) {
            setBreadCrumbTicket(breadCrumbArrCopy);

            const lastBreCrumObj = breadCrumbArrCopy[breadCrumbArrCopy.length - 1];

            // console.log("lastBreCrumObj Ticket::", lastBreCrumObj);

            await updateTicketTableData(
                lastBreCrumObj.currGeoDist,
                lastBreCrumObj.geoName
            );
        }
    };

    // Temperature Compliance
    const [showFilterTab, setShowFilterTab] = useState(false);
    const [acilityVariable, setFacilityVariable] = useState<any>(false);
    const [tempComperatureData, setTempComperatureData, tempComperatureDataRef] = useState<any>({ all: [], data: [] });
    const [valTemp, setValTemp] = useState<any>();
    const [nameVal, setnameVal, nameValRef] = useState<any>("");
    const [tempStatus, setTempStatus] = useState(false);
    const [pageVariable, setPageVariable] = useState(5);
    const [filterLoader, setFilterLoader] = useState(false);

    const [eventSummaryFilter, setEventSummaryFilter, eventSummaryFilterRef] = useState(false);
    const [eventDurationFilter, setEventDurationFilter, eventDurationFilterRef] = useState(false);

    const callBackname = (element: any) => {
        setnameVal(element);
    };
    const callbackData = (element: any) => {
        setTempComperatureData(element);
    };

    async function fetchTempCompliance(fromDateStr: any, toDateStr: any) {
        const fetchData = new DashBoardServices();
        const data = await fetchData.anoviTempCompliance(fromDateStr, toDateStr);
        return data;
    }

    const tempComplianceAnovi = (fromDateStrunix: any, toDateStrunix: any) => {
        setTempStatus(true);
        fetchTempCompliance(fromDateStrunix, toDateStrunix).then(
            async (compData: any) => {
                if (compData === "error") {
                    setTempComperatureData(compData);
                } else if (typeof compData === "number") {
                    setTempComperatureData(compData);
                } else {
                    let data = await compData.complianceSummary;
                    console.log("setTempComperatureData", data);
                    if (data.all[0]?.type === "fixedAsset") {
                        setFacilityVariable(true);
                    } else {
                        setFacilityVariable(false);
                    }
                    //v-1
                    setValTemp(data);
                    setnameVal(data.all[0]?.name);
                    setTempComperatureData(data);
                }
                setTempStatus(false);
            }
        );
    };
    const clickFilterTab = () => {
        setShowFilterTab(!showFilterTab);
        if (showFilterTab) {
            setFacilityVariable(false);
            setTempComperatureData(valTemp);
            // setnameVal(valTemp.all[0]?.name);
            if(valTemp !== undefined){
                setnameVal(valTemp.all[0]?.name);
            }
        }
    };
    const clickScrrenshot = () => {
        handleCapture();
    };
    const targetRef = useRef(null);

    const handleCapture = async () => {
        const dataURL = await ScreenShot(targetRef.current);
        downloadImage(dataURL);
        // console.log(dataURL); // You can save or use the dataURL as needed
    };
    const downloadImage = (dataURL: any) => {
        const link = document.createElement("a");
        link.href = dataURL;
        link.download = "screenshot.png"; // Set the filename here
        link.click();
    };

    const handleFilterForEventSummary = () => {
        setEventSummaryFilter(!eventSummaryFilter);
    }

    const handleFilterForEventDuration = () => {
        setEventDurationFilter(!eventDurationFilter);
    }

    const [displayNone, setDisplayNone] = useState(false);
    const [showSettingsPopup, setShowSettingsPopup] = useState(false);

    // Add PageId's to show full sized components
    const [fullSizedComponents, setFullSizedComponents] = useState<any>(['FLOW_FAC_SMRY_V1']);

    //Store Components in this Array.
    const componentsArray: any = [
        {
            component: <HVACSummary />,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'HVAC_ENG_VIEW_V1',
            settings: false,
            filter: undefined,
            headerLabel: 'HVAC Summary',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <EnergyDistribution displayNone={displayNone} showSettings={showEnergyDistributionSettingsPopup} setEnergyDistributionSettingsPopup={setEnergyDistributionSettingsPopup} />,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'EGRY_BALANCE_V1',
            settings: false,
            filter: undefined,
            headerLabel: 'Energy Distribution',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <Forecast setEnergyConsumptionFilter={setEnergyConsumptionFilter} energyConsumptionFilter={energyConsumptionFilter}/>,
            description: '',
            sequenceNum: 0,
            componentPortalId:
             'FORECASTS_V1',
            settings: false,
            filter: () => setEnergyConsumptionFilter(!energyConsumptionFilter),
            headerLabel: 'Forecast',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },

        {
            component: <SingleFacilityEnergyConsumption setEnergyConsumptionFilter={setEnergyConsumptionFilter} energyConsumptionFilter={energyConsumptionFilter} showSettingsPopup={showSettingsPopup} setShowSettingsPopup={setShowSettingsPopup} fullSizedComponents={fullSizedComponents} />,
            description: '',
            sequenceNum: 0,
            componentPortalId:
             'CONSUMPTION_V1',
            settings: true,
            filter: () => setEnergyConsumptionFilter(!energyConsumptionFilter),
            headerLabel: 'Consumption',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <OldFacilityEnergyConsumption />,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'EGY_CONSUMPTION_V2',
            settings: false,
            filter: undefined,
            headerLabel: 'Energy Consumption',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        // {
        //     component: <EnergyConsumption setEnergyConsumptionFilter={setEnergyConsumptionFilter} energyConsumptionFilter={energyConsumptionFilter} />,
        //     description: '',
        //     sequenceNum: 0,
        //     componentPortalId:
        //     'EGRY_BALANCE_V1',
        //     // 'EGY_CONSUMPTION_V2',
        //     settings: false,
        //     filter: () => setEnergyConsumptionFilter(!energyConsumptionFilter),
        //     headerLabel: 'Energy Consumption',
        //     footerLabel: '',
        //     downloadData: undefined,
        //     screenImg: false,
        //     clickScrrenshot: undefined,
        // },
        {
            component: <EventList />,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'VA_EVENT_LIST',
            settings: false,
            filter: undefined,
            headerLabel: 'Event List',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component:
                <> {eventSummaryLoader ? (
                    <div
                        style={{
                            height: "35vh",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <LoaderStyle />
                    </div>
                ) : eventSummaryResponseRef.current === "error" ? (
                    <DisplayError type={"err_1"} />
                ) : typeof eventSummaryResponseRef.current === "number" ? (
                    <DisplayError type={"err-500/404"} />
                ) : eventSummaryResponseRef.current.length === 0 ? (
                    <DisplayError type={"err-empty"} />
                ) : <>
                        {tabButtons.map((el: any, index: any) => {
                            return (
                                <button
                                    key={index}
                                    className={`eventSummaryBtn ${activeTabState === el.id ? "active" : "inActive"
                                        }`}
                                    onClick={() => handleTabChange(el.id)}
                                >
                                    <img
                                        key={index}
                                        loading="lazy"
                                        src={
                                            el.name === "Door Openings"
                                                ? DoorOpenings
                                                : el.name === "Defrost Cycle"
                                                    ? DefrostCycle
                                                    : Compressor
                                        }
                                    />
                                    {el.name}
                                </button>
                            );
                        })}
                        {eventSummaryTabChangeLoader ? (
                            <div
                                style={{
                                    height: "35vh",
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <LoaderStyle />
                            </div>
                        ) : (
                            <AnoviEventSummary
                                data={eventTabChangeResponseRef.current}
                                type={activeTabState}
                            />
                        )}
                    </>
                }</>,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'EVENT_SUMMARY_V1',
            settings: false,
            filter: undefined,
            headerLabel: 'Event Summary',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <>
                {complianceSummaryData === "loading" ? (
                    <div
                        style={{
                            height: "35vh",
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <LoaderStyle />
                    </div>
                ) : complianceSummaryData === "error" ? (
                    <DisplayError type={"err_1"} />
                ) : typeof complianceSummaryData === "number" ? (
                    <DisplayError type={"err-500/404"} />
                ) : R.isEmpty(complianceSummaryData?.complianceSummary) ? (
                    <DisplayError type={"err-empty"} />
                ) : (
                    <>
                        <CustDoughnut
                            right={rightOpen}
                            data={complianceSummaryData}
                        />
                        <h4 className="facility_name">Facility-wise Compliance</h4>
                        <StackedBarAnovi data={complianceSummaryData} />
                        <StackedHorizontalBar />
                    </>
                )}

                {complianceSummaryDataTwo === "loading" ? (
                    <div className="loaderStyle_div">
                        <LoaderStyle />
                    </div>
                ) : complianceSummaryDataTwo === "error" ? (
                    <DisplayError type={"err_1"} />
                ) : typeof complianceSummaryDataTwo === "number" ? (
                    <DisplayError type={"err-500/404"} />
                ) : R.isEmpty(complianceSummaryDataTwo?.complianceSummary) ? (
                    <DisplayError type={"err-empty"} />
                ) : (
                    <AnoviBarchart
                        data={complianceSummaryDataTwo?.complianceSummary}
                    />
                )}
                <button className="dowCust" onClick={() => setPopupState(true)}>
                    <img
                        src={vector}
                        loading="lazy"
                        alt="img"
                        style={{ height: 18, width: 12 }}
                    />
                    Download Reports
                </button>

                <LinkWithIcon
                    onClick={onClick}
                    cardname="Asset Summary"
                    label="View All Assets"
                    link="/asset-list"
                />
            </>,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'COMPLIANCE_SMRY',
            settings: false,
            filter: undefined,
            headerLabel: 'Compliance Summary',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <> {assetSummaryLoader ? (
                <div
                    style={{
                        height: "40vh",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <LoaderStyle />
                </div>
            ) : assetSummaryAnovi === "error" ? (
                <DisplayError type="error" />
            ) : typeof assetSummaryAnovi === "number" ? (
                <DisplayError type="err-500/404" />
            ) : typeof assetSummaryAnovi === "object" &&
                R.isEmpty(assetSummaryAnovi) ? (
                <DisplayError type="err-empty" />
            ) : (
                <>
                    <DonutChartComp
                        data={assetSummaryAnovi}
                        activeCount={""}
                        activeLabel={""}
                        inActiveCount={""}
                        inActiveLabel={""}
                        activeStatus={""}
                        inactiveStatus={""}
                        actContext={""}
                        inactContext={""}
                        donutData={{}}
                    />
                    {console.log({ assetSummaryAnovi })}

                    <LinkWithIcon
                        cardname="Asset Summary"
                        label="View All Assets"
                        link="/asset-list"
                        onClick={coursesPage}
                    />
                </>
            )}</>,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'FACILITY_ASSET_SMRY',
            settings: false,
            filter: undefined,
            headerLabel: "Asset Summary",
            footerLabel: "20 assets are inactive for more than 5 days.",
            downloadData: { assetSummaryAnovi },
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <> <AnoviAlertComp /> <LinkWithIcon cardname="Alerts" label="View All Alerts" link="/" onClick={coursesActivity} /></>,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'ALERT_SMRY',
            settings: false,
            filter: undefined,
            headerLabel: 'Alerts',
            footerLabel: '',
            downloadData: anoviAlertsDD,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <EventSummary  showFilter={eventSummaryFilter} hideFilter={handleFilterForEventSummary} />,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'VA_EVENT_SUMMARY',
            settings: false,
            filter: handleFilterForEventSummary,
            headerLabel: 'Event Counts',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <EventDuration  showFilter={eventDurationFilter} hideFilter={handleFilterForEventDuration} />,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'VA_EVENT_DURATION',
            settings: false,
            filter: handleFilterForEventDuration,
            headerLabel: 'Event Duration',
            footerLabel: '',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <>{currTicketData !== null &&
                !R.isNil(currTicketData) &&
                !R.isEmpty(currTicketData) &&
                typeof currTicketData !== "string" ? (
                <>
                    <TicketsTable
                        currGeoDist={currGeoDistTicket}
                        data={currTicketData}
                        onRowClick={onRowClickTicket}
                        onBackPressed={onBackPressedTicket}
                        showBackButton={showBackBtnTicket}
                        breadCrumb={breadCrumbTicket}
                    />
                </>
            ) : currTicketData === null ? (
                <DisplayNote />
            ) : currTicketData === "loading" ? (
                <div
                    style={{
                        height: "40vh",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <LoaderStyle />
                </div>
            ) : currTicketData.slice(0, 3) === "err" ? (
                <DisplayError type={currTicketData} />
            ) : (
                ""
            )}</>,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'TICKET_SUMMARY',
            settings: false,
            filter: undefined,
            headerLabel: 'Tickets',
            footerLabel: 'Run hours are 70% higher than yesterday.',
            downloadData: undefined,
            screenImg: false,
            clickScrrenshot: undefined,
        },
        {
            component: <div ref={targetRef}>
                {/* <TempCompilance filterState={showFilterTab} /> */}
                <TempCompilance filterState={showFilterTab} clickFilterTab={clickFilterTab} />
            </div>,
            description: '',
            sequenceNum: 0,
            componentPortalId: 'TEMP_COMPLIANCE_V1',
            settings: false,
            filter: clickFilterTab,
            headerLabel: "Temperature Compliance",
            footerLabel: '',
            downloadData: undefined,
            screenImg: true,
            clickScrrenshot: clickScrrenshot,
        },
    ]

    const [displayComponentsArr, setDisplayComponentsArr] = useState<any>([]);

    const showErrorMessage = (id: string) => {
        setErrorMessageToastState({
            show: true,
            id: id,
        });
        setTimeout(() => {
            setErrorMessageToastState({
                show: false,
                id: '',
            });
        }, 7000);
    };

    const hideErrorMessage = () => {
        setErrorMessageToastState({
            show: false,
            id: '',
        });
    };

    async function requestPermission() {
       await registerDeviceToFirebase(showErrorMessage);
      }

      const filterPortalPortletId = async () => {
          let dummyPortArr: any = [];
          const localStorageLoginData: any = localStorage.getItem("aoneLoginData");
          let pageIds = JSON.parse(localStorageLoginData);
          pageIds.loginDetails[0].portalDetails.forEach((obj: any) => {
              obj.portlets.forEach((el: any) => {
                  console.log(el.sequenceNum, 'PortletsID')
                  dummyPortArr.push({ portalPortletId: el.portalPortletId, description: el.description, sequenceNum: el.sequenceNum })
              })
          })
          setPortlets(dummyPortArr);
      };

    const isLoadingRef = useRef<boolean>(false);

    const fetchUserSpecificData = async () => {
        try {
            isLoadingRef.current = true;
                const response = await getUserSpecificData();
                filterPortalPortletId();
            } catch (e) {
                isLoadingRef.current = false;
            } finally {
                isLoadingRef.current = false;
            }
    };

    useEffect(() => {
        requestPermission();
        fetchUserSpecificData();
    }, [])


    useEffect(() => {
        if (portlets.length !== 0) {
            const filteredArrWithSeqNo: any = [];
            componentsArray.forEach((element: any) => {
                const tempObj = portlets.find((obj: any) => obj.portalPortletId === element.componentPortalId);
                if (tempObj !== undefined) {
                    filteredArrWithSeqNo.push({
                        ...element,
                        sequenceNum: tempObj.sequenceNum,
                        description: tempObj.description,
                    });
                }
            });

            const sortedComponentsArr = filteredArrWithSeqNo.sort((a: any, b: any) => a.sequenceNum - b.sequenceNum);
            setDisplayComponentsArr(sortedComponentsArr);

        }
    }, [tabButtons,eventSummaryTabChangeLoader, eventSummaryLoader, activeTabState, eventTabChangeResponseRef, eventTabChangeResponseRef.current, energyConsumptionFilter, fullSizedComponents, showSettingsPopup, displayNone, portlets, assetSummaryAnovi, assetSummaryLoader, showFilterTab, tempComperatureDataRef.current, showBackBtnTicket, filterLoader, tempStatus, currTicketData, currGeoDistTicket, breadCrumbTicket, eventSummaryFilter, eventDurationFilter]);

    // Enter PortletId's name here which want to render full screen and half screen components.
    const mixedScreenComponents = ['EGRY_BALANCE_V1', 'EGY_CONSUMPTION_V2', 'CONSUMPTION_V1'];
    const onClickMixedSizedComponents = (componentId: any) => {
        if (fullSizedComponents.includes(componentId)) {
            if (componentId == 'EGRY_BALANCE_V1') {
                setDisplayNone(true);
                setTimeout(() => {
                    setDisplayNone(false);
                }, 1000);
            }
            setFullSizedComponents((ele: any) => ele.filter((obj: any) => obj !== componentId));
            return;
        }
        if (componentId == 'EGRY_BALANCE_V1') {
            setDisplayNone(true);
            setTimeout(() => {
                setDisplayNone(false);
            }, 900);
        }
        setFullSizedComponents((ele: any) => [...ele, componentId]);
    }
    const settingsCallBack = (componentId: string) => {
        // alert(componentId);
        if (componentId === 'CONSUMPTION_V1') {
            setShowSettingsPopup(true)
        }
    }

    // Handling Mobile Screens..
    const [isMobile, setIsMobile] = useState(false);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 968); // You can adjust the threshold as needed
        };
        handleResize();
        // Attach resize event listener to update isMobile on window resize
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if(isAssetOpen){
        return <Outlet />;
    }

    if(isLoadingRef.current) {
        return <DashboardShimmer />;
    }

    return (
        <div className="row smb-100">
            {
                errorMessageToastState.show && (
                    <CustomToast message={errorMessageToastState.id} onClose={hideErrorMessage}  />
                )
            }
            {/* All Components will render below */}
            <div className={!rightOpen ? "col-xl-12 col-12 col-12 col-lg-12 col-md-12 d-flex flex-wrap gap-3" : toggleBtn ? "col-12" : "col-12"}>
                {
                    displayComponentsArr.length !== 0 &&
                    // displayComponentsArr.map((component: any) => <CardWrapper settingsCallBack={settingsCallBack} componentId={component.componentPortalId} onClickMixedSizedComponents={onClickMixedSizedComponents} showFullScreenICon={mixedScreenComponents.includes(component.componentPortalId)} key={component.componentPortalId}
                    displayComponentsArr.map((component: any) => <CardWrapper
                        settingsCallBack={settingsCallBack}
                        componentId={component.componentPortalId}
                        onClickMixedSizedComponents={onClickMixedSizedComponents}
                        showFullScreenICon={mixedScreenComponents.includes(component.componentPortalId)}
                        key={component.componentPortalId}
                        width={(isMobile || rightOpen) ? '100%' : (fullSizedComponents.includes(component.componentPortalId) ? '100%' : '49%')}
                        // headerLabel={component.headerLabel} footerLabel={component.footerLabel} infoTip={component.description} themeColors={isDark ? theme.dark.colors : theme.light.colors} typeTwo={true} footerLink={""} settings={component.settings} filter={component.filter} dgData={false} downloadData={component.downloadData} clickScrrenshot={component.clickScrrenshot} screenImg={component.screenImg} energyConsumptionFilter={component.filter} >
                        headerLabel={component.headerLabel}
                        footerLabel={component.footerLabel}
                        infoTip={component.description}
                        themeColors={isDark ? theme.dark.colors : theme.light.colors}
                        typeTwo={true}
                        footerLink={""}
                        settings={component.settings}
                        filter={component.filter}
                        dgData={false}
                        downloadData={component.downloadData}
                        clickScrrenshot={component.clickScrrenshot}
                        screenImg={component.screenImg}
                        energyConsumptionFilter={component.filter} >
                        {component.component}
                    </CardWrapper>)
                }
            </div>
        </div>
    )
}

export default CommonDashboard
