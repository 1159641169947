import React from 'react';
import './DashboardShimmer.scss';
import ShimmerEffectComponent from "../../../a1Components/shimmer/ShimmerEffectComponent";

const DashboardShimmer = () => {
    return (
        <div className='dashboardShimmerParentDiv'>
            {
                ["1", "2"].map((obj: string) => (
                    <div className='dashboardCardShimmerParentDiv' key={obj}>
                        <div className='dashboardCardHeader'>
                            <ShimmerEffectComponent width='30%' height='25px' borderRadius='2px'/>
                            <ShimmerEffectComponent width='20%' height='25px' borderRadius='2px'/>
                        </div>
                        <ShimmerEffectComponent width='100%' height='100%' borderRadius='2px'/>
                    </div>
                ))
            }
        </div>
    )
}

export default DashboardShimmer;
