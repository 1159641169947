import React, {useEffect,} from "react";
import './PumpAssetCard.scss';
import NoDataAvailableIcon from "../../../../assetList/assets/noDataAvailable.svg";
import RightIcon from "../../../assets/RightIcon.svg";
import {AssetData} from "../../../types/HVACSummaryLandingDataType";
import {LiveMeter} from "../../../../assetList/types";
import moment from "moment";
import {navigateAssetDetailPage} from "../../../../../Utils/utils";
import {useNavigate} from "react-router-dom";
import {HVACAssetHooks} from "../../../hooks/HVACAssetHooks";

type Props = {
    data: AssetData;
    showLine: boolean;
}

const PumpAssetCard = (props: Props) => {

    const {
        tripAlarmStatus,
        updateTripAlarmStatus,
    } = HVACAssetHooks();

    const navigate = useNavigate();

    const getCardValue = (id: string) => {
        if (props.data.hasOwnProperty("meterData") && props.data.meterData.hasOwnProperty("liveMeters")) {
            const runStatus = props.data.meterData.liveMeters.find((item: LiveMeter) => item.meterTypeId === id);
            return runStatus === undefined ? '-' : runStatus.description;
        } else {
            return '-';
        }
    }

    useEffect(() => {
        const extractData = () => {
            if (props.data.hasOwnProperty("meterData") && props.data.meterData.hasOwnProperty("liveMeters")) {
                const liveMeter = props.data.meterData.liveMeters.find((item: LiveMeter) => item.meterTypeId === 'EQUIP_TRIP_ST');
                if (liveMeter === undefined) {
                    updateTripAlarmStatus(null);
                } else {
                    updateTripAlarmStatus(liveMeter);
                }
            } else {
                updateTripAlarmStatus(null);
            }
        }
        extractData();
    }, [props.data]);

    return (
        <div className='pumpAssetCardMainCont'>
            <div className='pumpAssetCardMainDiv' onClick={() => navigateAssetDetailPage(props.data.assetId, navigate)}>
                <div className='pumpAssetCardHeaderDiv'>
                    <p className='pumpAssetName'>{props.data.assetName}</p>
                    <p className='pumpAssetStatus'>{getCardValue('EQUIP_STATUS')}</p>
                </div>
                <div className='pumpAssetCardFooter'>
                    <div className='pumpAssetCardFooterDiv'>
                        {
                            tripAlarmStatus === null ? (
                                <div className='noDataAvailableDiv'>
                                    <img src={NoDataAvailableIcon} alt='No Data Available'/>
                                    <p className='noDataAvailableText'>Data unavailable at the moment</p>
                                </div>
                            ) : (
                                <p className='pumpAssetValue'
                                   style={{color: tripAlarmStatus.colorCode === null ? '#000000' : tripAlarmStatus.colorCode}}>
                                    {tripAlarmStatus.description}
                                </p>
                            )
                        }
                        <p className='pumpAssetLastUpdatedAt'>{moment(props.data.lastCommTime).fromNow()}</p>
                    </div>
                    <img src={RightIcon} alt='Icon'/>
                </div>
            </div>
            {
                props.showLine && (
                    <div className='pumpAssetVerticalLine'/>
                )
            }
        </div>
    );
};

export default PumpAssetCard;
