import React,{useEffect, useState} from "react";
import { Modal } from "react-bootstrap";
import ViewPurpose from '../ViewUserIcons/view-purpose.svg';
import IconClose from '../../../../../assets/icons/IconClose.svg';
import DisabledIcon from '../../../../../assets/icons/DisabledIcon.svg';
import IconEdit from '../../../../../assets/icons/IconEdit.svg';
import PlusIcon from '../ViewUserIcons/PlusIcon.svg';
import MinusIcon from '../ViewUserIcons/MinusIcon.svg';
import ViewUserRolesIcon from '../ViewUserIcons/ViewUserRolesIcon.svg';
import Reports from '../ViewUserIcons/Reports.svg';
import './ViewUser.scss';
import { API_BASE_URL_DOWNLOAD } from "../../../../../Constants/constants";
import IfImgNotUpld from '../ViewUserIcons/IfImgNotUpld.svg';
export  const ViewUser=(props)=>{
  const { data,setViewUserPopup,setNewEditUserPopUp} = props;
  const [showEmailTooltip, setShowEmailTooltip] = useState(false);
  const [showPhoneTooltip, setShowPhoneTooltip] = useState(false);
  const [toggleRolesIcon, setToggleRolesIcon] = useState(true);
  const [toggleFacilitiesIcon, setToggleFacilitiesIcon] = useState(false);
  const [toggleFacilityGroupIcon, setToggleFacilityGroupIcon] = useState(false);
  const handleViewUserRolesTab = () => {
    setToggleRolesIcon(!toggleRolesIcon);
  }
  const handleViewUserFacilitiesTab = () => {
    setToggleFacilitiesIcon(!toggleFacilitiesIcon);
  }
  const handleViewUserFacilityGroupsTab = () => {
    setToggleFacilityGroupIcon(!toggleFacilityGroupIcon);
  }
  const[emailPurposes,setEmailPurposes]=useState([]);
  const[phonePurposes,setPhonePurposes]=useState([]);
  useEffect(() => {
    const purposes = data?.emailDetails?.[0]?.purposes;
    if (purposes && purposes.length > 0) {
      const descriptions = purposes.map(purpose => purpose.description);
      setEmailPurposes(descriptions);
    }
  }, [data]);
  useEffect(() => {
    const purposes = data?.phoneDetails?.[0]?.purposes;
    if (purposes && purposes.length > 0) {
      const descriptions = purposes.map(purpose => purpose.description);
      setPhonePurposes(descriptions);
    }
  }, [data]);
     return(
        <Modal
        className="viewUsersPopupModal"
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        show={setViewUserPopup}
        onHide={() => setViewUserPopup(false)}
        centered
        backdrop="static"
      >
        <div className="viewUser">
        <div className="viewUserHeaderContainer">
            <div className="viewUserProfileCont">
              <img className="profileImgCont" src={data?.mediaList.length === 0 ? IfImgNotUpld : `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${data?.mediaList?.[0]?.url}`} alt="icon" />
              <div className="profileDetailsDiv">
                <div className="profileDetails">
                  <div className="userNameTxt">{data?.userBasicDetails?.[0]?.firstName}{data?.userBasicDetails?.[0]?.lastName}</div>
                  <div className="userMailTxt">
                     {data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString ? (
                       <div className="infoContainer">
                         <span className="infoString">{data.emailDetails[0].commonDetails[0].infoString}</span>
                         <img
                           src={ViewPurpose}
                           alt="Purpose icon"
                           onMouseEnter={() => setShowEmailTooltip(true)}
                           onMouseLeave={() => setShowEmailTooltip(false)}
                           className="viewPurposeIcon"
                         />
                       </div>
                     ):('-')}
                   </div>
                   {showEmailTooltip && (
                    <div className="tooltip-content">
                      <ul>
                        {emailPurposes.map((el, index) => (
                          <li key={index}>{el}</li>
                        ))}
                      </ul>
                    </div>
                   )}
                   <div className="userNumberTxt">
                     {  data?.phoneDetails?.[0]?.commonDetails?.contactNumber ?(
                       <div className="infoContainer">
                         <span className="infoString">{data?.phoneDetails?.[0]?.commonDetails?.countryCode}{data?.phoneDetails?.[0]?.commonDetails?.contactNumber}</span>
                         <img
                           src={ViewPurpose}
                           alt="Purpose icon"
                           onMouseEnter={() => setShowPhoneTooltip(true)}
                           onMouseLeave={() => setShowPhoneTooltip(false)}
                           className="viewPurposeIcon"
                         />
                       </div>
                     ):('-')}
                   </div>
                   {showPhoneTooltip && (
                     <div className="tooltip-content">
                       <ul>
                         {phonePurposes.map((el, index) => (
                           <li key={index}>{el}</li>
                         ))}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="userReportsDiv">
              <div className="userReportContainer">
                <div className="reportsHeader"><img src={Reports} alt="icon" /><div className="reportsHeaderName">REPORTS TO</div></div>
                {/* If i get reports to data  from api i will use these commented divs in the future */}
                <div className="reporterName"><div className="reporterNameDiv">{'-'}</div></div>
                {/* <div className="reporterMailTxt"><div className="reporterMailDiv">{'-'}</div></div> */}
              </div>
              {/* <div className="reporterNumber">{'-'}</div> */}
            </div>
            <div className="closeIconDiv" onClick={() => setViewUserPopup(false)}>
              <img src={IconClose} alt="icon" className="viewUserPopCloseIcon" />
            </div>
          </div>
          <div className="viewUserBodyContainer">
            <div className="viewUserAddressCont">
              <div className="userNameDiv">
                <div className="addressHeader">Username</div>
                <div className="addressInfoTxt">{data?.userLoginDetails?.[0]?.userName}</div>
              </div>
              <div className="userNameDiv">
                <div className="addressHeader">Address</div>
                <div className="addressInfoTxt">
                  {(() => {
                    const addressDetails = data?.addressDetails?.[0]?.commonDetails;
                    const addressParts = [
                      addressDetails?.address1,
                      addressDetails?.address2,
                      addressDetails?.city,
                      addressDetails?.postalCode
                    ];
                    return addressParts.filter(Boolean).join(", ");
                  })()}
                </div>
              </div>
            </div>
            <div className="viewUserDetailsCont">
              <div className="viewUserExpandableTabs">
                <div className={`viewUserRolesTab ${toggleRolesIcon ? '' : 'expanded'}`} onClick={handleViewUserRolesTab} >
                  <div className="viewUserRolesSubTab">
                    {toggleRolesIcon ? <img src={MinusIcon} alt="icon" /> : <img src={PlusIcon} alt="icon" />}
                    <>Roles</>
                  </div>
                  {!toggleRolesIcon && data?.roleDetails && (
                    <div className="viewUserRolesTicketInfo">
                      {data?.roleDetails.length === 0 ? (
                        "None"
                      ) : data?.roleDetails.length === 1 ? (
                        data?.roleDetails?.[0]?.description
                      ) : 
                        <>
                          {data?.roleDetails?.[0]?.description}+{data?.roleDetails.length - 1}
                        </>
                      }
                    </div>)}
                </div>
                {toggleRolesIcon && (
                  <div className="viewUserRolesTabContent">
                    {data?.roleDetails?.map((el) => (
                      <div key={el.id} className="viewUserRolesName">
                        <img className="viewUserRolesIcon" src={ViewUserRolesIcon} alt="icon" />
                       <div className="viewUserRolesNameDiv"> <div className="viewUserRolesNameCont">{el.description}</div></div>
                      </div>
                    ))}
                  </div>
                )}
              <div className={`viewUserRolesTab ${toggleFacilitiesIcon ? '' : 'expanded'}`} onClick={handleViewUserFacilitiesTab} >
              <div className="viewUserRolesSubTab">{toggleFacilitiesIcon ? <img src={MinusIcon} alt="icon" /> : <img src={PlusIcon} alt="icon" />}Facilities</div>
                  {!toggleFacilitiesIcon && data?.facilityDetails && (
                    <div className="viewUserRolesTicketInfo">
                      {data?.facilityDetails.length === 0 ? ("None"
                      ) : data?.facilityDetails.length === 1 ? (
                        data.facilityDetails[0].facilityName
                      ) : (
                        <>
                          {data?.facilityDetails[0].facilityName}
                          +{data?.facilityDetails.length - 1}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {toggleFacilitiesIcon && (
                  <div className="viewUserRolesTabContent">    
                    {data?.facilityDetails?.length === 0 ? (
                       <div className="facilityTabNoneContent">
                         No facilies associated with this user
                       </div>
                     ) : (
                       data?.facilityDetails?.map((el, index) => (
                         <div className="facilityTab" key={index}>
                           <div className="viewUserFacility">
                             {el.facilityName}
                             <div className="viewUserFacilityRolesName">
                               <img src={ViewUserRolesIcon} alt="Role icon" />
                               {el.roleTypeDescription}
                             </div>
                           </div>
                         </div>
                       ))
                     )}
                  </div>
                )}
              <div className={`viewUserRolesTab ${toggleFacilityGroupIcon ? '' : 'expanded'}`} onClick={handleViewUserFacilityGroupsTab} >
              <div className="viewUserRolesSubTab">{toggleFacilityGroupIcon ? <img src={MinusIcon} alt="icon" /> : <img src={PlusIcon} alt="icon" />}Facility Groups</div>
                   {!toggleFacilityGroupIcon && data?.facilityDetails && (
                    <div className="viewUserRolesTicketInfo">
                      {data?.facilityGroupDetails.length ===0 ? ("None"
                      ) : data?.facilityGroupDetails.length ===1 ? (
                        data?.facilityGroupDetails?.[0]?.facilityGroupName
                      ) : (
                        <>
                          {data?.facilityGroupDetails?.[0]?.facilityGroupName}+
                          {data?.facilityGroupDetails.length - 1}
                        </>
                      )}
                    </div>
                  )}
                </div>
                {toggleFacilityGroupIcon && (
                  <div className="viewUserRolesTabContent">
                     {data?.facilityGroupDetails?.length === 0 ? (
                       <div className="facilityTabNoneContent">
                         No facility groups associated with this user
                       </div>
                     ) : (
                       data?.facilityGroupDetails?.map((el, index) => (
                         <div className="facilityTab" key={index}>
                           <div className="viewUserFacility">
                             {el.facilityGroupName}
                             <div className="viewUserFacilityRolesName">
                               <img src={ViewUserRolesIcon} alt="Role icon" />
                               {el.roleTypeDescription}
                             </div>
                           </div>
                         </div>
                       ))
                     )}
                   </div>
                 )}
              </div>
            </div>
          </div>
          <div className="viewUserFooterContainer">
            <div className="viewUserStatusCont">
              {data?.userLoginDetails?.[0]?.enabled === "Y" ? (
                <p className="userEnableTxt">enabled</p>
              ) : (
                <div className="userDisabledCont">
                  <img className="disableImg" src={DisabledIcon} alt="icon"/>
                  <p className="userDisabledTxt">disabled {data?.userLoginDetails?.[0]?.successiveFailedLogins}</p>
                </div>
              )}
            </div>
            <div className="viewUserBtnsCont">
              <button
                className="editBtn"
                onClick={() => setNewEditUserPopUp(true)}
              >
                <img src={IconEdit} alt="icon" />
                Edit
              </button>
            </div>
          </div>
          </div>  
      </Modal>
    )
}