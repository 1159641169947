import React, { useEffect } from "react";

import acControllerArrowIcon from "../../acController/assets/acControllerArrowIcon.svg";
import acControllerSuccessIcon from "../../acController/assets/acControllerSuccessIcon.svg";
import {AcControllerSetting, AssetCmd, WeekDaysDataType} from "../types";
import { weekDaysData } from "../../../Constants/constants";
import {getAssetAckCommand, updateAcControllerSettings} from "../service/AcControllerApi";
import { useQuery, useQueryClient } from "react-query";
import moment from "moment";
import {cancelExistingQuery} from "../../../Utils/utils";
import LocalStorageService from "../../../Utils/LocalStorageService";
import useState from "react-usestateref";

type PropTypes = {
  acControllerSetting: AcControllerSetting;
  assetId: string;
  macId: string;
  assetCmdData: AssetCmd;
};

function TimerMode({ acControllerSetting, assetId, macId, assetCmdData }: PropTypes) {
  const queryClient = useQueryClient();
  const [timerModeEnabled, setTimerModeEnabled] = useState<boolean>(false);
  const [isUpdatePermission, setIsUpdatePermission] = useState(false);

  const [selectedTime, setSelectedTime] = useState({
    onTime: "",
    offTime: "",
  });
  const [timeValue, setTimeValue] = useState({
    onTimeHour: "",
    onTimeMin: "",
    offTimeHour: "",
    offTimeMin: "",
  });

  const [weekDays, setWeekDays] = useState<WeekDaysDataType[]>(weekDaysData);
  const [weekDaysInNumber, setWeekDaysInNumber] = useState({
    decimal: 0,
    binary: "",
  });
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isValidTimeRange, setIsValidTimeRange] = useState(true);

  const [isApplyButtonEnabled, setIsApplyButtonEnabled] = useState(false);

  const [apiCallState, setApiCallState, apiCallStateRef] = useState({
    buttonText: "Apply",
    timeRemaining: 15,
    state: "",
  });
  const [currentTimeStamp, setCurrentTimeStamp, currentTimeStampRef] =
      useState(0);
  const [timeoutVal, setTimeoutVal, timeoutValRef] = useState<any>(null);
  const [timeRemainingVal, setTimeRemainingVal, timeRemainingValRef] =
      useState<any>(null);
  const [currentPollingIndex, setCurrentPollingIndex, currentPollingIndexRef] =
      useState(0);

  const acknowledgementTimeData = Number(assetCmdData?.ackTime);
  const defaultPollInterval = 0;
  const acknowledgementTime = assetCmdData?.ack === "Y" ?
      (assetCmdData.hasOwnProperty("ackTime") && !Number.isNaN(acknowledgementTimeData)) ?
          acknowledgementTimeData : defaultPollInterval : defaultPollInterval;
  const pollInterval = 5 * 1000; // 5 seconds

  const { data, error, isLoading, isFetching, refetch, dataUpdatedAt } =
      useQuery(
          "updateAcControllerSettingsForTimer",
          () =>
              updateAcControllerSettings({
                commandsJson: {
                  cmd: assetCmdData.cmdId,
                  deviceId: `'${macId}'`,
                  timerMode: {
                    opMode: false,
                    timerFlag: true,
                    onTimeHour: Number(timeValue.onTimeHour),
                    onTimeMin: Number(timeValue.onTimeMin),
                    offTimeHour: Number(timeValue.offTimeHour),
                    offTimeMin: Number(timeValue.offTimeMin),
                    dayOfWeek: reverseDecimalValue(weekDaysInNumber.decimal),
                  },
                },
                fixedAssetId: assetId,
                topic: assetCmdData.topic,
              }),
          {
            enabled: false,
            keepPreviousData: false,
          }
      );

  const {
    data: ackData,
    error: ackError,
    isLoading: ackIsLoading,
    isFetching: ackIsFetching,
    refetch: ackRefetch,
    dataUpdatedAt: ackDataUpdatedAt,
  } = useQuery(
      "getAssetAckCommandTimerMode",
      () => getAssetAckCommand(macId, assetId, currentTimeStampRef.current),
      {
        enabled: false,
        keepPreviousData: false,
      }
  );

  const reverseDecimalValue = (value: number) => {
    const binary = decimalToBinary(value);
    const binaryRepresentation = binary.split('').reverse().join('');
    return parseInt(binaryRepresentation, 2);
  }

  /**
   * Call the Triggred Api
   */
  const updateSettingInput = async () => {
    cancelExistingQuery("updateAcControllerSettingsForTimer", queryClient);
    const temp = {
      ...apiCallStateRef.current,
      state: "triggredApiLoading",
      timeRemaining: acknowledgementTime,
    };
    setApiCallState(temp);

    const currentTimestamp = moment()
        .valueOf();
    setCurrentTimeStamp(currentTimestamp);

    try {
      await refetch();
    } catch (error) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      console.error("Error re-fetching data:", error);
    }
  };

  const handleArrowClicks = () => {
    setTimerModeEnabled((prev) => !prev);
  };

  const handleDayClick = (value: string) => {
    setWeekDays((prevWeekDays) => {
      return prevWeekDays.map((day) => {
        if (day.key === value) {
          return { ...day, selected: !day.selected };
        } else {
          return day;
        }
      });
    });
  };

  useEffect(() => {
    const binaryRepresentation = weekDays
        .map((day) => (day.selected ? "1" : "0"))
        .join("");

    const decimalValue = parseInt(binaryRepresentation, 2);

    setWeekDaysInNumber({
      decimal: decimalValue,
      binary: binaryRepresentation,
    });
  }, [weekDays]);

  const convertTimeToSeconds = (time: string) => {
    const [hour, minute] = time.split(":");
    return parseInt(hour, 10) * 3600 + parseInt(minute, 10) * 60;
  };

  function decimalToBinary(decimalNumber: number): string {
    let binaryRepresentation = "";
    if (decimalNumber === null) {
      binaryRepresentation = "";
    } else {
      binaryRepresentation = decimalNumber.toString(2);
    }

    // Pad with leading zeros to ensure 7 digits
    while (binaryRepresentation.length < 7) {
      binaryRepresentation = "0" + binaryRepresentation;
    }

    return binaryRepresentation;
  }

  const convertGMTToLocal = (val: any) => {
    const localTime = val;
    const localTimeMoment = moment(localTime, "HH:mm");
    const gmtTimeMoment = localTimeMoment.utcOffset(0, true);
    const adjustedGmtTimeMoment = gmtTimeMoment
        .add(5, "hours")
        .add(30, "minutes");
    const adjustedGmtTime = adjustedGmtTimeMoment.format("HH:mm");

    return adjustedGmtTime;
  };

  const updateUseState = () => {
    const onTime = convertGMTToLocal(
        `${acControllerSetting.data.onTimeHour}:${acControllerSetting.data.onTimeMin}`
    );
    const offTime = convertGMTToLocal(
        `${acControllerSetting.data.offTimeHour}:${acControllerSetting.data.offTimeMin}`
    );
    const timerValue = {
      onTime: onTime,
      offTime: offTime,
    };
    setSelectedTime(timerValue);

    const tempWeekDays = weekDaysData;
    const dayOfWeek = acControllerSetting.data.dayOfWeek;
    const checkDayOfWeek = dayOfWeek === undefined ? 0 : dayOfWeek;
    const binaryValueRepresentation = decimalToBinary(checkDayOfWeek);
    const binaryValue = binaryValueRepresentation.split('').reverse().join('');

    const binaryList = binaryValue.split("");

    tempWeekDays.forEach((item, index) => {
      const binary = binaryList[index];
      if (binary === "1") {
        tempWeekDays[index].selected = true;
      }
    });

    setWeekDaysInNumber({
      binary: binaryValue,
      decimal: checkDayOfWeek,
    });
  };

  const convertLocalToGMT = (val: any) => {
    const localTime = val;
    const localTimeMoment = moment(localTime, "HH:mm");
    const gmtTimeMoment = localTimeMoment.utcOffset(0, true);
    const adjustedGmtTimeMoment = gmtTimeMoment
        .subtract(5, "hours")
        .subtract(30, "minutes");
    const adjustedGmtTime = adjustedGmtTimeMoment.format("HH:mm");

    return adjustedGmtTime;
  };

  const checkSecurityPermission = () => {
    const permissions = LocalStorageService.getSecurityPermissionData();
    const status = permissions?.includes("AST_CTRL_UPDATE");
    setIsUpdatePermission(status);
  }

  /**
   * Fetch the Acknowledment Api
   */
  const fetchAckCommand = async () => {
    if (apiCallStateRef.current.timeRemaining < 5) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      clearPolling();
    } else {
      setCurrentPollingIndex((prev) => prev + 1);
      try {
        await ackRefetch();
      } catch (error) {
        const temp = {
          ...apiCallStateRef.current,
          state: "",
          buttonText: "Retry",
        };
        setApiCallState(temp);
        console.error("Error re-fetching for Ack command:", error);
      }
    }
  };

  const clearPolling = () => {
    setCurrentPollingIndex(0);
    setCurrentTimeStamp(0);
    clearInterval(timeoutValRef.current);
    clearInterval(timeRemainingValRef.current);
    cancelExistingQuery("getAssetAckCommandUiMode", queryClient);
    const temp = {
      ...apiCallStateRef.current,
      timeRemaining: acknowledgementTime,
    };
    setApiCallState(temp);
  };

  const updateRemainingTimeForAck = () => {
    const interval = setInterval(() => {
      const temp = {
        ...apiCallStateRef.current,
        timeRemaining: apiCallStateRef.current.timeRemaining - 1,
      };
      setApiCallState(temp);
    }, 1 * 1000);
    setTimeRemainingVal(interval);
  };

  const poolAckCommand = () => {
    const temp = {
      ...apiCallStateRef.current,
      state: "ackCmdApiLoading",
      timeRemaining: acknowledgementTime,
    };
    setApiCallState(temp);
    updateRemainingTimeForAck();
    fetchAckCommand();
    const interval = setInterval(() => {
      fetchAckCommand();
    }, pollInterval);
    setTimeoutVal(interval);
  };

  useEffect(() => {
    const updateTimeValue = () => {
      const onTime = convertLocalToGMT(selectedTime.onTime);
      const offTime = convertLocalToGMT(selectedTime.offTime);

      const [onHour, onMinute] = onTime.split(":");
      const [offHour, offMinute] = offTime.split(":");
      const temp = {
        onTimeHour: onHour,
        onTimeMin: onMinute,
        offTimeHour: offHour,
        offTimeMin: offMinute,
      };
      setTimeValue(temp);
    };

    const checkForValidTimeRange = () => {
      if (
          selectedTime.onTime !== "" &&
          selectedTime.offTime !== "" &&
          convertTimeToSeconds(selectedTime.onTime) <
          convertTimeToSeconds(selectedTime.offTime)
      ) {
        setIsValidTimeRange(true);
      } else {
        setIsValidTimeRange(false);
      }
    };

    updateTimeValue();
    checkForValidTimeRange();
  }, [selectedTime]);

  useEffect(() => {
    if (
        acControllerSetting !== undefined &&
        acControllerSetting.hasOwnProperty("key") &&
        acControllerSetting.key !== ""
    ) {
      updateUseState();
    }
  }, [acControllerSetting]);

  useEffect(() => {
    if (data !== undefined && data === 200) {
      if(acknowledgementTime === 0){
        const temp = {
          ...apiCallStateRef.current,
          state: "success",
        };
        setApiCallState(temp);
        setTimeout(() => {
          const temp = {
            ...apiCallStateRef.current,
            state: "",
          };
          setApiCallState(temp);
        }, 3000);
      }else {
        poolAckCommand();
      }
    }
  }, [data, dataUpdatedAt]);

  useEffect(() => {
    if (ackData?.triggerAck) {
      clearPolling();
      let triggerAck = ackData?.triggerAck;
      triggerAck = JSON.parse(triggerAck);
      const temp = {
        ...apiCallStateRef.current,
        state: "success",
      };
      setApiCallState(temp);
      setTimeout(() => {
        const temp = {
          ...apiCallStateRef.current,
          state: "",
        };
        setApiCallState(temp);
      }, 3000);
    }
  }, [ackData, dataUpdatedAt]);

  useEffect(() => {
    if(apiCallStateRef.current.timeRemaining === 0) {
      const temp = {
        ...apiCallStateRef.current,
        state: "",
        buttonText: "Retry",
      };
      setApiCallState(temp);
      clearPolling();
    }
  }, [apiCallStateRef.current]);

  useEffect(() => {
    clearInterval(timeoutValRef.current);
    clearInterval(timeRemainingValRef.current);
    checkSecurityPermission();
    cancelExistingQuery("updateAcControllerSettingsForTimer", queryClient);
    return () => {
      clearInterval(timeoutValRef.current);
      clearInterval(timeRemainingValRef.current);
      setShowSuccessMessage(false);
      setIsValidTimeRange(false);
      cancelExistingQuery("updateAcControllerSettingsForTimer", queryClient);
      cancelExistingQuery("getAssetAckCommandTimerMode", queryClient);
      setWeekDays(weekDaysData);
    };
  }, []);

  return (
      <>
        {/* For Timer Mode */}
        <div className="bodyContentMainDiv">
          <div
              className="acControllerHeadingDiv"
              onClick={() => handleArrowClicks()}
          >
            <img
                src={acControllerArrowIcon}
                className={`acControllerArrowIcon ${
                    timerModeEnabled ? "rotate-90" : "rotate-0"
                }`}
            />
            <p className="acControllerHeadingText">Timer Mode</p>
          </div>
          {timerModeEnabled && (
              <>
                <div className="acControllerDataInputDiv">
                  <div className="acControllerDataDiv">
                    <p className="acControllerCategory">On Time</p>
                    <input
                        type="time"
                        className="acControllerInput noClockIcon"
                        value={selectedTime.onTime}
                        onChange={(e) =>
                            setSelectedTime({
                              ...selectedTime,
                              onTime: e.target.value,
                            })
                        }
                        readOnly={!isUpdatePermission}
                    />
                  </div>
                  <div className="acControllerDataDiv">
                    <p className="acControllerCategory">Off Time</p>
                    <input
                        type="time"
                        className="acControllerInput"
                        value={selectedTime.offTime}
                        onChange={(e) => {
                          setIsApplyButtonEnabled(true);
                          setSelectedTime({
                            ...selectedTime,
                            offTime: e.target.value,
                          })
                        }
                        }
                        min={selectedTime.onTime}
                        readOnly={!isUpdatePermission}
                    />
                  </div>
                </div>

                {selectedTime.onTime !== "" &&
                    selectedTime.offTime !== "" &&
                    !isValidTimeRange && (
                        <p className="timeErrorMessage">
                          Off time should be later than the on time
                        </p>
                    )}

                <p className="weekdaysHeading">Repeat</p>

                <div className="weekDaysContainer">
                  {weekDays.map((item, index) => (
                      <div
                          key={JSON.stringify(item)}
                          id={`weekday-${index}`}
                          className="weekDayText"
                          style={{
                            border: `1px solid ${
                                item.selected === true ? "blue" : "rgba(189, 203, 251, 1)"
                            }`,
                            backgroundColor:
                                item.selected === true
                                    ? "rgba(189, 203, 251, 0.5)"
                                    : "transparent",
                            cursor: isUpdatePermission ? "pointer" : "default",
                          }}
                          onClick={() => {
                            if(isUpdatePermission){
                              setIsApplyButtonEnabled(true);
                              handleDayClick(item.key);
                            }}}
                      >
                        {item.name}
                      </div>
                  ))}
                </div>
                {
                    isUpdatePermission && (
                        <>
                          {apiCallStateRef.current.state === "" ? (
                              <button
                                  className="applyButtonDiv"
                                  onClick={updateSettingInput}
                                  disabled={!isValidTimeRange && !isApplyButtonEnabled}
                                  style={{
                                    opacity: isValidTimeRange && isApplyButtonEnabled ? 1 : 0.3,
                                    cursor: isValidTimeRange && isApplyButtonEnabled ? 'pointer' : 'default',
                                  }}
                              >
                                <p className="applyButtonText">
                                  {apiCallStateRef.current.buttonText}
                                </p>
                              </button>
                          ) : apiCallStateRef.current.state === "triggredApiLoading" ||
                          apiCallStateRef.current.state === "ackCmdApiLoading" ? (
                              <div className="loading-container">
                                <div className="loading-spinner"></div>
                                {apiCallStateRef.current.state === "ackCmdApiLoading" &&
                                    apiCallStateRef.current.timeRemaining >= 0 && (
                                        <p className="acknowledgementWaitText">
                                          Awaiting acknowledgement{" "}
                                          {apiCallStateRef.current.timeRemaining}s
                                        </p>
                                    )}
                              </div>
                          ) : apiCallStateRef.current.state === "success" ? (
                              <div className="successDivCont">
                                <img src={acControllerSuccessIcon} alt="success-icon" />
                                <p className="successText"> Success</p>
                              </div>
                          ) : (
                              <></>
                          )}
                        </>
                    )
                }
              </>
          )}
        </div>
      </>
  );
}

export default TimerMode;
