// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken } from "firebase/messaging";
import LocalStorageService from "../Utils/LocalStorageService";
import { v4 as uuidv4 } from "uuid";
import { API_BASE_URL } from "../Constants/constants";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

// Messaging service
export const messaging = getMessaging(app);

export const registerDeviceToFirebase  = async (showErrorMessage) => {
  navigator.serviceWorker.register('/firebase-messaging-sw.js')
      .then((registration) => {
          requestForToken(showErrorMessage);
      })
      .catch((error) => {
        console.error("Service Worker registration failed: ", error);
      });
}

export const requestForToken = async (showErrorMessage) => {
  const permission = await Notification.requestPermission();

  if(showErrorMessage === undefined) {
    return;
  }

  if (permission === "granted") {
    const username = LocalStorageService.getUsername();
    const password = LocalStorageService.getPassword();
    const firebaseToken = LocalStorageService.getFirebaseTokenData();

    const uniqueId = uuidv4();

    if ((username === null && password === null) || firebaseToken !== null) {
      return;
    }

    try {
      const currentToken = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_VAPID_KEY,
      });
      if (currentToken) {
        const url = `${API_BASE_URL}register/notification`;
        const body = {
          userLoginId: username,
          password: password,
          deviceId: uniqueId,
          platform: "hybrid",
          version: "10",
          notificationId: currentToken,
        };

        const resp = await fetch(url, {
          method: "POST",
          body: JSON.stringify(body),
          headers: {
            "Content-Type": "application/json",
          },
        });


        if (resp.status === 200) {
          console.log("FCM token :: ", currentToken);
          LocalStorageService.setFirebaseTokenData(currentToken);
          LocalStorageService.setUniqueIDData(uniqueId);
        }
      } else {
        // Show permission request UI
        console.log(
          "FCM :: No registration token available. Request permission to generate one."
        );
      }
    } catch (err) {
      console.log("FCM :: An error occurred while retrieving token. ", err);
      showErrorMessage('NOTIFICATION_ERROR');
    }
  } else if (permission === "denied") {
    //notifications are blocked
    showErrorMessage('NOTIFICATION_ACCESS_DENIED');
  }
};

export const deregisterDevice = async () => {

  const userName = LocalStorageService.getUsername();
  const passWord = LocalStorageService.getPassword();
  const fcmToken = LocalStorageService.getFirebaseTokenData();

  let uniqueId = LocalStorageService.getUniqueIDData();
  if (fcmToken) {
    const body = {
      userLoginId: userName,
      password: passWord,
      deviceId: uniqueId,
      platform: "hybrid",
      version: "10",
      notificationId: fcmToken
    };

    const url = `${API_BASE_URL}unregister/notification`;

    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    console.log('FCM :: unregister notication response', resp);

    return resp.status;

  } else {
    return 'error';
  }
}
