import {useQuery, useQueryClient} from "react-query";
import axios from 'axios';
import { API_BASE_URL } from "../../../Constants/constants";
import LocalStorageService from '../../../Utils/LocalStorageService';
import React, {  useState} from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const PostData = async (
  payLoadObj: {
    firstName: string;
    lastName: string;
    addressPurpose: string;
    toName: string;
    attnName: string;
    address1: string;
    address2: string;
    city: string;
    stateProvinceGeoId: string;
    postalCode: string;
    countryGeoId: string;
    emailAddress: string;
    emailPurpose: string;
    phonePurpose: string;
    phoneNumberPrefix: string;
    USERNAME: string;
    PASSWORD: string;
    CONFIRM_PASSWORD: string;
    roleTypeIdList: string;
    assignUserFacility: string;
    assignUserFacilityGroup: string;
    updateUser: boolean;
    partyId: string | null;
  },
  images: File | null,
  queryClient: any,
  setNewEditUserPopUp: boolean,
) => {
  const token = LocalStorageService.getToken("@tokenID");
  const url1 = `${API_BASE_URL}ticketing/attachment`;
  const url = `${API_BASE_URL}person/createPerson`;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(payLoadObj),
    });
  const awaitResponse= await response.json()
    if (!response.ok) {
      setNewEditUserPopUp(true);
      console.log("response",awaitResponse)
      payLoadObj.updateUser===true? toast.error("Error"+":"+`${awaitResponse.apiErrors[0]}`):toast.error("Error"+":"+`${awaitResponse.apiErrors[0]}`);
      throw new Error(`Server Error: ${awaitResponse.status} - ${awaitResponse.statusText}`);
    }
    const responseData = await response.json();

    if (responseData.partyId && payLoadObj.updateUser === false && images) {
      const formData = new FormData();
      const tableDetails = {
        isGallery: false,
        enumCode: "PARTY",
        columnNames: [
          { uploadKey: "partyId", uploadValue: responseData.partyId },
          { uploadKey: "partyContentTypeId", uploadValue: "USERDEF" },
        ],
      };

      formData.append("tableDetails", JSON.stringify(tableDetails));
      formData.append("file", images);

      try {
        const attachmentResponse = await axios.post(url1, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        });

        console.log("Attachment created successfully:", attachmentResponse.data);
      } catch (attachmentError) {
        console.error("Error uploading attachments:", attachmentError);
        return { error: attachmentError };
      }
    } else if (payLoadObj.updateUser === true) {
      console.log("Using pre-existing images from the API for update.");
    }
    queryClient.invalidateQueries('PostData');
    return responseData;
  } catch (error) {
    console.error("Error:", error);
    toast.error("An error occurred while processing the request.");
    return { error: error.message };
  }
};

// export const fetchUserList = async (page, searchKey, searchValue,searchKeyValueFilters, pageSize) => {
//   const requestBody = {
//     viewIndex: page,
//     viewSize: pageSize,
//     extraDetails: "true",
//     ...(searchKey && searchValue
//       ? {
//           searchBy: [
//             {
//               searchKey,
//               searchValue: Array.isArray(searchValue) ? searchValue : [searchValue],
//             },
//           ],
//         }
//       : {}),
//   };
//   if (searchKeyValueFilters.length > 0) {
//     requestBody.searchBy = [
//     ...(requestBody.searchBy || []), 
//       ...searchKeyValueFilters,
//     ];
//   }
  
//   const response = await fetch(`${API_BASE_URL}user/getUserList`, {
//     method: "POST",
//     headers: { "Content-Type": "application/json" },
//     body: JSON.stringify(requestBody),
//   });
//   if (!response.ok) {
//     toast.error("Failed to fetch users");
//     throw new Error(`Failed to fetch users: ${response.status}`);
//   }

//   return await response.json();
// };
export const fetchUserList = async (page, searchKey, searchValue, searchKeyValueFilters, pageSize) => {
  const requestBody = {
    viewIndex: page,
    viewSize: pageSize,
    extraDetails: "true",
    ...(searchKey && searchValue
      ? {
          searchBy: [
            {
              searchKey,
              searchValue: Array.isArray(searchValue) ? searchValue : [searchValue],
            },
          ],
        }
      : {}),
  };

  if (searchKeyValueFilters.length > 0) {
    requestBody.searchBy = [
      ...(requestBody.searchBy || []),
      ...searchKeyValueFilters,
    ];
  }

  try {
    const response = await fetch(`${API_BASE_URL}user/getUserList`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(requestBody),
    });
   const awaitResponse=await response.json();
    if (!response.ok) {
      toast.error(`${awaitResponse.apiErrors}`+"Failed to fetch users");
      throw new Error(`Failed to fetch users: ${response.status}`);
    }
    return awaitResponse;
  } catch (error) {
    console.error("Error fetching user list:", error);
    throw error;
  }
};
