import {API_BASE_URL} from "../../../Constants/constants";
import LocalStorageService from "../../../Utils/LocalStorageService";

export const getUserSpecificData = async (condition = 'cache') => {
    try {
        const cache = LocalStorageService.getUserSpecificData();

        if(cache !== null && condition === 'cache'){
            return cache;
        }

        const options = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const url: string = `${API_BASE_URL}/assets/userSpecificDataNew`;

        const resp: Response = await fetch(url, options);

        if (resp.status !== 200) {
            return resp.status;
        }

        const response = await resp.json();

        if (response.hasOwnProperty('data')) {
            const data = response?.data?.all;

            const area = data?.area ? data?.area : [];
            const city = data?.city ? data?.city : [];
            const cluster = data?.cluster ? data?.cluster : [];
            const assetType = data?.assetType ? data?.assetType : [];
            const facility = data?.facility ? data?.facility : [];
            const location = data?.location ? data?.location : [];
            const region = data?.region ? data?.region : [];
            const subLocation = data?.subLocation ? data?.subLocation : [];
            const state = data?.state ? data?.state : [];
            const country = data?.country ? data?.country : [];

            LocalStorageService.setCityData(JSON.stringify(city));
            LocalStorageService.setFacilityData(JSON.stringify(facility));
            LocalStorageService.setClusterData(JSON.stringify(cluster));
            LocalStorageService.setRegionData(JSON.stringify(region));
            LocalStorageService.setAreaData(JSON.stringify(area));
            LocalStorageService.setLocationData(JSON.stringify(location));
            LocalStorageService.setSubLocationData(JSON.stringify(subLocation));
            LocalStorageService.setCountryData(JSON.stringify(country));
            LocalStorageService.setStateData(JSON.stringify(state));

            LocalStorageService.setUserSpecificData(response);

            LocalStorageService.setUserSpecificAssetTypeData(assetType);

            return response;

        } else {
            return 'error';
        }

    } catch (e) {
        return 'error';
    }

};
