import { StylesConfig } from 'react-select';

type OptionType = {
  value: string;
  label: string;
};

export const customStyles: StylesConfig<OptionType> = {
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    width: '273px',
    border: state.isFocused ? '2px solid #007bff' : '2px solid #ced4da',
    boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
    '&:hover': {
      border: '2px solid #007bff',
    },
    margin:'2px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  menuList: (provided) => ({
    ...provided,
    height:'200px',
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
};
export const customStylesCountry: StylesConfig<OptionType> = {
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    width: '320px',
    border: state.isFocused ? '1px solid #769dfe' : '1px solid #bdcbfb',
    borderRadius: '0px',
    boxShadow: state.isFocused ? '0 0 5px rgba(118, 157, 254, 0.5)' : 'none',
    padding: '2px',
  }),
  menu: (provided) => ({
    ...provided,
    width: '320px',
    zIndex: 1050,
  }),
  menuList: (provided) => ({
    ...provided,
    height:'150px',
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
};
export const usersDropDownCustomStyle: StylesConfig<OptionType> = {
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    width: '273px',
    border: "1px solid #d7dbec",
    boxShadow: state.isFocused ? '0 0 5px rgba(0, 123, 255, 0.5)' : 'none',
    '&:hover': {
      border: '2px solid #007bff'
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  input: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '16px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    padding: '8px',
  }),
};