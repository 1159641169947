import {
    AssetData,
    AssetTypeCount,
    HVACAssetData,
    HVACSummaryLandingDataType, PumpAssetData,
    SubCategory
} from "../types/HVACSummaryLandingDataType";
import {LiveMeter} from "../../assetList/types";

export default class HVACSummaryUtils {
    extractHVACCategoryData = (data: HVACSummaryLandingDataType) => {
        const id = ["AHU", "FCU"];
        const assetTypeCount = data.assetTypeCount;
        return assetTypeCount.filter((item: AssetTypeCount) => id.includes(item.fixedAssetTypeId));
    };

    getAssetCount = (data: HVACSummaryLandingDataType, assetCategoryTab: { id: string, name: string }[]) => {
        const assetCategoryTabIdList = assetCategoryTab.map((item) => item.id);
        const assetDataList = data.assetTypeCount.filter(
            (item: AssetTypeCount) =>
                assetCategoryTabIdList.includes(item.fixedAssetTypeId)
        );
        let count = 0;
        assetDataList.forEach((item: AssetTypeCount) => {
            count = count + item.assetIds.length;
        });
        return count;
    };

    extractChillerAssetData = (data: HVACSummaryLandingDataType, id: string) => {
        const chillerAssetData = data.assetTypeCount.find(
            (item: AssetTypeCount) =>
                item.fixedAssetTypeId === id
        );

        if (chillerAssetData === undefined) {
            return [];
        }

        return data.assets.filter((item: AssetData) => chillerAssetData.assetIds.includes(item.assetId));
    };

    extractAssetList = (data: HVACSummaryLandingDataType, selectedTab: string, chillerAssetData: HVACAssetData, pumpAssetData: PumpAssetData, coolingTowerAssetData: HVACAssetData) => {
        // For CHILLER Asset
        const chillerAssetList = this.extractChillerAssetData(data, 'CHILLER');
        const chillerPagination = selectedTab === "CHILLER" ? data.pagination : chillerAssetData.pagination;
        const updatedChillerAssetList = [...chillerAssetData.data, ...chillerAssetList];

        // For PUMP
        const pumpAssetList: PumpAssetData = this.extractPumpAssetData(data, pumpAssetData, selectedTab);

        // For Cooling Tower
        const coolingTowerAssetList = this.extractChillerAssetData(data, 'COOLING_TOWER');
        const coolingTowerPagination = selectedTab === "COOLING_TOWER" ? data.pagination : coolingTowerAssetData?.pagination;
        const updatedCoolingTowerAssetList = coolingTowerAssetData === undefined ? [...coolingTowerAssetList] : [...coolingTowerAssetData?.data, ...coolingTowerAssetList];

        return {
            chiller: {
                data: updatedChillerAssetList,
                pagination: chillerPagination,
            },
            pump: pumpAssetList,
            coolingTower: {
                data: updatedCoolingTowerAssetList,
                pagination: coolingTowerPagination,
            },
            pagination: data.pagination,
        }
    }

    extractPumpAssetData = (data: HVACSummaryLandingDataType, pumpAssetDataObj: PumpAssetData, selectedTab: string) => {
        const pumpAssetData = data.assetTypeCount.find(
            (item: AssetTypeCount) =>
                item.fixedAssetTypeId === "PUMP"
        );

        if (pumpAssetData === undefined) {
            return {
                chilled: {
                    id: '',
                    title: '',
                    data: [],
                },
                condensor: {
                    id: '',
                    title: '',
                    data: [],
                },
                pagination: data.pagination,
            };
        }

        const chilledWaterPumpSubCategory = pumpAssetData.subCategory?.find(
            (item: SubCategory) =>
                item.fixedAssetAssocTypeId === "CHILLER_TO_CHWP"
        );

        const condensorWaterPumpSubCategory = pumpAssetData.subCategory?.find(
            (item: SubCategory) =>
                item.fixedAssetAssocTypeId === "CHILLER_TO_CWP"
        );

        const chilledWaterPumpAssetList = chilledWaterPumpSubCategory === undefined ? [] : data.assets.filter((item: AssetData) => chilledWaterPumpSubCategory.assetIds.includes(item.assetId));

        const condensorWaterPumpAssetList = condensorWaterPumpSubCategory === undefined ? [] : data.assets.filter((item: AssetData) => condensorWaterPumpSubCategory.assetIds.includes(item.assetId));

        const result: PumpAssetData = {
            chilled: {
                id: chilledWaterPumpSubCategory?.fixedAssetAssocTypeId,
                title: chilledWaterPumpSubCategory?.fixedAssetTypeTo,
                data: pumpAssetDataObj === undefined ? [...chilledWaterPumpAssetList] : [...pumpAssetDataObj.chilled.data, ...chilledWaterPumpAssetList],
            },
            condensor: {
                id: condensorWaterPumpSubCategory?.fixedAssetAssocTypeId,
                title: condensorWaterPumpSubCategory?.fixedAssetTypeTo,
                data: pumpAssetDataObj === undefined ? [...condensorWaterPumpAssetList] : [...pumpAssetDataObj.condensor.data, ...condensorWaterPumpAssetList],
            },
            pagination:  selectedTab === "PUMP" ? data.pagination : pumpAssetDataObj?.pagination,
        };

        return result;

    };

    getChillerMetricData = (data: AssetData) => {
        if (data.hasOwnProperty('meterData') && data.meterData.hasOwnProperty('liveMeters')) {
            if (data.meterData.liveMeters !== null) {
                const capacityUtilization = data.meterData.liveMeters
                    .find((item: LiveMeter) => item.meterTypeId === "CAP_UTIL");

                const runStatus = data.meterData.liveMeters
                    .find((item: LiveMeter) => item.meterTypeId === "RUN_STATUS");

                return {
                    capacityUtilization: capacityUtilization === undefined ? null : capacityUtilization,
                    runStatus: runStatus === undefined ? null : runStatus,
                };
            } else {

                return null;
            }
        }
        return null;
    };

};

export const getDescription = (id: string): string => {
    const description = {
        'AHU': 'Air Handling Units',
        'FCU': 'Fan Coil Units'
    };
    return description[id] || '';
}

export function rgbToRgba(color: string, alpha = 1) {
    if (color.startsWith('rgba')) {
        return color.replace(/rgba\((\d+), (\d+), (\d+)(?:, [\d.]+)?\)/, `rgba($1, $2, $3, ${alpha})`);
    } else if (color.startsWith('rgb')) {
        return color.replace('rgb', 'rgba').replace(')', `, ${alpha})`);
    } else {
        return color;
    }
}