import React, { useState, useEffect, useRef } from "react";
import { Modal } from "react-bootstrap";
import './editUser.scss';
import IconClose from "../../../assets/icons/IconClose.svg";
import { API_BASE_URL_DOWNLOAD } from "../../../Constants/constants";
import IfImgNotUpld from '../usersCardContainer/userCard/ViewUserIcons/IfImgNotUpld.svg'
import UseStatics from "../utils/utils";
import Unchecked from '../../../assets/icons/Unchecked.svg';
import Checked from '../../../assets/icons/Checked.svg';
import DeleteIcon from '../usersCardContainer/userCard/ViewUserIcons/DeleteIcon.svg';
import AddIcon from '../../../assets/icons/AddIcon.svg';
import Select from "react-select";
import { useQueryClient } from 'react-query';
import { PostData } from "../service/PostData";
import { customStyles,customStylesCountry } from "./customStylesReactSelect";
import { EditBasicContainer } from "./EditBasicContainer";
import { EditAddressPopUp } from "./EditAddressPopUp";
import DisableUser from "../usersCardContainer/DisableUser/DisableUser";

export const EditUser=(props)=>{
    const { data, setNewEditUserPopUp } = props;
    const [validationMessages, setValidationMessages] = useState({});
    const [showEditAddress, setShowEditAddress] = useState(false);
    const [filteredRoles, setFilteredRoles] = useState<any[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRoles, setSelectedRoles] = useState<any>([]);
    const [combinedFacilityAndRole, setCombinedFacilityAndRole] = useState<{ facilityId: number, roleTypeId: number }[]>([]);
    const [combinedFacilityGroupAndRole, setCombinedFacilityGroupAndRole] = useState<{ facilityGroupId: number, roleTypeId: number }[]>([]);
   const[payLoadDisableUser,setPayLoadDisableUser]=useState([]);
    const {
      facilitiesData,
      facilityGroupsData,
      rolesArr,
      facilityRoleData,
      facilityGroupsRoleData,
    } = UseStatics();
    const [add, setAdd] = useState([
      { facility: "", role: "", selectedOptionsFacility: null, selectedOptionsRole: null },
    ]);
    const [images, setImages] = useState<File | string>(
      data?.mediaList.length === 0 ? { IfImgNotUpld } : `${API_BASE_URL_DOWNLOAD.slice(0,-1)}${data?.mediaList?.[0]?.url}`
    )
    const [openDropdowns, setOpenDropdowns] = useState(add.map(() => ({ facility: false, role: false })));
    const [selectedFacilityIds, setSelectedFacilityIds] = useState<{ [index: number]: string }>({});
    const [selectedRolesIds, setSelectedRolesIds] = useState<number[]>([]);
    const [enabledButton, setEnabledButton] = useState(data?.userLoginDetails?.[0]?.enabled)
    const [enabledButtonBoolean, setEnabledButtonBoolean] = useState(enabledButton === 'Y' ? true : false);
    const [basicInfo, setBasicInfo] = useState({
      firstName: data?.userBasicDetails?.[0]?.firstName,
      lastName: data?.userBasicDetails?.[0]?.lastName,
      email: data?.emailDetails?.[0]?.commonDetails?.[0]?.infoString,
      phoneNumber: data?.phoneDetails?.[0]?.commonDetails?.contactNumber,
      userName: data?.userLoginDetails?.[0]?.userName,
      toName: data?.addressDetails?.[0]?.commonDetails?.[0]?.toName,
      attnName: data?.addressDetails?.[0]?.commonDetails?.[0]?.attnName,
      addressLine1: data?.addressDetails?.[0]?.commonDetails?.[0]?.address1,
      addressLine2: data?.addressDetails?.[0]?.commonDetails?.[0]?.address2,
      city: data?.addressDetails?.[0]?.commonDetails?.[0]?.city,
      postalCode: data?.addressDetails?.[0]?.commonDetails?.[0]?.postalCode,
      teleCode: data?.phoneDetails?.[0]?.commonDetails?.countryCode,
      mediaUrl: data?.mediaList?.[0]?.url,
      enabled: data?.userLoginDetails?.[0]?.enabled,
      selectedAddressData: data?.addressDetails?.[0]?.purposes.map((item: any) => item.contactMechPurposeTypeId) || [],
      stateProvinceGeoId: data?.addressDetails?.[0]?.commonDetails?.[0]?.geoPointId,
      countryGeoId: data?.phoneDetails?.[0]?.countryCode,
      selectedEmailPurposeData: data?.emailDetails?.[0]?.purposes.map((item: any) => item.contactMechPurposeTypeId) || [],
      selectedPhonePurposeData: data?.phoneDetails?.[0]?.purposes.map((item: any) => item.contactMechPurposeTypeId) || [],
      roleDetails:data?.roleDetails?.map((item: any) => item.roleTypeId) || [],
      selectedPhoneNumberPrefix:data?.phoneDetails?.[0]?.countryCode,
      selectedState:data?.addressDetails?.[0]?.commonDetails?.geoPointId,
      selectedCountry:data?.addressDetails?.[0]?.commonDetails?.countryGeoId,
    });
    const [selectedRoleIds, setSelectedRoleIds] = useState<any[]>(basicInfo.roleDetails);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const selectRef = useRef(null);
  const[countryImgUrl,setCountryImgUrl]=useState('');
  const [imgUrl, setImgUrl] = useState(
    countryImgUrl === '' && countryImgUrl
  );
    const [activeTab, setActiveTab] = useState("basic");
    const [shouldValidate, setShouldValidate] = useState(false);
    const requiredFields = ["firstName", "lastName", "email",];
    const [addGroups, setAddGroups] = useState([{ facilityGroup: '', roleFacGrp: '', selectedOptionsFacilityGrps: null, selectedOptionsFacGrpRole: null }]);
    const [openDropdownsFacGrp, setOpenDropdownsFacGrp] = useState(addGroups.map(() => ({ facilityGroup: false, roleFacGrp: false })));
    const [selectedFacilityGroupIds, setSelectedFacilityGroupIds] = useState<any[]>({});
    const [selectedFacilityGroupRoleIds, setSelectedFacilityGroupRoleIds] = useState<any[]>({});
     const handleFacilityGrp = (index, selectedOption) => {
      const updatedAddGroups = [...addGroups];
      updatedAddGroups[index].selectedOptionsFacilityGrps = selectedOption;
      updatedAddGroups[index].facilityGroup = selectedOption?.label || '';
      setAddGroups(updatedAddGroups);
    };
    const handleFacGrpRole = (index, selectedOption) => {
      const updatedAddGroups = [...addGroups];
      updatedAddGroups[index].selectedOptionsFacGrpRole = selectedOption;
      updatedAddGroups[index].roleFacGrp = selectedOption?.label || '';
      setAddGroups(updatedAddGroups);
    };
    const handleAddFacilitiesGroups = () => {
      setAddGroups([...addGroups, { facilityGroup: '', roleFacGrp: '' }]);
      setOpenDropdownsFacGrp([...openDropdownsFacGrp, { facilityGroup: false, roleFacGrp: false }]);
    };
    const handleRemoveFacilitiesGroups = (index: number) => {
      const updatedAddGroups = addGroups.filter((_, i) => i !== index);
      setAddGroups(updatedAddGroups);
  
      const updatedOpenDropdownsFacGrp = openDropdownsFacGrp.filter((_, i) => i !== index);
      setOpenDropdownsFacGrp(updatedOpenDropdownsFacGrp);
  
      const updatedSelectedFacilityGroupRoleIds = Object.fromEntries(
        Object.entries(selectedFacilityGroupRoleIds)
          .filter(([key]) => parseInt(key) !== index)
          .map(([key, value]) => [key > index ? key - 1 : key, value])
      );
      setSelectedFacilityGroupRoleIds(updatedSelectedFacilityGroupRoleIds);
  
      const updatedSelectedFacilityGroupIds = Object.fromEntries(
        Object.entries(selectedFacilityGroupIds)
          .filter(([key]) => parseInt(key) !== index)
          .map(([key, value]) => [key > index ? key - 1 : key, value])
      );
      setSelectedFacilityGroupIds(updatedSelectedFacilityGroupIds);
    };
    const validateForm = () => {
      let isFormValid = true;
      const newValidationMessages = {};
      const shouldPerformValidation = shouldValidate || activeTab === "basic";
  
      if (shouldPerformValidation && activeTab === "basic") {
        for (let field of requiredFields) {
          if (!basicInfo[field]) {
            newValidationMessages[field] = "This field is mandatory.";
            isFormValid = false;
          } else if (validationMessages[field]) {
            newValidationMessages[field] = validationMessages[field];
          }
        }
      }
      setValidationMessages(newValidationMessages);
      if (isFormValid) {
        setShouldValidate(false);
      }
  
      return isFormValid;
    };
    const handleTabClick = (tab: string) => {
      if (tab === activeTab) return;
      setShouldValidate(true);
      if (validateForm()) {
        setActiveTab(tab);
      }
    };
    const handleAddFacilities = () => {
      setAdd([...add, { selectedOptionsFacility: '', selectedOptionsRole: '' }]);
      setOpenDropdowns([...openDropdowns, { facility: false, role: false }]);
    };
    const handleRemoveFacilities = (index: number) => {
      const updatedAdd = add.filter((_, i) => i !== index);
      setAdd(updatedAdd);
      const updatedOpenDropdowns = openDropdowns.filter((_, i) => i !== index);
      setOpenDropdowns(updatedOpenDropdowns);
      const updatedSelectedRoleIds = Object.fromEntries(
        Object.entries(selectedRoleIds)
          .filter(([key]) => parseInt(key) !== index)
          .map(([key, value]) => [key > index ? key - 1 : key, value])
      );
      setSelectedRoleIds(updatedSelectedRoleIds);
  
      const updatedSelectedFacilityIds = Object.fromEntries(
        Object.entries(selectedFacilityIds)
          .filter(([key]) => parseInt(key) !== index)
          .map(([key, value]) => [key > index ? key - 1 : key, value])
      );
      setSelectedFacilityIds(updatedSelectedFacilityIds);
    };
    const handleFacility = (index, selectedOption) => {
      const updatedAdd = [...add];
      updatedAdd[index].selectedOptionsFacility = selectedOption;
      updatedAdd[index].facility = selectedOption?.label || '';
      setAdd(updatedAdd);
    };
    const handleRole = (index, selectedOption) => {
      const updatedAdd = [...add];
      updatedAdd[index].selectedOptionsRole = selectedOption;
      updatedAdd[index].role = selectedOption?.label || '';
      setAdd(updatedAdd);
    };
    const filterRoles = (role) => {
      const { id, name } = role;
  
      if (selectedRolesIds.includes(id)) {
        setSelectedRoles((prevSelected) =>
          prevSelected.filter((selectedRole) => selectedRole.id !== id)
        );
        setSelectedRolesIds((prevSelected) =>
          prevSelected.filter((roleId) => roleId !== id)
        );
      } else {
        setSelectedRoles((prevSelected) => [...prevSelected, { id, name }]);
        setSelectedRolesIds((prevSelected) => [...prevSelected, id]);
      }
    };
    const handleNextClick = () => {
      setShouldValidate(true);
      if (validateForm()) {
        const nextTab =
          activeTab === "basic" ? "roles" :
            activeTab === "roles" ? "facilities" :
              activeTab === "facilities" ? "groups" : "";
  
        if (nextTab) {
          setActiveTab(nextTab);
        }
      }
    };
    const queryClient = useQueryClient();
    const handleUpdate = async (e) => {
      e.preventDefault();
      const payLoadObj = {
        firstName: data?.userBasicDetails?.[0]?.firstName || basicInfo.firstName,
        lastName: data?.userBasicDetails?.[0]?.lastName || basicInfo.lastName,
        addressPurpose: basicInfo.selectedAddressData,
        toName: data?.addressDetails?.[0]?.commonDetails?.[0]?.toName || basicInfo.toName,
        attnName: data?.addressDetails?.[0]?.commonDetails?.[0]?.attnName || basicInfo.attnName,
        address1: data?.addressDetails?.[0]?.commonDetails?.[0]?.address1 || basicInfo.addressLine1,
        address2: basicInfo.addressLine2,
        city: data?.addressDetails?.[0]?.commonDetails?.[0]?.city || basicInfo.city,
        stateProvinceGeoId: basicInfo.selectedState?.value,
        postalCode: data?.addressDetails?.[0]?.commonDetails?.[0]?.postalCode || basicInfo.postalCode,
        countryGeoId: basicInfo.selectedCountry?.value,
        emailAddress: basicInfo.email,
        emailPurpose: basicInfo.selectedEmailPurposeData,
        phonePurpose: basicInfo.selectedPhonePurposeData,
        phoneNumberPrefix: basicInfo.selectedPhoneNumberPrefix,
        phoneNumber: basicInfo.phoneNumber,
        USERNAME: basicInfo.userName,
        roleTypeIdList: selectedRolesIds,
        assignUserFacility: combinedFacilityAndRole,
        assignUserFacilityGroup: combinedFacilityGroupAndRole,
        updateUser: true, 
        partyId: data?.userLoginDetails?.[0]?.partyId,
      };
    await PostData(payLoadObj, images, queryClient,setNewEditUserPopUp);
    };
    useEffect(() => {
      setFilteredRoles(
        rolesArr.filter((role) => {
          if (role && role.name) {
            return role.name.toLowerCase().includes(searchTerm.toLowerCase());
          }
        })
      );
    }, [rolesArr, searchTerm]);
    useEffect(() => {
      const combined = add
          .map((item) => ({
              facilityId: item.selectedOptionsFacility ? item.selectedOptionsFacility.value : null,
              roleTypeId: item.selectedOptionsRole ? item.selectedOptionsRole.value : null,
          }))
          .filter(
              (item) => item.facilityId !== null && item.roleTypeId !== null
          );
  
      setCombinedFacilityAndRole(combined);
  }, [add]);
  
  useEffect(() => {
      const combinedGroups = addGroups
          .map((item) => ({
              facilityGroupId: item.selectedOptionsFacilityGrps ? item.selectedOptionsFacilityGrps.value : null,
              roleTypeId: item.selectedOptionsFacGrpRole ? item.selectedOptionsFacGrpRole.value : null,
          }))
          .filter(
              (item) => item.facilityGroupId !== null && item.roleTypeId !== null
          );
  
      setCombinedFacilityGroupAndRole(combinedGroups);
  }, [addGroups]);
  
    useEffect(() => {
      if (data && data.facilityDetails?.length > 0) {
          const defaultValues = data.facilityDetails.map((facilityDetail) => ({
              facility: facilityDetail.facilityName,
              role: facilityDetail.roleTypeDescription,
              selectedOptionsFacility: {
                  value: facilityDetail.facilityId,
                  label: facilityDetail.facilityName,
              },
              selectedOptionsRole: {
                  value: facilityDetail.roleTypeId,
                  label: facilityDetail.roleTypeDescription,
              }
          }));
  
          setAdd(defaultValues);
      }
  }, [data]);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (selectRef.current && !selectRef.current.contains(event.target)) {
          setIsDropdownOpen(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [selectRef]);
    const handleEnabledLogic = () => {
      setEnabledButtonBoolean(!enabledButtonBoolean);
      setEnabledButton(enabledButtonBoolean === true ? 'N' : 'Y');
    }
    useEffect(()=>{
      setBasicInfo(basicInfo)
    },[basicInfo])
    return(
      <>
 <Modal
        show={setNewEditUserPopUp}
        onHide={() => setNewEditUserPopUp(false)}
        centered
        size="lg"
        backdrop="static"
        className="AssignUserFacilityPopupModals"
      >
        <div className="EditUserPopUp">
          <div className="EditUserHeader">
            <p className="EditUserHeaderContent">Edit User</p>
            <div className="EditUserHeaderClose" onClick={() => setNewEditUserPopUp(false)}> <img  src={IconClose} alt="icon"/></div>
          </div>
          <div className="editUserHeaderNav">
          {[{ label: 'Basic Information', tabId: 'basic' }, { label: 'Roles', tabId: 'roles' }, { label: 'Facilities', tabId: 'facilities' }, { label: 'Facility Groups', tabId: 'groups' }].map((el) => (
            <p
              key={el.tabId}
              className={activeTab === el.tabId ? "activeditUserNavTxt" : "editUserNavTxt"}
              onClick={() => handleTabClick(el.tabId)}
            >
              {el.label}
            </p>
          ))}
          </div>
          {activeTab === "basic" && <EditBasicContainer data={data} basicInfo={basicInfo}  setBasicInfo={setBasicInfo} setNewEditUserPopUp={setNewEditUserPopUp} validationMessages={validationMessages} setValidationMessages={setValidationMessages}  showEditAddress={showEditAddress} setShowEditAddress={setShowEditAddress} setShouldValidate={setShouldValidate}/>}
          {activeTab === "roles" && (
            <div className="rolesContainer">
              <div className="rolesAddSearchCont">
                <input
                  className="searchInput"
                  type="search"
                  placeholder="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className="userRolesCheckBox">
                {filteredRoles.map((el) => (
                  <div className="addressPurposeCont" key={el.id}>
                    {selectedRolesIds.includes(el.id) ? (
                      <img src={Checked} alt="checked" onClick={() => filterRoles(el)} />
                    ) : (
                      <img src={Unchecked}  alt="unchecked"  onClick={() => filterRoles(el)} />
                    )}
                    <div className="rolesName">
                      <p className="textRoles">{el.name}</p>
                    </div>
                  </div>
                ))}

              </div>
            </div>
          )}
          {activeTab === "facilities" && (
            <div className="facilitiesContainer">
              <div className="facilitiesAddBtnCont">
                <button className="facilitiesAddBtn" onClick={handleAddFacilities}>
                  <img src={AddIcon} alt="icon" />
                  Add
                </button>
              </div>
              <div className="facilityRoleDiv">
                <div className="facilityAndRoleHeaders">
                  <p>Facility</p>
                  <p>Role</p>
                </div>
                <div className="facilityContainerDiv">
                  {add.map((el, index) => (
                    <div className="AssignUserFacilityContainer" key={index}>
                      <Select
                        value={el.selectedOptionsFacility}
                        styles={customStyles}
                        options={facilitiesData.map((facility) => ({
                          value: facility.id,
                          label: facility.name,
                        }))}
                        onChange={(selectedOption) => handleFacility(index, selectedOption)}
                        isClearable
                      />
                      <Select
                        value={el.selectedOptionsRole}
                        styles={customStyles}
                        options={facilityRoleData.map((role) => ({
                          value: role.id,
                          label: role.name,
                        }))}
                        isClearable
                        onChange={(selectedOption) => handleRole(index, selectedOption)}
                      />
                      <img
                        src={DeleteIcon}
                        alt="icon"
                        className="deleteIcon"
                        onClick={() => handleRemoveFacilities(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {activeTab === "groups" && (
            <div className="facilitiesContainer">
              <div className="facilitiesAddBtnCont">
                <button className="facilitiesAddBtn" onClick={handleAddFacilitiesGroups}>
                  <img src={AddIcon} alt="icon" />
                  Add
                </button>
              </div>
              <div className="facilityRoleDiv">
                <div className="facilityAndRoleHeaders">
                  <p>Facility Group</p>
                  <p>Role</p>
                </div>
                <div className="facilityContainerDiv">
                  {addGroups.map((el, index) => (
                    <div className="AssignUserFacilityContainer" key={index}>
                      <Select
                        value={el.selectedOptionsFacilityGrps}
                        styles={customStyles}
                        options={facilityGroupsData.map((facility) => ({
                          value: facility.id,
                          label: facility.name,
                        }))}
                        onChange={(selectedOption) => handleFacilityGrp(index, selectedOption)}
                        isClearable
                      />
                      <Select
                        value={el.selectedFacilityGroupRoleIds}
                        styles={customStyles}
                        options={facilityGroupsRoleData.map((role) => ({
                          value: role.id,
                          label: role.name,
                        }))}
                        isClearable
                        onChange={(selectedOption) => handleFacGrpRole(index, selectedOption)}
                      />
                      <img
                        src={DeleteIcon}
                        alt="icon"
                        className="deleteIcon"
                        onClick={() => handleRemoveFacilitiesGroups(index)}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          <div className="footerContainer">
            <div className="toggle-container" onClick={handleEnabledLogic}>
              <div className={`toggle-switch ${enabledButtonBoolean ? 'enabled' : 'disabled'}`} onClick={handleEnabledLogic}>
                <div className="toggle-text">{enabledButtonBoolean ? 'Enabled' : 'Disabled'}</div>
              </div>
            </div>
            <div className="buttonsContainer">
              <button className="userCancelBtn" onClick={() => setNewEditUserPopUp(false)}>
                Cancel
              </button>
              {activeTab !== "groups" ? (
                <button
                  className="userNextBtn"
                  onClick={handleNextClick}
                  style={{ backgroundColor: "blue" }}
                >
                  Next
                </button>
              ) : (
                <>
                <button className="userUpdateBtn" onClick={handleUpdate}>
                  Update User
                </button>
                </>
              )}
            </div>
          </div>
        </div>
      </Modal>
      {showEditAddress && <EditAddressPopUp data={data} showEditAddress={showEditAddress} setShowEditAddress={setShowEditAddress} basicInfo={basicInfo} setBasicInfo={setBasicInfo} validationMessages={validationMessages} setValidationMessages={setValidationMessages} setShouldValidate={setShouldValidate}/>}
      </>
    )
 }