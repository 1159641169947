import React, {useEffect, useRef,} from "react";
import './HVACSummary.scss'
import HVACCategoryComponent from "./hvacCategory/HVACCategoryComponent";
import HVACAssetComponent from "./hvacAsset/HVACAssetComponent";
import HVACSummaryShimmer from "./shimmerEffect/HVACSummaryShimmer";
import {useQuery, useQueryClient} from "react-query";
import {fetchHVACSummary} from "../service/HVACSummaryService";
import {cancelExistingQuery} from "../../../Utils/utils";

export const HVACSummary = () => {
    const queryClient = useQueryClient();
    const queryKey: string = 'HVACSummaryDashboard';

    const responseBodyObj  : React.MutableRefObject<object> = useRef<object>({
        "viewIndex": 1,
        "viewSize": 20,
        "hvacFixedAssetTypeId": "CHILLER",
    });

    const {data, error, isLoading, refetch, isFetching} = useQuery(
        queryKey,
        () => fetchHVACSummary(responseBodyObj.current),
        {
            enabled: false,
            keepPreviousData: false,
        });

    const fetchData = async () => {
        try {
            cancelExistingQuery(queryKey, queryClient);
            await refetch();
        } catch (exception) {
            console.error("Error re-fetching data:", exception);
        }
    };

    useEffect(() => {
        fetchData();
        return (() => {
            cancelExistingQuery(queryKey, queryClient);
        })
    }, []);

    if (isLoading || isFetching || data === undefined) {
        return (
            <div className='hvacSummaryParentMainDiv'>
                <HVACSummaryShimmer/>
            </div>
        )
    }

    return (
        <div className='hvacSummaryParentMainDiv'>
            <HVACCategoryComponent data={data}/>
            <div className='hvacCardWrapperLine'/>
            <HVACAssetComponent data={data}/>
        </div>
    )
};
