import { useEffect, useState } from "react";
import Select from "react-select";
import CloseIcon from "../../assets/icons/IconClose.svg";
import DashboardFilterData from "./DashboardFilterData";
import "./DashboardFilter.scss";

interface Filter {
  placeholder: string;
  options: { value: number; label: string }[];
  onChange: (selectedOption: { value: number; label: string } | null) => void;
}
interface selectedOptions {
  [key: string]: string | null;
  region: string | null;
  city: string | null;
  cluster: string | null;
  facility: string | null;
  area: string | null;
  location: string | null;
  subLocation: string | null;
}
interface selectedElement {
  filterByType: string;
  filterById: string;
}


function DashboardFilter(props: any) {
  const { setCurrentFilterByTypeAndId, setEnergyConsumptionFilter } = props;
  const data: any = DashboardFilterData();
  const [selectedOptions, setSelectedOptions] = useState<selectedOptions>({
    region: null,
    city: null,
    cluster: null,
    facility: null,
    area: null,
    location: null,
    subLocation: null,
  });
  const [filterArr, setFilterArr] = useState<Filter[]>([]);
  const [initialArr, setInitialArr] = useState<Filter[]>([]);
  const [curInd, setCurInd] = useState<number>();

  const [newSelectedElement, setSelectedElement] = useState<selectedElement>({
    filterByType: "",
    filterById: "",
  });

  const hierarchyLevels = ['region', 'city', 'cluster', 'facility', 'area', 'location', 'subLocation'].filter((key) => data[key]?.length > 0);

  // useEffect(() => {
  //   if (newSelectedElement.filterByType === '') {
  //     setCurrentFilterByTypeAndId({
  //       filterByType: "facility",
  //       filterById: "_NA_",
  //     })
  //     return;
  //   }
  //   setCurrentFilterByTypeAndId(newSelectedElement);
  // }, [newSelectedElement]);

  useEffect(() => {
    const initialDataArr = () => {
      const filteredArr = hierarchyLevels.map((element: any) => (
        {
          placeholder: `Select ${toCamelCase(element)}`,
          options: data[element].map((el: any) => ({ value: el.id, label: el.name })),
          onChange: (selectedOption: any) =>
            handleSelection(element, selectedOption)
        }
      ));
      setFilterArr(filteredArr);
      setInitialArr(filteredArr);
    }
    initialDataArr();
  }, [data]);

  useEffect(() => {
    const filterDropdowns = () => {
      let currentParentIds: string[] = [];

      let validIndex: number;
      validIndex = curInd !== undefined ? curInd : -1;

      while (validIndex >= 0 && !selectedOptions[hierarchyLevels[validIndex]]) {
        validIndex--;
      }

      if (validIndex >= 0 && selectedOptions[hierarchyLevels[validIndex]]) {
        currentParentIds = [selectedOptions[hierarchyLevels[validIndex]].value];

      }

      const updatedFilteredArr = filterArr.map((el, index) => {
        if (index > validIndex) {
          const nextElement = hierarchyLevels[index];
          if (!selectedOptions[hierarchyLevels[validIndex]]) {
            return { ...el, options: initialArr[index].options };
          }

          const filteredOptions = data[nextElement].filter((ele: any) => currentParentIds.includes(ele.parentId)).map((ele) => ({ value: ele.id, label: ele.name }));
          currentParentIds = filteredOptions.map((option: any) => option.value);

          return { ...el, options: filteredOptions };
        }
        return { ...el };
      });
      setFilterArr(updatedFilteredArr);
    };
    filterDropdowns();
  }, [selectedOptions]);

  const handleSelection = (element: string, selectedOption: string) => {
    setSelectedOptions((prevOptions: any) => {
      const curSelectedValue=prevOptions[element]?.label || null;
      if(curSelectedValue===selectedOption?.label){
        return prevOptions;
      }
      const updatedOptions = {
        ...prevOptions,
        [element]: selectedOption,
      };
      return clearDownstreamSelections(updatedOptions, element);
    });
    setCurInd(hierarchyLevels.indexOf(element));
  };

  const clearDownstreamSelections = (updatedOptions: any, element: any) => {
    const currentIndex = hierarchyLevels.indexOf(element);
    const clearElements = hierarchyLevels.slice(currentIndex + 1);

    clearElements.forEach((key) => {
      updatedOptions[key] = '';
    });

    let vind = currentIndex;
    while (vind >= 0 && !updatedOptions[hierarchyLevels[vind]]) {
      vind--;
    }
    if (vind >= 0)
      setCurrentFilterByTypeAndId({ filterByType: hierarchyLevels[vind].toLowerCase(), filterById: updatedOptions[hierarchyLevels[vind]].value });
    else {
      setCurrentFilterByTypeAndId({
        filterByType: "_NA_",
        filterById: "_NA_",
      })
    }
    return updatedOptions;
  };

  const toCamelCase = (str: String) => {
    return str
      .split(/[\s-]+/)
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      background: "#fff",
      border: "2px solid #a7b8f4",
      height: "35px",
      width: "145px",
      alignContent: "center",
      cursor: 'pointer',

      '@media (min-width: 400px) and (max-width: 760px)': {
        width: '130px',
        height: '15px'
      },
      '@media (max-width: 400px)': {
        width: '120px',
        height: '25px'
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    menuList: (provided: any) => ({
      ...provided,
      fontSize: '12px',
      maxWidth: '145px',
      "::-webkit-scrollbar": {
        width: "8px",
      },
      '@media (max-width: 720px)': {
        fontSize: '10px',
      },
    }),
    menu: (provided: any) => ({
      ...provided,
      maxWidth: '150%',
      marginTop: '0px',
      '@media (max-width: 720px)': {
        maxWidth: '100%',
      },
    }),
    valueContainer: (provided: any) => ({
      ...provided,
      fontSize: '12px',
      '@media (max-width: 720px)': {
        fontSize: '10px',
      },
    }),
    placeholder: (provided: any) => ({
      ...provided,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'noWrap',
      maxWidth: '90%',
    }),
    option: (provided:any)=>({
      ...provided,
      cursor: "pointer",
    })
  };

  return (
    <div className='dashboardFilterContainer'>
      <div className="dropdownContainer">
        {filterArr.map((el, index) => (
          <Select
            key={index}
            styles={customStyles}
            placeholder={el.placeholder}
            options={el.options}
            onChange={el.onChange}
            isClearable
            value={selectedOptions[hierarchyLevels[index]]}
          />
        ))}
         {<img
          className="closeFilterContainer"
          src={CloseIcon}
          loading="lazy"
          alt="CloseModal"
          onClick={() => setEnergyConsumptionFilter(false)}
        />}
      </div>

    </div>

  );
}

export default DashboardFilter;
